import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, AfterViewInit} from '@angular/core';
import {UploadService} from 'app/services/upload/upload.service';
import {SwalService} from '../../../services/swal.service';

declare var MediaRecorder: any;
declare var window: any;


@Component({
    selector: 'app-record-rtc',
    templateUrl: './record-rtc.component.html',
    styleUrls: ['./record-rtc.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RecordRtcComponent implements OnInit, AfterViewInit {

    @ViewChild('startButton') startButton: ElementRef;
    @ViewChild('startRecButton') startRecButton: ElementRef;
    @ViewChild('stopRecButton') stopRecButton: ElementRef;
    @ViewChild('playButton') playButton: ElementRef;
    @ViewChild('downloadButton') downloadButton: ElementRef;
    @ViewChild('recorded') recordedVideo: ElementRef;
    @ViewChild('localVideo') localVideo: ElementRef;
    @ViewChild('errorMsg') errorMsgElement: ElementRef;
    @ViewChild('gum') gumVideo: ElementRef;


    // @ViewChild('errorMsg') errorMsgElement: ElementRef;
    recordedBlobs: any = [];
    startButtonDisabled = false;
    callButtonDisabled = true;
    isStarted = false;
    hangupButtonDisabled = true;
    downloadButtonDisabled = true;
    playButtonDisabled = true;
    stopRecButtonDisabled = true;
    startTime;
    offerOptions = {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1
    };
    mediaSource: any;
    mediaRecorder: any;
    downloadUrl: any = '';
    cameraStarted = false;
    displayTimer = {
        minutes: 0,
        seconds: 0
    };
    timerId: any;

    constructor(private uploadService: UploadService, private swalService: SwalService) {
    }

    ngOnInit() {
        this.gumVideo.nativeElement.volume = 0;
    }

    ngAfterViewInit() {
        this.gumVideo.nativeElement.volume = 0;
    }

    timer() {
        this.timerId = setTimeout(() => {
            this.updateTime(); // update Model
            this.timer();
        }, 1000);
    }

    updateTime() {
        this.displayTimer.seconds++;
        if (this.displayTimer.seconds === 60) {
            this.displayTimer.seconds = 0;
            this.displayTimer.minutes++;
        }
    }

    handleSuccess(stream) {
        //   recordButton.disabled = false;
        console.log('getUserMedia() got stream:', stream);
        window.stream = stream;
        // this.localStream = stream;
        this.gumVideo.nativeElement.controls = false;
        // const gumVideo = document.querySelector('video#gum');
        this.gumVideo.nativeElement.srcObject = stream;
    }

    async init(constraints) {
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            this.cameraStarted = true;
            this.playButtonDisabled = true;
            this.gumVideo.nativeElement.volume = 0;
            this.handleSuccess(stream);
        } catch (e) {
            console.error('navigator.getUserMedia error:', e);
            let messageInfo = 'You need to have a camera to record a video.';
            let message = 'Seems like camera is not available in your system.';
            if (e.name === 'NotAllowedError') {
                message = 'Permission Required.';
                messageInfo = 'Please allow browser to access camera to record a video.';
            }
            const swalData = {
                title: 'Error',
                message: message,
                info: messageInfo
            };
            this.swalService.error(swalData);
            //  this.errorMsgElement.nativeElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
        }
    }

    async startCamera() {
        const hasEchoCancellation = true;
        const constraints = {
            audio: {
                echoCancellation: {exact: hasEchoCancellation}
            },
            video: {
                width: 1280, height: 720
            }
        };
        console.log('Using media constraints:', constraints);
        await this.init(constraints);
    }

    handleSourceOpen(event) {
        console.log('MediaSource opened');
        const sourceBuffer = this.mediaSource.addSourceBuffer('video/webm; codecs="vp8"');
        console.log('Source buffer: ', sourceBuffer);
    }

    startStopRec() {
        if (this.isStarted) {
            this.stopRec();
            return;
        } else {
            this.startRec();
        }
    }

    startRec() {
        this.recordedBlobs = [];
        this.gumVideo.nativeElement.currentTime = 0;

        let options = {mimeType: 'video/webm;codecs=vp9'};
        //   const errorMsgElement = <HTMLElement> document.querySelector('#errorMsg');
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            console.error(`${options.mimeType} is not Supported`);
            this.errorMsgElement.nativeElement.innerHTML = `${options.mimeType} is not Supported`;
            options = {mimeType: 'video/webm'};
            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                console.error(`${options.mimeType} is not Supported`);
                this.errorMsgElement.nativeElement.innerHTML = `${options.mimeType} is not Supported`;
                options = {mimeType: 'video/webm;codecs=vp8'};
                if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                    console.error(`${options.mimeType} is not Supported`);
                    this.errorMsgElement.nativeElement.innerHTML = `${options.mimeType} is not Supported`;
                    options = {mimeType: ''};
                }
            }
            this.swalService.error({
                message: `${options.mimeType} is not Supported`,
                info: 'Please try with another browser.'
            });
        }
        try {
            this.mediaRecorder = new MediaRecorder(window.stream, options);
        } catch (e) {
            console.error('Exception while creating MediaRecorder:', e);

            this.swalService.error({
                message: 'Exception while creating MediaRecorder',
                info: JSON.stringify(e)
            });
            return;
        }
        this.isStarted = true;
        console.log('Created MediaRecorder', this.mediaRecorder, 'with options', options);
        this.downloadButtonDisabled = true;
        this.playButtonDisabled = true;
        this.mediaRecorder.onstop = (event) => {
            console.log('Recorder stopped: ', event);
        };
        this.mediaRecorder.ondataavailable = this.handleDataAvailable.bind(this);
        this.timer();
        this.mediaRecorder.start(10); // collect 10ms of data
        console.log('MediaRecorder started', this.mediaRecorder);
    }

    stopRec() {
        console.log(this.recordedBlobs);
        clearTimeout(this.timerId);
        this.displayTimer = {
            minutes: 0,
            seconds: 0
        };
        this.stopRecButtonDisabled = true;
        this.playButtonDisabled = false;
        this.downloadButtonDisabled = false;
        this.isStarted = false;
        this.mediaRecorder.stop();
    }

    handleDataAvailable(event) {
        if (event.data && event.data.size > 0) {
            console.log(event.data);
            this.recordedBlobs.push(event.data);
        }
    }

    download() {
        const blob = new Blob(this.recordedBlobs, {type: 'video/webm'});
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // const file = new File([blob], 'file1');
        blob['lastModifiedDate'] = new Date();
        blob['name'] = 'file1';
        const file = <File> blob;
        const formData = new FormData();
        formData.append('file', file, file.name);
        this.uploadService.uploadToS3(formData).subscribe(response => {
            console.log(response);
            const result = response['data'];
            this.downloadUrl = result.shorten_url;
        }, err => {
            console.log(err);
        });
        // a.style.display = 'none';
        // a.href = url;
        // a.download = 'test.webm';
        // document.body.appendChild(a);
        // a.click();
        // setTimeout(() => {
        //     document.body.removeChild(a);
        //     window.URL.revokeObjectURL(url);
        // }, 100);
    }

    playRec() {
        const superBuffer = new Blob(this.recordedBlobs, {type: 'video/webm'});

        this.gumVideo.nativeElement.src = null;
        this.gumVideo.nativeElement.srcObject = null;
        this.gumVideo.nativeElement.src = window.URL.createObjectURL(superBuffer);
        this.gumVideo.nativeElement.controls = true;
        this.gumVideo.nativeElement.volume = 1;
        this.gumVideo.nativeElement.play();
    }
}
