import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {tap, catchError} from 'rxjs/operators';

import {siteConfig} from '../../siteSettings';

@Injectable({
    providedIn: 'root'
})
export class RolesService {
    private _url_base: string = siteConfig.nodeAPIBaseUrl;
    private _url_create_role: string = this._url_base + 'role/create';
    private _url_update_role: string = this._url_base + 'role/update';
    private _url_getAll_roles: string = this._url_base + 'role/list';
    private _url_delete_role: string = this._url_base + 'role/delete';
    private roleObj;


    constructor(private http: HttpClient) {
    }

    getRolePermission(queryParams) {
        let params = new HttpParams();
        params = params.append('roleId', queryParams.roleId);
        params = params.append('parentId', queryParams.parentId);
        params = params.append('navigationType', queryParams.navigationType);
        params = params.append('type', queryParams.type);
        return this.http.get(`${siteConfig.nodeAPIBaseUrl}rback/getPermissions`, {params});
    }

    saveRolePermissions(postData) {
        return this.http.post(`${siteConfig.nodeAPIBaseUrl}rback/savePermissions`, postData);
    }

    savePagePermissions(postData) {
        return this.http.post(`${siteConfig.nodeAPIBaseUrl}rback/save-page-permissions`, postData);
    }

    createRole(postData) {
        return this.http.post(this._url_create_role, postData);
    }

    updateRole(postData) {
        return this.http.put(this._url_update_role, postData);
    }

    getRoles() {
        return this.http.get(this._url_getAll_roles);
    }

    deleteRole(roleId) {
        return this.http.delete(this._url_delete_role + "?roleId=" + roleId);
    }

    setRole(roleObj) {
        this.roleObj = roleObj;
    }

    getRole() {
        return this.roleObj;
    }

    getPageLevelPermission(queryParams) {
        let params = new HttpParams();
        params = params.append('roleId', queryParams.roleId || 0);
        params = params.append('parentId', queryParams.parentId || 0);
        params = params.append('navigationType', queryParams.navigationType || 'OTHER');
        params = params.append('type', queryParams.type || 'OTHER');
        return this.http.get(`${siteConfig.nodeAPIBaseUrl}rback/get-page-level-permissions`, {params});
    }

    getAceDefaultRole() {
        return this.http.get(`${siteConfig.nodeAPIBaseUrl}role/get-default-role-for-ace`);
    }
}
