import {Component, OnInit, ViewChild} from '@angular/core';
import {DxDataGridComponent, DxFormComponent} from 'devextreme-angular';
import {Pulse3Service} from '../../../../services/pulse3/pulse3.service';

@Component({
    selector: 'app-ace-report',
    templateUrl: './ace-report.component.html',
    styleUrls: ['./ace-report.component.scss']
})
export class AceReportComponent implements OnInit {
    showFilters = false;
    @ViewChild('dxAceReportGrid') dxChatReportGrid: DxDataGridComponent;
    @ViewChild('dxFilterForm') dxFilterForm: DxFormComponent;
    aceReportData: any = [];
    filterFormData: any = {};
    dxDateFormat = 'MM-dd-yyyy';
    statuses: any = [
        {
            value: 'All',
            name: 'All'
        },
        {
            value: 'Active',
            name: 'Active'
        },
        {
            value: 'NotActive',
            name: 'Not Active'
        },
        {
            value: 'NeverLoggedin',
            name: 'Never Logged In'
        }
    ];

    periods: any = [
        {
            value: 'TD',
            name: 'Today'
        },
        {
            value: 'YD',
            name: 'Yesterday'
        },
        {
            value: 'TW',
            name: 'This Week'
        },
        {
            value: 'LW',
            name: 'Last Week'
        },
        {
            value: 'TM',
            name: 'This Month'
        },
        {
            value: 'LM',
            name: 'Last Month'
        },
        {
            value: 'TQ',
            name: 'This Quarter'
        },
        {
            value: 'LQ',
            name: 'Last Quarter'
        },
        {
            value: 'TY',
            name: 'This Year'
        },
        {
            value: 'LY',
            name: 'Last Year'
        },
        {
            value: 'YT',
            name: 'Year to date'
        },
    ];

    constructor(private pulse3Service: Pulse3Service) {
    }

    ngOnInit() {
    }

    getACEReport() {
        const params = {
            status: this.filterFormData.frmStatus,
            period: this.filterFormData.frmPeriod
        };
        this.pulse3Service.getAceLogs(params).subscribe((result: any) => {
            this.aceReportData = result.data;
        });
    }

    resetFilterForm(e) {
        this.dxFilterForm.instance.resetValues();
        this.aceReportData = [];
    }

    exportToExcel(e) {
        this.dxChatReportGrid.instance.exportToExcel(false);
    }

}
