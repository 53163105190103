import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {
    startOfDay,
    subDays,
    addDays,
    endOfMonth,
    addHours
} from 'date-fns';
import {siteConfig} from '../../siteSettings';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {

    events: any = [];
    onEventsUpdated: Subject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the defaults
        this.onEventsUpdated = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getEvents({})
            ]).then(
                ([events]: [any]) => {
                    resolve();
                }).catch(err => {
                console.log(err);
                resolve();
            });
        });
    }

    /**
     * Get events
     *
     * @returns {Promise<any>}
     */
    getEvents(queryParams: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let params = new HttpParams();
            if (queryParams.isAdmin) {
                if (queryParams.roleId) {
                    params = params.set('roleId', queryParams.roleId);
                }
                if (queryParams.userId) {
                    params = params.set('userId', queryParams.userId);
                }
            }
            if (queryParams.isRM && queryParams.userId) {
                params = params.set('userId', queryParams.userId);
            }
            this._httpClient.get(`${siteConfig.nodeAPIBaseUrl}events/list`, {params})
                .subscribe((response: any) => {
                    this.events = response['data'];
                    this.onEventsUpdated.next(this.events);
                    resolve(this.events);
                }, reject);
        });
    }

    createEvent(postData): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${siteConfig.nodeAPIBaseUrl}events/create`, postData)
                .subscribe((response: any) => {
                    this.getEvents({});
                }, reject);
        });
    }

    /**
     * Update events
     *
     * @param events
     * @returns {Promise<any>}
     */
    updateEvents(postData): Promise<any> {

        return new Promise((resolve, reject) => {

            this._httpClient.put(`${siteConfig.nodeAPIBaseUrl}events/update`, postData)
                .subscribe((response: any) => {
                    this.getEvents({});
                }, reject);
        });
    }

    deleteEvent(eventId) {
        return new Promise((resolve, reject) => {

            this._httpClient.delete(`${siteConfig.nodeAPIBaseUrl}events/delete/${eventId}`)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getPendingApprovals() {
        const url = `${siteConfig.nodeAPIBaseUrl}events/getPendingApprovals`;
        return this._httpClient.get(url);
    }

    updatePendingApprovals(postData) {
        const url = `${siteConfig.nodeAPIBaseUrl}events/updateApprovals`;
        return this._httpClient.put(url, postData);
    }

    getAllCompanies() {
        const url = `${siteConfig.nodeAPIBaseUrl}events/getCompanies`;
        return this._httpClient.get(url);
    }

    createCompany(postData) {
        const url = `${siteConfig.nodeAPIBaseUrl}events/createCompany`;
        return this._httpClient.post(url, postData);
    }

    createContact(postData) {
        const url = `${siteConfig.nodeAPIBaseUrl}events/createContact`;
        return this._httpClient.post(url, postData);
    }

}
