<div class="page-layout simple fullwidth" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-20" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto">
                    <div class="pulse-main-heading" fxFlex="50">
                        Form Sample
                    </div>
                </div>
                <div class="p-30 pt-15 pb-15 form-wrapper">
                    <form (submit)="onFormSubmit($event)">
                        <div class="form-container mb-20">

                            <dx-form id="form"
                                     [formData]="formData"
                                     [readOnly]="false"
                                     [showColonAfterLabel]="true"
                                     [showValidationSummary]="true"
                                     [colCount]="12"
                                     labelLocation="top"
                                     validationGroup="customerData">
                                <dxi-item itemType="group" [colSpan]="6" [colCount]="1">
                                    <dxi-item itemType="group" [colSpan]="12" [colCount]="2">

                                        <dxi-item dataField="frmFirstName" [colSpan]="6">
                                            <dxo-label text="First Name"></dxo-label>
                                            <dxi-validation-rule
                                                type="required"
                                                message="First Name is required">
                                            </dxi-validation-rule>
                                        </dxi-item>
                                        <dxi-item dataField="frmLastName" [colSpan]="6">
                                            <dxo-label text="Last Name"></dxo-label>
                                            <dxi-validation-rule
                                                type="required"
                                                message="Last Name is required">
                                            </dxi-validation-rule>
                                        </dxi-item>

                                    </dxi-item>
                                    <dxi-item dataField="email" [colSpan]="7">
                                        <dxo-label text="Email"></dxo-label>
                                        <dxi-validation-rule type="email"
                                                             message="Enter valid email"></dxi-validation-rule>
                                        <dxi-validation-rule type="required"
                                                             message="Email is required."></dxi-validation-rule>
                                    </dxi-item>

                                </dxi-item>


                            </dx-form>

                        </div>
                        <dx-button text="Save"
                                   type="success"
                                   [useSubmitBehavior]="true">
                        </dx-button>
                    </form>
                </div>

            </div>
        </div>

        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto">
                    <div class="pulse-main-heading" fxFlex="50">
                        Grid Sample
                    </div>
                </div>

                <div class="p-30 pt-15 pb-15 grid-wrapper">
                    <!-- Refer the below link for datagrid of devextreme -->
                    <!--https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/-->
                    <dx-data-grid [dataSource]="customers" keyExpr="ID">
                        <dxi-column dataField="Name"></dxi-column>
                        <dxi-column dataField="CompanyName"></dxi-column>
                        <dxi-column dataField="City"></dxi-column>
                        <dxi-column dataField="State"></dxi-column>
                    </dx-data-grid>
                </div>
            </div>
        </div>

    </div>
    <!-- container -->

</div>
