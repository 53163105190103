<div *ngIf="previewContent.props.id"  style="padding:10px">
  <!--  -->
  <ul class="list-group">
    <li class="list-group-item">
      Name :
      <span
        [innerHTML]="
          previewContent.props.mailboxName || previewContent.props.filename
            | textFormatter: searchTextGlobal.query
        "
      ></span>
      <!-- <HighLighter searchText={this.props.globalSearchText} text={from_emailAddress_name} /> -->
    </li>
    <li class="list-group-item">
      Email :
      <span
        [innerHTML]="
          previewContent.props.from_emailAddress_address
            | textFormatter: searchTextGlobal.query
        "
      ></span>
      <!--  <HighLighter searchText={this.props.globalSearchText} text={from_emailAddress_address} /> -->
    </li>
    <li class="list-group-item" *ngIf="previewContent.props.subject">
      Subject :
      <span
        [innerHTML]="
          previewContent.props.subject | textFormatter: searchTextGlobal.query
        "
      ></span>
      <!--  <HighLighter searchText={this.props.globalSearchText} text={from_emailAddress_address} /> -->
    </li>
    <li class="list-group-item" *ngIf="previewContent.props.subject">
      Subject :
      <span
        [innerHTML]="
          previewContent.props.subject | textFormatter: searchTextGlobal.query
        "
      ></span>
      <!--  <HighLighter searchText={this.props.globalSearchText} text={from_emailAddress_address} /> -->
    </li>
    <li class="list-group-item">
      Date : {{ previewContent.props.sentDateTime || previewContent.props.uploadDate  | dateFormatter }}
      <!-- <HighLighter searchText={this.props.globalSearchText} text={sentDateTime} /> -->
    </li>
    <li class="list-group-item" *ngIf="previewContent.props.importance">
      Importance :
      <span
        [innerHTML]="
          previewContent.props.importance
            | textFormatter: searchTextGlobal.query
        "
      ></span
      >{{ previewContent.props.importance }}
      <!-- <HighLighter searchText={this.props.globalSearchText} text={sentDateTime} /> -->
    </li>
  </ul>
  <hr class="list-group-item" />
  <div
    class="list-group-item-html"
    [innerHTML]="
      previewContent.props.body_content || previewContent.props.content | textFormatter: searchTextGlobal.query
    "
  ></div>
  <!-- <HighLighter searchText={this.props.globalSearchText} text={subject} /> -->
</div>
