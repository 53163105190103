import {NgModule, Optional, SkipSelf} from '@angular/core';
import {PulseDirectivesModule} from '@pulse/directives/directives';
import {PulseTranslationLoaderService} from '@pulse/services/translation-loader.service';
import {PulseSplashScreenService} from '@pulse/services/splash-screen.service';

@NgModule({
    providers: [
        PulseDirectivesModule,
        PulseTranslationLoaderService,
        PulseSplashScreenService
    ]
})
export class PulseModule {
    constructor(@Optional() @SkipSelf() parentModule: PulseModule) {
        if (parentModule) {
            throw new Error('PulseModule is already loaded. Import it in the AppModule only!');
        }
    }
}
