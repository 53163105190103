import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {SwalService} from './swal.service';
import {LoginService} from './login.service';
import {Router} from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private loginService: LoginService, public router: Router) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = localStorage.getItem('userToken');
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `JWT ${token}`
                }
            });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                 //   console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // redirect to the login route
                    // or show a modal
                    console.log('Unauthorized ERROR', error);
                    this.loginService.logout();
                    this.router.navigate(['login']);

                }
                console.log('API ERROR', error);
                const swalData = {
                    title: 'Error',
                    message: error && error.error.message ? error.error.message : 'Internal server error',
                    info: 'Please <a href="/login">login</a> again and try again.'
                };
                // const swalService = new SwalService();
                //   swalService.error(swalData).then(result => {

                 // });
                return throwError(error);
            }));
    }
}
