import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';

import {siteConfig} from '../siteSettings';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';

@Injectable()
export class LoginService implements Resolve<any> {
    isLoginSubject = new BehaviorSubject<boolean>(LoginService.hasToken());
    isLoggedInObj: Observable<any>;

    constructor(private _http: HttpClient, public jwtHelper: JwtHelperService, private router: Router) {
        this.isLoggedInObj = this.isLoginSubject.asObservable();
    }

    private static hasToken(): boolean {
        return !!localStorage.getItem('userToken');
    }

    // Resolver for non logged in pages
    resolve(route: ActivatedRouteSnapshot) {

        if (this.isAuthenticated()) {
            this.router.navigate(['/dashboard']);
            return false;
        }
        this.logout();
        return true;
    }

    login(data) {
        const url = `${siteConfig.nodeAPIBaseUrl}login`;
        return this._http.post(url, data);
    }

    /**
     * Log out the user then tell all the subscribers about the new status
     */
    logout(): void {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userData');
        this.isLoginSubject.next(false);
    }

    public isAuthenticated(): boolean {

        const token = localStorage.getItem('userToken');

        // Check whether the token is expired and return
        // true or false
        return LoginService.hasToken() && !this.jwtHelper.isTokenExpired(token);
    }
}
