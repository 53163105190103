import {Injectable} from '@angular/core';
import swal from 'sweetalert2';

@Injectable()
export class SwalService {

    private swalButtons = {
        confirmButtonClass: 'pulse-btn pulse-btn-primary',
        cancelButtonClass: 'pulse-btn pulse-btn-normal',
        buttonsStyling: false
    };

    constructor() {
    }
    /**
     *
     * @param swalData
     * @returns {Promise<any> | Promise<module:sweetalert2.SweetAlertResult>}
     */
    success(swalData) {
        const htmlTemplate = SwalService.getHtmlTemplate('success', swalData);
        const defaultData = {
            title: swalData.title || 'Success',
            html: htmlTemplate,
            type: 'success',
            cancelButtonText: 'Close',
            showConfirmButton: false,
            showCancelButton: true,
            ...this.swalButtons
        };
        return swal({...defaultData, ...swalData});
    }
    /**
     *
     * @param swalData: {title, message, info}
     * @returns {Promise<any> | Promise<module:sweetalert2.SweetAlertResult>}
     */
    error(swalData) {
        const htmlTemplate = SwalService.getHtmlTemplate('error', swalData);
        return swal({
            title: swalData.title || 'Error',
            html: htmlTemplate,
            type: 'error',
            cancelButtonText: 'Close',
            showConfirmButton: false,
            showCancelButton: true,
            timer: swalData.timer || null,
            ...this.swalButtons
        });
    }

    /**
     *
     * @param swalData: {title, message, info}
     * @returns {Promise<any> | Promise<module:sweetalert2.SweetAlertResult>}
     */
    info(swalData) {
        const htmlTemplate = SwalService.getHtmlTemplate('info', swalData);
        return swal({
            title: swalData.title || 'Info',
            html: htmlTemplate,
            type: 'info',
            cancelButtonText: 'Close',
            showConfirmButton: false,
            showCancelButton: true,
            ...this.swalButtons
        });
    }

    /**
     *
     * @param swalData: {title, message, info}
     * @returns {Promise<any> | Promise<module:sweetalert2.SweetAlertResult>}
     */
    warn(swalData) {
        const htmlTemplate = SwalService.getHtmlTemplate('warning', swalData);
        return swal({
            title: swalData.title || 'Warning!',
            html: htmlTemplate,
            type: 'warning',
            cancelButtonText: 'Close',
            showConfirmButton: false,
            showCancelButton: true,
            ...this.swalButtons
        });
    }

    /**
     * @param swalData: {title, message, info}
     * @returns {Promise<any> | Promise<module:sweetalert2.SweetAlertResult>}
     *
     */
    prompt(swalData: any) {
        const htmlTemplate = SwalService.getHtmlTemplate('prompt', swalData);
        const defaultData = {
            title: swalData.title || 'Are you sure?',
            html: htmlTemplate,
            type: 'question',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            ...this.swalButtons
        };
        return swal({...defaultData, ...swalData});
    }

    /**
     *
     * @param swalData: {title, message, info}
     * @returns {Promise<module:sweetalert2.SweetAlertResult> | Promise<any>}
     */
    delete(swalData: any) {
        const htmlTemplate = SwalService.getHtmlTemplate('confirm', swalData);

        let confirmBtnTxt = "Delete";
        if( swalData.confirmButtonText !== undefined ){
            confirmBtnTxt = swalData.confirmButtonText;
        }

        return swal({
            title: swalData.title || 'Are you sure?',
            html: htmlTemplate,
            type: 'question',
            cancelButtonText: 'Cancel',
            confirmButtonText: confirmBtnTxt,
            showCancelButton: true,
            ...this.swalButtons
        });
    }

    /**
     *
     * @param type
     * @param swalData
     * @returns {string}
     */
    static getHtmlTemplate(type, swalData) {
        let icon = '';
        switch (type) {
            case 'success' :
                icon = 'icon-success.svg';
                break;
            case 'confirm' :
                icon = 'icon-delete.svg';
                break;
            case 'prompt':
                icon = 'icon-question.svg';
                break;
            case 'warning' :
                icon = 'icon-warn.svg';
                break;
            case 'error':
                icon = 'icon-error.svg';
                break;
            default:
                icon = 'icon-info.svg';
        }
        return `<div class="pulse-swal pulse-swal-${type}">
                    <div class="pulse-swal-icon">
                        <img src="assets/icons/${icon}" />
                    </div>
                    <div class="swal-content-after_icon">
                        <p class="pulse-swal-message">${swalData.message}</p>
                        <p class="pulse-swal-info">${swalData.info}</p>
                    </div>
                </div>`;
    }

}
