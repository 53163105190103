import {Injectable} from '@angular/core';

import {siteConfig} from '../../siteSettings';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UploadService {

    constructor(private _http: HttpClient) {
    }

    uploadToS3(postData) {
        const url = `${siteConfig.nodeAPIBaseUrl}uploads/fileUpload`;
        return this._http.post(url, postData);
    }

    uploadIntroToS3(postData) {
        return this._http.post(`${siteConfig.nodeAPIBaseUrl}uploads/upload-intro`, postData);
    }
}
