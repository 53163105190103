import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {siteConfig} from '../../siteSettings';

@Injectable()
export class Pulse3Service {

    constructor(private _http: HttpClient) {
    }

    getRequestLogs(): Observable<any[]> {
        const url = `${siteConfig.pulse3APIUrl}logs`;
        return this._http.get<any[]>(url);
    }

    getAceLogs(reqParams): Observable<any[]> {
        let params = new HttpParams();
        Object.keys(reqParams).map(key => {
            params = params.append(key, reqParams[key]);
        });
        const url = `${siteConfig.pulse3APIUrl}apps/ace/report`;
        return this._http.get<any[]>(url, {params});
    }
}
