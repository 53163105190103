<div class="page-layout simple fullwidth" pulsePerfectScrollbar>
  <div class="container mt-12">
      <div class="content mat-white-bg mat-elevation-z4 mb-20" fxLayout="column">
          <div class="content-wrapper">
            <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto" fxLayoutAlign="space-between center">
              <div class="pulse-main-heading">
                  Timesheet Update<!-- <span class="badge ng-star-inserted">{{ workerTimeSheetCount }}</span> -->
              </div>
            </div>
              <div class="p-30 pt-15 pb-15 grid-wrapper">
                <div fxLayout="row" style="position: relative;left: 10px;">
                  <div fxFlex="20" fxLayout="column">
                      <div class="dx-field-value" style="width: 95% !important;">
                          <dx-tag-box
                                placeholder="Select Clients"
                                [multiline]="false"
                                [searchEnabled]="true"
                                [showSelectionControls]="true"
                                [maxDisplayedTags]="2" valueExpr="businessEntityId" displayExpr="businessName"
                                [showMultiTagOnly]="false"
                                [items]="vmsClientList"
                                [(value)]="selectedClients" (onValueChanged)="refreshGridForClient($event)">
                            </dx-tag-box>
                      </div>
                  </div>
                  <div fxFlex="20" fxLayout="column">
                      <dx-select-box style="width: 95% !important;padding-left: 12px;" placeholder="Date Range" [dataSource]="selectBoxDataSource" [searchEnabled]="true"  (onValueChanged)="selectDateRange($event)">
                      </dx-select-box>
                  </div>
                  <div fxFlex="15" fxLayout="column">
                      <dx-check-box id="targetElement" text="Skip PDF Downloads" (onValueChanged)="checkBoxToggled($event)">
                      </dx-check-box>
                      <dx-tooltip target="#targetElement" showEvent="dxhoverstart" hideEvent="dxhoverend">
                          <div *dxTemplate="let data of 'content'">
                              <p>To accelerate updates</p>
                          </div>
                      </dx-tooltip>
                  </div>
                  <div fxFlex="5" fxLayout="column">
                      <div class="dx-field-value" style="width: 100%;">
                          <dx-button style="height: 36px !important;" icon="refresh" (onClick)="searchTimesheetUpdate();">
                          </dx-button>
                      </div>
                  </div>
                </div>

                <!-- date picker popup -->
                <dx-popup title="Custom Date Range" [width]="500"
                [height]="200" [(visible)]="isPopupVisible" style="width: 50% !important;height: 200px;">
                    <div *dxTemplate="let data of 'content'">
                        <div fxFlex="50" fxLayout="column">
                          <dx-date-box style="width: 95% !important;padding-left: 10px;" [(ngModel)]="fromDate" displayFormat="yyyy-MM-dd" [min]="minDate" [max]="maxDate" placeholder="Start date">
                          </dx-date-box>
                        </div>
                        <div fxFlex="50" fxLayout="column">
                          <dx-date-box style="width: 95% !important;padding-left: 10px;" [(ngModel)]="toDate" displayFormat="yyyy-MM-dd" [min]="minDate" [max]="maxDate" placeholder="Last date">
                          </dx-date-box>
                        </div>

                      <dx-button text="Done" class="dateButton" (onClick)="buttonClicked(fromDate,toDate)"></dx-button>
                    </div>
                </dx-popup>


              </div>
              <div class="p-30 pt-15 pb-15 grid-wrapper">
                <table #tab id="tab">
                    <tr>
                        <th>Client</th>
                        <th>Total Existing</th>
                        <th>Created</th>
                        <th>Updated</th>
                        <th>Error</th>
                        <th>progress</th>
                    </tr>
                    <tr *ngFor="let k of objectKeys(progressobj)">
                        <td>{{progressobj[k].businessName}}</td>
                        <td>{{progressobj[k].TotalExisting}}</td>
                        <td>{{progressobj[k].Created}}</td>
                        <td>{{progressobj[k].Updated}}</td>
                        <td>{{progressobj[k].Errored}}</td>
                        <td>
                            <div class="progress-wrapper">
                                <round-progress [current]="progressobj[k].Progrss" [stroke]="4" [radius]="25" [max]="100">
                                </round-progress>
                                <div class="text">{{progressobj[k].Progrss | number : '1.1-1'}}</div>
                            </div>
                        </td>
                    </tr>
                  </table>
            </div>

            <!-- Error popup -->
            <dx-popup title="Error" [width]="300"
            [height]="150" [(visible)]="isPopupVisibleError" style="width: 50% !important;height: 150px;">
                <div *dxTemplate="let data of 'content'">
                    <div fxLayout="column" style="    padding-bottom: 15px;text-align: center;">
                        <span>Unable to complete login</span>
                    </div>
                    <div fxLayout="column">
                        <dx-button text="Close" class="dateButton" (onClick)="butCloseClicked()"></dx-button>
                    </div>
                </div>
            </dx-popup>
            </div>
        </div>
  </div>
</div>
