<div id="forms" class="page-layout simple fullwidth _sharecomp" fxLayout="column">
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 " fxLayout="column">
            <div class="content-wrapper" id="sharePost" >
                <div class="page-title-wrapper">
                    <div class="pulse-main-heading">
                        Social Share
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                        <dx-button icon="fa fa-filter" matTooltip="Toggle Filter" class="pulse-btn has-icon"
                                   (onClick)="showFilterRow = !showFilterRow">
                        </dx-button>
                        <a routerLink="/smb-logs" class="pulse-btn pulse-btn-primary has-icon"
                           fxLayout="row" fxLayoutAlign="center"
                           matTooltip="Go to SMB Logs">
                            <mat-icon>arrow_back</mat-icon>
                        </a>
                    </div>

                </div>

                <div class="p-30 pb-15 pt-15 grid-wrapper _socialshare fit-to-screen" pulsePerfectScrollbar>
                    <form (submit)="onFormSubmit($event)" class="add-share-form" #sharePost="ngForm"
                          enctype="multipart/form-data">
                        <div class="grid-block" fxLayout="row" fxLayoutGap="20px">
                            <div class="grid-col" fxFlex="50" fxLayout="column">
                                <div class="box" fxLayout fxLayout="column" fxLayoutAlign="left" fxLayoutGap="10px"
                                     fxLayoutGap.xs="0">
                                    <dx-form id="form"
                                             [(formData)]="frmShareData"
                                             [readOnly]="false"
                                             [showColonAfterLabel]="true"
                                             [showValidationSummary]="true"
                                             [colCount]="12"
                                             labelLocation="top"
                                             validationGroup="frmShareData">
                                        <dxi-item
                                            dataField="frmFacebook_message" cssClass="height-150"
                                            editorType="dxTextArea"
                                            [colSpan]="12"
                                            [editorOptions]="{ placeholder: 'Write Message...' }">
                                            <dxo-label text="LinkedIn"></dxo-label>
                                        </dxi-item>
                                        <dxi-item
                                            itemType="button"
                                            horizontalAlignment="right"
                                            [colSpan]="6"
                                            [buttonOptions]="{icon:'fa fa-angle-double-down',onClick:copyDown}">
                                        </dxi-item>
                                        <dxi-item
                                            itemType="button"
                                            horizontalAlignment="left"
                                            [colSpan]="6"
                                            [buttonOptions]="{icon:'fa fa-angle-double-up',onClick:copyUp}">
                                        </dxi-item>
                                        <dxi-item
                                            dataField="frmTwitter_message" cssClass="height-150"
                                            editorType="dxTextArea"
                                            [colSpan]="12"
                                            [editorOptions]="{ placeholder: 'Write Message...'}">
                                            <dxo-label text="Twitter"></dxo-label>
                                        </dxi-item>
                                    </dx-form>
                                    <div class="dx-field" fxFlex="100">
                                        <i class="material-icons icon-images">add_photo_alternate</i>
                                        <dx-file-uploader
                                            accept="image/*"
                                            uploadMode="useForm"
                                            [(value)]="uplodedDocs"
                                            (change)="handleFileInput($event.target.files)"
                                        >
                                        </dx-file-uploader>
                                    </div>
                                </div>
                            </div>
                            <div class="grid-col" fxFlex="50">
                                <div class="box">
                                    <dx-data-grid [dataSource]="data"
                                                  [showRowLines]="true"
                                                  [showColumnLines]="true"
                                                  [showBorders]="true"
                                                  [allowColumnResizing]="true"
                                                  [height]="'calc(100vh - 230px)'"
                                                  keyExpr="ID">

                                        <dxo-paging [pageSize]="15"></dxo-paging>
                                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[15, 30, 50]" [showInfo]="true"></dxo-pager>

                                        <dxo-filter-row [visible]="showFilterRow"></dxo-filter-row>
                                        <dxi-column dataField="Name" caption="User Name" alignment="left"></dxi-column>

                                        <!-- <dxi-column
                                            dataField="Title"
                                            caption="Position"
                                            alignment="center"
                                            headerCellTemplate="selectAll"> -->
                                        <!-- <dxi-column
                                            caption="Facebook"
                                            [allowSorting]="false"
                                            alignment="center"
                                            dataField="facebook"
                                            cellTemplate="selectSingleFacebook"
                                            headerCellTemplate="selectAllFB">
                                        </dxi-column> -->
                                        <dxi-column
                                            caption="LinkedIn"
                                            alignment="center"
                                            dataField="linkedin"
                                            [width]="60"
                                            cellTemplate="selectSingleLinkedIn"
                                            [allowSorting]="false"
                                            headerCellTemplate="selectAllLI">
                                        </dxi-column>
                                        <dxi-column
                                            caption="Twitter"
                                            alignment="center"
                                            dataField="twitter"
                                            [width]="60"
                                            cellTemplate="selectSingleTwitter"
                                            [allowSorting]="false"
                                            headerCellTemplate="selectAllTW">
                                        </dxi-column>
                                        <!-- </dxi-column> -->

                                        <!-- <div *dxTemplate="let info of 'selectAll'">
                                            <p class="dxo-checkbox">
                                            <dx-check-box
                                                (onOptionChanged)="checkBoxChange($event,info,'all')"
                                                name="all"
                                                text="Select All"
                                                [value]="false">
                                            </dx-check-box>
                                            </p>
                                        </div> -->
                                        <!-- <div *dxTemplate="let info of 'selectAllFB'">
                                            <p class="dxo-checkbox">
                                            <dx-check-box
                                                (onOptionChanged)="checkBoxChange($event,info,'allFacebook')"
                                                name="allFacebook"
                                                text="Select All Facebook"
                                                [value]="false">
                                            </dx-check-box>
                                            <i class="fab fa-fb"></i>
                                        </p>
                                        </div> -->
                                        <div *dxTemplate="let info of 'selectAllTW'">
                                            <div class="dxo-checkbox">
                                                <dx-check-box
                                                    (onOptionChanged)="checkBoxChange($event,info,'allTwitter')"
                                                    name="allTwitter"
                                                    text=" "
                                                    [disabled]="false"
                                                    [value]="false">
                                                </dx-check-box>
                                                <i class="fab fa-twitter s-18"></i>
                                            </div>
                                        </div>
                                        <div *dxTemplate="let info of 'selectAllLI'">
                                            <div class="dxo-checkbox">
                                                <dx-check-box
                                                    (onOptionChanged)="checkBoxChange($event,info,'allLinkedin')"
                                                    name="allLinkedin"
                                                    text=" "
                                                    [value]="false">
                                                </dx-check-box>
                                                <i class="fab fa-linkedin s-18"></i>
                                            </div>
                                        </div>
                                        <!-- <div *dxTemplate="let info of 'selectSingleFacebook'">
                                            <p *ngIf="info.data.facebook" class="dxo-checkbox">
                                            <dx-check-box
                                                (onOptionChanged)="checkboxSingle($event,info,'fb')"
                                                name="single"
                                                text="Select Single"
                                                [value]="info.data.selectFB">
                                            </dx-check-box></p>
                                        </div> -->
                                        <div *dxTemplate="let info of 'selectSingleTwitter'">
                                            <div *ngIf="info.data.twitter" class="dxo-checkbox">
                                                <dx-check-box
                                                    (onOptionChanged)="checkboxSingle($event,info,'tw')"
                                                    name="single"
                                                    [disabled]="false"
                                                    [value]="info.data.selectTW">
                                                </dx-check-box>
                                            </div>
                                        </div>
                                        <div *dxTemplate="let info of 'selectSingleLinkedIn'">
                                            <div *ngIf="info.data.linkedin" class="dxo-checkbox">
                                                <dx-check-box
                                                    (onOptionChanged)="checkboxSingle($event,info,'li')"
                                                    name="single"
                                                    [value]="info.data.selectLI">
                                                </dx-check-box>
                                            </div>
                                        </div>

                                    </dx-data-grid>
                                </div>
                            </div>
                        </div>
                        <div class="my-12" fxLayout="row" fxLayoutAlign="space-between center">
                            <a routerLink="/smb-logs"
                                class="pulse-btn pulse-btn-normal">Cancel
                            </a>
                            <dx-button
                                text="Publish"
                                [useSubmitBehavior]="true"
                                validationGroup="frmShareData"
                                class="pulse-btn pulse-btn-primary">
                            </dx-button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>
