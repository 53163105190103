<!-- SIDENAV HEADER -->
<div class="sidenav-header">
    <!-- CHATS TOOLBAR -->
    <mat-toolbar>

        <!-- TOOLBAR TOP -->
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">

            <!-- USER AVATAR WRAPPER -->
            <div class="avatar-wrapper">

                <!-- USER AVATAR -->
                <img (click)="changeLeftSidenavView('user')"
                     [src]="user.avatar || defaultAvatar"
                     class="mat-avatar avatar"
                     [alt]="user.name"/>
                <!-- / USER AVATAR -->

                <mat-icon class="s-16 status" [ngClass]="user.status"
                          [matMenuTriggerFor]="userStatusMenu"></mat-icon>

                <!-- USER STATUS -->
                <mat-menu id="user-status-menu" #userStatusMenu="matMenu">

                    <button mat-menu-item (click)="setUserStatus('online')">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="s-16 status online"></mat-icon>
                            <span>Online</span>
                        </div>
                    </button>

                    <button mat-menu-item (click)="setUserStatus('away')">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="s-16 status away"></mat-icon>
                            <span>Away</span>
                        </div>
                    </button>

                    <button mat-menu-item (click)="setUserStatus('do-not-disturb')">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="s-16 status do-not-disturb"></mat-icon>
                            <span>Do not disturb</span>
                        </div>
                    </button>

                    <button mat-menu-item (click)="setUserStatus('offline')">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="s-16 status offline"></mat-icon>
                            <span>Offline</span>
                        </div>
                    </button>
                </mat-menu>
                <!-- / USER STATUS -->

            </div>
            <!-- / USER AVATAR -->

            <div>

                <button *ngIf="user.isUser == true" mat-icon-button aria-label="Invite User"
                        matTooltip="Invite Candidate"
                        (click)="enableInvitePopup(0)">
                    <mat-icon>person_add</mat-icon>
                </button>

                <button *ngIf="user.isUser == true" mat-icon-button aria-label="Invite User"
                        matTooltip="Send SMS"
                        (click)="enableSMSPopup(0)">
                    <mat-icon>sms</mat-icon>
                </button>

                <!-- <button mat-icon-button
                        [matMenuTriggerFor]="userMenu"
                        aria-label="more">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #userMenu="matMenu">
                    <button mat-menu-item (click)="changeLeftSidenavView('user')">
                        Profile
                    </button>
                    <button mat-menu-item (click)="logout()">
                        Logout
                    </button>
                </mat-menu> -->
            </div>

        </mat-toolbar-row>
        <!-- / TOOLBAR TOP -->

        <!-- TOOLBAR BOTTOM -->
        <mat-toolbar-row>

            <!-- SEARCH -->
            <div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">

                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="s-16 secondary-text">search</mat-icon>
                    <input [(ngModel)]="searchText" type="text" placeholder="Search or start new chat" fxFlex>
                </div>

            </div>
            <!-- / SEARCH -->

        </mat-toolbar-row>
        <!-- / TOOLBAR BOTTOM -->

    </mat-toolbar>
    <!-- / CHATS TOOLBAR -->

</div>
<!-- / SIDENAV HEADER -->

<!-- SIDENAV CONTENT -->
<div class="sidenav-content" style="background-color: #f5f5f5;" pulsePerfectScrollbar fxFlex>

    <!-- CHATS CONTENT -->
    <div>

        <!-- CHATS LIST-->


        <mat-tab-group>
            <mat-tab label="Chats">

                <ng-template mat-tab-label>
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">
                        <mat-icon class="chat-contact-icon">chat</mat-icon>
                        Chat
                    </div>
                </ng-template>

                <div class="chat-list" fxLayout="column">

                    <button mat-button class="contact"
                            *ngFor="let chat of user.chatList | filter: searchText"
                            (click)="getChat(chat.contactId)" [ngClass]="(isContactActive==chat.contactId)?'active':''">

                        <div fxLayout="row" fxLayoutAlign="start center">

                            <div class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
                                <img [src]="chat.avatar || defaultAvatar" class="mat-avatar avatar"
                                     alt="{{chat.name}}"/>
                                <mat-icon class="s-16 status"
                                          [ngClass]="(chat.contactId==socketID)?socketStatus:chat.status"></mat-icon>
                            </div>

                            <div fxLayout="row" fxFlex>

                                <div class="pr-4" fxFlex fxLayout="column" fxLayoutAlign="center start">
                                    <div class="contact-name">{{chat.name}}</div>
                                    <div class="contact-last-message">{{chat.lastMessage}}</div>
                                </div>

                                <div fxLayout="column" fxLayoutAlign="start end">
                                    <div class="contact-last-message-time">
                                        {{chat.lastMessageTime | date:'short'}}
                                    </div>
                                    <div *ngIf="chat.unread != 0" class="mt-4 unread-message-count accent">
                                        {{ chat.contactId==applyBudgetUserID?budgetCount:chat.unread }}
                                        <!-- {{chat.unread}} -->
                                    </div>
                                    <!-- <div class="mt-4 unread-message-count accent">8</div> -->
                                </div>

                            </div>

                        </div>

                    </button>

                </div>

            </mat-tab>

            <mat-tab label="Contacts">

                <ng-template mat-tab-label>
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">
                        <mat-icon class="chat-contact-icon">contacts</mat-icon>
                        Contacts
                    </div>
                </ng-template>

                <div class="contact-list" fxLayout="column">
                    <button mat-button class="contact"
                            *ngFor="let contact of contacts| filter: searchText"
                            (click)="getChat(contact.user_id)">

                        <div fxLayout="row" fxLayoutAlign="start center">

                            <div class="avatar-wrapper" fxFlex="0 1 auto">
                                <img [src]="contact.avatar || defaultAvatar" class="mat-avatar avatar"
                                     alt="{{contact.name}}"/>
                                <mat-icon class="s-16 status" id="{{contact.user_id}}"
                                          [ngClass]="(contact.user_id==socketID)?socketStatus:contact.status"></mat-icon>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start start">
                                <div class="contact-name">{{contact.name}}</div>
                                <p class="contact-mood">{{contact.mood}}</p>
                            </div>

                        </div>

                    </button>

                </div>

            </mat-tab>
        </mat-tab-group>


        <!-- <div matSubheader *ngIf="(user.chatList | filter: searchText).length > 0">
            Chats
        </div> -->

        <!-- / CHATS LIST-->

        <!-- CONTACTS LIST-->


        <!-- <div matSubheader *ngIf="(contacts | filter: searchText).length > 0">
            Contacts
        </div> -->

        <!-- / CONTACTS LIST-->

        <!-- NO RESULTS MESSAGE -->
        <div *ngIf="(contacts | filter: searchText).length === 0" class="no-results-message">
            No results..
        </div>
        <!-- NO RESULTS MESSAGE-->

    </div>
    <!-- / CHATS CONTENT -->

</div>
<!-- / SIDENAV CONTENT -->


<!-- CLIENT POPUP -->
<dx-popup class="popup" [width]="650" [height]="300" [showTitle]="true" [title]="popupTitle"
          [dragEnabled]="false" [deferRendering]="false"
          [closeOnOutsideClick]="true"
          [(visible)]="invitePopup">
    <div *dxTemplate="let data of 'content'">

        <form class="invite-form" (ngSubmit)="sendInvite($event)">
            <div id="invite-form-container">
                <dx-form #sendInviteDxForm [(formData)]="inviteData" [readOnly]="false"
                         [showValidationSummary]="true"
                         [colCount]="2" labelLocation="top" validationGroup="inviteValidation">
                    <dxi-item itemType="group" [colCount]="12" cssClass="hasButton dxInputGroup">
                        <dxi-item dataField="frmCountryCode" editorType="dxSelectBox" cssClass="pr-0"
                                  [colSpan]="5" [editorOptions]="{
                            dataSource: countryCodes,
                            valueExpr: 'id',
                            displayExpr: 'name'
                        }" [template]="'countryCodeTemplate'">
                            <dxo-label text="Contact #"></dxo-label>
                            <dxi-validation-rule
                                type="required"
                                message="Phone Number is required">
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item dataField="frmContactPhone" [colSpan]="5" cssClass="px-0"
                                  [editorOptions]="{mask:'(000) 000-0000',
                                        useMaskedValue: false,
                                        maskInvalidMessage: 'Please enter a correct number' }">
                            <dxo-label text=" " [showColon]="false"></dxo-label>
                            <dxi-validation-rule
                                type="required"
                                message="Phone Number is required">
                            </dxi-validation-rule>

                        </dxi-item>
                        <dxi-item [colSpan]="2" editorType="dxButton" [editorOptions]="{
                                                          icon: 'search',
                                                          onClick: onContactPhoneChanged
                                                        }">
                        </dxi-item>
                    </dxi-item>
                    <!-- ===================================================================== -->

                    <dxi-item dataField="frmUserFirstName">
                        <dxo-label text="First Name"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="First Name is required">
                        </dxi-validation-rule>
                    </dxi-item>

                    <dxi-item dataField="frmUserLastName">
                        <dxo-label text="Last Name"></dxo-label>
                    </dxi-item>

                    <dxi-item dataField="frmContactEmail">
                        <dxo-label text=" Email"></dxo-label>
                    </dxi-item>
                    <div *dxTemplate="let data of 'countryCodeTemplate'">
                        <dx-select-box [dataSource]="countryCodes" valueExpr="callCode" displayExpr="callCode"
                                       fieldTemplate="field" [searchExpr]="['name', 'alpha2Code', 'callCode']"
                                       (onValueChanged)="onCountryCodeChanged($event)"
                                       [value]="inviteData.frmCountryCode"
                                       [deferRendering]="false" [searchEnabled]="true">
                            <div *dxTemplate="let data2 of 'field'">
                                <div class="custom-item pl-16" fxLayout="row" fxLayoutAlign="start center">

                                    <img width="25" *ngIf="data2?.flag" [src]="data2?.flag"/>
                                    <dx-text-box class="product-name"
                                                 [value]="data2?.callCode"
                                    ></dx-text-box>
                                </div>

                            </div>
                            <div *dxTemplate="let data2 of 'item'">

                                <div class="custom-item" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">

                                    <img width="25px" [src]="data2.flag"/>
                                    <div class="product-name">
                                        <span>({{data2.callCode}}) </span>
                                        <span>{{data2.alpha2Code}}</span>
                                    </div>
                                </div>
                            </div>
                        </dx-select-box>
                    </div>
                    <!-- End -->
                </dx-form>
            </div>

            <div class="mt-20" fxLayout="row" fxLayoutAlign="space-between">
                <dx-button text="Cancel" class="pulse-btn pulse-btn-normal"
                           (onClick)="enableInvitePopup(1)">
                </dx-button>
                <dx-button text="Send" [useSubmitBehavior]="true" validationGroup="inviteValidation"
                           class="pulse-btn pulse-btn-primary">
                </dx-button>

            </div>
        </form>
    </div>
</dx-popup>

<!-- Direct SMS POPUP -->
<dx-popup class="popup" [width]="400" [height]="480" [showTitle]="true" [title]="'Send SMS'"
          [dragEnabled]="false" [deferRendering]="false"
          [closeOnOutsideClick]="false"
          [(visible)]="smsPopup">

    <!--<dx-scroll-view direction="both" >-->

    <mat-tab-group>
        <mat-tab label="Quick SMS">

            <!--<ng-template mat-tab-label>-->
            <!--<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">-->
            <!--<mat-icon class="chat-contact-icon">chat</mat-icon>-->
            <!--Chat-->
            <!--</div>-->
            <!--</ng-template>-->

            <form (ngSubmit)="sendQuickSMS($event)">
                <dx-scroll-view direction="both" class="popup_scroll_container">
                    <dx-form [formData]="sendSMSFormData" [showValidationSummary]="true" #quickSMSForm
                             [showColonAfterLabel]="true" [colCount]="1" labelLocation="top"
                             validationGroup="quickSMSFormValidation">
                        <dxi-item dataField="frmPhoneNumbers" editorType="dxTextArea"
                                  [editorOptions]="{height:'70'}">
                            <dxo-label text="Phone Numbers"></dxo-label>
                            <dxi-validation-rule
                                type="required"
                                message="Phone Numbers are required">
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item dataField="frmTemplate" editorType="dxSelectBox"
                                  [editorOptions]="{
                                            dataSource: templateList,
                                            displayExpr: 'text',
                                            valueExpr: 'value',
                                            onValueChanged: onTemplateChanged
                                                }">
                            <dxo-label text="Template"></dxo-label>
                        </dxi-item>

                        <dxi-item dataField="frmSMSMessage" editorType="dxTextArea"
                                  [editorOptions]="{height:'75'}">
                            <dxo-label text="Message"></dxo-label>
                            <dxi-validation-rule
                                type="required"
                                message="Message is required">
                            </dxi-validation-rule>
                        </dxi-item>
                    </dx-form>
                </dx-scroll-view>

                <div class="mt-20" fxLayout="row" fxLayoutAlign="space-between">
                    <dx-button text="Cancel" class="pulse-btn pulse-btn-normal"
                               (onClick)="smsPopup = false;quickSMSForm.instance.resetValues() ">
                    </dx-button>
                    <dx-button text="Send" [useSubmitBehavior]="true" validationGroup="quickSMSFormValidation"
                               class="pulse-btn pulse-btn-primary">
                    </dx-button>

                </div>

            </form>

        </mat-tab>

        <mat-tab label="Bulk SMS">

            <!--<ng-template mat-tab-label>-->
            <!--<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="50px">-->
            <!--<mat-icon class="chat-contact-icon">contacts</mat-icon>-->
            <!--Contacts-->
            <!--</div>-->
            <!--</ng-template>-->

            Features will come soon!

            <!-- <form (ngSubmit)="sendQuickSMS($event)">
                <dx-form [formData]="sendSMSFormData" [showValidationSummary]="true"
                         [showColonAfterLabel]="true" [colCount]="1" labelLocation="top"
                         validationGroup="quickSMSFormValidation">
                    <dxi-item>
                        <a href="/assets/files/contacts.csv" class="pulse-yellow" target="_blank">Download Template
                            here</a>
                    </dxi-item>
                    <dxi-item>
                        <dx-file-uploader
                            accept="csv"
                            uploadMode="useForm"
                            [(value)]="uploadedCSV"
                            selectButtonText="Select CSV"
                            (change)="handleFileUpload($event)"
                        >
                        </dx-file-uploader>
                    </dxi-item>
                    <dxi-item dataField="frmTemplate" editorType="dxSelectBox"
                              [editorOptions]="{
                                        dataSource: templateList,
                                        displayExpr: 'name',
                                        valueExpr: 'content',
                                        onValueChanged: onTemplateChanged
                                            }">
                        <dxo-label text="Template"></dxo-label>
                    </dxi-item>

                    <dxi-item dataField="frmSMSMessage" editorType="dxTextArea"
                              [editorOptions]="{height:'75'}">
                        <dxo-label text="Message"></dxo-label>
                    </dxi-item>
                </dx-form>
                <div class="mt-20" fxLayout="row" fxLayoutAlign="space-between">
                    <dx-button text="Cancel" class="pulse-btn pulse-btn-normal"
                               (onClick)="sendSMSPopup = false">
                    </dx-button>
                    <dx-button text="Save" [useSubmitBehavior]="true" validationGroup="quickSMSFormValidation"
                               class="pulse-btn pulse-btn-primary">
                    </dx-button>

                </div>
            </form> -->

        </mat-tab>
    </mat-tab-group>
    <!--</dx-scroll-view>-->

</dx-popup>
