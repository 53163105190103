import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FivePercentileComponent} from './five-percentile/five-percentile.component';
import {PulseSharedModule} from '../../../../@pulse/shared.module';

const routes: Routes = [
  {
    path: 'five-percentile',
    component: FivePercentileComponent
  }
];

@NgModule({
  declarations: [FivePercentileComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PulseSharedModule,
  ]
})
export class FivePercentileModule { }
