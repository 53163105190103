<div class="page-layout simple fullwidth" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto">
                    <div class="pulse-main-heading" fxFlex="50">
                        Aptask Recorder
                    </div>
                </div>
                <div class="p-30 pt-15 pb-15 content-area" fxLayout="column" fxLayoutAlign="center center">
                    <div class="video-content" fxLayout="row" fxLayoutAlign="space-between center">
                        <video id="gum" #gum playsinline autoplay muted></video>
                        <!--<video id="recorded" #recorded playsinline loop></video>-->
                    </div>
                    <div class="my-12" *ngIf="playButtonDisabled">
                        <h2 class="my-0">{{displayTimer.minutes | number: '2.0-0'}}:{{displayTimer.seconds | number: '2.0-0'}}</h2>
                    </div>
                    <div class="action-buttons" fxLayout="row">
                        <button id="start" class="pulse-btn pulse-btn-normal" (click)="startCamera()">
                            <i class="fa fa-video"></i>
                        </button>
                        <button id="record" class="pulse-btn pulse-btn-normal record" (click)="startStopRec()"
                                [disabled]="!cameraStarted">
                            <i class="fa " [ngClass]="{'fa-stop': isStarted, 'fa-circle': !isStarted}"></i>
                        </button>

                        <button class="pulse-btn pulse-btn-normal" (click)="playRec()" [disabled]="playButtonDisabled">
                            <i class="fa fa-play"></i>
                        </button>
                        <!--<button class="pulse-btn pulse-btn-primary" id="play" >Play</button>-->
                        <button class="pulse-btn pulse-btn-normal" id="download" (click)="download()"
                                [disabled]="downloadButtonDisabled">
                            <i class="fa fa-save"></i>
                        </button>
                    </div>

                    <div class="mt-20" *ngIf="downloadUrl">
                       Click here to play: <a [href]="downloadUrl" class="downloadUrl" target="_blank">{{downloadUrl}}</a>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
