import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {SignaturePad} from 'angular2-signaturepad/signature-pad';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {SwalService} from '../../services/swal.service';
import {ConsultantService} from '../../services/consultant/consultant.service';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from '../../services/common/common.service';

@Component({
  selector: 'app-eeo-details',
  templateUrl: './eeo-details.component.html',
  styleUrls: ['./eeo-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EeoDetailsComponent implements OnInit, AfterViewInit {
    @ViewChildren('sigContainer1') public sigContainer1: QueryList<ElementRef>;
    @ViewChild(SignaturePad) public signaturePad: SignaturePad;

    iscaptchaResponse = false;
    captchaResponse: any = '';
    captcha = '6Le4a28UAAAAABlYy6xoxq6oCkorsXK1ibsO3qNm';
    checkEEORequest = true;
    currentDate = moment(new Date());
    localLogsDate = moment(this.currentDate).local().format('MM/DD/YYYY HH:mm:ss');
    consultantName = '';

    isFormSubmitted = false;
    radioOptions: any = [];

    frmEeoData: any = {
        frmAnswer: null
    };
    consultantID = 0;
    public form: FormGroup;

    public options: Object = {};
    public _signature: any = null;

    signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor\
        canvasWidth: 500,
        canvasHeight: 300
    };

    public resolved(captchaResponse: string) {
        this.iscaptchaResponse = true;
        this.captchaResponse = captchaResponse;
        console.log(`Resolved captcha with response: ${captchaResponse}`);
    }

    constructor(private route: ActivatedRoute,
                private _consultantObj: ConsultantService,
                private swalService: SwalService,
                fb: FormBuilder,
                private commonService: CommonService) {
        this.route.params.subscribe(res => this.consultantID = res.consultantId || this.consultantID);
        this.CheckEEOData();
        this.getEEOData();
        this.form = fb.group({
            signatureField1: ['', Validators.required]
        });
    }

    ngOnInit() {
    }

    public ngAfterViewInit() {
        this.setOptions();
        this.signaturePad.set('minWidth', 5);
        this.signaturePad.clear();
    }
    formSubmit(e) {
        e.preventDefault();
        if (this.iscaptchaResponse) {
            const postData = {
                consultantID: this.consultantID,
                eeoAnswer: this.frmEeoData.frmAnswer,
                captcha: this.captchaResponse,
                eeo_signature: this.signaturePad.toDataURL()
            };
            this._consultantObj.saveEeoDetails(postData).subscribe(result => {
                if (result) {
                    this.isFormSubmitted = true;
                    /*this.iscaptchaResponse = false;
                    this.captchaResponse = '';*/
                }
            }, error => {
                this.isFormSubmitted = false;
                this.iscaptchaResponse = false;
                this.captchaResponse = '';
                const swalData = {
                    title: 'Error',
                    message: error.error && error.error.message || 'Something went wrong.',
                    info: 'Please try again.'
                };

                this.swalService.error(swalData);
            });
        } else {
            const swalData = {
                title: 'Pulse',
                message: 'Please Validate Captcha',
                info: ''
            };
            this.swalService.warn(swalData);
            return false;
        }

    }
    getEEOData() {
        this._consultantObj.getEEOQuestion().subscribe((data) => {
            this.radioOptions = data['data'];
        });
    }

    CheckEEOData() {
        this._consultantObj.checkEEO(this.consultantID).subscribe((data) => {
            const temp = data['data'];
            this.consultantName = temp[0].ConsultantName;
            this.checkEEORequest = !(temp.length === 0 || temp[0].eeo_sent === 1);

        });
    }

    public setOptions() {
        this.signaturePad.set('dotSize', 1);
        this.signaturePad.set('penColor', 'rgb(0,0,0)');
    }

    /*===== SIGNATURE PLUGIN FUNCTION ====*/
    get signature(): any {
        return this._signature;
    }

    set signature(value: any) {
        this._signature = value;
    }

    public writeValue(value: any): void {
        if (!value) {
            return;
        }
        this._signature = value;
        this.signaturePad.fromDataURL(this.signature);
    }

    public registerOnTouched(): void {
        // no-op
    }

    public drawBegin(): void {
        // console.log('Begin Drawing');
    }

    public drawComplete(): void {
        this.signature = this.signaturePad.toDataURL('assets/image/jpeg', 0.5);
    }

    public clear(): void {
        this.signaturePad.clear();
        this.signature = '';
    }
}
