import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {PulseSharedModule} from '../../../@pulse/shared.module';
import {DxButtonModule, DxDataGridModule, DxFormModule} from 'devextreme-angular';
import {ConsultantService} from '../../services/consultant/consultant.service';
import {SignaturePadModule} from 'angular2-signaturepad';
import {RecaptchaModule} from 'ng-recaptcha';
import {EquipmentReleaseComponent} from './equipment-release.component';

const routes = [
    {
        path: 'equipment-release/:consultantId',
        component: EquipmentReleaseComponent
    }
];

@NgModule({
    declarations: [EquipmentReleaseComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        PulseSharedModule,

        DxFormModule,
        DxButtonModule,
        SignaturePadModule,
        RecaptchaModule,
        DxDataGridModule
    ],

    providers: [ConsultantService]
})
export class EquipmentReleaseModule { }
