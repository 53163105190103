import {Injectable} from '@angular/core';
import {CanDeactivate, Router, ActivatedRoute} from '@angular/router';
import {CanDeactivateComponent} from './can-deactivate.component';
import {SwalService} from 'app/services/swal.service';
import {CommonService} from '../common/common.service';

@Injectable({
    providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
    constructor(
        private route: ActivatedRoute,
        private swalService: SwalService,
        private commonService: CommonService,
        private router: Router) {
    }

    async canDeactivate(component: CanDeactivateComponent) {
        let shouldDeactivate = true;
        if(typeof component.canDeactivate !== 'function') {
            return shouldDeactivate;
        }
        if (!component.canDeactivate()) {
            const swalData = {
                title: 'Are you sure?',
                message: 'You have unsaved changes!',
                info: 'If you leave, your changes will be lost.'
            };
            const result = await this.swalService.prompt(swalData);
            shouldDeactivate = !!result.value;
        }

        if (this.route.children && this.route.children[0].component && this.route.children[0].component['name'] == 'ConsultantMainComponent'
            && shouldDeactivate) {
            this.commonService.fireEvent('clearSearch');
        }
        return shouldDeactivate;

    }
}
