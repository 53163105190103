import {CalendarEventAction} from 'angular-calendar';
import {startOfDay, endOfDay} from 'date-fns';

export class CalendarEventModel {
    start: any;
    end?: any;
    title: string;
    color: {
        primary: string;
        secondary: string;
    };
    actions?: CalendarEventAction[];
    allDay?: boolean;
    cssClass?: string;
    resizable?: {
        beforeStart?: boolean;
        afterEnd?: boolean;
    };
    draggable?: boolean;
    meta?: {
        eventType: number;
        eventID: number;
        user: number;
        location: string,
        company: number,
        contact: number,
        comments: string,
        mealCost: number,
        miles: number,
    };

    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?) {
        data = data || {};

        this.start = new Date(data.start) || startOfDay(new Date());
        this.end = new Date(data.end) || endOfDay(new Date());

        this.title = data.title || '';
        this.color = {
            primary: data.color && data.color.primary || '#1e90ff',
            secondary: data.color && data.color.secondary || '#D1E8FF'
        };
        this.draggable = data.draggable || false;
        this.resizable = {
            beforeStart: data.resizable && data.resizable.beforeStart || true,
            afterEnd: data.resizable && data.resizable.afterEnd || true
        };
        this.actions = data.actions || [];
        this.allDay = data.allDay || false;
        this.cssClass = data.cssClass || '';
        this.meta = {
            eventType: data.meta && data.meta.eventType || null,
            eventID: data.meta && data.meta.eventID || null,
            location: data.meta && data.meta.location || '',
            company: data.meta && data.meta.company || null,
            user: data.meta && data.meta.user || null,
            contact: data.meta && data.meta.contact || null,
            comments: data.meta && data.meta.comments || '',
            mealCost: data.meta && data.meta.mealCost || null,
            miles: data.meta && data.meta.miles || null,
        };
    }
}
