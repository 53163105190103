import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {siteConfig} from '../../siteSettings';


@Injectable()
export class SmbshareService {

    constructor(
        private _http: HttpClient
    ) {
    }

    sharePost(body: any) {
        let url = siteConfig.nodeAPIBaseUrl + 'smb_auth/sharePost';
        let data = this._http.post<any>(url, body);
        return data;
    }

    getAllUserToken() {
        let url = siteConfig.nodeAPIBaseUrl + 'smb_auth/getAllUserToken';
        let data = this._http.get<any>(url);
        return data;
    }


}
