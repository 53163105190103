import {Component, OnInit, ViewChild} from '@angular/core';
import {ReportsService} from 'app/services/reports/reports.service';
import {DxDataGridComponent, DxFormComponent, DxFormModule} from 'devextreme-angular';

@Component({
    selector: 'app-chat-report',
    templateUrl: './chat-report.component.html',
    styleUrls: ['./chat-report.component.scss']
})
export class ChatReportComponent implements OnInit {
    @ViewChild('dxChatReportGrid') dxChatReportGrid: DxDataGridComponent;
    @ViewChild('dxFilterForm') dxFilterForm: DxFormComponent;
    chatReportData: any = [];
    showFilters: boolean = false;
    dateFilterData: any = {};
    dxDateFormat = 'MM-dd-yyyy';

    constructor(private reportService: ReportsService) {
        this.getSMSReport();
    }

    ngOnInit() {
    }

    getSMSReport() {
        const postData = {
            customerID: 1,
            fromDate: this.dateFilterData.fromDate || null,
            toDate: this.dateFilterData.toDate || null
        };
        this.reportService.getSMSReport(postData).subscribe((response: any) => {
            console.log(response);
            if (response.code === 200) {
                this.chatReportData = response.data;
            }
        });
    }

    onSubmitFilter(e) {

    }
    resetFilterForm(e) {
        this.dxFilterForm.instance.resetValues();
        this.getSMSReport();
    }
    exportToExcel(e) {
        this.dxChatReportGrid.instance.exportToExcel(false);
    }
}
