import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { PulseDirectivesModule } from './directives/directives';

import { PulsePipesModule } from '@pulse/pipes/pipes.module';
import { DxLoadPanelModule } from "devextreme-angular";
import { ChatMediaModule } from '@pulse/components/ChatMedia/chatmedia.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,

        PulseDirectivesModule,
        PulsePipesModule,

        DxLoadPanelModule,
        ChatMediaModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        PulseDirectivesModule,
        PulsePipesModule,

        DxLoadPanelModule,
        ChatMediaModule
    ],
    declarations: [
    ]
})
export class PulseSharedModule {
}
