import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';

import { JobPortalService } from 'app/services/job-portal/job_portal.service';

import * as _ from 'lodash';

@Component({
  selector: 'job-portal-extract',
  templateUrl: './job-portal-extract.component.html',
  styleUrls: [ './job-portal-extract.component.scss' ],
  providers: [ JobPortalService ]
})
export class JobPortalExtractComponent implements OnInit {
  totalActiveScrapeJobs: number = 0;

  enabledScrapers = [];
  allScrapeJobs = [];
  jobPortalJobs = [];
  zipCodeList: string[] = [];

  selectedScraper: string = '';
  url: string = '';
  selectedZipCodes: string[] = [];

  constructor(private jobPortalService: JobPortalService) {}

  ngOnInit () {
    this.zipCodeList = this.jobPortalService.getZipCodeList()

    this.jobPortalService.getAllJobPortalNames()
      .subscribe(data => this.enabledScrapers = data)

    this.updateScrapeJobs()
  }

  updateScrapeJobs () {
    let update = (scrapeJobId, data) => {
      let index = _.findIndex(this.allScrapeJobs, { scrapeJobId })
      this.allScrapeJobs[index] = _.merge(this.allScrapeJobs[index], data)
    }

    this.jobPortalService.getAllScrapeJobs().subscribe(data => {
      // delete progress=100% jobs - to update the status
      this.allScrapeJobs = _.filter(this.allScrapeJobs,
        x => x.status !== 'Processing')

      // get just the new jobs
      let newActiveScrapeJobs = []
      let scrapeJobs = _.differenceBy(data, this.allScrapeJobs, 'scrapeJobId')
      scrapeJobs = _.map(scrapeJobs, x => {
        if (x.status === 'Processing') {
          x.progress = '0%'
          newActiveScrapeJobs.push(x)
        } else if (x.status === 'Completed') {
          x.progress = '100%'
        }
        return x
      })

      // append just the new scrape jobs
      this.allScrapeJobs = _.concat(this.allScrapeJobs, scrapeJobs)

      // update total active scrape jobs
      this.totalActiveScrapeJobs = _.filter(this.allScrapeJobs,
        x => x.status === 'Processing').length;

      // for all new active scrape jobs, start following the progress
      for (let job of newActiveScrapeJobs) {
        try {
          let progressStream = this.jobPortalService
            .scrapeProgress(job.scrapeJobId)
          progressStream.addEventListener('message', _event => {
            let { scrapeJobId, event, data } = JSON.parse(_event.data);

            if (event === 'percentage') {
              update(scrapeJobId, { progress: `${parseInt(data)}%` });
            } else if (event === 'error') {
              notify(data, 'error', 1000);
              update(scrapeJobId,
                { status: 'Errored', progress: '0%', error: data });
            } else if (event === 'finish') {
              notify(`ScrapeJob #${job.scrapeJobId} Complete - ` +
                `Created: ${data.created}, Updated: ${data.updated}`,
                'success', 1000);
            } else if (event === 'done') {
              progressStream.close();
              setTimeout(() => this.updateScrapeJobs(), 1000);
            }
          })
        } catch (err) {
          notify(err, 'error' , 1000);
        }
      }
    }, err => {
      notify(err.error.error, 'error', 1000);
    })
  }

  displayAllJobs () {
    this.jobPortalService.getScrapeResults('all').subscribe(data => {
      if (!data || data.length === 0) {
        notify('No jobs available in database', 'error', 1000)
        this.jobPortalJobs = []
      } else {
        this.jobPortalJobs = data
      }
    }, err => {
      this.jobPortalJobs = [];
      notify(err.error.error, 'error', 1000);
    });
  }

  displayJobs (event) {
    let scrapeJobId = event.selectedRowsData[0].scrapeJobId
    this.jobPortalService.getScrapeResults(scrapeJobId).subscribe(data => {
      this.jobPortalJobs = data.jobPortalJobs
      if (!this.jobPortalJobs || this.jobPortalJobs.length === 0) {
        notify(`No jobs for ScrapeJob #${scrapeJobId}, yet`, 'error', 1000)
      }
    }, err => {
      this.jobPortalJobs = [];
      notify(err.error.error, 'error', 1000);
    });
  }

  submitScrapeJob (event) {
    this.jobPortalJobs = [];
    let zipCodes = this.getSelectedZipCodes()
    this.jobPortalService.scrape(this.selectedScraper, zipCodes, this.url)
      .subscribe(
        data => {
          notify(`New ScrapeJob #${data.id} initiated`, 'success', 1000);
          this.updateScrapeJobs();
        }, err => {
          notify(err.error.err, 'error', 1000);
        }
      )
    event.preventDefault();
  }

  abortScrapeJob (scrapeJobId) {
    this.jobPortalService.scrapeAbort(scrapeJobId).subscribe(
      data => {
        notify(`ScrapeJob #${scrapeJobId} stopped`, 'success', 1000);
        this.updateScrapeJobs();
      }, err => {
        notify(err.error.err, 'error', 1000);
      }
    )
  }

  getSelectedZipCodes () {
    // trim out the city/state info
    return _.map(this.selectedZipCodes, x => x.split(' ')[0])
  }
}
