import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DxDataGridComponent, DxFormComponent} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import {ReportsService} from '../../../../services/reports/reports.service';
import * as moment from 'moment';
import {SwalService} from '../../../../services/swal.service';
import {CommonService} from '../../../../services/common/common.service';
import {exportDataGrid} from 'devextreme/pdf_exporter';
import {jsPDF} from 'jspdf';
import 'jspdf-autotable';

@Component({
    selector: 'dynamic-report',
    templateUrl: './dynamic-report.component.html',
    styleUrls: ['./dynamic-report.component.scss']
})
export class DynamicReportComponent implements OnInit, OnDestroy {
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
    @ViewChild('dxSearchDetailsForm') dxSearchDetailsForm: DxFormComponent;
    consultantsMapping: any;
    payrollMapping: any;
    pnlMapping: any;
    extDetailsMapping: any;
    consolidateMapping: any;
    userNameOfRolesMapping: any;
    // equipmentMapping: any;
    dynamicCount: any;
    reportData: any;
    countDetails: boolean;
    countDetailsPopup = false;
    radioField: any;
    radioFieldText: any;

    countDetailsFields = ['First Name', 'Last Name', 'E-mail', 'source', 'country_id', 'state_id', 'source', 'job_status_id', 'job_sub_status_id',
        'job_category', 'live_in_state_id', 'work_in_state_id',
        'work_auth_id', 'client_id', 'vendor_id', 'Pay Terms'];
    // countDetailsFields = Object.keys(this.consolidateMapping)

    loadingVisible = false;
    tabs: any = [
        {
            id: 0,
            text: 'Select from list',
            icon: 'hierarchy'
        },
        {
            id: 1,
            text: 'Multiselect',
            icon: 'check'
        },
    ];
    selectedTab = 0;
    groupedSelectedBox: DataSource;
    groupedCheckBoxes: any;
    selectedFields = [{
        field: '',
        text: '',
        table: '',
        order: ''
    }];

    periodData: any = [
        {ID: 'ALL', Name: 'All'},
        {ID: 'custom', Name: 'Custom Dates'},
        {ID: 'TD', Name: 'Today'},
        {ID: 'YD', Name: 'Yesterday'},
        {ID: 'TW', Name: 'This Week'},
        {ID: 'TM', Name: 'This Month'},
        {ID: 'TQ', Name: 'This Quarter'},
        {ID: 'TY', Name: 'This Year'},
        {ID: 'LW', Name: 'Last Week'},
        {ID: 'LM', Name: 'Last Month'},
        {ID: 'LQ', Name: 'Last Quarter'},
        {ID: 'LY', Name: 'Last Year'}
    ];

    consultantStatuses: any = [
        {id: 'all', name: 'All'},
        {id: '2800008', name: 'Active'},
        {id: '2800010', name: 'Terminated'}
    ];
    consultantTypes: any = [
        {id: 'all', name: 'All'},
        {id: '200000', name: 'Contractor'},
        {id: '200004', name: 'Full Time'}
    ];

    idFields = ['consultant_id', 'first_name', 'last_name', 'email'];

    countField = ['Count'];

    filterFormData: any = {};

    tableSource = [];
    finalTableSource = [];

    countDetailsData: any = [];
    id: any;
    masterData: any = {};
    searchHistory: any = [];
    consolidateValue: any;
    selectedFieldsSearch: any = [];
    showSearchHistoryPopup = false;
    public searchHistoryForm: any = {};
    reportType = [
        {value: 'public', text: 'Public'},
        {value: 'private', text: 'Private'}
    ];
    status: any = [];
    historyValues = [
        {value: 'update', text: 'Overwrite Search History'},
        {value: 'save', text: 'Save Search History'}
    ]
    isSelectedHistory = false;

    constructor(
        private reportService: ReportsService,
        private swalService: SwalService,
        private commonService: CommonService,
    ) {
        this.onPeriodSelect = this.onPeriodSelect.bind(this);
        this.customizeExport = this.customizeExport.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.filterFormData.consultantStatus = 'all';
        this.filterFormData.consultantType = 'all';
        this.searchHistoryForm.frmSaveType = 'update';
        this.filterFormData.consolidatedReport = false;
        this.generateSource(true);
        this.countDetails = false;
    }

    customizeExport(event) {
        if (event.value === '[object Object]') {
            event.value = event.gridCell.value.text;
        }
        return event;
    }

    onExporting() {
        const doc = new jsPDF();
        exportDataGrid({
            jsPDFDocument: doc,
            component: this.dataGrid.instance,
            customizeCell({ gridCell, pdfCell }) {
                if (gridCell.rowType === 'data' && pdfCell.content === '[object Object]') {
                    pdfCell.content = gridCell.value.text;
                }
            },
        }).then(() => {
            doc.save('Custom-Sheet - ' + moment().format('MM-DD-YYYY') + '.pdf');
        });
    }

    generateSource(init: boolean = false) {
        if (init) {
            this.filterFormData = {};
        }

        this.tableSource = [];
        this.selectedFields = [{
            field: '',
            text: '',
            table: '',
            order: ''
        }];
        const groupedData: any[] = this.getGroupedData();
        this.groupedSelectedBox = new DataSource({
            store: new ArrayStore({
                data: groupedData,
                key: 'id'
            }),
            group: 'category',
        });

        this.groupedCheckBoxes = {};
        groupedData.forEach(item => {
            if (!this.groupedCheckBoxes[item.category]) {
                this.groupedCheckBoxes[item.category] = {all: false, fields: []};
            }
            this.groupedCheckBoxes[item.category].fields.push({
                ...item,
                checked: false
            });
        });
        this.isSelectedHistory = false;
        this.dxSearchDetailsForm?.instance?.resetValues();
        this.searchHistory = this.searchHistory.map(i => {
            if (i.isSelectedShow) {
                i.isSelectedShow = false;
            }
            return i;
        })
    }


    onDropDownOpened(e) {
        e.component._popup.option('width', 130);
    }

    onPeriodSelect(e: any) {
        if (!e.value) {
            return;
        }
        let dateFrom = null;
        let dateTo = null;
        if (e.value == 'TM') {
            dateFrom = moment().startOf('month').toDate();
            dateTo = moment().endOf('month').toDate();
        } else if (e.value == 'TQ') {
            dateFrom = moment().startOf('quarter').toDate();
            dateTo = moment().endOf('quarter').toDate();
        } else if (e.value == 'TY') {
            dateFrom = moment().startOf('year').toDate();
            dateTo = moment().endOf('year').toDate();
        } else if (e.value == 'LM') {
            dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
            dateTo = moment().subtract(1, 'month').endOf('month').toDate();
        } else if (e.value == 'LQ') {
            dateFrom = moment().subtract(1, 'quarter').startOf('quarter').toDate();
            dateTo = moment().subtract(1, 'quarter').endOf('quarter').toDate();
        } else if (e.value == 'LY') {
            dateFrom = moment().subtract(1, 'year').startOf('year').toDate();
            dateTo = moment().subtract(1, 'year').endOf('year').toDate();
        } else if (e.value == 'LW') {
            dateFrom = moment().subtract(1, 'weeks').startOf('week').toDate();
            dateTo = moment().subtract(1, 'weeks').endOf('week').toDate();
        } else if (e.value == 'TW') {
            dateFrom = moment().startOf('week').toDate();
            dateTo = moment().endOf('week').toDate();
        } else if (e.value == 'YD') {
            dateFrom = moment().subtract(1, 'day').toDate();
            dateTo = moment().subtract(1, 'day').toDate();
        } else if (e.value == 'TD') {
            dateFrom = moment().toDate();
            dateTo = moment().toDate();
        } else if (e.value == 'ALL') {
            dateFrom = null;
            dateTo = null;
        }

        this.filterFormData.dateFrom = dateFrom;
        this.filterFormData.dateTo = dateTo;

    }

    onStatusSelect = (e) => {
        if (e.value === 'all') {
            this.filterFormData.status = null;
        } else if (e.value === '200004') {
            this.filterFormData.status = null;
        }
    }

    selectAllChecked(event, key) {
        this.groupedCheckBoxes[key].all = event.value;
        this.groupedCheckBoxes[key].fields = this.groupedCheckBoxes[key].fields.map(t => {
            t.checked = !!event.value;
            return t;
        });
    }

    onChangeChecked(event, field) {
        if (!event.value) {
            this.groupedCheckBoxes[field.key].fields.map(t => {
                if (this.selectedFields.some(i => i.field === t.id) && !t.checked) {
                    const index = this.selectedFields.findIndex(i => {
                        return i.field === t.id;
                    });
                    this.selectedFields.splice(index, 1);
                }
            });
        }
    }

    handleRadioValue(e, fields) {
        if (this.filterFormData.consolidatedReport) {
            this.radioField = e.value;
            this.radioFieldText = fields.filter(t => t.id == e.value).map(f => f.text).toString();
        }
    }

    changeValue(event) {
        this.generateSource();
        if (this.filterFormData.consolidatedReport) {
            this.selectedTab = 1;
        }
    }

    getGroupedData() {
        this.getConsultantMapping();
        this.getPayrollMapping();
        this.getExtraDetailsMapping();
        this.getPnlMapping();
        this.getConsolidateMapping();
        this.getUserNameOfRolesMapping();
        // this.getEquipmentMapping();


        const consolidateMap = Object.keys(this.consolidateMapping).map(item => {
            return {
                category: 'Consolidate',
                table: 'consultants',
                id: this.consolidateMapping[item],
                text: item
            };
        });
        const consultantsMap = Object.keys(this.consultantsMapping).map(item => {
            return {
                category: 'Consultant',
                table: 'consultants',
                id: this.consultantsMapping[item],
                text: item
            };
        });
        const extDetailsMap = Object.keys(this.extDetailsMapping).map(item => {
            return {
                category: 'Ext. Details',
                table: 'consultant_ext_details',
                id: this.extDetailsMapping[item],
                text: item
            };
        });
        const payrollMap = Object.keys(this.payrollMapping).map(item => {
            return {
                category: 'Payroll',
                table: 'payroll_consultant',
                id: this.payrollMapping[item],
                text: item
            };
        });
        const pnlMap = Object.keys(this.pnlMapping).map(item => {
            return {
                category: 'Pnl',
                table: 'consultant_pnl',
                id: this.pnlMapping[item],
                text: item
            };
        });
        const userRoleMap = Object.keys(this.userNameOfRolesMapping).map(item => {
            return {
                category: 'User',
                table: 'consultants_additional_resource',
                id: this.userNameOfRolesMapping[item],
                text: item
            };
        });
        // const equipmentMap = Object.keys(this.equipmentMapping).map(item => {
        //     return {
        //         category: 'Equipment',
        //         table: 'consultant_equipment',
        //         id: this.equipmentMapping[item],
        //         text: item
        //     };
        // });
        if (this.filterFormData.consolidatedReport) {
            // return [...consolidateMap , ...extDetailsMap, ...consultantsMap, ...payrollMap, ...pnlMap];
            return [...consolidateMap];
        }
        return [...consultantsMap, ...extDetailsMap, ...payrollMap, ...pnlMap, ...userRoleMap];
    }

    addNewFields() {
        // this.tableSource = [];
        this.selectedFields.push({
            field: '',
            table: '',
            text: '',
            order: ''
        });
    }

    selectItem(event, index) {
        // this.tableSource = [];
        this.selectedFields[index].field = event.selectedItem.id;
        this.selectedFields[index].table = event.selectedItem.table;
        this.selectedFields[index].text = event.selectedItem.text;
        const group = Object.keys(this.groupedCheckBoxes);
        group.forEach(g => {
            this.groupedCheckBoxes[g].fields.map(t => {
                t.checked = this.selectedFields.some(i => i.field === t.id);
            });
        });
    }

    removeFields(index) {
        // this.tableSource = [];
        const group = Object.keys(this.groupedCheckBoxes);
        group.forEach(g => {
            this.groupedCheckBoxes[g].fields.map(t => {
                if (t.id === this.selectedFields[index].field) {
                    t.checked = false;
                }
            });
        });
        this.selectedFields.splice(index, 1);
    }

    saveSearchHistory() {
        this.loadingVisible = true;
        const user = JSON.parse(localStorage.getItem('userData'));
        const body = {
            period: this.filterFormData.frmPeriod,
            fromDate: this.filterFormData.dateFrom ? moment(this.filterFormData.dateFrom).format('YYYY-MM-DD') : null,
            toDate: this.filterFormData.dateTo ? moment(this.filterFormData.dateTo).format('YYYY-MM-DD') : null,
            consultant_status: this.filterFormData.consultantStatus || null,
            consultant_type: this.filterFormData.consultantType || null,
            consolidate_report: this.filterFormData.consolidatedReport || false,
            field_list: JSON.stringify(this.selectedFieldsSearch),
            status: this.filterFormData.status || null,
            report_name: this.searchHistoryForm.frmName,
            report_type: this.searchHistoryForm.frmType,
            userId: this.searchHistoryForm.frmType === 'private' ? user.id : null
        }
        if (this.isSelectedHistory && this.searchHistoryForm.frmSaveType === 'update') {
            const item = this.searchHistory.filter(i => i.isSelectedShow === true);
            this.reportService.updateDynamicReportSearchHistory(item[0].id, body).subscribe(resp => {
                this.showSearchHistoryPopup = false;
                this.loadingVisible = false;
                const swalData = {
                    title: 'Success',
                    message: 'Search History Update Successfully!',
                    info: ''
                };
                this.swalService.success(swalData);
                this.getDynamicReportSearch();
            })
        } else {
            this.reportService.saveDynamicReportSearchHistory(body).subscribe((resp) => {
                this.showSearchHistoryPopup = false;
                this.loadingVisible = false;
                const swalData = {
                    title: 'Success',
                    message: 'Search History Saved Successfully!',
                    info: ''
                };
                this.swalService.success(swalData);
                this.getDynamicReportSearch();
            })
        }
    }

    generateReport() {
        this.tableSource = [];
        this.selectedFieldsSearch = [];
        const selectedFields = {};
        let selectedFieldsFilter = this.selectedFields.filter(t => t.field);
        const filterFields = [];
        if (this.selectedTab === 1) {
            // selectedFieldsFilter = [];
            const group = Object.keys(this.groupedCheckBoxes);
            group.forEach(g => {
                const fields = this.groupedCheckBoxes[g].fields.filter(f => f.checked);
                fields.forEach(t => {
                    if (!selectedFieldsFilter.some(i => i.field === t.id)) {
                        filterFields.push({
                            field: t.id,
                            table: t.table,
                            text: t.text,
                            order: ''
                        });
                    }
                });
                const field = this.groupedCheckBoxes[g].fields.filter(f => !f.checked);
                field.forEach(t => {
                    if (filterFields.some(i => i.field === t.id)) {
                        const index = filterFields.findIndex(i => {
                            return i.field === t.id;
                        });
                        filterFields.splice(index, 1);
                    }
                });
            });
            if (this.filterFormData.consolidatedReport && !this.consolidateValue) {

                selectedFieldsFilter.push(
                    {
                        field: `${this.radioField}`,
                        table: `${this.radioField}` == 'role_name' ? 'consultants_additional_resource' : 'consultants',
                        text: `${this.radioFieldText}`,
                        order: ''
                    },
                    {
                        field: 'Count',
                        table: 'consultants',
                        text: 'Count',
                        order: ''
                    });

            }
            this.selectedFields = [...selectedFieldsFilter, ...filterFields];
            selectedFieldsFilter = [...selectedFieldsFilter, ...filterFields];
        }
        if (selectedFieldsFilter.length > 0) {
            this.selectedFields.forEach(item => {
                if (!selectedFields[item.table]) {
                    selectedFields[item.table] = [];
                }
                selectedFields[item.table].push({
                    field: item.field,
                    order: item.order
                });
                if (this.filterFormData.consolidatedReport && item.field === 'Count') {
                    return;
                }
                this.selectedFieldsSearch.push({
                    field: item.field,
                    order: item.order,
                    text: item.text,
                    table: item.table
                });
            });
        }
        const postBody = {
            fields: selectedFields,
            period: this.filterFormData.frmPeriod,
            dateFrom: this.filterFormData.dateFrom ? moment(this.filterFormData.dateFrom).format('YYYY-MM-DD') : null,
            dateTo: this.filterFormData.dateTo ? moment(this.filterFormData.dateTo).format('YYYY-MM-DD') : null,
            consultantStatus: this.filterFormData.consultantStatus,
            type: this.filterFormData.consultantType,
            consolidateReport: this.filterFormData.consolidatedReport,
            countDetails: this.countDetails,
            status: this.filterFormData.status
        };
        const reportAPI = this.reportService.getDynamicReport(postBody);
        this.loadingVisible = true;

        reportAPI.subscribe((resp) => {
            this.loadingVisible = false;
            this.searchHistory = this.searchHistory.map(t => {
                if (t.isShow) {
                    t.isShow = false;
                    t.isSelectedShow = true;
                }
                return t;
            })
            this.tableSource =
                resp.data.map(t => {
                    const keys = Object.keys(t);
                    const common = keys.filter(x => this.idFields.includes(x));
                    common.forEach(i => {
                        t[i] = {
                            text: t[i],
                            id: t['con_id']
                        };
                    });
                    return t;
                });

            if (postBody.fields.hasOwnProperty('consultants_additional_resource') && !this.filterFormData.consolidatedReport) {
                let filterArray: any = [];
                this.tableSource.map(data => {
                    data['role_name'] = data['role_name'].split(' ').join('_');
                    data[data['role_name']] = data['user_name'];
                    delete data['role_name'];
                    delete data['user_name'];
                    let array = this.tableSource.filter(t => t.con_id == data.con_id);
                    if (array.length > 1) {
                        array = array.reduce(((previousValue, currentValue) => ({...previousValue, ...currentValue})));
                        filterArray.push(array);
                    } else {
                        filterArray.push(array[0]);
                    }
                });
                filterArray = [...new Map(filterArray.map(item => [item['con_id'], item])).values()];
                this.tableSource = filterArray;
            }
        });
    }


    ngOnInit() {
        this.commonService.masterData
            .subscribe(data => {
                this.masterData = data;
                if (data.jobStatus) {
                    this.status = [{value: 'all', master_lookup_name: 'All'}, ...data.jobStatus];
                }
            });
        this.getDynamicReportSearch();
    }

    getDynamicReportSearch() {
        this.reportService.getDynamicReportSearchHistory().subscribe(resp => {
            let searchHistory = [];
            const user = JSON.parse(localStorage.getItem('userData'));
            if (this.searchHistory && this.searchHistory.length > 0) {
                searchHistory = this.searchHistory.filter(t => t.isShow);
            }
            this.searchHistory = [];
            resp.data.map(i => {
                if (i.report_type === 'private' && i.userId === user.id) {
                    this.searchHistory.push(i);
                } else if (i.report_type === 'public') {
                    this.searchHistory.push(i);
                }
            })
            if (searchHistory && searchHistory.length) {
                this.searchHistory = this.searchHistory.map(t => {
                    if (t.id === searchHistory[0].id) {
                        t = {
                            ...t,
                            isSelectedShow: true
                        }
                    }
                    return t;
                })
            }
            this.searchHistory = this.searchHistory.map(t => {
                const period = this.periodData.filter(i => i.ID === t.period);
                t.periodAt = t.period;
                const consultantStatus = this.consultantStatuses.filter(i => i.id === t.consultant_status);
                t.consultant_statusAt = t.consultant_status;
                const type = this.consultantTypes.filter(i => i.id === t.consultant_type);
                t.consultant_typeAt = t.consultant_type;
                const status = this.masterData?.jobStatus?.filter(i => i.value == t.status);
                t.statusAt = t.status;
                t.period = period.length > 0 && period[0].Name
                t.consultant_status = consultantStatus.length > 0 ? consultantStatus[0].name : ''
                t.consultant_type = type.length > 0 ? type[0].name : '';
                t.status = status?.length > 0 ? status[0].master_lookup_name : '';
                t.consolidate_report = t.consolidate_report !== 0;
                const selectedList = JSON.parse(t.field_list);
                t.field_list = selectedList
                const fieldList = [];
                selectedList.map((i: any) => {
                    fieldList.push(i.text)
                    t = {
                        ...t,
                        fields: [...fieldList],
                    }
                })
                return t;
            });
        })
    }

    selectedSearch(item) {
        const group = Object.keys(this.groupedCheckBoxes);
        group.forEach(g => {
            this.groupedCheckBoxes[g].fields.map(t => {
                t.checked = false;
            });
        });
        this.searchHistory = this.searchHistory.map(t => {
            if (t.isShow) {
                t.isShow = false
            }
            if (t.id === item.data.id) {
                t.isShow = true
            }
            return t;
        })
        const period = this.periodData.filter(i => i.Name === item.data.period);
        this.filterFormData.frmPeriod = period[0].ID
        const consultantStatus = this.consultantStatuses.filter(i => i.name === item.data.consultant_status);
        const type = this.consultantTypes.filter(i => i.name === item.data.consultant_type);
        const status = this.masterData.jobStatus.filter(i => i.master_lookup_name === item.data.status);
        this.filterFormData.consultantStatus = consultantStatus.length > 0 && consultantStatus[0].id
        this.filterFormData.consultantType = type.length > 0 && type[0].id
        this.filterFormData.status = status.length > 0 && status[0].value;
        this.filterFormData.consolidatedReport = item.data.consolidate_report;
        if (this.filterFormData.consolidatedReport) {
            this.consolidateValue = item.data.field_list[0].field;
        }
        const groupedCheckBoxes = {};
        this.tableSource = [];
        setTimeout(() => {
            this.filterFormData.dateFrom = item.data.fromDate;
            this.filterFormData.dateTo = item.data.toDate;
            this.selectedFields = item.data.field_list;
            Object.keys(this.groupedCheckBoxes).map((i) => {
                item.data.field_list.map(f => {
                    const data = this.groupedCheckBoxes[i].fields.map(t => {
                        if (f.field === t.id) {
                            t.checked = true;
                        }
                        return t;
                    });
                    groupedCheckBoxes[i] = {
                        ...groupedCheckBoxes[i],
                        all: false,
                        fields: [...data]
                    }
                })
            })
            this.groupedCheckBoxes = groupedCheckBoxes
        }, 10)
    }

    selectedSearchRemove(item) {
        this.searchHistory = this.searchHistory.map(t => {
            if (t.id === item.data.id) {
                t = {
                    ...t,
                    isShow: false
                }
            }
            return t;
        })
        this.filterFormData.frmPeriod = null;
        this.filterFormData.dateFrom = null;
        this.filterFormData.dateTo = null;
        this.filterFormData.consultantStatus = null;
        this.filterFormData.consultantType = null;
        this.filterFormData.consolidatedReport = false;
        this.filterFormData.status = false;
        const group = Object.keys(this.groupedCheckBoxes);
        group.forEach(g => {
            this.groupedCheckBoxes[g].fields.map(t => {
                if (item.data.field_list.some(i => i.field === t.id)) {
                    t.checked = false;
                }
            });
        });
        this.selectedFields = [{
            field: '',
            text: '',
            table: '',
            order: ''
        }];
    }

    onSaveSearchHistoryPopup() {
        this.searchHistory.forEach(i => {
            if (i.isSelectedShow) {
                this.isSelectedHistory = true;
                this.searchHistoryForm.frmName = i.report_name
                this.searchHistoryForm.frmType = i.report_type
            }
        })
        this.showSearchHistoryPopup = true
    }

    removeSearchHistory(data) {
        const swalData = {
            title: 'Confirmation',
            message: `Are you sure you want to delete search history ?`,
            info: ''
        };
        this.swalService.prompt(swalData).then(result => {
            if (result.value) {
                this.loadingVisible = true;
                this.reportService.removeDynamicReportSearchHistory(data.id).subscribe((resp) => {
                    this.getDynamicReportSearch();
                    this.loadingVisible = false;
                })
            }
        })
    }

    changeColumn(e) {
        if (e.fullName?.split('.')[1] === 'visibleIndex') {
            setTimeout(() => {
                const dataSource: any = this.dataGrid.instance.getVisibleColumns();
                let searchHistory = this.searchHistory.filter(t => t.isSelectedShow);
                if (searchHistory && searchHistory.length > 0) {
                    searchHistory = searchHistory[0];
                    const list = [], textList = [];
                    dataSource.map(i => {
                        const order = searchHistory.field_list.filter(f => f.field === i.dataField)
                        if (order && order.length > 0 && searchHistory.fields.some(item => item === order[0].text)) {
                            list.push(order[0]);
                            textList.push(order[0].text);
                        }
                    });
                    const body = {
                        period: this.filterFormData.frmPeriod,
                        fromDate: this.filterFormData.dateFrom ? moment(this.filterFormData.dateFrom).format('YYYY-MM-DD') : null,
                        toDate: this.filterFormData.dateTo ? moment(this.filterFormData.dateTo).format('YYYY-MM-DD') : null,
                        consultant_status: this.filterFormData.consultantStatus || null,
                        consultant_type: this.filterFormData.consultantType || null,
                        consolidate_report: this.filterFormData.consolidatedReport || false,
                        field_list: JSON.stringify(list),
                        status: this.filterFormData.status || null
                    }
                    this.reportService.updateDynamicReportSearchHistory(searchHistory.id, body).subscribe(resp => {
                        this.searchHistory = this.searchHistory.map(t => {
                            if (searchHistory.id === t.id) {
                                t.field_list = list;
                                t.fields = textList;
                            }
                            return t;
                        });
                    })
                }
            }, 50)
        }
    }

    ngOnDestroy() {
    }

    selectTab(e) {
        // this.tableSource = [];
        // this.generateSource();
        this.selectedTab = this.tabs[e.itemIndex].id;
    }

    detailDataOfCount(data) {
        const field = Object.values(data)[0];
        const selectedFieldsFilter = this.selectedFields.filter(t => t.field);
        if (selectedFieldsFilter.length > 0) {
            const selectedFields = {};
            this.selectedFields.forEach(item => {
                if (!selectedFields[item.table]) {
                    selectedFields[item.table] = [];
                }
                selectedFields[item.table].push({
                    field: item.field,
                    order: item.order
                });
            });

            const postData = {
                fields: selectedFields,
                fieldName: Object.values(data)[0],
                dateFrom: this.filterFormData.dateFrom ? moment(this.filterFormData.dateFrom).format('YYYY-MM-DD') : null,
                dateTo: this.filterFormData.dateTo ? moment(this.filterFormData.dateTo).format('YYYY-MM-DD') : null,
                status: this.filterFormData.consultantStatus,
                type: this.filterFormData.consultantType,
                consolidateReport: this.filterFormData.consolidatedReport,
                countDetails: !this.countDetails
            };
            this.loadingVisible = true;
            this.reportService.getDynamicReportCountDetails(postData).subscribe((data) => {

                if (selectedFields.hasOwnProperty('consultants_additional_resource')) {
                    data.data.map( t => {
                        const split = t['First Name'].split(' ');
                        t['First Name'] = split[0];
                        t['Last Name'] = split[1];
                        t['Role Name'] = t['Role'];
                        delete t['Role'];
                    });
                }
                this.countDetailsData = data.data.map(t => t);
                this.loadingVisible = false;
                this.countDetailsPopup = true;
            });
        }
    }

    getConsultantMapping() {
        this.consultantsMapping = {
            'Consultant Id': 'consultant_id',
            Email: 'email',
            'Alternate Email': 'alternate_email',
            'First Name': 'first_name',
            'Last Name': 'last_name',
            Mobile: 'mobile',
            Home: 'home',
            Country: 'country_id',
            Address: 'address1',
            Address2: 'address2',
            City: 'city',
            State: 'state_id',
            'Zip Code': 'zip_code',
            SSN: 'ssn',
            'Date of birth': 'dob',
            'Source From': 'source',
            'Other Source': 'source_from_other_info',
            'Work Authorization': 'work_auth_id',
            'Other Work': 'other_for_work_auth',
            'Expiration Date': 'expiration_date',
            'Pay Terms': 'pay_terms',
            Gender: 'gender',
            Client: 'client_id',
            'Job ID': 'job_id',
            'Job Title': 'job_title_id',
            'Start date': 'actual_start_date',
            'Tentative End': 'estimated_term_date',
            Status: 'job_status_id',
            'Sub Status': 'job_sub_status_id',
            'Job Category': 'job_category',
            'Vendor name': 'vendor_id',
            'Live in state': 'live_in_state_id',
            'Work in Address': 'work_address',
            'Work in City': 'work_city',
            'Work in State': 'work_in_state_id',
            'Work Zip Code': 'work_zip_code',
            'Bill Rate': 'bill_rate',
            'Bill Rate Currency': 'bill_rate_currency_id',
            'Bill Rate Type': 'bill_rate_type_id',
            'Pay Rate': 'pay_rate',
            'Pay Rate Currency': 'pay_rate_currency_id',
            'Pay Rate Type': 'pay_rate_type_id',
            'Hireright ID': 'hireright_id',
            'Reporting Manager': 'frmreporting_manager',
            'Background Email': 'background_email',
            Remarks: 'remarks',
            'Main Status': 'main_status',
            'Created Date': 'created_at'
        };
    }

    getPayrollMapping() {
        this.payrollMapping = {
            'OT Eligible': 'prl_ot_eligible',
            'OT Rate': 'prl_ot_rate',
            'Company Code': 'prl_company_code',
            Department: 'prl_department',
            'ADP ID': 'prl_adp_id',
            'Federal Filling Status': 'prl_federal_status',
            'Federal Exemption': 'prl_federal_exemption',
            'Bank Name': 'prl_bank_name',
            'Pay Rate': 'prl_pay_rate',
            'Pay Rate Type': 'prl_pay_rate_type_id',
            FLSA: 'prl_flsa',
            Gender: 'prl_pay_gender',
            'Custom Area 1': 'prl_custom_area_1',
            'Custom Area 2': 'prl_custom_area_2',
            'Custom Area 3': 'prl_custom_area_3',
            Dependents: 'prl_dependence',
            Deductions: 'prl_deductions',
            'Fed Additional Tax Amount': 'prl_additional_tax',
            'Other Income': 'prl_other_income',
            'State Additional Tax Amount': 'prl_state_additional_tax',
            'State Filing Status': 'prl_state_status',
            'State Exemption': 'prl_state_exemption'
        };
    }

    getExtraDetailsMapping() {
        this.extDetailsMapping = {
            // 'Home No.': 'ext_personal_details_home_no',
            // 'Cell No.': 'ext_personal_details_cell_no',
            // 'Work No.': 'ext_personal_details_work_no',
            // 'Personal Email': 'ext_personal_details_personal_email',
            // 'Work Email': 'ext_personal_details_personal_details_work_email',
            // 'Mode of Contact': 'ext_personal_details_mode_of_contact',
            // 'Contact Type': 'ext_personal_details_contact_type',
            // 'Manager Name': 'ext_work_information_manager_name',
            // 'Manager Contact': 'ext_work_information_manager_contact',
            // 'Manager Email': 'ext_work_information_manager_email',
            // 'Work Address': 'ext_work_information_work_address',
            // 'Work Address Line2': 'ext_work_information_work_address_line2',
            // 'Work City': 'ext_work_information_work_city',
            // 'Work State': 'ext_work_information_work_state',
            // 'Contact Name': 'ext_emergency_contact_contact_name',
            // Relation: 'ext_emergency_contact_relation',
            // 'Contact Number': 'ext_emergency_contact_contact_number',
            // 'Contact Email': 'ext_emergency_contact_contact_email',
            // 'Anniversary Date': 'ext_mile_stones_anniversary_date',
            // 'Work Anniversary': 'ext_mile_stones_work_anniversary',
            'Ethnicity Selected': 'eeo_answer',
        };
    }

    getPnlMapping() {
        this.pnlMapping = {
            'Hourly Bill Rate': 'pnl_hourly_bill_rate',
            'Client Discount %': 'pnl_client_discount_percentage',
            'Other Fees(Client)': 'pnl_other_fees_client',
            'Net Invoice Rate': 'pnl_net_invoice_rate',
            'Hourly Pay Rate': 'pnl_hourly_pay_rate',
            'Payroll Load %': 'pnl_payroll_load_percentage',
            'Loaded Pay Rate': 'pnl_loaded_pay_rate',
            'Other Fees(Consultant)': 'pnl_other_fees_consultant',
            'Class/Department': 'pnl_class',
            'Gross Profit $': 'pnl_gross_profit',
            'OR Name': 'pnl_or_name',
            'OR Commission %': 'pnl_or_commission_percentage',
            'OR Commission $': 'pnl_or_commission_ammount',
            'Net Profit $': 'pnl_net_profit_ammount',
            'Net Profit %': 'pnl_net_profit_percentage',
            'Mark Up%': 'pnl_mark_up_percentage',
            'QB Items': 'pnl_qb_item_id',
            'VMS Consultant': 'pnl_vms_consultant_id',
        };
    }

    getConsolidateMapping() {
        this.consolidateMapping = {
            'Source From': 'source',
            Country: 'country_id',
            State: 'state_id',
            'Work Authorization': 'work_auth_id',
            'Pay Terms': 'pay_terms',
            Client: 'client_id',
            Status: 'job_status_id',
            'Sub Status': 'job_sub_status_id',
            'Job Category': 'job_category',
            'Vendor name': 'vendor_id',
            'Live in state': 'live_in_state_id',
            'Work in State': 'work_in_state_id',
            ...this.userNameOfRolesMapping

        };

    }

    getUserNameOfRolesMapping() {
        this.userNameOfRolesMapping = {
            'Account Executive': 'Account_Executive',
            'Team Lead': 'Team_Lead',
            Recruiter: 'Recruiter',
            'Recruiting Manager': 'Recruiting_Manager',
            AE: 'AE',
            Referral: 'Referral',
        };
    }

    // getEquipmentMapping() {
    //     this.equipmentMapping = {
    //         Item: 'product_id',
    //         'Release Date': 'release_date',
    //         'Serial #': 'serial_no',
    //         Model: 'model_no',
    //         'Returned Date': 'return_date',
    //         Remarks: 'remarks',
    //     };
    // }
}
