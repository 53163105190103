import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {CalendarModule} from './my-calendar/calendar.module';
import {ChatModule} from './chat/chat.module';
import {AuthGuardService as AuthGuard} from 'app/services/auth/auth-guard.service';
import {MyCalendarComponent} from './my-calendar/my-calendar.component';
import {ChatComponent} from './chat/chat.component';
import {CalendarService} from 'app/services/calendar/calendar.service';
import {ChatService} from 'app/services/chat/chat.service';
import { PendingApprovalsComponent } from './pending-approvals/pending-approvals.component';
import {DxDataGridModule, DxMenuModule, DxButtonModule, DxPopupModule, DxFormModule} from 'devextreme-angular';
import {PulseSharedModule} from '@pulse/shared.module';

const routes = [
    {
        path: 'touchpoint',
        component: MyCalendarComponent,
        canActivate: [AuthGuard],
        resolve: [CalendarService],
    },
    {
        path: 'touchpoint/calendar',
        component: MyCalendarComponent,
        resolve: [CalendarService],
        canActivate: [AuthGuard]
    },
    {
        path: 'touchpoint/chat',
        component: ChatComponent,
        resolve: [ChatService],
        canActivate: [AuthGuard]
    },
    {
        path: 'touchpoint/pending-approvals',
        component: PendingApprovalsComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [PendingApprovalsComponent],
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        CommonModule,
        PulseSharedModule,
        ChatModule,
        CalendarModule,
        DxDataGridModule,
        DxMenuModule,
        DxButtonModule,
        DxPopupModule,
        DxFormModule
    ]
})
export class TouchPointModule {
}
