import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import {PulseSharedModule} from '@pulse/shared.module';
import {ChatService} from 'app/services/chat/chat.service';
import {ChatComponent} from './chat.component';
import {ChatStartComponent} from './chat-start/chat-start.component';
import {ChatViewComponent} from './chat-view/chat-view.component';
import {ChatChatsSidenavComponent} from './sidenavs/left/chats/chats.component';
import {ChatUserSidenavComponent} from './sidenavs/left/user/user.component';
import {ChatLeftSidenavComponent} from './sidenavs/left/left.component';
import {ChatRightSidenavComponent } from './sidenavs/right/right.component';
import {ChatContactSidenavComponent} from './sidenavs/right/contact/contact.component';

import {
    DxTextBoxModule,
    DxValidatorModule,
    DxSelectBoxModule,
    DxFormModule,
    DxPopupModule,
    DxButtonModule, DxScrollViewModule
} from 'devextreme-angular';
import {AuthGuardService as AuthGuard} from '../../../../services/auth/auth-guard.service';
import {MatTooltipModule} from "@angular/material/tooltip";

const routes: Routes = [
    {
        path: 'chat',
        component: ChatComponent,
        children: [],
        resolve: {
            chat: ChatService
        },
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        ChatComponent,
        ChatViewComponent,
        ChatStartComponent,
        ChatChatsSidenavComponent,
        ChatUserSidenavComponent,
        ChatLeftSidenavComponent,
        ChatRightSidenavComponent,
        ChatContactSidenavComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        DxTextBoxModule,
        DxValidatorModule,
        DxSelectBoxModule,
        DxFormModule,
        DxPopupModule,
        DxButtonModule,
        DxScrollViewModule,

        MatSelectModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatRadioModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTabsModule,

        PulseSharedModule,
        MatTooltipModule
    ],
    providers: [
        ChatService
    ]
})
export class ChatModule {
}
