<div [ngSwitch]="view" class="views">
    <chat-chats-sidenav class="view"
                        *ngSwitchCase="'chats'"
                        >
    </chat-chats-sidenav>

    <chat-user-sidenav class="view"
                       *ngSwitchCase="'user'"

                       pulsePerfectScrollbar>
    </chat-user-sidenav>
</div>
