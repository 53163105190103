import {NgModule} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import {PulseConfirmDialogComponent} from './confirm-dialog.component';

@NgModule({
    declarations: [
        PulseConfirmDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ]
})
export class PulseConfirmDialogModule {
}
