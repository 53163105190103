import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { PulseSharedModule } from '@pulse/shared.module';

import { PIPLComponent } from './pipl.component';
import {DxFormModule, DxButtonModule, DxDataGridModule, DxTabPanelModule, DxCheckBoxModule} from 'devextreme-angular';

import { AuthGuardService as AuthGuard } from 'app/services/auth/auth-guard.service';
import {NgxTwitterTimelineModule} from "ngx-twitter-timeline";

const routes = [
  {
    path: 'pipl',
    component: PIPLComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    PIPLComponent
  ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        PulseSharedModule,

        DxFormModule,
        DxButtonModule,
        DxDataGridModule,
        NgxTwitterTimelineModule,
        DxTabPanelModule,
        DxCheckBoxModule
    ],
  exports: [
    PIPLComponent
  ]
})

export class PiplModule {
}
