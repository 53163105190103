import {Directive, Input, OnInit, HostListener, OnDestroy, HostBinding} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {MediaObserver} from '@angular/flex-layout';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PulseMatchMediaService} from '@pulse/services/match-media.service';
import {PulseMatSidenavHelperService} from '@pulse/directives/pulse-mat-sidenav/pulse-mat-sidenav.service';

@Directive({
    selector: '[pulseMatSidenavHelper]'
})
export class PulseMatSidenavHelperDirective implements OnInit, OnDestroy {
    @HostBinding('class.mat-is-locked-open')
    isLockedOpen: boolean;

    @Input()
    pulseMatSidenavHelper: string;

    @Input()
    matIsLockedOpen: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {PulseMatchMediaService} _pulseMatchMediaService
     * @param {PulseMatSidenavHelperService} _pulseMatSidenavHelperService
     * @param {MatSidenav} _matSidenav
     * @param {ObservableMedia} _observableMedia
     */
    constructor(
        private _pulseMatchMediaService: PulseMatchMediaService,
        private _pulseMatSidenavHelperService: PulseMatSidenavHelperService,
        private _matSidenav: MatSidenav,
        private _observableMedia: MediaObserver
    ) {
        // Set the defaults
        this.isLockedOpen = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Register the sidenav to the service
        this._pulseMatSidenavHelperService.setSidenav(this.pulseMatSidenavHelper, this._matSidenav);

        if (this._observableMedia.isActive(this.matIsLockedOpen)) {
            this.isLockedOpen = true;
            this._matSidenav.mode = 'side';
            this._matSidenav.toggle(true);
        } else {
            this.isLockedOpen = false;
            this._matSidenav.mode = 'over';
            this._matSidenav.toggle(false);
        }

        this._pulseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if (this._observableMedia.isActive(this.matIsLockedOpen)) {
                    this.isLockedOpen = true;
                    this._matSidenav.mode = 'side';
                    this._matSidenav.toggle(true);
                } else {
                    this.isLockedOpen = false;
                    this._matSidenav.mode = 'over';
                    this._matSidenav.toggle(false);
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

@Directive({
    selector: '[pulseMatSidenavToggler]'
})
export class PulsMatSidenavTogglerDirective {
    @Input()
    pulseMatSidenavToggler: string;

    /**
     * Constructor
     *
     * @param {PulseMatSidenavHelperService} _pulseMatSidenavHelperService
     */
    constructor(
        private _pulseMatSidenavHelperService: PulseMatSidenavHelperService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On click
     */
    @HostListener('click')
    onClick(): void {
        this._pulseMatSidenavHelperService.getSidenav(this.pulseMatSidenavToggler).toggle();
    }
}
