import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linkedin-search',
  templateUrl: './linkedin-search.component.html',
  styleUrls: ['./linkedin-search.component.scss']
})
export class LinkedinSearchComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }
    

}
