<aside class="sidebar">
    <div class="sidebar__header">Everything</div>
    <nz-radio-group
        [(ngModel)]="indexType.type"
        (ngModelChange)="indexTypeChangeHandle()"
        [nzSize]="'small'"
    >
        <label
            nz-radio-button
            nz-popover
            nzValue="email"
            [nzPopoverContent]="folderTemplate"
        >
            <i class="fas fa-envelope-open-text"></i>
            {{folderTypeFilter.tabCounts.email | textLengthFormatter : 12}}
        </label>
        <label
            nz-radio-button
            nz-popover
            nzValue="attachment"
            [nzPopoverContent]="attachmentTemplate"
        >
            <i class="fas fa-paperclip"></i>
            {{folderTypeFilter.tabCounts.attachment | textLengthFormatter : 12}}
        </label>
        <ng-template #folderTemplate>
            Mail {{folderTypeFilter.tabCounts.email}}
        </ng-template>
        <ng-template #attachmentTemplate>
            Attachment {{folderTypeFilter.tabCounts.attachment}}
        </ng-template>

    </nz-radio-group>
    <div class="sidebar__body">
        <nz-collapse>
            <nz-collapse-panel
                [nzHeader]="panelMail.name"
                [nzActive]="panelMail.active"
                [nzExpandedIcon]="expandedIconEmail"
            >
                <ng-template #expandedIconEmail>
                    <i class="fas fa-user"></i>
                    <label
                        nz-checkbox
                        (change)="checkAllValue($event, 'user')"
                        [(ngModel)]="asideFilter.userFilter"
                    ></label>
                </ng-template>
                <nz-checkbox-wrapper
                    class="sidebar__body--menu"
                    style="width: 100%;"
                    (nzOnChange)="checkValue($event)"
                >
                    <div
                        nz-row
                        *ngFor="let mail of userMailFilters"
                    >
                        <label
                            nz-checkbox
                            [(ngModel)]="mail.checked"
                        >
                            {{ mail.mail }}
                        </label>
                    </div>
                </nz-checkbox-wrapper>
            </nz-collapse-panel>
        </nz-collapse>
        <nz-collapse *ngIf="folderTypeFilter.filters.length > 0 && indexType.type === 'email'">
            <nz-collapse-panel
                [nzHeader]="panelFolderType.name"
                [nzActive]="panelFolderType.active"
                [nzExpandedIcon]="expandedIconFolder"
            >
                <ng-template #expandedIconFolder>
                    <i class="fas fa-folder"></i>
                    <label
                        nz-checkbox
                        (change)="checkAllValue($event, 'folder')"
                        [(ngModel)]="asideFilter.folderFilter"
                    ></label>
                </ng-template>
                <nz-checkbox-wrapper
                    class="sidebar__body--menu"
                    style="width: 100%;"
                    (nzOnChange)="checkValue($event)"
                >
                    <div
                        nz-row
                        *ngFor="let folder of folderTypeFilter.filters"
                    >
                        <label
                            nz-checkbox
                            [(ngModel)]="folder.checked"
                        >
                            {{ folder.name }} ({{ folder.count }})
                        </label>
                    </div>
                </nz-checkbox-wrapper>
            </nz-collapse-panel>
        </nz-collapse>
        <nz-collapse *ngIf="folderTypeFilter.attachemntFilters.length > 0 && indexType.type !== 'email'">
            <nz-collapse-panel
                nzHeader="Attachment"
                [nzActive]="panelFolderType.active"
                [nzExpandedIcon]="expandedIconFolder"
            >
                <ng-template #expandedIconFolder>
                    <i class="fas fa-folder"></i>
                    <label
                        nz-checkbox
                        (change)="checkAllValue($event, 'folder')"
                        [(ngModel)]="asideFilter.folderFilter"
                    ></label>
                </ng-template>
                <nz-checkbox-wrapper
                    class="sidebar__body--menu"
                    style="width: 100%;"
                    (nzOnChange)="checkValue($event)"
                >
                    <div
                        nz-row
                        *ngFor="let folder of folderTypeFilter.attachemntFilters"
                    >
                        <label
                            nz-checkbox
                            [(ngModel)]="folder.checked"
                        >
                            {{ folder.name }} ({{ folder.count }})
                        </label>
                    </div>
                </nz-checkbox-wrapper>
            </nz-collapse-panel>
        </nz-collapse>
    </div>

</aside>
