import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {siteConfig} from '../../siteSettings';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
        //'Authorization': `JWT ${localStorage.getItem('userToken')}`
    })
};

@Injectable()
export class SmbauthService {

    constructor(
        private _http: HttpClient
    ) {
    }

    twitterLogin(id) {
        let url = siteConfig.nodeAPIBaseUrl + 'smb_auth/request-twitter-token/' + id;
        let data = this._http.get<any>(url, httpOptions);
        return data;
    }

    linkedIn(body: any) {
        let url = siteConfig.nodeAPIBaseUrl + 'smb_auth/linkedIn';
        let data = this._http.post<any>(url, body, httpOptions);
        return data;
    }

    twitter(body: any) {
        let url = siteConfig.nodeAPIBaseUrl + 'smb_auth/twitter';
        let data = this._http.post<any>(url, body, httpOptions);
        return data;
    }

    saveToken(body: any) {
        let url = siteConfig.nodeAPIBaseUrl + 'smb_auth/addToken';
        let data = this._http.post<any>(url, body, httpOptions);
        return data;
    }

    getDataByUserId(id) {
        let url = siteConfig.nodeAPIBaseUrl + 'smb_auth/getToken/' + id;
        let data = this._http.get<any>(url, httpOptions);
        return data;
    }

    deAthorized(body: any) {
        let url = siteConfig.nodeAPIBaseUrl + 'smb_auth/deauthorized';
        let data = this._http.post<any>(url, body, httpOptions);
        return data;
    }

}
