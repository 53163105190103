import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

// VMS List API
import { VmsServiceService } from '../../../../../services/vms-service/vms-service.service' ;

@Component({
  selector: 'app-timesheet-search',
  templateUrl: './timesheet-search.component.html',
  styleUrls: ['./timesheet-search.component.scss']
})
export class TimesheetSearchComponent implements OnInit {

  vmsClientList;
  vmsTimeSheetStatus;
  selectedCLient: any;
  selectedStatus: any;
  selectedDateRange;
  checkBoxTimesheet: boolean;
  workerSearchFilter;
  showFilterRow = false;
  selectedClients;


  //currentDate;
  selectBoxDataSource:any[] = ["Custom Range"];
  m_day;
  dropDownDates;

  minDate = new Date(1900, 0, 1);
  maxDate = new Date();
  currentDate = new Date();
  isPopupVisible;
  istimesheetPopupVisible;
  istimesheetPdfVisible;

  fromDate;
  toDate;

  workerDataSource;
  workerNoTimesheetData: any[] = [];
  timesheetDataSource;
  timesheetId;
  workerTimeSheetCount = 0;
  gridShow: boolean;
  selectedTimesheetId;

  pdfSrc: string;

  menuItems = [
    {
        icon: 'preferences',
        items: [
            {name: 'View PDF', icon: "doc"},
            {name: 'Download', icon: "download"}
        ]
    }
  ]

  constructor(private router: Router,
    private route: ActivatedRoute,
    private vms_Name_List: VmsServiceService,
    public datePipe: DatePipe) { }

    ngOnInit() {
      this.loadClientList();
    }
  
    loadClientList(){
      // VMS Clients
      this.vms_Name_List.getVMSClients().subscribe(data => {
        this.vmsClientList = JSON.parse(JSON.stringify(data));
      });

      // VMS timesheet Status
      this.vms_Name_List.getAllTimesheetStatuses().subscribe(data => {
        this.vmsTimeSheetStatus = JSON.parse(JSON.stringify(data));
      });

      this.nexttestweek();
    }

    nexttestweek(){
      var i, d = new Date;
      for (i = 1; i <= 4; ++i) {
          this.setSundays(d, i);
      }
    }


    setSundays(d, week_starts_monday) {
      var c_day = d.getDay();
      if (week_starts_monday && c_day === 0)
      c_day = 7; // adjust so `0` is last week sunday, 7 is this week sunday
      d.setDate(d.getDate() - c_day );
      this.m_day = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 6);
      this.m_day = this.datePipe.transform(this.m_day, 'yyyy-MM-dd');
      d = this.datePipe.transform(d, 'yyyy-MM-dd');
      this.dropDownDates = this.m_day +' - '+ d;
      this.selectBoxDataSource.push(this.dropDownDates);
      return d;
    }

    selectDateRange(customRange){
      console.log(customRange.value);
      this.selectedDateRange = customRange.value;
      if(this.selectedDateRange == 'Custom Range'){
        this.isPopupVisible = true;
      }else{
        let dateString;
        dateString = customRange.value;
        this.fromDate = dateString.substring(0,10);
        this.toDate = dateString.substring(dateString.length - 10);
      }
    }

    buttonClicked(frmDateVal, toDateVal){
      this.fromDate = this.datePipe.transform(frmDateVal, 'yyyy-MM-dd');
      this.toDate = this.datePipe.transform(toDateVal, 'yyyy-MM-dd');
      this.isPopupVisible = false;
    }

    refreshGridForClient(args) {
      this.selectedCLient= args['value'];
    }
    refreshGridForStatus(args) {
      this.selectedStatus= args['value'];
    }

    checkBoxToggled(e) {
      this.checkBoxTimesheet = e.value;
    };


    searchTiimesheet(){
      if(this.workerSearchFilter == undefined || this.selectedStatus){
        this.workerSearchFilter = '';
        this.selectedStatus = '';
      }
      if(this.checkBoxTimesheet ==  true){
        this.workerDataSource = [];
        this.gridShow = true;
        this.vms_Name_List.getAllWorkersWithNoTimesheetsInPeriod(this.selectedCLient,this.fromDate, 
          this.toDate).subscribe(data => {
            let respData = data;
            for(let i of respData){
              let noTimeSheet = {
                'ClientName': i.ClientName,
                'fullName': i.fullName,
                'timesheet': '-',
                'fromDate': '-',
                'toDate': '-',
                'status': '-',
                'stHours': '-',
                'otHours': '-',
                'dtHours': '-',
                'nbHours': '-',
                'otherHours': '-',
              };
              this.workerNoTimesheetData.push(noTimeSheet);
            }
            this.workerTimeSheetCount = data.length;
        });
      }else{
        this.workerNoTimesheetData = [];
        this.gridShow = false;
        this.vms_Name_List.getAllTimesheetsInPeriod(this.selectedCLient, this.fromDate, 
          this.toDate, this.selectedStatus, this.workerSearchFilter).subscribe(data => {
            this.workerDataSource = data;
            this.workerTimeSheetCount = data.length;
        });
      }
    }

    onCellClick(args){
      this.selectedTimesheetId = args['data'].id;
      let resp = args.value;
      if(resp != undefined){
        this.timesheetId = args.value;
        this.vms_Name_List.getTimesheetsForWoker(this.selectedCLient, args.value).subscribe(data => {
          this.istimesheetPopupVisible = true;
          this.timesheetDataSource = data;
        });
      }
    }

    optionClick(event: any) {
      if(event.itemData.name === 'View PDF') {
        this.vms_Name_List.getTimesheetPDFView(this.selectedCLient, this.selectedTimesheetId).subscribe(data => {
          var file = new Blob([JSON.parse(data['_body'])], { type: 'application/pdf' });            
          var fileURL = URL.createObjectURL(file);
          this.pdfSrc = fileURL;
          this.istimesheetPdfVisible = true;
          //window.open(fileURL);
        });
      }else if(event.itemData.name === 'Download') {
        this.vms_Name_List.getTimesheetPDFURL(this.selectedCLient, this.selectedTimesheetId).subscribe(data => {
        });
      }
    }

    toggleFilter() {
      if(this.showFilterRow==false){
          this.showFilterRow = true;
      }else{
          this.showFilterRow = false;
      }
    }

}
