import {Injectable} from '@angular/core';
import * as io from 'socket.io-client';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {PulseUtils} from '@pulse/utils';
import {siteConfig} from '../../siteSettings';
import {CommonService} from '../common/common.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    // Connect to the socket
    private socket = io(`${siteConfig.socketNetworkName}`, {
        secure: true,
        query: {
            token: 'JWT ' + localStorage.getItem('userToken')
        }
    });
    // private socket = io("http://localhost:7050");

    contacts: any[];
    chats: any[];
    user: any;
    loggedInUserDetails: any;
    selectedUserId = 0;
    onChatSelected: BehaviorSubject<any>;
    onContactSelected: BehaviorSubject<any>;
    onChatsUpdated: Subject<any>;
    onUserUpdated: Subject<any>;
    onEditContactSelected: Subject<any>;
    onContactUpdated: Subject<any>;
    onLeftSidenavViewChanged: Subject<any>;
    onRightSidenavViewChanged: Subject<any>;

    countryCodes: any = [];
    socketUserId = 0;
    sockerUserStatus = '';
    defaultMessageType = 'sms';
    selectedChatObj: any = {};

    constructor(
        private _httpClient: HttpClient,
        private commonService: CommonService
    ) {

        this.loggedInUserDetails = JSON.parse( localStorage.getItem('userData') );

        // Set the defaults
        this.onChatSelected = new BehaviorSubject(null);
        this.onContactSelected = new BehaviorSubject(null);
        this.onChatsUpdated = new Subject();
        this.onUserUpdated = new Subject();
        this.onEditContactSelected = new Subject();
        this.onLeftSidenavViewChanged = new Subject();
        this.onRightSidenavViewChanged = new Subject();

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getContacts(),
                this.getChats(),
                this.getUser(),
                this.commonService.getCountryCodes()
            ]).then(
                ([contacts, chats, user, countryCodes]) => {
                    this.contacts = contacts;
                    this.chats = chats;
                    this.user = user;
                    this.countryCodes = countryCodes;
                    resolve();
                },
                reject
            );
        });
    }

    getJoinInformationFromSocket(): any {
        const observableMessage = new Observable<{userid: number, message: string}>(observable => {

            this.socket.on('New user Joined', (data) => {
                console.log('Socket Working Inside');
                observable.next(data);
            });

            return () => {};
        });

        return observableMessage;
    }

    getJoinInformationFromCommonRoom(): any {
        const observableMessage = new Observable<{userid: number, message: string}>(observable => {

            this.socket.on('user joined commonroom', (data) => {
                console.log('Common room working');
                observable.next(data);
            });

            return () => {};
        });

        return observableMessage;
    }

    getNewMessageInformationFromSocket(): any {
        const observableMessage = new Observable<{user: string, message: any}>(observable => {

            this.socket.on('new message received', (data) => {
                // console.log('new message received');
                // console.log(data);
                // console.log(data.message.conversation_id);
                // console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXX");
                // console.log(this.chats);

                let chatData = {};
                for (let i = 0; i < this.chats.length ; i++) {

                    if ( data.message.conversation_id[0] != this.chats[i].conversation_id[0]) {
                        continue;
                    }
                    chatData = this.chats[i];
                }

                // Check for candidate logs in
                let isCandidate = 0;
                if (this.loggedInUserDetails.id == data.message.candidate_id) {
                    isCandidate = 1;
                }

                let ownerType = 0;
                if ( isCandidate == 1) {

                    if ( this.loggedInUserDetails.id == data.message.created_by ) {
                        ownerType = 1;
                    }

                } else {

                    const found = data.message.user_ids.find(function(element) {
                        return element == data.message.created_by;
                    });

                    if (found != undefined) {
                        ownerType = 1;
                    }

                }

                chatData['dialog'].push ( {
                    who: data.message.created_by,
                    message: data.message.message,
                    toWhomName : data.message.toWhomName,
                    time: new Date(),
                    messageType: data.message.type,
                    sms_delivered: false,
                    sms_sid: data.sms_sid,
                    sms_status: '',
                    ownerType: ownerType
                });

                // console.log("========= New Chat Data ========");
                // console.log( chatData );

                data['pageScroll'] = 0;
                if ( this.selectedUserId ==  data.message.created_by) {
                    this.onChatSelected.next({...chatData});
                    data['pageScroll'] = 1;
                }

                observable.next(data);
            });

            return () => {};
        });

        return observableMessage;
    }

    newMemberJoined(user, roomName): any {
        this.socket.emit('join', {user: user, room: roomName});
    }

    applyUserStatusFromSocket(): any {
        const observableMessage = new Observable<{userID: string, userStatus: string}>(observable => {

            this.socket.on('user Status Changed Received', (data) => {
                console.log(data);

                this.socketUserId = data.userID;
                this.sockerUserStatus = data.userStatus;

                observable.next(data);
            });

            return () => {};
        });

        return observableMessage;
    }

    chageBadgeCountFromSocket(): any {
        const observableMessage = new Observable<{userID: string, userStatus: string}>(observable => {

            this.socket.on('incrementBadge received', (data) => {
                console.log(data);
                observable.next(data);
            });

            return () => {};
        });

        return observableMessage;
    }

    smsStatusUpdateReceivedFromSocket(): any {
        const observableMessage = new Observable<{userID: string, userStatus: string}>(observable => {

            this.socket.on('smsStatusUpdated', (data) => {
                console.log(data);
                observable.next(data);
            });
            return () => {};
        });

        return observableMessage;
    }

    /**
     * Get chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    getChat(contactId, inernalCall= 0): Promise<any> {

        // console.log( "contact ID" );
        // console.log(contactId);
        // console.log("===========================");
        // console.log(this.contacts);
        // console.log( this.contacts[contactId] );

        this.selectedUserId = contactId;
        // this.socket.emit('join', selectedContactDetails['email'] + "_" + contactId);

        // if( inernalCall==0 ){
        //     this.newMemberJoined(this.user['name'], this.user['email'] + "_" + this.loggedInUserDetails.id);
        // }

        const chatItem = this.user.chatList.find((item) => {
            return item.contactId === contactId;
        });

        // Create new chat, if it's not created yet.
        if (!chatItem) {

            this.createNewChatForInternalUser(contactId).then((newChats) => {
                this.getChat(contactId);
            });
            return;
        }

        return new Promise((resolve, reject) => {

            let chatContact = this.contacts.find((contact) => {
                return contact.user_id == contactId;
            });

            if ( chatContact == undefined ) {
                chatContact = {
                    avatar: chatItem.avatar,
                    country_code: chatItem.country_code,
                    email: chatItem.email,
                    first_name: chatItem.first_name,
                    jobDivaWithURL: chatItem.jobDivaWithURL,
                    jobdiva_id: chatItem.jobdiva_id,
                    last_name: chatItem.last_name,
                    mobile_no: chatItem.mobile_no,
                    mood: chatItem.mood,
                    name: chatItem.name,
                    sms_number_twillio: chatItem.sms_number_twillio,
                    status: chatItem.status,
                    user_id: chatItem.contactId,
                    voip_number: chatItem.voip_number
                };
            }

            let dataToMatch = 0;
            if ( this.loggedInUserDetails.id ==  chatItem.contactId ) {
                dataToMatch = chatItem.id;
            } else {
                dataToMatch = chatItem.contactId;
            }

            let chatData = {};
            for (let i = 0; i < this.chats.length ; i++) {

                if (dataToMatch != this.chats[i].id) {
                    continue;
                }
                chatData = this.chats[i];
            }

            const convID = chatData['conversation_id'][0];
            let url = `${siteConfig.nodeAPIBaseUrl}messages/getConversationByConvID?searchType=2&searchValue=${convID}`;

            debugger;

            this._httpClient.get(url)
            .subscribe((response: any) => {

                debugger;

                chatData['contact'] = chatContact;

                this.newMemberJoined(this.user['name'], chatData['conversation_id'][0]);

                chatData['dialog'] = response['data'][0]['dialog'];

                this.onChatSelected.next({...chatData});

                // Reset the count of the Selected User
                url = `${siteConfig.nodeAPIBaseUrl}/messages/clearBadge`;
                const reqData = {
                    conversationId : chatData['conversation_id'][0],
                    userID : contactId
                };

                this._httpClient.post(url, reqData)
                .subscribe((response: any) => {
                    resolve( chatData );
                });

            });

        });
    }

    createNewChatForInternalUser(contactId): Promise<any> {
        return new Promise((resolve, reject) => {

            const finalInviteData = {
                customer_id : 1,
                userId : this.loggedInUserDetails.id,
                contactId : contactId
            };

            this.sendInviteToInternalUser(finalInviteData)
            .subscribe(data => {

                const resp = data['data'];

                const chatDataNew = {
                    conversation_id : resp['conversation_id'],
                    id : resp['contactId']
                };

                const chatListItemNew = {
                    avatar: resp['avatar'],
                    contactId: resp['contactId'],
                    id: resp['id'],
                    lastMessage: resp['lastMessage'],
                    lastMessageTime: resp['lastMessageTime'],
                    name: resp['name'],
                    status: data['status'],
                    unread: resp['unread'],
                    messageType: resp['messageType']
                };

                this.createNewChatNew(chatDataNew, chatListItemNew, '', 0);

                return;
            });

        });
    }

    createNewChatNew(chatDataNew, chatListItemNew, newUserDetails, inviteFlag= 0): Promise<any> {

        return new Promise((resolve, reject) => {

            const chat = chatDataNew;

            // Add new chat list item to the user's chat list
            this.user.chatList.push(chatListItemNew);

            this.chats.push(chat);

            if ( inviteFlag == 1 ) {
                this.contacts.push(newUserDetails[0]);
            }

            this.getUser().then(updatedUser => {
                this.onUserUpdated.next(updatedUser);
                resolve(updatedUser);
            });

        });

    }

    /**
     * Create new chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    createNewChat(contactId): Promise<any> {
        return new Promise((resolve, reject) => {

            const contact = this.contacts.find((item) => {
                return item.id === contactId;
            });

            const chatId = PulseUtils.generateGUID();

            const chat = {
                id: chatId,
                dialog: []
            };

            const chatListItem = {
                contactId: contactId,
                id: chatId,
                lastMessageTime: '2017-02-18T10:30:18.931Z',
                name: contact.name,
                unread: null
            };

            // Add new chat list item to the user's chat list
            this.user.chatList.push(chatListItem);

            this.chats.push(chat);

            this.getUser().then(updatedUser => {
                this.onUserUpdated.next(updatedUser);

                resolve(updatedUser);
            });


            // Post the created chat
            // this._httpClient.post('api/chat-chats', {...chat})
            //     .subscribe((response: any) => {
            //
            //         // Post the new the user data
            //         this._httpClient.post('api/chat-user/' + this.user.id, this.user)
            //             .subscribe(newUserData => {
            //
            //                 // Update the user data from server
            //                 this.getUser().then(updatedUser => {
            //
            //                 });
            //             });
            //     }, reject);
        });
    }

    /**
     * Select contact
     *
     * @param contact
     */
    selectContact(contact): void {
        this.onContactSelected.next(contact);
    }

    /**
     * Set user status
     *
     * @param status
     */
    setUserStatus(status): void {

        console.log('=======XXXXXXxx============');

        const url = `${siteConfig.nodeAPIBaseUrl}/users/updateStautsFromChat`;
        const userStausData = {
            userID: this.loggedInUserDetails.id,
            status: status
        };
        this._httpClient.post(url, userStausData);

        this.user.status = status;
    }

    setUserStatusNew(status): Promise<any> {
        return new Promise((resolve, reject) => {

            const url = `${siteConfig.nodeAPIBaseUrl}/users/updateStautsFromChat`;
            const userStausData = {
                userID: this.loggedInUserDetails.id,
                status: status
            };

            this._httpClient.post(url, userStausData)
            .subscribe((response: any) => {
                this.user.status = status;

                this.socket.emit('userStatusChangedApplied', {userID: this.loggedInUserDetails.id, userStatus: status});

            }, reject);
        });
    }

    /**
     * Update user data
     *
     * @param userData
     */
    updateUserData(userData): void {

        // console.log(userData);

        const url = `${siteConfig.nodeAPIBaseUrl}/users/updateStautsFromChat`;
        const userStausData = {
            userID: this.loggedInUserDetails.id,
            status: userData.status
        };

        this._httpClient.post(url, userStausData)
        .subscribe((response: any) => {
            this.user.status = userData.status;

            this.socket.emit('userStatusChangedApplied', {userID: this.loggedInUserDetails.id, userStatus: userData.status});

        });
    }

    /**
     * Update the chat dialog
     *
     * @param chatId
     * @param dialog
     * @returns {Promise<any>}
     */
    updateDialog(dialog): Promise<any> {


        dialog['to'] = this.selectedUserId;
        // dialog['created_byid'] = this.loggedInUserDetails.id;

        // let selectedContactDetails = [];

        // for (let contactLp = 0; contactLp < this.contacts.length; contactLp++) {
        //     if ( this.contacts[contactLp].user_id == this.selectedUserId ) {
        //         selectedContactDetails = this.contacts[contactLp];
        //     }
        // }

        const url = `${siteConfig.nodeAPIBaseUrl}/messages/comment`;
        return new Promise((resolve, reject) => {

            this._httpClient.post(url, dialog)
            .subscribe(updatedChat => {

                // console.log("=====Last message details=====");
                // console.log(updatedChat);

                dialog['user_id'] = updatedChat['messageData']['user_id'];
                dialog['candidate_id'] = updatedChat['messageData']['candidate_id'];

                dialog['userUnReadCount'] = updatedChat['messageData']['userUnReadCount'] + 1;
                dialog['candidateUnReadCount'] = updatedChat['messageData']['candidateUnReadCount'] + 1;
                dialog['sms_delivered'] = false;
                dialog['sms_sid'] = updatedChat['messageData']['sms_sid'];
                dialog['user_ids'] = updatedChat['messageData']['user_ids'];
                dialog['toWhomName'] = this.loggedInUserDetails.first_name + ' ' + this.loggedInUserDetails.last_name;

                // Emit message to sent to a room
                this.socket.emit('new message sent', {room: updatedChat['messageData']['_id'], message: dialog});
                // Emit message for count
                this.socket.emit('incrementBadge', {room: updatedChat['messageData']['_id'], message: dialog});

                resolve(updatedChat);
            }, reject);

        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(): Promise<any> {

        const url = `${siteConfig.nodeAPIBaseUrl}/messages/getContantListByUser?userID=${this.loggedInUserDetails.id}`;
        return new Promise((resolve, reject) => {
            // resolve(ChatFakeDb.contacts);
            this._httpClient.get(url)
                .subscribe((response: any) => {
                    resolve(response.data);
                }, reject);
        });
    }

    /**
     * Get chats
     *
     * @returns {Promise<any>}
     */
    getChats(): Promise<any> {
        return new Promise((resolve, reject) => {
            // resolve(ChatFakeDb.chats);

            const url = `${siteConfig.nodeAPIBaseUrl}/messages/getAllChatByUser?userID=${this.loggedInUserDetails.id}`;

            this._httpClient.get(url)
                .subscribe((response: any) => {

                    // console.log('ChatData', response.data);

                    resolve(response.data);
                }, reject);
        });
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any> {
        return new Promise((resolve, reject) => {

            // resolve(ChatFakeDb.user);
            const url = `${siteConfig.nodeAPIBaseUrl}/messages/getConversationByUser?userID=${this.loggedInUserDetails.id}`;
            this._httpClient.get(url)
                .subscribe((response: any) => {
                    resolve(response.data);
                }, reject);
        });
    }

    /**
     *
     * @param reqData
     */
    sendInvite(reqData: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}/messages/invite`;
        return this._httpClient.post(url, reqData);
    }

    /**
     *
     * @param reqData
     */
    sendInviteToInternalUser(reqData: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}/messages/inviteToInternal`;
        return this._httpClient.post(url, reqData);
    }

    /**
     *
     * @param reqData
     */
    getTemplateList() {
        const url = `${siteConfig.nodeAPIBaseUrl}messages/getTemplateList`;
        return this._httpClient.get<any>(url);
    }

    updateCandidateData(userId, postData) {
        return new Promise((resolve, reject) => {
            const url = `${siteConfig.nodeAPIBaseUrl}users/updateUser/${userId}`;
            this._httpClient.put(url, postData)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    deleteContact(userId) {
        // const url = `${siteConfig.nodeAPIBaseUrl}users/deleteUser/${userId}`;
        const url = `${siteConfig.nodeAPIBaseUrl}users/deleteUser/${userId}`;
        return this._httpClient.delete<any>(url);
    }

    getChatObject(chat) {
        this.selectedChatObj = chat;
    }

}
