import {Component, OnInit} from '@angular/core';
import {CalendarService} from '../../../../services/calendar/calendar.service';
import {CommonService} from '../../../../services/common/common.service';
import {forkJoin} from 'rxjs';
import {SwalService} from '../../../../services/swal.service';

@Component({
    selector: 'app-pending-approvals',
    templateUrl: './pending-approvals.component.html',
    styleUrls: ['./pending-approvals.component.scss']
})
export class PendingApprovalsComponent implements OnInit {
    pendingApprovals = [];
    menuItems = [
        {
            icon: 'preferences',
            items: [
                {name: 'Approve', icon: 'edit', id: 'approve'},
                {name: 'Delete', icon: 'clear', id: 'delete'},

            ]
        }
    ];
    eventTypes = {};
    dxDateTimeFormat = 'MM-dd-yyyy hh:mm a';
    dateTimeFormat = 'MM-dd-yyyy hh:mm a';
    updateEventPopup = false;
    updateEventData: any = {};
    selectedEvent: any = {};

    constructor(private calenderService: CalendarService,
                private commonService: CommonService,
                private swalService: SwalService
    ) {
    }

    ngOnInit() {
      this.retrievePendingApprovals();
    }

    retrievePendingApprovals() {
        forkJoin([this.calenderService.getPendingApprovals(),
            this.commonService.getMasterData('eventTypes')]).subscribe((results: any) => {
            // results[0] is our character
            // results[1] is our character homeworld
            console.log(results);
            if (results[1].code === 200) {
                const result = results[1]['data'];
                for (let i = 0; i < result.length; i++) {
                    this.eventTypes[result[i].value] = result[i].master_lookup_name;
                }
            }
            if (results[0].code === 200) {
                this.pendingApprovals = results[0].data;
                this.pendingApprovals.map(record => {
                    const contacts = record.company_contacts ? JSON.parse(record.company_contacts) : [];
                    record.eventType = this.eventTypes[record.event_type];
                    console.log(contacts);
                    record.contactDetails = contacts.find(x => x.id === record.contact_id);
                    // record.start = new Date(record.start);
                    // record.end = new Date(record.end);
                });
            }

        });
    }

    getPendingApprovals() {
        this.calenderService.getPendingApprovals().subscribe((response: any) => {
            console.log(response);
            if (response.code == 200) {
                this.pendingApprovals = response.data;
                this.pendingApprovals.map(record => {
                    record.eventType = this.eventTypes[record.event_type];
                });
            }
        });
    }

    getEventTypes() {
        this.commonService.getMasterData('eventTypes').subscribe(response => {
            const result = response['data'];
            for (let i = 0; i < result.length; i++) {
                this.eventTypes[result[i].value] = result[i].master_lookup_name;
            }
        });
    }

    updateRequest(req) {
        const postData = {
            status: req,
            remarks: this.updateEventData.remarks || null,
            eventId: this.selectedEvent.event_id
        };
        this.calenderService.updatePendingApprovals(postData).subscribe((response: any) => {
            if (response.code === 200) {
                this.updateEventPopup = false;
                const swalData = {
                    title: 'Company Created',
                    message: `Company is successfully created!`,
                    info: 'Company list is updated in form!'
                };
                this.swalService.success(swalData);
                this.getPendingApprovals();
            }
        });
    }

    openUpdateEventPopup(e, event) {
        this.updateEventPopup = true;
        this.selectedEvent = event;
    }
}
