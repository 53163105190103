import {Component, OnInit} from '@angular/core';
import {RolesService} from 'app/services/roles/role.service'
import {Router} from '@angular/router';
import {SwalService} from '../../../../services/swal.service';

export interface PeriodicElement {
    name: string;
    role_id: number;
    is_active: number;
    description: string;
}

@Component({
    selector: 'app-role-list',
    templateUrl: './role-list.component.html',
    styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {
    displayedColumns: string[] = ['role_id', 'name', 'is_active', 'description'];
    dataSource: PeriodicElement[];

    constructor(
        private rolesService: RolesService,
        private router: Router,
        private swalService: SwalService) {
        this.getRoleList();

    }

    currentSelectedObject: PeriodicElement;

    menuItems = [
        {
            icon: 'preferences',
            items: [
                {name: 'Edit', icon: 'edit', id: 'edit'},
                {name: 'Delete', icon: 'clear', id: 'delete'},
                {name: 'Permission', icon: 'selectall', id: 'permission'}
            ]
        }
    ];

    ngOnInit() {

    }

    // Getting API for getRoleList
    getRoleList() {
        // Getting API for getRoleList
        let swalData = {};
        this.rolesService.getRoles()
            .subscribe(
                response => {
                    this.dataSource = response['data'];
                    console.log('this.dataSource', this.dataSource);
                },
                error => {
                    swalData = {
                        title: 'Error',
                        message: `Unable to fetch roles !`,
                        info: 'Server error'
                    };
                    this.swalService.error(swalData)
                });
    }

    navigateToAdd() {
        this.router.navigate(['/role/add']);
    }

    // selectedAction(ev){
    //   console.log(ev);
    //   if(ev.id == 1){
    //     this.rolesService.setRole(this.currentSelectedObject);
    //     this.router.navigate(['/role/edit/'+this.currentSelectedObject.role_id]);
    //   }
    //   if(ev.id == 2){
    //     this.deleteRole();
    //   }
    // }

    onRowClickData(data) {
        this.currentSelectedObject = data;
        console.log(data);
    }

    deleteRole() {
        let self = this;
        let swalData = {
            title: 'Delete Record',
            message: `Are you sure you want to delete role :` + self.currentSelectedObject.name + '?',
            info: 'Delete action cannot be reverted!'
        };
        this.swalService.delete(swalData).then(function (result) {
            if (result.value) {
                self.rolesService.deleteRole(self.currentSelectedObject.role_id).subscribe(response => {
                    console.log(response);
                    swalData = {
                        title: 'Success',
                        message: `Role deleted successfully!`,
                        info: ''
                    };
                    self.swalService.success(swalData)
                    self.getRoleList();
                }, (error) => {
                    swalData = {
                        title: 'Error',
                        message: `Unable to delete role!`,
                        info: 'Server error'
                    };
                    self.swalService.error(swalData)
                });
            }
        });

        // this.rolesService.deleteRole(this.currentSelectedObject.role_id)
        //   .subscribe(
        //     response => {
        //       console.log(response);
        //       this.getRoleList();
        //      },
        //     error => { });
    }

    optionClick(event: any, record) {
        if (event.itemData.id == 'permission') {
            this.router.navigate(['role-permissions/' + record.data.role_id, {name: record.data.name}]);
        }
        else if (event.itemData.id == 'edit') {
            this.rolesService.setRole(this.currentSelectedObject);
            this.router.navigate(['/role/edit/' + this.currentSelectedObject.role_id]);
        }
        else if (event.itemData.id == 'delete') {
            this.deleteRole();
        }
    }

}
