import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SwalService} from 'app/services/swal.service';
import {CommonService} from '../../services/common/common.service';
import io from 'socket.io-client';
import {siteConfig} from '../../siteSettings';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {AudioHelper} from './audio-helper';
import {DomSanitizer} from '@angular/platform-browser';

const MOMENT_FORMAT = 'DD-MM-YYYY';
const TIME_FORMAT = 'hh:mm A';

@Component({
    selector: 'candidate-chat',
    templateUrl: './candidate-chat.component.html',
    styleUrls: ['./candidate-chat.component.scss']
})
export class CandidateChatComponent implements OnInit, OnDestroy {

    sendDisable = false;
    textareaDisable = false;
    recording = false;
    audioHelper: AudioHelper = null;
    recordingDuration = '';
    token = '';
    blob: any = null;
    socket = null;
    chatsByDate: any = {};
    yesterdayDate = moment().local().subtract(1, 'day').format(MOMENT_FORMAT);
    todayDate = moment().local().format(MOMENT_FORMAT);
    _moment = moment;
    _Object = Object;
    TIME_FORMAT = TIME_FORMAT;
    textMessage = '';
    insertedMessageData = null
    fromUserType = 'c';
    fromUserId = null;
    toUserType = 'r';
    toUserId = null;
    docImgElement = null;
    @ViewChild('scrollMe') private myScrollContainer: ElementRef;

    constructor(private route: ActivatedRoute,
                protected _sanitizer: DomSanitizer,
                private commonService: CommonService,
                private swalService: SwalService) {
    }

    ngOnInit() {
        setTimeout(() => {
            document.getElementById('atlwdg-trigger').remove();
            document.getElementById('atlwdg-trigger').remove();
            this.docImgElement = document.getElementsByClassName('logo-icon')[0];
            this.docImgElement.setAttribute('src', '/assets/images/logo/aptask-chat.png');
            this.docImgElement.style.marginLeft = '10px';
            this.docImgElement.style.marginRight = '10px';
            this.docImgElement.style.width = '40px';
            const span = document.createElement('span');
            span.id = 'recruiterName';
            this.docImgElement.parentElement.appendChild(span);
        }, 500);

        this.route.params.subscribe(params => {
            if (params.code) {
                console.log('params.code', params.code);
                this.token = params.code;
                console.log('---------------', `${siteConfig.chatServer}?token=${params.code}`);
                this.socket = io(`${siteConfig.chatServer}?token=${params.code}`, {
                    secure: true
                });
                this.socket.on('connected', (res) => {
                    this.chatsByDate = {};
                    console.log(res);

                    if (res.status) {
                        this.fromUserId = res.data.candidate.id;
                        this.toUserId = res.data.user.user_id;
                        this.socket.emit('get-candidate-chats', {
                            userId: res.data.candidate.id,
                            personId: res.data.user.user_id,
                            userType: 'c',
                            personType: 'r'
                        });
                    }

                    try {
                        const content = this.docImgElement.parentElement.inn;
                        // this.docImgElement.parentElement.append(`${res.data.user.first_name} ${res.data.user.last_name}`);
                        document.getElementById('recruiterName').innerText = `${res.data.user.first_name} ${res.data.user.last_name}`;
                    } catch (e) {
                        console.log(e);
                    }
                });
                this.socket.on('error', (err) => {
                    console.log(err);
                });
                this.socket.on('candidate-chats', (res) => {
                    console.log('candidate-chats', res);
                    if (res.status) {
                        res.data.chats.forEach(each => {
                            this.pushToChatList(each);
                        });
                        this.scrollToBottom();
                        this.markAllRead();
                    }
                });
                this.socket.on('private-message', (res) => {
                    console.log('private-message', res);
                    if (res.status) {
                        this.pushToChatList(res.data.messageData);
                        this.scrollToBottom();
                        this.markAllRead();
                    }
                });
                this.socket.on('set-message-status', (res) => {
                    console.log('set-message-status', res);
                    if (res.status) {
                        if (res.data.statusData.personId == this.toUserId && res.data.statusData.personType == this.toUserType) {
                            this.updateMessageStatus(res.data.statusData.message_status);
                        }
                    }
                });
            }
        });
    }

    markAllRead() {
        this.socket.emit('mark-all-read', {
            userId: this.fromUserId,
            personId: this.toUserId,
            userType: this.fromUserType,
            personType: this.toUserType
        });
    }

    updateMessageStatus(status) {
        // tslint:disable-next-line:forin
        for (const key in this.chatsByDate) {
            // tslint:disable-next-line:forin
            for (const index in this.chatsByDate[key].data) {
                this.chatsByDate[key].data[index].message_status = status;
            }
        }
    }

    ngOnDestroy(): void {
        this.socket.disconnect();
    }

    getJsonMedia(media): any {
        try {
            return JSON.parse(media);
        } catch (e) {
            return media;
        }
    }

    pushToChatList(item, convertMediaToJson = true): any {
        this.yesterdayDate = moment().local().subtract(1, 'day').format(MOMENT_FORMAT);
        this.todayDate = moment().local().format(MOMENT_FORMAT);
        const currentMessageDate = moment.utc(item.created_at).local().format(MOMENT_FORMAT);
        if (!this.chatsByDate[currentMessageDate]) {
            this.chatsByDate[currentMessageDate] = {date: currentMessageDate, data: []};
        }
        if (convertMediaToJson) {
            item.media = this.getJsonMedia(item.media);
        }
        this.chatsByDate[currentMessageDate].data.push(item);
        return {
            key: currentMessageDate,
            index: (this.chatsByDate[currentMessageDate].data.length - 1)
        };
    }

    scrollToBottom(): void {
        setTimeout(() => {
            try {
                this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
            } catch (err) {
                console.log(err);
            }
        }, 200);
    }

    sendMessage() {
        if (this.textMessage && this.textMessage.trim() !== '') {
            this.socket.emit('send-private-message', {
                fromUserId: this.fromUserId,
                toUserId: this.toUserId,
                message: this.textMessage,
                messageType: 'sms',
                fromType: this.fromUserType,
                toType: this.toUserType
            });

            this.pushToChatList({
                created_at: moment.utc().format(),
                fromUserId: this.fromUserId,
                from_type: this.fromUserType,
                media: null,
                message: this.textMessage,
                message_status: 1,
                toUserId: this.toUserId,
                to_type: this.toUserType
            });
            this.scrollToBottom();

            this.textMessage = '';
        } else {
            // error message
        }
    }

    startRecord() {
        if (this.recording) {
            // stop recording
            this.audioHelper.stopRecording().then(blob => {
                this.textareaDisable = false;
                this.recording = false;
                this.blob = blob;

                this.insertedMessageData = this.pushToChatList({
                    created_at: moment.utc().format(),
                    fromUserId: this.fromUserId,
                    from_type: this.fromUserType,
                    media: {
                        type: 'audio',
                        // tslint:disable-next-line:max-line-length
                        src: this._sanitizer.bypassSecurityTrustUrl(this.audioHelper.getUrlFromBlob(this.blob)),
                        duration: this.recordingDuration
                    },
                    message: '',
                    message_status: 0,
                    toUserId: this.toUserId,
                    to_type: this.toUserType
                }, false);
                this.sendAudioMessage();
                this.scrollToBottom();
            }).catch(e => {
                this.swalService.error({message: e.message, info: ''});
                console.log(e);
            });
        } else {
            // start recording
            this.audioHelper = new AudioHelper();
            this.audioHelper.startRecording().then((res: any) => {
                if (res.started) {
                    this.recordingDuration = '00:00';
                    this.textMessage = '';
                    this.textareaDisable = true;
                    this.recording = true;
                    this.audioHelper.onRecording((duration: any) => {
                        this.recordingDuration = duration.displayedElapsedTime;
                    });
                }
            }).catch(e => {
                this.swalService.error({message: e.message, info: ''});
                console.log(e);
            });
        }
    }

    sendAudioMessage(): void {
        const fd = new FormData();

        fd.append('fromUserId', this.fromUserId);
        fd.append('toUserId', this.toUserId);
        fd.append('message', '');
        fd.append('messageType', 'sms');
        fd.append('fromType', this.fromUserType);
        fd.append('toType', this.toUserType);
        fd.append('token', this.token);
        fd.append('file', this.audioHelper.getMP3FileFromBlob(this.blob));
        fd.append('duration', this.recordingDuration);
        fd.append('mySocketId', this.socket.id);

        this.commonService.sendChatAudio(fd).subscribe((res: any) => {
            if (res.code == 200) {
                if (this.insertedMessageData) {
                    /** if recruiter is online => this status automatically become 2. So need to check for 0 **/
                    if (this.chatsByDate[this.insertedMessageData.key].data[this.insertedMessageData.index].message_status == 0) {
                        this.chatsByDate[this.insertedMessageData.key].data[this.insertedMessageData.index].message_status = 1;
                    }
                }
                this.blob = null;
            } else {
                this.swalService.error({message: 'Could not send audio', info: ''});
                if (this.insertedMessageData) {
                    delete this.chatsByDate[this.insertedMessageData.key].data[this.insertedMessageData.index];
                }
            }
        });
    }
}
