import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UsersService} from "../../../../services/users/users.service";
import {SwalService} from "../../../../services/swal.service";
import {UploadService} from "../../../../services/upload/upload.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DxFileUploaderComponent} from "devextreme-angular";
import {siteConfig} from '../../../../siteSettings';

declare var MediaRecorder: any;
declare var window: any;

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent implements OnInit {

    @ViewChild('gum') gumVideo: ElementRef;
    @ViewChild('errorMsg') errorMsgElement: ElementRef;
    @ViewChild('profileUploader') dxFileUploader: DxFileUploaderComponent;
    @ViewChild('introElement') videoPlayer: ElementRef;

    currentUser: any = {};
    userName: string = '';
    introUrl: string = null;
    profileImage: any = [];
    profileImageUrl: string = '/assets/images/avatars/profile.jpg';
    profilePreviewUrl: string = '/assets/images/avatars/profile.jpg';
    defaultImageUrl: string = '/assets/images/avatars/profile.jpg';

    recordedBlobs: any = [];
    loginHistory: any = null;
    isStarted = false;
    downloadButtonDisabled = true;
    playButtonDisabled = true;
    stopRecButtonDisabled = true;
    mediaRecorder: any;
    downloadUrl: any = '';
    cameraStarted = false;
    displayTimer = {
        minutes: 0,
        seconds: 0
    };
    timerId: any;
    loadingVisible = false;
    popupVisible = false;
    passwordPattern: string = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d@$!%*?&]{8,}$`
    changeEditorOption:any;
    currentPasswordMode:string = "password"
    newPasswordMode:string = "password"
    conNewPasswordMode:string = "password"

    constructor(private userService: UsersService,
                private swalService: SwalService,
                private route: ActivatedRoute,
                private router:Router,
                private uploadService: UploadService) {
        this.currentUser = JSON.parse(localStorage.getItem('userData'));

        this.profileImageUrl = this.currentUser.avatar;
        this.profilePreviewUrl = this.currentUser.avatar;
        this.introUrl = this.currentUser.video_intro_url;
        this.userName = this.currentUser.first_name + ' ' + this.currentUser.last_name;
        this.setUserProfile();
        this.changeCurrentPasswordInputMode = this.changeCurrentPasswordInputMode.bind(this);
        this.changeNewPasswordInputMode = this.changeNewPasswordInputMode.bind(this);
        this.changeConNewPasswordInputMode = this.changeConNewPasswordInputMode.bind(this);
        this.userService.getUserLoginHistory().subscribe((response:any) => {
            if(response.code == 200){
                this.loginHistory = response.data;
            }
        });

    }



    ngOnInit() {
        this.changeEditorOption = {mode:'password'};
    }

    setUserProfile(){
        this.userService.getUserDetail().subscribe((response:any) => {
            if(response.code == 200){
                let userData = response.data;
                this.currentUser.first_name = userData.first_name;
                this.currentUser.first_name = userData.first_name;
                this.currentUser.last_name = userData.last_name;
                this.currentUser.mobile_no = userData.mobile_no;
                this.currentUser.social_link = userData.social_link;
                this.currentUser.calendly_link = userData.calendly_link;
                this.currentUser.email = userData.email;
                this.currentUser.voip_number = userData.voip_number;
                this.currentUser.voip_ext = userData.voip_ext;
                this.currentUser.sms_number_twillio = userData.sms_number_twillio;
                this.currentUser.reporting_manager = userData.reporting_manager;
                this.currentUser.chatUrl = `${siteConfig.webChatUrl}${userData.wc_user_id}`;
            }
        });
    }

    ngAfterViewInit() {
        this.gumVideo.nativeElement.volume = 0;
    }

    saveProfile(e) {
        const postData = {
            first_name: this.currentUser.first_name,
            last_name: this.currentUser.last_name,
            email: this.currentUser.email,
            mobile_no: this.currentUser.mobile_no,
            id: this.currentUser.id,
            social_link: this.currentUser.social_link,
            calendly_link: this.currentUser.calendly_link,
        }
        this.loadingVisible = true;
        this.userService.updateUserProfile(postData).subscribe(response => {
                this.userService.userUpdatedSubject.next(this.currentUser);
                localStorage.setItem('userData', JSON.stringify(this.currentUser));
                if (response['code'] == 200) {
                    this.userName = this.currentUser.first_name + ' ' + this.currentUser.last_name;
                    let swalData = {
                        title: 'Success',
                        message: `Profile updated successfully!`,
                        info: ''
                    };
                    this.swalService.success(swalData).then(() => {
                        // this.router.navigate(['/role/list']);
                    });
                } else {
                    let swalData = {
                        title: 'Error',
                        message: response['message'] || `Some error in updating your profile.`,
                        info: 'Please try again later.'
                    };
                    this.swalService.error(swalData).then(() => {
                        // this.router.navigate(['/role/list']);
                    });
                }
            },
            err => {
                this.loadingVisible = false;
                console.log(err);
                let swalData = {
                    title: 'Error',
                    message: err.error['message'] || `Some error in updating your profile.`,
                    info: 'Please try again later.'
                };
                this.swalService.error(swalData)
            },()=>{
                this.loadingVisible = false;
            });
    }

    uploadProfilePicture(e) {
        let reader = new FileReader();

        if(this.profileImage.length > 0){
            reader.readAsDataURL(this.profileImage[0]); // read file as data url

            reader.onload = (event:any) => { // called once readAsDataURL is completed
                this.profilePreviewUrl = event.target.result;
            }
        }
    }

    saveProfilePicture(e){
        if (!this.profileImage || this.profileImage == null || (Array.isArray(this.profileImage) && this.profileImage.length <= 0)) {
            const swalData = {
                title: 'File is missing!',
                message: 'Oops! Seems like you didn\'t select any image.',
                info: 'Please select the image to be replaced with current one.'
            };
            this.swalService.error(swalData);
            return;
        }
        const formData = new FormData();
        formData.append('file', this.profileImage[0], this.profileImage[0].name);

        this.loadingVisible = true;
        this.userService.uploadUserProfilePicture(formData).subscribe(response => {
            if(response['code'] == 200) {
                console.log(response['data']);
                let swalData = {
                    title: 'Success',
                    message: `Profile picture upload successfully!`,
                    info: ''
                };
                this.profileImageUrl = response['data'].shorten_url;
                this.currentUser.avatar = response['data'].shorten_url;
                this.userService.userUpdatedSubject.next(this.currentUser);
                localStorage.setItem('userData', JSON.stringify(this.currentUser));
                this.swalService.success(swalData);

            }
        }, err => {
            this.loadingVisible = false;
            console.log(err);
            let swalData = {
                title: 'Error',
                message: err.error['message'] || `Some error in updating your profile.`,
                info: 'Please try again later.'
            };
            this.swalService.error(swalData)
        }, ()=>{
            this.loadingVisible = false;
        });
    }

    removeProfileImage(e){
        /*this.profileImage = null;
        this.profilePreviewUrl = this.profileImageUrl;
        this.dxFileUploader.instance.reset();
        return;*/

        const postData = {
            avatar: this.defaultImageUrl,
            email: this.currentUser.email
        };
        this.loadingVisible = true;
        this.userService.updateUserProfile(postData).subscribe(response => {
                this.loadingVisible = false;
                if (response['code'] == 200) {
                    let swalData = {
                        title: 'Success',
                        message: `Profile picture removed successfully!`,
                        info: ''
                    };

                    this.currentUser.avatar = this.defaultImageUrl;
                    this.profileImageUrl = this.defaultImageUrl;
                    this.profilePreviewUrl = this.defaultImageUrl;
                    this.userService.userUpdatedSubject.next(this.currentUser);
                    localStorage.setItem('userData', JSON.stringify(this.currentUser));

                    // console.log(localStorage.getItem('userData'));

                    this.swalService.success(swalData);
                } else {
                    let swalData = {
                        title: 'Error',
                        message: response['message'] || `Some error in removing your profile picture.`,
                        info: 'Please try again later.'
                    };
                    this.swalService.error(swalData).then(() => {
                        // this.router.navigate(['/role/list']);
                    });
                }
            },
            err => {
                this.loadingVisible = false;
                console.log(err);
                let swalData = {
                    title: 'Error',
                    message: err.error['message'] || `Some error in removing your profile.`,
                    info: 'Please try again later.'
                };
                this.swalService.error(swalData)
            },()=>{
                this.loadingVisible = false;
            });
    }

    updatePassword(e){
        const postData = {
            oldPassword : this.currentUser.frmCurrPwd,
            newPassword : this.currentUser.frmNewPwd,
            newRePassword : this.currentUser.frmReNewPwd,
        };

        this.userService.updatePasswordFromCurrent(postData).subscribe(response => {
                if (response['code'] == 200) {
                    let swalData = {
                        title: 'Success',
                        message: `Password updated successfully!`,
                        info: ''
                    };
                    this.swalService.success(swalData).then(() => {
                        /*this.currentUser.frmCurrPwd = '';
                        this.currentUser.frmNewPwd = '';
                        this.currentUser.frmReNewPwd = '';*/
                        // this.router.navigate(['/role/list']);
                    });
                } else {
                    let swalData = {
                        title: 'Error',
                        message: response['message'] || `Some error in updating your password.`,
                        info: 'Please try again later.'
                    };
                    this.swalService.error(swalData);
                }
            },
            err => {
                if(err.status == 400){
                    console.log(err);
                    let swalData = {
                        title: 'Error',
                        message: err.error.data['message'] || `Your old password is not match with our records`,
                        info: 'Please try again later.'
                    };
                    this.swalService.error(swalData)
                } else {
                    console.log(err);
                    let swalData = {
                        title: 'Error',
                        message: err.error['message'] || `Some error in updating your Password.`,
                        info: 'Please try again later.'
                    };
                    this.swalService.error(swalData)
                }
            });
        console.log(postData);
    }

    passwordComparison = () => {
        return this.currentUser.frmNewPwd;
    };

    checkComparison() {
        return true;
    }

    getSelectedFiles () {
        return this.profileImage;
    }

    onFormSubmit(e) {
        console.log(e);
    }

    /*SHORT VIDEO FUNCTIONS START*/
    timer() {
        this.timerId = setTimeout(() => {
            this.updateTime(); // update Model
            this.timer();
        }, 1000);
    }

    updateTime() {
        this.displayTimer.seconds++;
        if(this.displayTimer.seconds === 31){
            this.stopRec();
        }
        if (this.displayTimer.seconds === 60) {
            this.displayTimer.seconds = 0;
            this.displayTimer.minutes++;
        }

    }

    handleSuccess(stream) {
        //   recordButton.disabled = false;
        console.log('getUserMedia() got stream:', stream);
        window.stream = stream;
        // this.localStream = stream;
        this.gumVideo.nativeElement.controls = false;
        // const gumVideo = document.querySelector('video#gum');
        this.gumVideo.nativeElement.srcObject = stream;
    }

    async init(constraints) {
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            this.cameraStarted = true;
            this.playButtonDisabled = true;
            this.gumVideo.nativeElement.volume = 0;
            this.handleSuccess(stream);
        } catch (e) {
            console.error('navigator.getUserMedia error:', e);
            let messageInfo = 'You need to have a webcam to record a video.';
            if(e.name === 'NotAllowedError') {
                messageInfo = 'Please allow browser to use webcam to record a video.'
            }
            const swalData = {
                title: 'Error',
                message: `${e.toString()}`,
                info: messageInfo
            }
            this.swalService.error(swalData);
            // this.errorMsgElement.nativeElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
        }
    }

    async startCamera() {
        const hasEchoCancellation = true;
        const constraints = {
            audio: {
                echoCancellation: {exact: hasEchoCancellation}
            },
            video: {
                width: 1280, height: 720
            }
        };
        console.log('Using media constraints:', constraints);
        await this.init(constraints);
    }

    startStopRec() {
        if (this.isStarted) {
            this.stopRec();
            return;
        } else {
            this.startRec();
        }
    }

    startRec() {
        this.recordedBlobs = [];
        this.gumVideo.nativeElement.currentTime = 0;

        let options = {mimeType: 'video/webm;codecs=vp9'};
        //   const errorMsgElement = <HTMLElement> document.querySelector('#errorMsg');
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {

            console.error(`${options.mimeType} is not Supported`);

            this.errorMsgElement.nativeElement.innerHTML = `${options.mimeType} is not Supported`;
            options = {mimeType: 'video/webm'};
            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                console.error(`${options.mimeType} is not Supported`);
                this.errorMsgElement.nativeElement.innerHTML = `${options.mimeType} is not Supported`;
                options = {mimeType: 'video/webm;codecs=vp8'};
                if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                    console.error(`${options.mimeType} is not Supported`);
                    this.errorMsgElement.nativeElement.innerHTML = `${options.mimeType} is not Supported`;
                    options = {mimeType: ''};
                }
            }
            this.swalService.error({
                message: `${options.mimeType} is not Supported`,
                info: 'Please try with another browser.'
            })
        }
        try {
            this.mediaRecorder = new MediaRecorder(window.stream, options);
        } catch (e) {
            console.error('Exception while creating MediaRecorder:', e);
            this.swalService.error({
                message: 'Exception while creating MediaRecorder',
                info: JSON.stringify(e)
            })
            return;
        }
        this.isStarted = true;
        console.log('Created MediaRecorder', this.mediaRecorder, 'with options', options);
        this.downloadButtonDisabled = true;
        this.playButtonDisabled = true;
        this.mediaRecorder.onstop = (event) => {
            console.log('Recorder stopped: ', event);
        };
        this.mediaRecorder.ondataavailable = this.handleDataAvailable.bind(this);
        this.timer();
        this.mediaRecorder.start(10); // collect 10ms of data
        console.log('MediaRecorder started', this.mediaRecorder);
    }

    stopRec() {
        if(!this.isStarted){
            return;
        }
        console.log(this.recordedBlobs);
        clearTimeout(this.timerId);
        this.displayTimer = {
            minutes: 0,
            seconds: 0
        };
        this.stopRecButtonDisabled = true;
        this.playButtonDisabled = false;
        this.downloadButtonDisabled = false;
        this.isStarted = false;
        this.mediaRecorder.stop();
        window.stream.getVideoTracks()[0].stop();
        this.download();
    }

    handleDataAvailable(event) {
        if (event.data && event.data.size > 0) {
            console.log(event.data);
            this.recordedBlobs.push(event.data);
        }
    }

    download() {
        const blob = new Blob(this.recordedBlobs, {type: 'video/webm'});
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // const file = new File([blob], 'file1');
        blob['lastModifiedDate'] = new Date();
        blob['name'] = 'file1';
        const file = <File> blob;
        const formData = new FormData();
        formData.append('file', file, file.name);

        this.loadingVisible = true;
        this.uploadService.uploadIntroToS3(formData).subscribe(response => {
            console.log(response);
            const result = response['data'];
            this.introUrl = result.shorten_url;

            this.currentUser.video_intro_url = result.shorten_url;
            this.userService.userUpdatedSubject.next(this.currentUser);
            localStorage.setItem('userData', JSON.stringify(this.currentUser));

            let swalData = {
                title: 'Success',
                message: `Your video intro is uploaded successfully.`,
                info: 'Please try again later.'
            };
            this.swalService.success(swalData)

        }, err => {
            console.log(err);
            let swalData = {
                title: 'Error',
                message: err.error['message'] || `Some error in updating video intro.`,
                info: 'Please try again later.'
            };
            this.swalService.error(swalData)
        }, ()=>{
            this.loadingVisible = false;
        });
    }

    playRec() {
        const superBuffer = new Blob(this.recordedBlobs, {type: 'video/webm'});

        this.gumVideo.nativeElement.src = null;
        this.gumVideo.nativeElement.srcObject = null;
        this.gumVideo.nativeElement.src = window.URL.createObjectURL(superBuffer);
        this.gumVideo.nativeElement.controls = true;
        this.gumVideo.nativeElement.volume = 1;
        this.gumVideo.nativeElement.play();
    }
    /*SHORT VIDEO FUNCTIONS END*/

    onCancel(e) {
        this.setUserProfile();
    }

    showIntro() {
        this.popupVisible = true;
        this.videoPlayer.nativeElement.play();
    }

    copyLink(textToCopy) {
        let textarea = null;
        textarea = document.createElement("textarea");
        textarea.style.height = "0px";
        textarea.style.left = "-100px";
        textarea.style.opacity = "0";
        textarea.style.position = "fixed";
        textarea.style.top = "-100px";
        textarea.style.width = "0px";
        document.body.appendChild(textarea);
        // Set and select the value (creating an active Selection range).
        let chatUrl = textToCopy
        textarea.value = chatUrl;
        textarea.select();
        // Ask the browser to copy the current selection to the clipboard.
        let successful = document.execCommand("copy");
        if (successful) {
            // do something
        } else {
            // handle the error
        }
        if (textarea && textarea.parentNode) {
            textarea.parentNode.removeChild(textarea);
        }
    }

    changeCurrentPasswordInputMode(event) {
        this.currentPasswordMode = this.currentPasswordMode === "text" ? "password" : "text"
    }
    changeNewPasswordInputMode(event) {
        this.newPasswordMode = this.newPasswordMode === "text" ? "password" : "text"
    }
    changeConNewPasswordInputMode(event) {
        this.conNewPasswordMode = this.conNewPasswordMode === "text" ? "password" : "text"
    }
}
