<mat-sidenav-container>

    <div id="pulse-main-content">

        <pulse-header></pulse-header>

        <div id="wrapper">

            <!-- NAVBAR: Left -->
            <!--<fuse-sidebar [name]="'navbar'"-->
            <!--[folded]="fuseSettings.layout.navigationFolded"-->
            <!--[lockedOpen]="'gt-md'"-->
            <!--class="left-navbar" [ngClass]="fuseSettings.colorClasses.navbar"-->
            <!--*ngIf="fuseSettings.layout.navigation === 'left' || fuseSettings.layout.navigation === 'top'">-->
            <!--<fuse-navbar layout="vertical"></fuse-navbar>-->
            <!--</fuse-sidebar>-->
            <!-- / NAVBAR: Left -->

            <div class="content-wrapper">

                <pulse-content #pulseContent></pulse-content>

            </div>

        </div>

        <pulse-footer class="above"></pulse-footer>

    </div>

</mat-sidenav-container>

<!--<fuse-theme-options></fuse-theme-options>-->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#pulseContent' }"
               [(visible)]="loadingVisible" [deferRendering]="false"
               [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>
