import { Pipe, PipeTransform } from '@angular/core';
import { PulseUtils} from '@pulse/utils';

@Pipe({name: 'searchFilter'})
export class SearchFilterPipe implements PipeTransform
{
    transform(items: any[], searchText: string, property: string): any[] {
        if(!items) return [];
        if(!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter( it => {
          return it.fullName.toLowerCase().includes(searchText);
        });
       }
}
