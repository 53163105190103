<div class="page-layout simple fullwidth" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading" >
                        ACE Report
                    </div>
                    <div>
                        <form (ngSubmit)="getACEReport()" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" >
                            <dx-form #dxFilterForm [formData]="filterFormData" [colCount]="2" validationGroup="filterGroup">
                                <dxi-item dataField="frmStatus" [colSpan]="1" editorType="dxSelectBox"
                                          [editorOptions]="{ items: statuses, valueExpr: 'value', displayExpr: 'name', searchEnabled: true }">
                                    <dxo-label text="Status"></dxo-label>
                                    <dxi-validation-rule type="required" message="Please select status">
                                    </dxi-validation-rule>
                                </dxi-item>
                                <dxi-item dataField="frmPeriod" [colSpan]="1" editorType="dxSelectBox"
                                          [editorOptions]="{ items: periods, valueExpr: 'value', displayExpr: 'name', searchEnabled: true }">
                                    <dxo-label text="Period"></dxo-label>
                                    <dxi-validation-rule type="required" message="Please select period">
                                    </dxi-validation-rule>
                                </dxi-item>
                            </dx-form>
                            <div fxLayout="row" fxLayoutGap="10px">

                                <dx-button class="pulse-btn pulse-btn-primary" validationGroup="filterGroup"
                                           [useSubmitBehavior]="true" icon="fa fa-search"></dx-button>
                                <button class="pulse-btn pulse-btn-normal px-4" (click)="resetFilterForm($event)">
                                    <i class="dx-icon dx-icon-refresh s-20"></i>
                                </button>
                            </div>
                        </form>

                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" >
                        <dx-button icon="fa fa-filter" class="pulse-btn pulse-btn-primary has-icon"
                                   aria-label="Show filter" (onClick)="showFilters =!showFilters" [disabled]="aceReportData.length <= 0">
                        </dx-button>

                        <dx-button icon="fa fa-file-excel" class="pulse-btn pulse-btn-primary has-icon"
                                   aria-label="Export to excel" (onClick)="exportToExcel($event)" [disabled]="aceReportData.length <= 0">
                        </dx-button>
                    </div>
                </div>
                <div class="p-30 pt-15 pb-15 content-area" fxLayout="column" fxLayoutAlign="center center">
                    <dx-data-grid #dxAceReportGrid [dataSource]="aceReportData" [showBorders]="true">
                        <dxo-filter-row [visible]="showFilters"></dxo-filter-row>
                        <dxo-sorting mode="multiple"></dxo-sorting>
                        <dxi-column dataField="UserName" ></dxi-column>
                        <!--<dxi-column dataField="title" caption="Subject"></dxi-column>-->
                        <dxi-column dataField="Role"></dxi-column>
                        <dxi-column dataField="TeamName" caption="Team"></dxi-column>
                        <dxi-column dataField="ExtensionNumber"></dxi-column>
                        <dxi-column dataField="ExtensionVersion" caption="ACE Version" ></dxi-column>
                        <dxi-column dataField="LastUsed" dataType="date" format="MM/dd/yyyy hh:mm"></dxi-column>
                        <dxi-column dataField="TotalUsed" dataType="number" [width]="100"></dxi-column>
                        <!--<dxi-column [width]="80" cellTemplate="cellTemplate" caption="Actions"></dxi-column>-->
                    </dx-data-grid>
                </div>
            </div>
        </div>
    </div>
</div>



