import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import {PulseSharedModule} from '@pulse/shared.module';
import {VedioAudioDialogComponent} from './vedio-audio-dialog.component';

@NgModule({
    declarations: [
        VedioAudioDialogComponent
    ],
    imports: [

        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,

        PulseSharedModule
    ],
    exports:[
        VedioAudioDialogComponent
    ]
})
export class VedioAudioDialogModule {
}
