import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { ColorPickerModule } from 'ngx-color-picker';
import {CalendarModule as AngularCalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {PulseSharedModule} from '@pulse/shared.module';
import {PulseConfirmDialogModule} from '@pulse/components/confirm-dialog/confirm-dialog.module';

import {MyCalendarComponent} from './my-calendar.component';
import {CalendarService} from 'app/services/calendar/calendar.service';
import {CalendarEventFormDialogComponent} from './event-form/event-form.component';
import {DxSelectBoxModule} from 'devextreme-angular';
import {FormsModule} from '@angular/forms';


const routes: Routes = [
    {
        path: 'calendar',
        component: MyCalendarComponent,
        children: [],
        resolve: {
            chat: CalendarService
        }
    }
];

@NgModule({
    declarations: [
        MyCalendarComponent,
        CalendarEventFormDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSelectModule,
        DxSelectBoxModule,
        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        PulseSharedModule,
        PulseConfirmDialogModule,
        FormsModule
    ],
    providers: [
        CalendarService
    ]
})
export class CalendarModule {
}
