<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
    <div class="container mt-12">
        <!-- CONTENT -->
        <div class="mat-white-bg {{(!editMode) ? ' mat-elevation-z4' : ''}}" fxLayout="column">
            <div class="content-wrapper" fxLayout="column">
                <!-- REACTIVE FORM EXAMPLE -->
                <div *ngIf="!editMode" class="page-title-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading">
                        <span >{{pageTitle}}</span>

                    </div>
                    <dx-button text="Reset"
                               (onClick)="reset()"
                               class="pulse-btn pulse-btn-normal">
                    </dx-button>

                </div>

                <div class="{{(!editMode) ? 'p-30 pt-15 ' : ''}} pb-15 " id="addUserForm">
                    <form (submit)="onFormSubmit($event)" class="add-user-form">

                        <div id="form-container" class="mb-20">

                            <dx-form id="form"
                                     [formData]="frmUserData"
                                     [readOnly]="false"
                                     [showColonAfterLabel]="true"
                                     [showValidationSummary]="true"
                                     [colCount]="12"
                                     labelLocation="top"
                                     validationGroup="customerData">

                                <!-- ==================================================================== -->
                                <dxi-item [colCount]="3" [colSpan]="12" itemType="group">

                                    <dxi-item dataField="frmFirstName" [colSpan]="7">
                                        <dxo-label text="First Name"></dxo-label>
                                        <dxi-validation-rule
                                            type="required"
                                            message="First Name is required">
                                        </dxi-validation-rule>
                                    </dxi-item>

                                    <!-- ==================================================================== -->
                                    <dxi-item dataField="frmLastName" [colSpan]="6">
                                        <dxo-label text="Last Name"></dxo-label>
                                        <dxi-validation-rule
                                            type="required"
                                            message="Last Name is required">
                                        </dxi-validation-rule>
                                    </dxi-item>

                                    <dxi-item dataField="frmEmail" [colSpan]="6"
                                              [helpText]="emailInUseText" [editorOptions]="{
                                                onValueChanged: onEmailChange
                                              }">
                                        <dxo-label text="Email"></dxo-label>
                                        <dxi-validation-rule
                                            type="required"
                                            message="Email is required">
                                        </dxi-validation-rule>
                                        <dxi-validation-rule
                                            type="pattern"
                                            [pattern]="emailPattern"
                                            message="Please, enter correct email">
                                        </dxi-validation-rule>
                                        <dxi-validation-rule
                                            type="async"
                                            message="Email already exist"
                                            [validationCallback]="isEmailExistCheck"
                                        ></dxi-validation-rule>
                                    </dxi-item>
                                    <!-- ==================================================================== -->

                                            <dxi-item itemType="group" [colCount]="15" cssClass="hasButton dxInputGroup">
                                        <dxi-item dataField="frmCountryCode" editorType="dxSelectBox" cssClass="pr-0"
                                                  [colSpan]="6"  helpText="Enter the office number in USA phone format"
                                                  [editorOptions]="{
                                                                dataSource: countryCodes,
                                                                valueExpr: 'id',
                                                                displayExpr: 'name'
                                                            }" [template]="'countryCodeTemplate'">
                                            <dxo-label text="Office #"></dxo-label>
                                            <dxi-validation-rule
                                                type="required"
                                                message="Country Code is required">
                                            </dxi-validation-rule>
                                        </dxi-item>
                                        <dxi-item dataField="frmMobile" [colSpan]="6" cssClass="hideMark"
                                                  [editorOptions]="phoneNumberOptions">

                                            <dxo-label text=" " [showColon]="false"></dxo-label>
                                            <dxi-validation-rule
                                                type="required"
                                                message="Office Number is required">
                                            </dxi-validation-rule>
                                        </dxi-item>
                                        <dxi-item [colSpan]="3" dataField="frmExtNumber">
                                            <dxo-label text="EXT #"></dxo-label>
                                        </dxi-item>
                                    </dxi-item>
<!--//================================================================================================================-->

                                    <dxi-item itemType="group" [colCount]="15" cssClass="hasButton dxInputGroup">
                                        <dxi-item dataField="frmPersonalCountryCode" editorType="dxSelectBox" cssClass="pr-0"
                                                  [colSpan]="6"
                                                  [editorOptions]="{
                                                                dataSource: countryCodes,
                                                                valueExpr: 'id',
                                                                displayExpr: 'name'
                                                            }" [template]="'personalCountryCodeTemplate'">
                                            <dxo-label text="Mobile #"></dxo-label>
                                        </dxi-item>
                                        <dxi-item dataField="frmPersonalMobile" [colSpan]="6" cssClass="hideMark"
                                                  [editorOptions]="phoneNumberOptions">

                                            <dxo-label text=" " [showColon]="false"></dxo-label>
                                        </dxi-item>
                                    </dxi-item>

<!--===================================================================================================================                                    -->
                                    <dxi-item dataField="frmPersonalEmail" [colSpan]="6"
                                              [helpText]="emailInUseText">
                                        <dxo-label text="Personal Email"></dxo-label>
                                        <dxi-validation-rule
                                            type="pattern"
                                            [pattern]="emailPattern"
                                            message="Please, enter correct personal email">
                                        </dxi-validation-rule>
                                    </dxi-item>
<!--=====================================================================================================-->
<!--                                    <dxi-item>-->
<!--                                        <dx-select-box-->
<!--                                            [displayExpr]="'name'"-->
<!--                                            [valueExpr]="'id'"-->
<!--                                            [value]="frmUserData.frmReportingTo"-->
<!--                                            [dataSource]="reportingTo"-->
<!--                                            (onSelectionChanged)="changeSelectBox($event,'reportingTo')"-->
<!--                                            [searchEnabled]="true">-->
<!--                                        </dx-select-box>-->
<!--                                        <dxo-label text="Reporting to"></dxo-label>-->
<!--                                        <dxi-validation-rule-->
<!--                                            type="required"-->
<!--                                            message="Reporting to is required">-->
<!--                                        </dxi-validation-rule>-->
<!--                                    </dxi-item>-->

                                    <dxi-item dataField="frmReportingTo"
                                              editorType="dxSelectBox"
                                              [editorOptions]="{ items: reportingTo, valueExpr: 'id',
                                                  elementAttr: {'data-name': 'reportingTo'},
                                                  onSelectionChanged: changeSelectBox,
                                                  displayExpr: 'name', searchEnabled: true}">
                                        <dxo-label text="Reporting to"></dxo-label>
                                        <dxi-validation-rule
                                            type="required"
                                            message="Reporting to is required">
                                        </dxi-validation-rule>
                                    </dxi-item>

                                    <!-- ==================================================================== -->

                                    <dxi-item dataField="frmPrimaryRole" [colSpan]="6"
                                              editorType="dxTagBox"
                                              [editorOptions]="{
                                                items: primaryRole,
                                                valueExpr: 'id',
                                                displayExpr: 'name',
                                                searchEnabled: true,
                                                value:frmUserData.frmPrimaryRole
                                                 }">
                                        <dxo-label text="Primary Role"></dxo-label>
                                        <dxi-validation-rule
                                            type="required"
                                            message="Primary Role is required">
                                        </dxi-validation-rule>
                                    </dxi-item>

                                    <dxi-item itemType="group" [colCount]="2" cssClass="hasButton dxInputGroup">
<!--                                        <dxi-item>-->
<!--                                            <dx-select-box-->
<!--                                                [displayExpr]="'name'"-->
<!--                                                [valueExpr]="'id'"-->
<!--                                                [value]="frmUserData.frmLocation"-->
<!--                                                [dataSource]="countryList"-->
<!--                                                (onSelectionChanged)="changeSelectBox($event,'location')"-->
<!--                                                [searchEnabled]="true">-->
<!--                                            </dx-select-box>-->
<!--                                            <dxo-label text="Country"></dxo-label>-->
<!--                                            <dxi-validation-rule-->
<!--                                                type="required"-->
<!--                                                message="Country is required">-->
<!--                                            </dxi-validation-rule>-->
<!--                                        </dxi-item>-->
                                        <dxi-item dataField="frmLocation"
                                                  editorType="dxSelectBox"
                                                  [editorOptions]="{ items: countryList, valueExpr: 'id',
                                                  elementAttr: {'data-name': 'location'},
                                                  onSelectionChanged: changeSelectBox,
                                                  displayExpr: 'name', searchEnabled: true}">
                                            <dxo-label text="Country"></dxo-label>
                                            <dxi-validation-rule
                                                type="required"
                                                message="Country is required">
                                            </dxi-validation-rule>
                                        </dxi-item>

<!--                                        <dxi-item>-->
<!--                                            <dx-select-box-->
<!--                                                [displayExpr]="'name'"-->
<!--                                                [valueExpr]="'id'"-->
<!--                                                [value]="frmUserData.frmSubLocation"-->
<!--                                                [dataSource]="locationList"-->
<!--                                                (onSelectionChanged)="changeSelectBox($event,'sub_location')"-->
<!--                                                [searchEnabled]="true">-->
<!--                                            </dx-select-box>-->
<!--                                            <dxo-label text="Location"></dxo-label>-->
<!--                                        </dxi-item>-->
                                        <dxi-item dataField="frmSubLocation"
                                                  editorType="dxSelectBox"
                                                  [editorOptions]="{ items: locationList, valueExpr: 'id',
                                                  elementAttr: {'data-name': 'sub_location'},
                                                  onSelectionChanged: changeSelectBox,
                                                  displayExpr: 'name', searchEnabled: true}">
                                            <dxo-label text="Location"></dxo-label>
                                        </dxi-item>
                                    </dxi-item>
                                    <dxi-item dataField="frmDivision"
                                              editorType="dxSelectBox"
                                              [editorOptions]="{ items: divisions, valueExpr: 'id',
                                                  elementAttr: {'data-name': 'division'},
                                                  onSelectionChanged: changeSelectBox,
                                                  displayExpr: 'name', searchEnabled: true}">
                                        <dxo-label text="Division"></dxo-label>
                                    </dxi-item>
<!--                                    <dxi-item>-->
<!--                                        <dx-select-box-->
<!--                                            [displayExpr]="'name'"-->
<!--                                            [valueExpr]="'id'"-->
<!--                                            [value]="frmUserData.frmDivision"-->
<!--                                            [dataSource]="divisions"-->
<!--                                            (onSelectionChanged)="changeSelectBox($event,'division')"-->
<!--                                            [searchEnabled]="true">-->
<!--                                        </dx-select-box>-->
<!--                                        <dxo-label text="Division"></dxo-label>-->
<!--                                    </dxi-item>-->

                                    <dxi-item itemType="group" [colCount]="2" cssClass="hasButton dxInputGroup">
                                        <dxi-item dataField="frmTwilioSmsNumber" [editorOptions]="phoneNumberOptions">
                                            <dxo-label text="Twilio SMS Number"></dxo-label>
                                        </dxi-item>
                                        <dxi-item [colSpan]="1" dataField="frmJobDivaId">
                                            <dxo-label text="JobDiva Id"></dxo-label>
                                        </dxi-item>
<!--                                        <dxi-item dataField="frmVOIPNumber">-->
<!--                                            <dxo-label text="VOIP Number"></dxo-label>-->
<!--                                        </dxi-item>-->
                                    </dxi-item>

                                    <ng-container *ngIf="frmUserData.frmPrimaryRole && frmUserData.frmPrimaryRole.includes(6)">
                                        <dxi-item dataField="frmDocusignUser">
                                            <dxo-label text="Docusign User"></dxo-label>
                                        </dxi-item>
                                        <dxi-item dataField="frmDocusignPass" [editorOptions]="{mode:'password'}">
                                            <dxo-label text="Docusign Password"></dxo-label>
                                        </dxi-item>
                                    </ng-container>
<!--                                    <dxi-item itemType="group" [colCount]="2" cssClass="hasButton dxInputGroup">-->
<!--                                        <dxi-item [colSpan]="1" dataField="frmWidgetChatId">-->
<!--                                            <dxo-label text="Chat Id"></dxo-label>-->
<!--                                        </dxi-item>-->
<!--                                    </dxi-item>-->
                                    <dxi-item dataField="frmSignature" [colSpan]="2"
                                              editorType="dxTextArea"
                                              [editorOptions]="{ height: 50 }">
                                        <dxo-label text="Signature"></dxo-label>
                                    </dxi-item>
                                    <dxi-item [colCount]="2" [colSpan]="12" itemType="group">
                                        <dxi-item dataField="frmUserTarget" editorType="dxNumberBox">
                                            <dxo-label text="User GP Target"></dxo-label>
                                        </dxi-item>
<!--                                        <dxi-item>-->
<!--                                            <dx-select-box-->
<!--                                                [displayExpr]="'name'"-->
<!--                                                [valueExpr]="'id'"-->
<!--                                                [value]="frmUserData.frmUserStatus"-->
<!--                                                [dataSource]="statusList"-->
<!--                                                (onSelectionChanged)="changeSelectBox($event,'userStatus')"-->
<!--                                                [searchEnabled]="true">-->
<!--                                            </dx-select-box>-->
<!--                                            <dxo-label text="Status"></dxo-label>-->
<!--                                        </dxi-item>-->
                                        <dxi-item dataField="frmUserStatus"
                                                  editorType="dxSelectBox"
                                                  [editorOptions]="{ items: statusList, valueExpr: 'id',
                                                  elementAttr: {'data-name': 'userStatus'},
                                                  onSelectionChanged: changeSelectBox,
                                                  displayExpr: 'name', searchEnabled: true}">
                                            <dxo-label text="Status"></dxo-label>
                                        </dxi-item>
                                    </dxi-item>

<!--                                    <dxi-item dataField="botId" [colSpan]="7">-->
<!--                                        <dxo-label text="Bot Id"></dxo-label>-->
<!--                                    </dxi-item>-->

<!--                                    <dxi-item>-->
<!--                                        <dx-select-box-->
<!--                                            [displayExpr]="'name'"-->
<!--                                            [valueExpr]="'id'"-->
<!--                                            [value]="frmUserData.frmTeam"-->
<!--                                            [dataSource]="teamList"-->
<!--                                            (onSelectionChanged)="changeSelectBox($event,'team')"-->
<!--                                            [searchEnabled]="true">-->
<!--                                        </dx-select-box>-->
<!--                                        <dxo-label text="Team"></dxo-label>-->
<!--                                    </dxi-item>-->

                                    <dxi-item dataField="frmTeam"
                                              editorType="dxSelectBox"
                                              [editorOptions]="{ items: teamList, valueExpr: 'id',
                                                  elementAttr: {'data-name': 'team'},
                                                  onSelectionChanged: changeSelectBox,
                                                  displayExpr: 'name', searchEnabled: true}">
                                        <dxo-label text="Team"></dxo-label>
                                    </dxi-item>

                                    <dxi-item dataField="callProvider" editorType="dxSelectBox"
                                              [editorOptions]="{items: callProviders, displayExpr: 'displayExpr', valueExpr: 'value'}">
                                        <dxo-label>Call Provider:</dxo-label>
                                    </dxi-item>

                                    <dxi-item dataField="frmClickSendToken" [colSpan]="6">
                                        <dxo-label text="Clicksend Token"></dxo-label>
                                    </dxi-item>

                                </dxi-item>

                                <!-- ===================== COUNTRY CODE =============================================== -->
                                <div *dxTemplate="let data of 'countryCodeTemplate'">

                                    <dx-select-box [dataSource]="countryCodes" valueExpr="callCode" displayExpr="callCode"
                                                   fieldTemplate="field" [searchExpr]="['name', 'alpha2Code', 'callCode']"
                                                   (onValueChanged)="onCountryCodeChanged($event)"
                                                   [value]="frmUserData.frmCountryCode"
                                                   [deferRendering]="false" [searchEnabled]="true">
                                        <div *dxTemplate="let data2 of 'field'">
                                            <div class="custom-item pl-16" fxLayout="row" fxLayoutAlign="start center">

                                                <img width="25" *ngIf="data2?.flag" [src]="data2?.flag"/>
                                                <dx-text-box class="product-name"
                                                             [value]="data2?.callCode"
                                                ></dx-text-box>
                                            </div>

                                        </div>
                                        <div *dxTemplate="let data2 of 'item'">

                                            <div class="custom-item" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">

                                                <img width="25px" [src]="data2.flag"/>
                                                <div class="product-name">
                                                    <span>({{data2.callCode}}) </span>
                                                    <span>{{data2.alpha2Code}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </dx-select-box>
                                </div>

                                <!-- ===================== PERSONAL COUNTRY CODE =============================================== -->
                                <div *dxTemplate="let data of 'personalCountryCodeTemplate'">

                                    <dx-select-box [dataSource]="countryCodes" valueExpr="callCode" displayExpr="callCode"
                                                   fieldTemplate="fields" [searchExpr]="['name', 'alpha2Code', 'callCode']"
                                                   (onValueChanged)="onPersonalCountryCodeChanged($event)"
                                                   [value]="frmUserData.frmPersonalCountryCode"
                                                   [deferRendering]="false" [searchEnabled]="true">
                                        <div *dxTemplate="let data2 of 'fields'">
                                            <div class="custom-item pl-16" fxLayout="row" fxLayoutAlign="start center">

                                                <img width="25" *ngIf="data2?.flag" [src]="data2?.flag"/>
                                                <dx-text-box class="product-name"
                                                             [value]="data2?.callCode"
                                                ></dx-text-box>
                                            </div>

                                        </div>
                                        <div *dxTemplate="let data2 of 'item'">

                                            <div class="custom-item" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">

                                                <img width="25px" [src]="data2.flag"/>
                                                <div class="product-name">
                                                    <span>({{data2.callCode}}) </span>
                                                    <span>{{data2.alpha2Code}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </dx-select-box>
                                </div>
                            </dx-form>

                        </div>
                        <div class="actions-buttons" fxLayout="row" fxLayoutAlign="space-between center">
                            <dx-button
                                text="Cancel"
                                class="pulse-btn pulse-btn-normal"
                                (onClick)="cancel()"
                            >
                            </dx-button>
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
                                <!--<dx-check-box-->
                                    <!--*ngIf="!editMode"-->
                                    <!--[value]="isApproved"-->
                                    <!--(onValueChanged)="isApprovedUser($event)"-->
                                    <!--text="Is Direct Approve?"-->
                                <!--&gt;</dx-check-box>-->
                                <dx-button *ngIf="!editMode"
                                           text="Save and Add More"
                                           validationGroup="customerData"
                                           class="pulse-btn pulse-btn-primary"
                                           (onClick)="formAction('more')"
                                           [useSubmitBehavior]="true">
                                </dx-button>

                                <dx-button *ngIf="!editMode"
                                           text="Save"
                                           validationGroup="customerData"
                                           class="pulse-btn pulse-btn-primary"
                                           (onClick)="formAction('save')"
                                           [useSubmitBehavior]="true">
                                </dx-button>
                                <dx-button *ngIf="editMode"
                                           text="Save"
                                           class="pulse-btn pulse-btn-primary"
                                           validationGroup="customerData"
                                           (onClick)="formAction('edit')"
                                           [useSubmitBehavior]="true">
                                </dx-button>
                            </div>
                        </div>


                    </form>

                </div>
            </div>

        </div>
    </div>

</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#forms' }"
               [(visible)]="loadingVisible"
               [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>
