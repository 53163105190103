<div class="page-layout simple fullwidth" fusePerfectScrollbar>
  <div class="container mt-12">
      <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
          <div class="content-wrapper">
              <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto" fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading">
                       Timesheet Search <span class="badge ng-star-inserted">{{ workerTimeSheetCount }}</span>
                    </div>
                    <!-- <div class="asp-main-heading" fxFlex="3">
                        <div class="dx-field-value" style="width: 45px; float: right;">
                            <dx-button icon="find" style="height: 33px;widows: 42px; background-color: white; border-color: #ddd;"
                            (onClick)="toggleFilter();">
                            </dx-button>
                        </div>
                    </div> -->
                </div>
              <div class="p-30 pt-15 pb-15 grid-wrapper">
                  <div fxLayout="row" style="position: relative;left: 10px;">
                        <div fxFlex="20" fxLayout="column">
                            <div class="dx-field-value" style="width: 95% !important;">
                                <dx-tag-box dx-tag-box  placeholder="Select Clients" [multiline]="false"
                                [searchEnabled]="true" [showSelectionControls]="true" [maxDisplayedTags]="2" valueExpr="businessEntityId" displayExpr="businessName"
                                [showMultiTagOnly]="false" [items]="vmsClientList" [(value)]="selectedClients" (onValueChanged)="refreshGridForClient($event)">
                                </dx-tag-box>
                            </div>
                        </div>
                        <div fxFlex="20" fxLayout="column">
                            <dx-select-box style="width: 95% !important;padding-left: 12px;" placeholder="Date Range" [dataSource]="selectBoxDataSource" [searchEnabled]="true"  (onValueChanged)="selectDateRange($event)">
                            </dx-select-box>
                        </div>
                        <div fxFlex="15" fxLayout="column">
                            <dx-check-box id="targetElement" text="Missing Timesheets Filter" (onValueChanged)="checkBoxToggled($event)">
                            </dx-check-box>
                            <dx-tooltip target="#targetElement" showEvent="dxhoverstart" hideEvent="dxhoverend">
                                <div *dxTemplate="let data of 'content'">
                                    <p>show workers with no timesheets in the given date range</p>
                                </div>
                            </dx-tooltip>
                        </div>
                        <div fxFlex="20" fxLayout="column">
                          <div class="dx-field-value" style="width: 95% !important;">
                              <dx-tag-box class="searchBox" [dataSource]="vmsTimeSheetStatus" [searchEnabled]="true"
                                  placeholder="Select Status" [readOnly]="checkBoxTimesheet" (onValueChanged)="refreshGridForStatus($event)">
                              </dx-tag-box>
                          </div>
                        </div>
                        <div fxFlex="20" fxLayout="column">
                            <input type="text" class="search_text" [readOnly]="checkBoxTimesheet" [(ngModel)]="workerSearchFilter" placeholder="Worker Name Filter">
                        </div>
                        <div fxFlex="5" fxLayout="column">
                            <div class="dx-field-value" style="width: 100%;">
                                <dx-button style="height: 36px !important;" icon="refresh" (onClick)="searchTiimesheet();">
                                </dx-button>
                            </div>
                        </div>
                  </div>

                  <!-- date picker popup -->
                  <dx-popup title="Custom Date Range" [width]="500"
                  [height]="200" [(visible)]="isPopupVisible" style="width: 50% !important;height: 200px;">
                      <div *dxTemplate="let data of 'content'">
                          <div fxFlex="50" fxLayout="column">
                            <dx-date-box style="width: 95% !important;padding-left: 10px;" [(ngModel)]="fromDate" displayFormat="yyyy-MM-dd" [min]="minDate" [max]="maxDate" placeholder="Start date">
                            </dx-date-box>
                          </div>
                          <div fxFlex="50" fxLayout="column">
                            <dx-date-box style="width: 95% !important;padding-left: 10px;" [(ngModel)]="toDate" displayFormat="yyyy-MM-dd" [min]="minDate" [max]="maxDate" placeholder="Last date">
                            </dx-date-box>
                          </div>

                        <dx-button text="Done" class="dateButton" (onClick)="buttonClicked(fromDate,toDate)"></dx-button>
                      </div>
                  </dx-popup>

                  <!-- TImesheet datagrid -->
                  <dx-data-grid *ngIf="gridShow == false" [dataSource]="workerDataSource" [showRowLines]="true" [showColumnLines]="false"
                    [showBorders]="true" [allowColumnResizing]="true" (onCellClick)="onCellClick($event)">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager
                            [showPageSizeSelector]="true"
                            [allowedPageSizes]="[10, 30, 50, 100]"
                            [showInfo]="true">
                        </dxo-pager>

                        <dxi-column [width]="120" caption="Client Name" dataField="Worker.Client.businessName" dataType="string"></dxi-column>
                        <dxi-column [width]="130" caption="Timesheet ID" dataField="id" cellTemplate="timesheetId" dataType="string"></dxi-column>
                        <dxi-column [width]="150" caption="Worker Name" dataField="Worker.fullName" dataType="string"></dxi-column>
                        <dxi-column [width]="90" caption="From Date" dataField="fromDate" dataType="date" format="MM/dd/yyyy"></dxi-column>
                        <dxi-column [width]="90" caption="To Date" dataField="toDate" dataType="date" format="MM/dd/yyyy"></dxi-column>
                        <dxi-column [width]="80" caption="Status" dataField="status" dataType="string"></dxi-column>
                        <dxi-column [width]="80" caption="ST Hours" dataField="stHours" dataType="number" format="##,###0.00"></dxi-column>
                        <dxi-column [width]="80" caption="OT Hours" dataField="otHours" dataType="number" format="##,###0.00"></dxi-column>
                        <dxi-column [width]="80" caption="DT Hours" dataField="dtHours" dataType="number" format="##,###0.00"></dxi-column>
                        <dxi-column [width]="80" caption="NB Hours" dataField="nbHours" dataType="number" format="##,###0.00"></dxi-column>
                        <dxi-column [width]="100" caption="Other Hours" dataField="otherHours" dataType="number" format="##,###0.00"></dxi-column>
                        <dxi-column [width]="30" dataField="Action" dataType="string" cellTemplate="cellTemplate"></dxi-column>

                        <dxo-filter-row [visible]="showFilterRow"></dxo-filter-row>

                        <div class="grid-menu" *dxTemplate="let data of 'cellTemplate'">
                            <div >
                                <dx-menu style="padding: 0px 0px 0px !important;" [dataSource]="menuItems"
                                    displayExpr="name"
                                    (onItemClick)="optionClick($event)"></dx-menu>
                            </div>
                        </div>
                        <div *dxTemplate="let cell of 'timesheetId'">
                            <div style="color:#039be5;cursor: pointer;">{{ cell.text }}</div>
                        </div>
                </dx-data-grid>

                <!-- No Timesheet Grid-->
                <dx-data-grid *ngIf="gridShow == true" [dataSource]="workerNoTimesheetData" [showRowLines]="true" [showColumnLines]="false"
                    [showBorders]="true" [allowColumnResizing]="true">
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager
                        [showPageSizeSelector]="true"
                        [allowedPageSizes]="[10, 30, 50, 100]"
                        [showInfo]="true">
                    </dxo-pager>

                    <dxi-column [width]="120" caption="Client Name" dataField="ClientName" dataType="string"></dxi-column>
                    <dxi-column [width]="130" caption="Timesheet ID" dataField="timesheet" dataType="string"></dxi-column>
                    <dxi-column [width]="200" caption="Worker Name" dataField="fullName" dataType="string"></dxi-column>
                    <dxi-column [width]="90" caption="From Date" dataField="fromDate" dataType="date" format="MM/dd/yyyy"></dxi-column>
                    <dxi-column [width]="90" caption="To Date" dataField="toDate" dataType="date" format="MM/dd/yyyy"></dxi-column>
                    <dxi-column [width]="80" caption="Status" dataField="status" dataType="string"></dxi-column>
                    <dxi-column [width]="80" caption="ST Hours" dataField="stHours" dataType="number" format="##,###0.00"></dxi-column>
                    <dxi-column [width]="80" caption="OT Hours" dataField="otHours" dataType="number" format="##,###0.00"></dxi-column>
                    <dxi-column [width]="80" caption="DT Hours" dataField="dtHours" dataType="number" format="##,###0.00"></dxi-column>
                    <dxi-column [width]="80" caption="NB Hours" dataField="nbHours" dataType="number" format="##,###0.00"></dxi-column>
                    <dxi-column [width]="100" caption="Other Hours" dataField="otherHours" dataType="number" format="##,###0.00"></dxi-column>
                </dx-data-grid>

                <!-- timesheet popup -->
                <dx-popup title="Timesheet #{{timesheetId}}" [width]="600" [(visible)]="istimesheetPopupVisible">
                    <div *dxTemplate="let data of 'content'">
                            <dx-data-grid [dataSource]="timesheetDataSource" [showRowLines]="true" [showColumnLines]="false"
                            [showBorders]="true" [allowColumnResizing]="true">
                                <dxo-paging [pageSize]="10"></dxo-paging>
                                <dxo-pager
                                    [showPageSizeSelector]="true"
                                    [allowedPageSizes]="[10, 30, 50, 100]"
                                    [showInfo]="true">
                                </dxo-pager>

                                <dxi-column [width]="120" caption="Date" dataField="date" dataType="date" format="MM/dd/yyyy"></dxi-column>
                                <dxi-column [width]="80" caption="ST Hours" dataField="st" dataType="number" format="##,###0.00"></dxi-column>
                                <dxi-column [width]="80" caption="OT Hours" dataField="ot" dataType="number" format="##,###0.00"></dxi-column>
                                <dxi-column [width]="80" caption="DT Hours" dataField="dt" dataType="number" format="##,###0.00"></dxi-column>
                                <dxi-column [width]="80" caption="NB Hours" dataField="nb" dataType="number" format="##,###0.00"></dxi-column>
                                <dxi-column [width]="100" caption="Other Hours" dataField="other" dataType="number" format="##,###0.00"></dxi-column>

                        </dx-data-grid>

                    </div>
                </dx-popup>


                <!-- timesheet PDF Viewer popup -->
                <dx-popup title="Timesheet" [width]="880" [(visible)]="istimesheetPdfVisible">
                    <div *dxTemplate="let data of 'content'" style="overflow-x: scroll;">
                        <pdf-viewer [src]="pdfSrc" [zoom]="1" [render-text]="true" style="display: block;"
                        ></pdf-viewer>
                    </div>
                </dx-popup>


              </div>
          </div>
      </div>
  </div>
</div>
