<div class="search-block">
    <div class="search-block__icon"><span (click)="toggleCollapse()" style="cursor:pointer;" class="icon-bars"></span></div>
    <div class="search-block__input">
        <ul>
            <li class="search-body">
                <div class="feild-block">
                    <i class="fas fa-search"></i>
                    <input
                        class="form-control"
                        (keyup)="searchTextChange($event)"
                        [(ngModel)]="searchTerm.query"
                        placeholder="Search"
                    >
                </div>
            </li>
            <li>
                <a (click)="resetFilters()">Clear</a>
            </li>
            <li>
                <a>Save</a>
            </li>
        </ul>
    </div>

    <div class="search-block__controls">
        <ul>
            <!-- <li>
        <a>
          <i class="fas fa-folder-open"></i> <span>Open</span>
        </a>
      </li> -->
            <li>
                <a [href]="mailto">
                    <i class="fas fa-reply"></i>
                    <span>Reply</span>
                </a>
            </li>
            <!-- <li>
        <a>
          <i class="fas fa-reply-all"></i> <span>Reply All</span>
        </a>
      </li> -->
            <li>
                <a>
                    <i class="fas fa-arrow-alt-circle-right"></i>
                    <span>Forward</span>
                </a>
            </li>
            <li>
                <a>
                    <i class="fas fa-trash-alt"></i>
                    <span>Delete</span>
                </a>
            </li>
            <!-- <li>
                <a>
                    <i class="fas fa-envelope"></i>
                    <span>Mark Read</span>
                </a>
            </li> -->
            <!-- <li>
        <a>
          <i class="fas fa-cut"></i> <span>Move</span>
        </a>
      </li> -->
        </ul>
    </div>
</div>
