<div id="forms" class="page-layout simple fullwidth p-24" fxLayout="column" pulsePerfectScrollbar>
    <div class="container mt-12">
        <div class="content">
            <div class="mat-white-bg mat-elevation-z4 mb-24">
                <div class="page-title-wrapper" fxLayout="row" fxFlex="1 0 auto">
                    <div class="pulse-main-heading" *ngIf="action">Add Role</div>
                    <div class="pulse-main-heading" *ngIf="!action">Edit Role</div>
                </div>
                <div class="add-role-form">
                    <form class="add-user-form" (submit)="onFormSubmit($event)">

                        <div id="form-container" class="mb-20">

                            <dx-form id="form"
                                     [readOnly]="false"
                                     [showColonAfterLabel]="true"
                                     [showValidationSummary]="true"
                                     [colCount]="12"
                                     labelLocation="top"
                                     [formData]="role"
                                     validationGroup="roleValidationGroupData"
                            >
                                <dxi-item dataField="name" [colSpan]="6">
                                    <dxo-label text="Role Name"></dxo-label>
                                    <dxi-validation-rule
                                        type="required"
                                        message="Role Name is required">
                                    </dxi-validation-rule>
                                </dxi-item>
                                <dxi-item dataField="description" [colSpan]="6">
                                    <dxo-label text="Descritpion"></dxo-label>
                                </dxi-item>
                                <dxi-item dataField="is_active"
                                          editorType="dxSwitch"
                                          [editorOptions]="{switchedOffText:'No',switchedOnText:'Yes'}"
                                          [colSpan]="6">
                                </dxi-item>
                            </dx-form>
                        </div>
                        <div class="buttons-container">
                            <div class="left">
                                <dx-button text="Cancel"
                                           type="normal"
                                           class="pulse-btn pulse-btn-normal"
                                           (onClick)="cancelForm()"
                                >
                                </dx-button>
                            </div>
                            <div class="right">
                                <dx-button *ngIf="action"
                                           text="Save"
                                           type="success"
                                           validationGroup="roleValidationGroupData"
                                           class="pulse-btn pulse-btn-primary"
                                           [useSubmitBehavior]="true"
                                >
                                </dx-button>
                                <dx-button *ngIf="!action"
                                           text="Save"
                                           type="success"
                                           class="pulse-btn pulse-btn-primary"
                                           validationGroup="roleValidationGroupData"
                                           [useSubmitBehavior]="true"
                                >
                                </dx-button>
                            </div>
                        </div>
                    </form>
                    <!-- <div id="form-container" class="mb-20">
                          <form (submit)="onFormSubmit($event)">
                      <dx-form id="form"
                          [colCount]="2"
                          [formData]="role">
                          <dxi-item dataField="name" [editorOptions]="{ width: '100%' }">
                              <dxo-label text="Role Name"></dxo-label>
                              <dxi-validation-rule type="required" message="Role name is required"></dxi-validation-rule>
                              <dxi-validation-rule type="stringLength" [min]="2" message="Role name must have at least 2 characters"></dxi-validation-rule>
                          </dxi-item>
                          <dxi-item dataField="description" [editorOptions]="{ width: '100%' }" editorType="dxTextArea" [editorOptions]="{ height: 90 }">
                                  <dxo-label text="Role Description"></dxo-label>
                          </dxi-item>
                          <dxi-item dataField="is_active"  editorType="dxSwitch" [editorOptions]="{switchedOffText:'No',switchedOnText:'Yes'}"></dxi-item>
                          <dxi-item itemType="empty" [colSpan]="1"></dxi-item>
                          <dxi-item itemType="group" [colCount]="4">
                          <dxi-item itemType="button" class="right" [buttonOptions]="buttonOptions"></dxi-item>
                          <dxi-item itemType="button" class="left"
                          [buttonOptions]="{
                              text: 'Cancel',
                              type: 'normal',
                              useSubmitBehavior: false,
                              onClick: cancelForm
                            }">
                          </dxi-item>
                          <dxi-item itemType="empty" [colSpan]="2"></dxi-item>
                          </dxi-item>
                          <dxi-item itemType="empty" [colSpan]="1"></dxi-item>
                      </dx-form>
                      </form>
                  </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
