//KEYS

export const MAIL_FILTER = "mailboxEmailAddress";
export const FOLDER_FILTER = "folderName";
export const ATTACHMENT_FILTER = "mongo_metadata.type";
export const ES_KEY_BODY_CONTENT = "body_content";
export const ES_KEY_HIGHLIGHT = "highlight";
export const SEND_DATE_TIME = "sentDateTime";
export const SUBJECT = "subject";
export const SENDER_EMAIL_ADDRESS = "sender_emailAddress_address";
export const SENDER_EMAIL_NAME = "sender_emailAddress_name";
export const FOLDER_NAME = "folderName";
export const EMAIL_INDEX_TYPE = "email";
export const ATTACHMENT_INDEX_TYPE = "attachment";
export const ATTACHMENT_FILENAME = "filename";
export const ATTACHMENT_TYPE = "mongo_metadata.type";
export const ATTACHMENT_SIZE = "mongo_metadata.size";
export const ATTACHMENT_UPLOAD_TIME = "mongo_metadata.UploadTime";

//API

export const EMAIL_SEARCH = "emailsearch/email";

export const USER_ENTITLEMENT = "getUserEntitlement?email=";


