import {NgModule, Component, ViewChild, enableProdMode, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {PulseTranslationLoaderService} from '@pulse/services/translation-loader.service';

import {locale as english} from './i18n/en';
import {locale as turkish} from './i18n/tr';
import {SwalService} from '../../../../services/swal.service';

import {Item, SmbLogsService} from '../../../../services/smblogs/smblogs.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-smb-log',
    templateUrl: './smb-log.component.html',
    styleUrls: ['./smb-log.component.scss'],
    providers: [SmbLogsService]
})
export class SmbLogComponent implements OnInit {
    userData : any = [];
    items: any = [];
    logItems:any;
    logId: any;
    isLogPopupVisible = false;
    menuItems = [
        {
            icon: 'preferences',
            items: [
                {name: 'Log', icon: "doc"},
            ]
        }
    ];
   // @ViewChild(SmbAuthFormComponent) subAuthForm: SmbAuthFormComponent;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private pulseTranslationLoader: PulseTranslationLoaderService,
        private swalService: SwalService,
        private smbLogsService: SmbLogsService
    ) {
        //super();
        this.pulseTranslationLoader.loadTranslations(english, turkish);

    }
    goToSmbShare(){
         this.router.navigate(['smbshare']);
    }

    ngOnInit() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.items = Item;
        // this.smbLogsService.getAllLogsByUserId(localStorage.getItem("user_id")).subscribe(
        this.smbLogsService.getAllLogsByUserId(this.userData.id).subscribe(
                (result) => {
                console.log("getAllLogsByUserIdRESULT", result);
                this.items = result;
            },
            (error) => {
                console.log("getAllLogsByUserIdERROR", error);

            }
        );

    }
    optionClick(event: any) {
        if (event.itemData.name == 'Log') {
            this.smbLogsService.getPostDetailsByLogId(this.logId).subscribe(
                (result) => {
                    this.isLogPopupVisible = true;
                    this.logItems = result;
                },
                (error) => {
                    console.log("optionClickERROR", error);

                }
            );
        }
    }
    onCellClick(event: any) {
        if (!event || !event.data) {
            return;
        }
        this.logId = event.data.Action;
    }
    ngAfterViewInit() {

    }

    updateNewHireType($event) {

    }

    redirectTo(path) {

    }
}
