import { Component, OnInit } from "@angular/core";
import { ListServiceService } from "../../../../../services/emailSearch/list-service.service";

@Component({
  selector: "app-content-preview",
  templateUrl: "./content-preview.component.html",
  styleUrls: ["./content-preview.component.scss"]
})
export class ContentPreviewComponent implements OnInit {
  constructor(private listService: ListServiceService) {}
  previewContent: any = "";
  searchTextGlobal: string;
  ngOnInit() {
    this.previewContent = this.listService.getSelectedForPreview();
    this.searchTextGlobal = this.listService.getMailBoxList();
  }
}
