import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {DxSelectBoxModule, DxTagBoxModule, DxTemplateModule } from 'devextreme-angular';
import {PulseHeaderComponent} from './header.component';
import {PulseSharedModule} from '@pulse/shared.module';
import {PulseNavigationModule} from '@pulse/components/navigation/navigation.module';
import {MatRippleModule} from "@angular/material/core";
@NgModule({
    declarations: [
        PulseHeaderComponent
    ],
    imports: [
        RouterModule,
        PulseSharedModule,
        DxTemplateModule,
        DxTagBoxModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatTooltipModule,
        DxSelectBoxModule,
        PulseNavigationModule,
        MatRippleModule
    ],
    exports: [
        PulseHeaderComponent
    ]
})
export class PulseHeaderModule {
}
