<div class="page-layout simple fullwidth pulse-report" pulsePerfectScrollbar>
    <!-- container -->
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto">
                    <div class="pulse-main-heading" fxFlex="50">
                        LinkedIn Search
                    </div>
                </div>
                <div class="p-30 pt-15 pb-15 iframe-wrapper vh-height">
                    <iframe width="100%" height="100%" frameborder="0" src="http://contact.aptask.net/listing"></iframe>
                </div>

            </div>
        </div>
    </div>
    <!-- container -->
</div>
