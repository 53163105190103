import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthGuardService as AuthGuard} from 'app/services/auth/auth-guard.service';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PulseSharedModule} from '@pulse/shared.module'
import {
    DxDataGridModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxFormModule,
    DxPopupModule,
    DxButtonModule,
    DxTemplateModule,
    DxMenuModule,
    DxSwitchModule,
    DxCheckBoxModule,
    DxTreeViewModule, DxListModule,
    DxAccordionModule, DxDropDownBoxModule, DxTagBoxModule,
} from 'devextreme-angular';
import {AccountListComponent} from './account-list/account-list.component';
import {FormComponent} from './form/form.component';

const routes = [
    {
        path: 'scraper-account',
        component: AccountListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'scraper-account/edit/:id',
        component: FormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'scraper-account/add',
        component: FormComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [AccountListComponent, FormComponent],
    imports: [
        PulseSharedModule,
        DxDataGridModule,
        DxSelectBoxModule,
        DxTextAreaModule,
        DxFormModule,
        DxPopupModule,
        DxButtonModule,
        DxTemplateModule,
        DxMenuModule,
        DxSwitchModule,
        DxCheckBoxModule,
        DxTreeViewModule,
        DxListModule,
        DxAccordionModule,
        DxTemplateModule,
        BrowserModule,
        DxDataGridModule,
        ReactiveFormsModule,
        FormsModule,
        PulseSharedModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        DxDropDownBoxModule,
        DxTagBoxModule
    ]
})
export class ScraperAccountModule {
}
