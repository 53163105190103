import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {siteConfig} from '../../siteSettings';

@Component({
    selector: 'app-account-verification',
    templateUrl: './account-verification.component.html',
    styleUrls: ['./account-verification.component.scss']
})
export class AccountVerificationComponent implements OnInit {

    responseCode: number = 404;
    responseHeading: string = `Welcome to <span>${siteConfig.companyName}</span>`;
    responseMessage: string = 'Unauthorized access code';

    constructor(private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            this.responseCode = params.code || 404;
        });

        this.getStatusMessage();
    }

    ngOnInit() {
    }

    getStatusMessage() {
        switch (Number(this.responseCode)) {
            case 605:
                this.responseHeading = 'Congratulations!!! \n' +
                    'Welcome to <span>' + siteConfig.companyName + '</span>. ';
                this.responseMessage = 'Your account has been verified.';
                break;

            case 600:
                this.responseHeading = 'System Error.';
                this.responseMessage = 'please contact to Site Admin.';
                break;

            case 602:
                this.responseHeading = 'Token Expired.';
                this.responseMessage = 'your token is expired';
                break;

            case 603:
                this.responseHeading = 'Invalid Token.';
                this.responseMessage = '';
                break;

            case 604:
                this.responseHeading = 'Email not found.';
                this.responseMessage = 'Please check your email.';
                break;

            case 601:
                this.responseHeading = 'Account already verified';
                this.responseMessage = '';
                break;

        }
    }
}
