import {Component, OnInit} from '@angular/core';
import {ScraperAccountsService} from 'app/services/scraper-accounts/scraper-accounts.service';
import {Router} from '@angular/router';
import {SwalService} from '../../../../services/swal.service';

export interface PeriodicElement {
    name: string;
    role_id: number;
    is_active: number;
    description: string;
}

@Component({
    selector: 'scraper-account-list',
    templateUrl: './account-list.component.html',
    styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {
    displayedColumns: string[] = ['role_id', 'name', 'username', 'password', 'inUse', 'userId'];
    dataSource: PeriodicElement[];

    constructor(
        private scraperAccountService: ScraperAccountsService,
        private router: Router,
        private swalService: SwalService) {

    }

    currentSelectedObject: PeriodicElement;

    menuItems = [
        {
            icon: 'preferences',
            items: [
                {name: 'Edit', icon: 'edit', id: 'edit'},
                // {name: 'Delete', icon: 'clear', id: 'delete'},
                // {name: 'Assign Team', icon: 'selectall', id: 'assignTeam'}
                // {name: 'Assign Team', icon: 'group', id: 'assignTeam'}
            ]
        }
    ];

    ngOnInit() {
        this.getRoleList();
    }

    // Getting API for getRoleList
    getRoleList() {
        // Getting API for getRoleList
        let swalData = {};
        this.scraperAccountService.getAccounts().subscribe(
        response => {
            this.dataSource = response['data'];
            console.log('this.dataSource', this.dataSource);
        },
        error => {
            swalData = {
                title: 'Error',
                message: `Unable to fetch roles !`,
                info: 'Server error'
            };
            this.swalService.error(swalData);
        });
    }

    navigateToAdd() {
        this.router.navigate(['/scraper-account/add']);
    }

    // selectedAction(ev){
    //   console.log(ev);
    //   if(ev.id == 1){
    //     this.rolesService.setRole(this.currentSelectedObject);
    //     this.router.navigate(['/role/edit/'+this.currentSelectedObject.role_id]);
    //   }
    //   if(ev.id == 2){
    //     this.deleteRole();
    //   }
    // }

    onRowClickData(data) {
        this.currentSelectedObject = data;
        console.log(data);
    }

    deleteRole() {
        // this.rolesService.deleteRole(this.currentSelectedObject.role_id)
        //   .subscribe(
        //     response => {
        //       console.log(response);
        //       this.getRoleList();
        //      },
        //     error => { });
    }

    optionClick(event: any, record) {
        console.log(event, record);
        if (event.itemData.id === 'edit') {
            this.router.navigate(['/scraper-account/edit/' + record.data.id]);
            // record.data
        }
    }

}
