import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthGuardService as AuthGuard} from '../../../services/auth/auth-guard.service';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {
    DxButtonModule, DxCheckBoxModule, DxDataGridModule,
    DxFileUploaderModule,
    DxFormModule, DxMenuModule,
    DxPopupModule, DxScrollViewModule, DxSelectBoxModule,
    DxTabPanelModule, DxTextBoxModule, DxValidatorModule
} from 'devextreme-angular';

import {PulseSharedModule} from '../../../../@pulse/shared.module';
import {UserListComponent} from './user-list/user-list.component';

// import {UserEditComponent} from "./user-edit/user-edit.component";
// import {UsersService} from "../../../services/users/users.service";
// import {MatTooltipModule} from "@angular/material/tooltip";

import {UserEditComponent} from './user-edit/user-edit.component';
import { UserDetailMainComponent } from './user-detail-main/user-detail-main.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CanDeactivateGuard } from '../../../services/can-deactivate/can-deactivate.guard';
import { UserCommissionComponent } from './user-commission/user-commission.component';
import { UserOnboardingComponent } from './user-onboarding/user-onboarding.component';
import { UserPayrollComponent } from './user-payroll/user-payroll.component';
import { UserDatesComponent } from './user-dates/user-dates.component';


const routes = [
    {
        path: 'my-profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user',
        component: UserListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user/list',
        component: UserListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user/add',
        component: UserEditComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user/edit/:id',
        redirectTo: 'user/edit/details/:id',

        /*component: UserEditComponent,
        canActivate: [AuthGuard]*/
    },
    {
        path: 'user/edit/:slug/:id',
        component: UserDetailMainComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
    }
];


@NgModule({
    declarations: [UserProfileComponent, UserListComponent, UserEditComponent, UserDetailMainComponent, UserCommissionComponent, UserOnboardingComponent, UserPayrollComponent, UserDatesComponent],
    imports: [
        PulseSharedModule,
        CommonModule,
        RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'}),
        DxTabPanelModule,
        DxFormModule,
        DxFileUploaderModule,
        DxButtonModule,
        DxPopupModule,
        DxDataGridModule,
        DxMenuModule,
        DxCheckBoxModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxScrollViewModule,
        MatTooltipModule,
        MatTabsModule,
        MatCardModule,
        MatInputModule,
        MatIconModule,
        MatMenuModule,
        MatListModule,
        MatSelectModule,
        PdfViewerModule,
        MatTableModule,
        DxValidatorModule
    ]
})
export class UserModule {
}
