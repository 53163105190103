<div class="page-layout simple fullwidth" pulsePerfectScrollbar>
    <!-- container -->
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-20" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto"
                     fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading">
                        Dynamic Report
                    </div>
                    <div>
                        <dx-button class="pulse-btn pulse-btn-normal px-4" (onClick)="generateSource()">
                            <i class="dx-icon dx-icon-refresh s-20"></i>
                        </dx-button>
                    </div>
                </div>
                <div class="p-30 pt-15 pb-15 grid-wrapper">
                    <dx-form id="form" #dxFilterDataForm [readOnly]="false"
                             [formData]="filterFormData"
                             [showColonAfterLabel]="true"
                             [showValidationSummary]="false"
                             [colCount]="1"
                             validationGroup="filterFormDataGroup"
                             labelLocation="top">
                        <dxi-item itemType="group" [colCount]="12">
                            <dxi-item dataField="frmPeriod" editorType="dxSelectBox" cssClass="pr-4" [colSpan]="3"
                                      [editorOptions]="{ items: periodData, valueExpr: 'ID',
                                               displayExpr: 'Name', required: true, searchEnabled: true, onValueChanged: onPeriodSelect,
                                                onOpened: onDropDownOpened
                                                }">
                                <dxo-label text="Period"></dxo-label>
                                <dxi-validation-rule
                                    type="required"
                                    message="Please select period">
                                </dxi-validation-rule>
                            </dxi-item>

                            <dxi-item dataField="dateFrom" editorType="dxDateBox" cssClass="px-8" [colSpan]="3"
                                      [editorOptions]="{displayFormat: 'dd-MM-yyyy', width: '130px', required: true,
                                      disabled: filterFormData.frmPeriod !== 'custom'}">
                                <dxo-label text="Date From"></dxo-label>
                                <dxi-validation-rule *ngIf="filterFormData.frmPeriod === 'custom'"
                                    type="required"
                                    message="Date From is required">
                                </dxi-validation-rule>
                            </dxi-item>
                            <dxi-item dataField="dateTo" editorType="dxDateBox" cssClass="px-8" [colSpan]="3"
                                      [editorOptions]="{displayFormat: 'dd-MM-yyyy', width: '130px', required: true,
                                      disabled: filterFormData.frmPeriod !== 'custom'}">
                                <dxo-label text="Date To"></dxo-label>
                                <dxi-validation-rule *ngIf="filterFormData.frmPeriod === 'custom'"
                                    type="required"
                                    message="Date to is required">
                                </dxi-validation-rule>
                            </dxi-item>
                            <dxi-item dataField="consultantStatus" editorType="dxSelectBox" [colSpan]="3"
                                      cssClass="px-8 text-nowrap"
                                      [editorOptions]="{ items: consultantStatuses, valueExpr: 'id',
                                               displayExpr: 'name', searchEnabled: true}">
                                <dxo-label text="Consultant Status"></dxo-label>
                            </dxi-item>
                            <dxi-item dataField="consultantType" editorType="dxSelectBox" [colSpan]="3"
                                      cssClass="pr-8 text-nowrap"
                                      [editorOptions]="{ items: consultantTypes, valueExpr: 'id',
                                               displayExpr: 'name', searchEnabled: true, onValueChanged: onStatusSelect}">
                                <dxo-label text="Consultant Type"></dxo-label>
                            </dxi-item>

                            <dxi-item dataField="status" editorType="dxSelectBox" [colSpan]="3"
                                      cssClass="px-8 text-nowrap"
                                      [editorOptions]="{ items: status, valueExpr: 'value',
                                               displayExpr: 'master_lookup_name', searchEnabled: true,
                                               disabled: filterFormData.consultantType !== '200000'}">
                                <dxo-label text="Status"></dxo-label>
                            </dxi-item>

                            <dxi-item dataField="consolidatedReport" editorType="dxCheckBox" [colSpan]="3"
                                      [editorOptions]="{onValueChanged: changeValue}">
                                <dxo-label text="Consolidate Report"></dxo-label>
                            </dxi-item>

                        </dxi-item>
                    </dx-form>
                    <div id="tabs" class="mt-12">
                        <dx-tabs *ngIf="!filterFormData.consolidatedReport"
                            [dataSource]="tabs"
                            [selectedIndex]="0"
                            [width]="'auto'"
                            (onItemClick)="selectTab($event)"
                        ></dx-tabs>
                        <div class="content dx-fieldset" fxLayout="column" *ngIf="selectedTab === 0">
                            <ng-container *ngFor="let fields of selectedFields; let i = index">
                                <div fxLayout="row">
                                    <div class="dx-field" fxFlex="80">
                                        <dx-select-box
                                            [searchEnabled]="true"
                                            [dataSource]="groupedSelectedBox"
                                            [grouped]="true"
                                            [value]="fields.field"
                                            (onSelectionChanged)="selectItem($event, i)"
                                            displayExpr="text"
                                            valueExpr="id"
                                        ></dx-select-box>
                                    </div>
                                    <div class="dx-field" fxFlex="20" *ngIf="!filterFormData.consolidatedReport">
                                        <dx-button class="pulse-btn pulse-btn-normal" style="display: inline-block"
                                                   icon="plus" *ngIf="i === (selectedFields.length - 1)"
                                                   (onClick)="addNewFields()"></dx-button>
                                        <dx-button *ngIf="i === 0" class="pulse-btn pulse-btn-normal"
                                                   [ngClass]="{'pulse-btn-primary': fields.order === 'ASC'}"
                                                   style="display: inline-block"
                                                   icon="chevronup" (onClick)="fields.order = 'ASC'"></dx-button>
                                        <dx-button *ngIf="i === 0" class="pulse-btn pulse-btn-normal"
                                                   [ngClass]="{'pulse-btn-primary': fields.order === 'DESC'}"
                                                   style="display: inline-block"
                                                   icon="chevrondown" (onClick)="fields.order = 'DESC'"></dx-button>
                                        <dx-button class="pulse-btn pulse-btn-normal" style="display: inline-block"
                                                   icon="clear" *ngIf="i !== 0" (onClick)="removeFields(i)"></dx-button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="content dx-fieldset" fxLayout="column" *ngIf="selectedTab === 1  && !filterFormData.consolidatedReport">
                            <ng-container *ngFor="let fields of groupedCheckBoxes | keyvalue">
                                <div class="check-header">
                                    {{fields.key}}
                                    <dx-check-box text="All" [value]="fields.value.all"
                                                  [elementAttr]="{ class: 'my-10 multi-check' }"
                                                  (onValueChanged)="selectAllChecked($event, fields.key)">
                                    </dx-check-box>
                                </div>
                                <div fxLayout="row">
                                    <div class="dx-field">
                                        <dx-check-box *ngFor="let field of fields.value.fields; let i = index"
                                                      [text]="field.text"
                                                      [elementAttr]="{ class: 'my-10 multi-check' }"
                                                      [(value)]="field.checked"
                                                      (onValueChanged)="onChangeChecked($event, fields)">
                                        </dx-check-box>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="content dx-fieldset" fxLayout="column" *ngIf="selectedTab === 1 && filterFormData.consolidatedReport">
                            <ng-container *ngFor="let fields of groupedCheckBoxes | keyvalue">
                                <div class="check-header">
                                    {{fields.key}}
                                </div>
                                <div fxLayout="row">
                                    <div class="dx-field">

                                        <dx-radio-group
                                            [dataSource]="fields.value.fields" displayExpr="text" valueExpr="id"
                                            layout="horizontal"
                                            [(value)]="consolidateValue || fields.value.fields[id]"
                                            (onValueChanged)="handleRadioValue($event, fields.value.fields)"
                                        >
                                        </dx-radio-group>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <dx-button class="pulse-btn pulse-btn-normal mb-12" width="190" type="normal" [disabled]="!filterFormData.frmPeriod ||
                             (filterFormData.frmPeriod === 'custom' && (!filterFormData.dateFrom || !filterFormData.dateTo))"
                                   text="Generate report"
                                   (onClick)="generateReport()"></dx-button>
                    </div>

                    <div class="grid-wrapper" *ngIf="tableSource.length > 0">
                        <div fxLayout="column">
                            <div class="pdf-icon" fxLayout="row wrap" fxFlex="1 0 auto">
                                <dx-button icon="importselected"
                                           (onClick)="onSaveSearchHistoryPopup()"
                                           matTooltip="Save Search History"
                                           class="pulse-btn pulse-btn-normal justify-end m-0 save-history">
                                </dx-button>
                                <dx-button icon="pdffile"
                                           (onClick)="onExporting()"
                                           matTooltip="Export to pdf"
                                           class="pulse-btn pulse-btn-normal justify-end m-0">
                                </dx-button>
                                <dx-data-grid [dataSource]="tableSource" [showRowLines]="true"
                                              [rowAlternationEnabled]="true" [showColumnLines]="false"
                                              [showBorders]="true"
                                              [allowColumnResizing]="true"
                                              (onOptionChanged)="changeColumn($event)"
                                              [allowColumnReordering]="true"
                                              height="600px" style="max-width: 100% !important;">

                                    <dxo-paging [pageSize]="12"></dxo-paging>
                                    <dxo-export [enabled]="true" [customizeExcelCell]="customizeExport"></dxo-export>
                                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[15, 30, 50]"
                                               [showInfo]="true"></dxo-pager>

                                    <ng-container *ngFor="let fields of selectedFields">
                                        <dxi-column [dataField]="fields.field"
                                                    *ngIf="!idFields.includes(fields.field) && !countField.includes(fields.field)"
                                                    dataType="string"
                                                    [caption]="fields.text"></dxi-column>
                                        <dxi-column [dataField]="fields.field" *ngIf="idFields.includes(fields.field)"
                                                    cellTemplate="cellTemplateName" dataType="string"
                                                    [caption]="fields.text"></dxi-column>
                                        <dxi-column [dataField]="fields.field"
                                                    *ngIf="filterFormData.consolidatedReport && countField.includes(fields.field)"
                                                    cellTemplate="countTemplateName" dataType="string"
                                                    [caption]="fields.text"></dxi-column>
                                    </ng-container>

                                    <div *dxTemplate="let data of 'cellTemplateName'">
                                        <a [routerLink]="['/consultant/edit/'+ data?.value?.id]">{{data?.value?.text}}</a>
                                    </div>
                                    <div *dxTemplate="let data of 'countTemplateName'">
                                        <a [routerLink]="" (click)="detailDataOfCount(data?.data)">{{data?.text}}</a>
                                    </div>
                                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                                </dx-data-grid>
                            </div>
                        </div>
                    </div>
                    <div class="grid-wrapper" *ngIf="searchHistory.length > 0">
                        <div class="history-heading"><span>Search History</span></div>
                        <div fxLayout="column">
                            <div fxLayout="row wrap" fxFlex="1 0 auto">
                                <dx-data-grid [dataSource]="searchHistory" [showRowLines]="true"
                                              [rowAlternationEnabled]="true" [showColumnLines]="false"
                                              [showBorders]="true"
                                              [columnAutoWidth]="true"
                                              [allowColumnResizing]="true">
                                    <dxo-paging [pageSize]="12"></dxo-paging>
                                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[15, 30, 50]"
                                               [showInfo]="true"></dxo-pager>
                                    <dxi-column dataField="report_name" dataType="string" caption="Report Name"></dxi-column>
                                    <dxi-column dataField="period" dataType="string" caption="Period"></dxi-column>
                                    <dxi-column dataField="fromDate" format="MM-dd-yy" dataType="date" caption="Date From"></dxi-column>
                                    <dxi-column dataField="toDate" format="MM-dd-yy" dataType="date" caption="Date To"></dxi-column>
                                    <dxi-column dataField="consultant_status" dataType="string" caption="Consultant Status"></dxi-column>
                                    <dxi-column dataField="status" dataType="string" caption="Status"></dxi-column>
                                    <dxi-column dataField="consultant_type" dataType="string" caption="Consultant Type"></dxi-column>
                                    <dxi-column dataField="consolidate_report" dataType="string" caption="Consultant Report"></dxi-column>
                                    <dxi-column dataField="fields" dataType="string" caption="Fields" [width]="400"></dxi-column>
                                    <dxi-column [width]="80" cellTemplate="cellTemplate" alignment="center" [fixed]="true" fixedPosition="right"></dxi-column>
                                    <div *dxTemplate="let data of 'cellTemplate'" class="search-detail">
                                        <dx-button icon="selectall" (onClick)="selectedSearch(data)"></dx-button>
                                        <dx-button icon="close" (onClick)="selectedSearchRemove(data)" [visible]="data.data.isShow === true"></dx-button>
                                        <dx-button icon="trash" (onClick)="removeSearchHistory(data.data)"></dx-button>
                                    </div>
                                </dx-data-grid>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <dx-popup class="popup" [width]="1000" [height]="580" [showTitle]="true"
              title="Consultant Details"
              [dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="countDetailsPopup">
        <div class="grid-wrapper" *ngIf="countDetailsData.length > 0">
            <div fxLayout="column">
                <div fxLayout="row wrap" fxFlex="1 0 auto">

                    <dx-data-grid [dataSource]="countDetailsData" [showRowLines]="true"
                                  [rowAlternationEnabled]="true" [showColumnLines]="false"
                                  [showBorders]="true"
                                  [allowColumnResizing]="true"
                                  height="500px" style="max-width: 100% !important;">
                        <dxo-paging [pageSize]="12"></dxo-paging>
                        <dxo-export [enabled]="true" [customizeExcelCell]="customizeExport"></dxo-export>
                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[15, 30, 50]"
                                   [showInfo]="true"></dxo-pager>

                        <ng-container *ngFor="let fields of countDetailsFields">
                            <dxi-column [dataField]="fields" *ngIf="fields.includes(countDetailsData)"
                                        dataType="string"
                                        [caption]="fields"></dxi-column>
                        </ng-container>
                    </dx-data-grid>

                </div>

            </div>
        </div>

    </dx-popup>

    <dx-popup class="popup" [width]="450" [height]="isSelectedHistory ? 320 : 290" [showTitle]="true" title="Search History"
              [dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="showSearchHistoryPopup">
        <div *dxTemplate="let data of 'content'">
            <form (submit)="saveSearchHistory()">
                <dx-form #dxSearchDetailsForm
                         [formData]="searchHistoryForm"
                         [showColonAfterLabel]="true"
                         [showValidationSummary]="false"
                         [colCount]="1"
                         labelLocation="top"
                         validationGroup="filterFormDataGroup">
                    <dxi-item [visible]="isSelectedHistory"
                        cssClass="type-radio-button"
                        dataField="frmSaveType"
                        itemType="simple"
                        editorType="dxRadioGroup"
                        [label]="{visible: false}"
                        [editorOptions]="{ dataSource: historyValues,
                                     layout: 'horizontal', valueExpr: 'value',
                                     value: searchHistoryForm.frmSaveType,
                                     textExpr: 'text'}">
                    </dxi-item>
                    <dxi-item dataField="frmName">
                        <dxo-label text="Report Name"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="Report Name is required">
                        </dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="frmType" editorType="dxSelectBox"
                              [editorOptions]="{items: reportType, valueExpr: 'value', displayExpr:'text'}">
                        <dxo-label text="Report Type"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="Report Type is required">
                        </dxi-validation-rule>
                    </dxi-item>
                </dx-form>
                <div class="mt-20" fxLayout="row" fxLayoutAlign="space-between center">
                    <dx-button text="Cancel" class="pulse-btn pulse-btn-normal"
                               (onClick)="showSearchHistoryPopup = false">
                    </dx-button>
                    <dx-button text="Yes" [useSubmitBehavior]="true"
                               [disabled]="!(searchHistoryForm.frmName && searchHistoryForm.frmType)"
                               class="pulse-btn pulse-btn-primary">
                    </dx-button>
                </div>
            </form>
        </div>
    </dx-popup>

    <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#record_list' }"
                   [(visible)]="loadingVisible"
                   [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
    </dx-load-panel>


</div>
