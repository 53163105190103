<div class="list-widget">
    <div class="list-widget__header"></div>
    <div
        class="list-widget__body"
        id="list-widget-body"
    >
        <nz-table
            #headerTable
            [nzLoading]="listOfData.loading"
            [nzData]="listOfData.list"
            [nzScroll]="{x: '2000px' , y: 'hidden' }"
            [nzShowPagination]="false"
            nzSize="small"
            class="class-table"                                                                            
        >
            <thead>
                <tr>
                    <th
                        [nzWidth]="item.width"
                        nzCustomFilter
                        *ngFor="let item of indexType.type == 'email' ? emailTableHeaders.emailTableHeaders : emailTableHeaders.attachmentTableHeaders"
                    >
                        <input
                            *ngIf="item.type == 'input'"
                            type="text"
                            nz-input
                            [placeholder]="item.label"
                            (keyup)="onColumnSearch(item.key)"
                            [(ngModel)]="item.inputModel"
                        >
                        <nz-range-picker
                            *ngIf="item.type == 'date'"
                            [(ngModel)]="item.inputModel"
                            (ngModelChange)="onColumnSearch($event)"
                        ></nz-range-picker>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let data of listOfData.list"
                    (click)="onRowClick(data)"
                    [ngClass]="selectedPreview.id == data.id ? 'selected_row' : ''"
                >
                    <td
                        nz-popover
                        [nzContent]="popupComponent"
                        *ngFor="let item of indexType.type == 'email' ? emailTableHeaders.emailTableHeaders : emailTableHeaders.attachmentTableHeaders"
                    >
                        <span
                            *ngIf="item.type == 'input' && item.key != 'mongo_metadata.size'"
                            [innerHTML]="
                            ( item.key.split('.').length > 1 ? data[item.key.split('.')[0]][item.key.split('.')[1]] : data[item.key] )  | textLengthFormatter : 20 | textFormatter : item.inputModel || searchTextGlobal.query
                            "
                        ></span>
                        <span
                            *ngIf="item.type == 'input' && item.key == 'mongo_metadata.size'"
                            [innerHTML]="
                            ( item.key.split('.').length > 1 ? data[item.key.split('.')[0]][item.key.split('.')[1]] : data[item.key] ) | fileSizeFormatter
                            "
                        ></span>
                        <span
                            *ngIf="item.type == 'date'"
                            [innerHTML]="
                                ( item.key.split('.').length > 1 ? data[item.key.split('.')[0]][item.key.split('.')[1]] : data[item.key] ) | dateFormatter
                            "
                        ></span>
                        <ng-template #popupComponent>
                            <div [innerHTML]="item.key.split('.').length > 1 ? data[item.key.split('.')[0]][item.key.split('.')[1]] : data[item.key]"></div>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </div>
    <div
        class="list-widget__footer"
        *ngIf="listOfData.list.length > 0"
    >
        <nz-pagination
            [nzTotal]="listOfData.total"
            [nzPageIndex]="listOfData.current"
            (nzPageIndexChange)="currentPageDataChange($event)"
            [nzSize]="'small'"
            [nzPageSize]="listOfData.size"
        ></nz-pagination>
    </div>
</div>
