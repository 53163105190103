import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {tap, catchError} from 'rxjs/operators';

import {siteConfig} from '../../siteSettings';

@Injectable({
    providedIn: 'root'
})
export class ScraperAccountsService {
    private _url_base: string = siteConfig.pulse3APIUrl;
    private roleObj;


    constructor(private http: HttpClient) {
    }

    getAccounts() {
        return this.http.get(`${this._url_base}apps/ai-engage/scraper-site-account`);
    }

    getById(id) {
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.get(`${this._url_base}apps/ai-engage/scraper-site-account`, {params});
    }

    add(data) {
        return this.http.post(`${this._url_base}apps/ai-engage/scraper-site-account`, data);
    }

    update(id, data) {
        return this.http.put(`${this._url_base}apps/ai-engage/scraper-site-account/${id}`, data);
    }
}
