import { Component, OnInit } from '@angular/core';
import {UsersService} from '../../../../services/users/users.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SwalService} from '../../../../services/swal.service';
import * as moment from 'moment';

@Component({
  selector: 'app-user-dates',
  templateUrl: './user-dates.component.html',
  styleUrls: ['./user-dates.component.scss']
})
export class UserDatesComponent implements OnInit {
    userId = 0;
    frmUserDatesData: any = {};
    frmUserData: any = null;
    loadingVisible = false;
  constructor(public usersService: UsersService,
              private router: Router,
              private route: ActivatedRoute,
              private swalService: SwalService) {
      this.route.params.subscribe(params => this.userId = params.id);

      this.usersService.listUserObject.subscribe((result) => {
          if (result) {
              this.frmUserData = result;
              this.mapUserData(result);
          } else {
              this.usersService.getUserByID(this.userId)
                  .subscribe(
                      (resultUser: any) => {
                          this.frmUserData.frmQbUserId = resultUser.qb_user_reff_id;
                      },
                      (error) => {
                          this.loadingVisible = false;
                          console.log('getUserByIdERROR', error);
                      }, () => {
                          this.loadingVisible = false;
                      }
                  );
          }
      });
  }

  ngOnInit() {
  }

    mapUserData(result) {
        this.frmUserData.frmQbUserId = result.qb_user_reff_id;
        this.frmUserDatesData.frmStartDate = result.user_start_date;
        this.frmUserDatesData.frmEndDate = result.user_end_date;
    }

    onFormSubmit(e) {
        const finalData = {
            user_id: this.userId,
            qb_user_reff_id: this.frmUserData.qb_user_reff_id,
            user_start_date: (this.frmUserDatesData.frmStartDate) ?
                moment(new Date(this.frmUserDatesData.frmStartDate)).format('YYYY-MM-DD') : null,
            user_end_date: (this.frmUserDatesData.frmEndDate) ?
                moment(new Date(this.frmUserDatesData.frmEndDate)).format('YYYY-MM-DD') : null,
        };

        this.loadingVisible = true;
        this.usersService.editUser(this.userId, finalData)
            .subscribe(
                (result: any) => {
                    if (result.code === 200) {
                        const swalData = {
                            title: 'Success',
                            message: 'User updated.',
                            info: ''
                        };
                        // /* this.swalService.success(swalData); */
                        this.router.navigate(['user/list']);
                    } else {
                        console.log(result);
                        const swalData = {
                            title: 'Error',
                            message: 'Error code ' + result.code || 'Something went wrong',
                            info: 'Something went wrong'
                        };
                        this.swalService.error(swalData);
                    }
                },
                (error) => {
                    this.loadingVisible = false;
                    console.log('editUserERROR', error);
                    const swalData = {
                        title: 'Error',
                        message: error.statusText || 'Something went wrong',
                        info: error.error.messages || 'Something went wrong'
                    };
                    this.swalService.error(swalData);
                    // this.swalService.error(error.error.error.message || 'Something went wrong');
                },
                () => {
                    this.loadingVisible = false;
                }
            );
    }

    cancel() {
        this.router.navigate(['user/list']);
    }
}
