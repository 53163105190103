import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import {PulseSharedModule} from '@pulse/shared.module';
import {HomePageComponent} from './homepage.component';
import {LoginService} from '../../services/login.service';

const routes: any = [{
    path: 'home',
    component: HomePageComponent,
    resolve: [LoginService]
}];

@NgModule({
    declarations: [
        HomePageComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,

        PulseSharedModule
    ]
})
export class HomePageModule {
}
