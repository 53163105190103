<div class="page-layout simple fullwidth" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4" fxLayout="column">

            <div id="chat" class="page-layout carded fullwidth inner-scroll">

                <!-- TOP BACKGROUND -->
                <div class="top-bg accent"></div>
                <!-- / TOP BACKGROUND -->

                <!-- CENTER -->
                <div class="center p-0">

                    <!-- CONTENT CARD -->
                    <div class="content-card">

                        <mat-sidenav-container>

                            <!-- LEFT SIDENAV -->
                            <mat-sidenav class="sidenav" position="start" opened="true" mode="side"
                                         pulseMatSidenavHelper="chat-left-sidenav"
                                         matIsLockedOpen="gt-md">
                                <chat-left-sidenav></chat-left-sidenav>
                            </mat-sidenav>
                            <!-- / LEFT SIDENAV -->

                            <!-- CONTENT -->
                            <chat-start *ngIf="!selectedChat"></chat-start>

                            <chat-view *ngIf="selectedChat"></chat-view>
                            <!-- / CONTENT -->

                            <!-- RIGHT SIDENAV -->
                            <mat-sidenav class="sidenav" position="end" opened="false" mode="over"
                                         pulseMatSidenavHelper="chat-right-sidenav">
                                <chat-right-sidenav></chat-right-sidenav>
                            </mat-sidenav>
                            <!-- / RIGHT SIDENAV -->

                        </mat-sidenav-container>

                    </div>
                    <!-- / CONTENT CARD -->

                </div>
                <!-- / CENTER -->

            </div>
        </div>
    </div>
</div>
