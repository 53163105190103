import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {
    NavigationEnd,
    NavigationStart,
    ActivatedRoute,
    Router,
    RoutesRecognized
} from '@angular/router';
import {DxSelectBoxComponent} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {UsersService} from 'app/services/users/users.service';
import {LoginService} from 'app/services/login.service';
import {CommonService} from 'app/services/common/common.service';
import {ConsultantService} from '../../services/consultant/consultant.service';
import {NotificationService} from '@pulse/services/notification.service';
import {PusherService} from '../../services/pusher/pusher.service';
import {siteConfig} from '../../siteSettings';
import notify from "devextreme/ui/notify";
import {Location} from "@angular/common";

@Component({
    selector: 'pulse-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [ConsultantService]
})
export class PulseHeaderComponent implements OnInit, OnDestroy {

    @ViewChild(DxSelectBoxComponent) dxSelectBox: DxSelectBoxComponent;
    isLoggedIn = false;
    showLoadingBar = false;
    currentUser: any = {};
    leftNavigation: any = [];
    rightNavigation: any = [];
    datasource: any = [];
    searchBoxVisible: false;
    defaultAvatarUrl: string = '/assets/images/avatars/profile.jpg';
    selectBoxDataSource: any = {};
    searchedConsultantId: number = null;
    consultantSearched: boolean = false;
    nextPath: any = '';
    isAdmin = false;
    newMessageReceived = false;
    notifications: any[] = [];
    isLoginButtonVisible: boolean = true;
    showNotifications: boolean = false;
    siteConfig: any = siteConfig;
    showHeader: boolean = true;
    href = '';
    constructor(public router: Router,
                private location: Location,
                private commonService: CommonService,
                private usersService: UsersService,
                private consultantService: ConsultantService,
                private loginService: LoginService,
                private notificationService: NotificationService,
                private pusherService: PusherService
    ) {
        this.href = window.location.href;
        // window.addEventListener("online", () => {debugger});
        window.addEventListener("offline", () => {
            notify("Connection problem", "error", 3000);
        });
        // debugger;
        this.showHeader = !(this.location.path().indexOf('/display/') > -1);

        this.loginService.isLoggedInObj.subscribe(value => {
            if (value) {
                this.currentUser = JSON.parse(localStorage.getItem('userData'));
                this.pusherService.setLoggedInUser()
                this.currentUser.fullName = this.currentUser.first_name;
                this.currentUser.fullName += this.currentUser.last_name ? ` ${this.currentUser.last_name}` : '';
                this.isLoggedIn = true;
                if (this.currentUser.roleNames) {
                    this.currentUser.displayRoleName = this.currentUser.roleNames.split(',')[0];
                }
                this.commonService.getAllMasterData();
                this.commonService.getAllBusinessEntities();
                // this.usersService.getUsersPermissions().subscribe((result: any) => {
                //     this.usersService.userPermissionsRetrieved$.next(result.data);
                // });
                this.getNavigation();

                if (this.currentUser.wc_user_id) {
                    this.pusherService.loadChat(this.currentUser.wc_user_id)
                }

            } else {
                this.isLoggedIn = false;
                // this.router.navigate(['login']);
            }

        });

        this.usersService.onUserUpdated.subscribe(result => {
            if (!result) {
                return;
            }
            this.currentUser = result;
            this.currentUser.fullName = this.currentUser.first_name;
            this.currentUser.fullName += this.currentUser.last_name ? ` ${this.currentUser.last_name}` : '';
            if (this.currentUser.roleNames) {
                this.currentUser.displayRoleName = this.currentUser.roleNames.split(',')[0];
            }

            if (this.currentUser && this.currentUser.roleNames && this.currentUser.roleNames.indexOf('Admin') > -1) {
                this.isAdmin = true;
            }

        });
    }

    ngOnDestroy(): void {
        this.pusherService.unSubscribeFromPusher()
    }

    ngOnInit() {
        if (this.isLoggedIn) {
            // this.commonService.getAllMasterData();
            // this.commonService.getAllBusinessEntities();
            this.selectBoxDataSource = new DataSource({
                store: new CustomStore({
                    key: 'ID',
                    load: (loadOptions) => {
                        const finalData = [];
                        if (!loadOptions.searchValue) {
                            return finalData;
                        }
                        return this.consultantService.searchConsultant(loadOptions.searchValue)
                            .toPromise()
                            .then(response => {
                                // Here, you can perform operations unsupported by the server
                                const result = response['data'];

                                for (let i = 0; i < result.length; i++) {
                                    const tmpArray = {
                                        ConsultantID: result[i]['consultant_id'],
                                        ConsultantFullName: result[i]['ConsultantFullName'],
                                        email: result[i]['email'],
                                        NamewithEmail: result[i]['ConsultantFullName'] + ' (' + result[i]['email'] + ')'
                                    };
                                    if (result[i]['saveStatus']) {
                                        tmpArray.NamewithEmail = result[i]['saveStatus'] + ' - ' + tmpArray.NamewithEmail;
                                    }
                                    finalData.push(tmpArray);
                                }
                                // this.datasource = finalData;
                                return finalData;
                            });
                    }
                })

            });
        }

        this.router.events
            .subscribe((e) => {
                if (e instanceof RoutesRecognized) {
                    if (e && e.url.indexOf("meetup") > -1) {
                        this.isLoginButtonVisible = false;
                    } else {
                        this.isLoginButtonVisible = true;
                    }
                    this.nextPath = e.url;
                }
            });
        this.commonService._listners$.subscribe(event => {
            if (!(this.nextPath.indexOf('consultant/edit') > -1 && this.router.url.indexOf('consultant/edit') > -1)
                && event === 'clearSearch') {
                this.consultantSearched = false;
                this.dxSelectBox.instance.reset();
            }
        });



    }

    getNavigation() {

        this.usersService.getNavigationByUser(this.currentUser.id).subscribe(response => {
            if (response['code'] === 200) {
                this.leftNavigation = response['data'].leftNav || [];
                this.searchBoxVisible = response['data'].hasSearchPermission || false;
                this.rightNavigation = response['data'].rightNav || [];
            }
            if (this.rightNavigation.length > 0) {
                this.rightNavigation = this.rightNavigation.filter(navigation => navigation.id != "Notifications" && navigation.id != "recruiter Dashboard");
            }
        });
    }

    searchConsultant() {
        if (this.searchedConsultantId) {
            this.consultantSearched = true;
            this.router.navigate(['/consultant/edit/' + this.searchedConsultantId]);
            window.localStorage.setItem('selectedIndex', '8');

        }
    }


    logout() {
        this.loginService.logout();
        this.router.navigate(['login']);
        window.location.reload();
    }

    notificationClick() {
        this.newMessageReceived = false;
        this.showNotifications = !this.showNotifications;
    }

    removeClickedNotification(notify) {
        this.showNotifications = false;
        let index = this.notifications.findIndex(item => item.contactId === notify.contactId);
        this.notifications.splice(index, 1);
    }
}

export class HeaderComponent {
}
