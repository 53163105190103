<div class="page-layout blank" pulsePerfectScrollbar fxLayout="column">
    <div class="eeo-toolbar">
        <img src="assets/images/ASP-Logo.svg" alt="ApTask - Pulse">
    </div>
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-24 p-30 pt-0" fxLayout="column" *ngIf="!formSigned">
            <div class="content-wrapper">
                <h1 class="text-center text-bold">Equipment Release Letter</h1>
                <p>
                    I, <strong>{{consultantName}}</strong> have received the property listed below from Fintech Consulting LLC DBA ApTask
                </p>

                <div id="equipment-list" class="pt-15">
                    <h3><strong>Assign product details</strong></h3>
                    <dx-data-grid [dataSource]="equipmentList"
                                  [rowAlternationEnabled]="true"
                                  [showBorders]="true">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager
                            [showPageSizeSelector]="true"
                            [allowedPageSizes]="[10, 20, 50, 100, 500]"
                            [showInfo]="true">
                        </dxo-pager>

                        <dxi-column dataField="item_name" caption="Item"></dxi-column>
                        <dxi-column dataField="serial_no" caption="Serial #"></dxi-column>
                        <dxi-column dataField="model_no" caption="Model"></dxi-column>
                        <dxi-column dataField="release_date" dataType="date" format="MM/dd/yyyy" caption="Release Date"></dxi-column>
                    </dx-data-grid>
                </div>

                <p><small>*This can include but is not limited to: Computers, printers, monitors, phones, pagers, keys, client tokens, badges etc.</small></p>
                <p>
                    The usage period for my Equipment will start on the date that the equipment is received and will continue till the term of the employment, unless the equipment is returned earlier. You agree to use the equipment solely for work performed for ApTask, ApTask will have the right to inspect such work in the form of data usage reports, content monitoring and other methods / reports. I accept that ApTask is the sole owner of the equipment. You will not transfer, sell or lease the equipment without prior written consent from ApTask.
                </p>
                <p>
                    You agree to return the Equipment to ApTask within 10 days of termination of employment (Voluntary or involuntary) in the same condition that it was on the day you received it, normal wear and tear excepted. In the event that the Equipment is damaged, lost, stolen or destroyed during the usage period, you agree to fix or replace the Equipment at you own cost. If you do not repair or replace the Equipment within 10 days after the usage period ends, you authorize ApTask to deduct the appropriate charges from your final pay, any balance that was not recovered from the final pay will be paid directly by you to ApTask immediately upon request
                </p>
                <p>
                    I HEREBY RELEASE FROM LIABILITY AND AGREE TO INDEMNIFY AND HOLD APTASK HARMLESS, FOR ANY LIABILITY IN CONNECTION WITH THE USE OR POSSESSION OF THE EQUIPMENT.
                </p>
                <!--<p>Employee / Contractor :</p>-->
                <p>Date : {{currDate}}</p>
                <br/>
                <p>The above items were <b>returned and received</b> by:</p>
            </div>

            <div class="content-wrapper">
                <h1 class="text-bold mb-0">Invitation to self-identify</h1>
                <div class="question-wrapper">
                    <!--<form (submit)="submitEquipmentSign($event)" [formGroup]="form">
                        <input type="hidden" formControlName="firstName">

                    </form>-->

                    <div class="sig-container mb-12" fxLayout="row" fxLayoutAlign="start start">
                        <signature-pad class="mat-elevation-z2 mr-12" [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad>
                        <button type="button" class="pulse-btn pulse-btn-primary" (click)="signaturePad.clear()" >Clear Signature</button>
                    </div>
                    <div>
                        <re-captcha (resolved)="resolved($event)" siteKey={{captcha}}></re-captcha>
                    </div>
                    <div class="text-center" fxLayout="row" fxLayoutAlign="start start">
                        <dx-button text="Save" type="success" validationGroup="customerData" (click)="submitEquipmentSign($event)" class="pulse-btn pulse-btn-primary">
                        </dx-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrapper" *ngIf="formSigned">
            <div class="message-box success">
                No signatures remaining.
            </div>
        </div>
    </div>
</div>
