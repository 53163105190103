import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {siteConfig} from 'app/siteSettings';
import {CommonService} from '../common/common.service';
import {RolesService} from '../roles/role.service';

export class UsersList {
    constructor(public PrimaryRole: string,
                public reports_to: any,
                public user_status: any,
                public email: string,
                public firstname: string,
                public lastname: string,
                public role_id: number,
                public user_id: number) {
    }
}


@Injectable()
export class UsersService {
    userUpdatedSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('userData')));
    listUserObject = new BehaviorSubject(null);
    onUserUpdated: Observable<any>;
    reportingManagers: any;
    countries: any;
    divisions: any;
    roles: any;
    countryCodes: any;
    userPermissionsRetrieved$: BehaviorSubject<any>;
    constructor(private _http: HttpClient, private commonService: CommonService, private roleService: RolesService) {
        this.userPermissionsRetrieved$ = new BehaviorSubject<any>([]);
        this.onUserUpdated = this.userUpdatedSubject.asObservable();
    }

    resetPassword(body, token) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', `JWT ${token}`);
        const url = `${siteConfig.nodeAPIBaseUrl}users/updatePassword`;
        return this._http.post<any>(url, body, {headers});
    }

    forgotPassword(body) {
        const url = `${siteConfig.nodeAPIBaseUrl}forgotPassword`;
        return this._http.post<any>(url, body);
    }

    isEmailExist(email) {
        const url = `${siteConfig.nodeAPIBaseUrl}users/email_exists?input=${email}`;
        return this._http.get<any>(url);
    }

    isUserEmailExist(email) {
        const url = `${siteConfig.nodeAPIBaseUrl}users/user_email_exists?input=${email}`;
        return this._http.get<any>(url);
    }

    getNavigationByUser(userID) {
        const url = `${siteConfig.nodeAPIBaseUrl}rback/getNavigationByUser?userID=${userID}`;
        return this._http.get(url);
    }

    getRoleList() {
        const url = `${siteConfig.nodeAPIBaseUrl}role/list`;
        return this._http.get(url);
    }

    updateUserProfile(postData) {
        return this._http.post(`${siteConfig.nodeAPIBaseUrl}users/update-profile`, postData);
    }

    uploadUserProfilePicture(postData) {
        const url = `${siteConfig.nodeAPIBaseUrl}uploads/userProfilePictureUpload`;
        return this._http.post(url, postData);
    }

    /*=== service for update password using edit profile ===*/
    updatePasswordFromCurrent(postData) {
        return this._http.post(`${siteConfig.nodeAPIBaseUrl}users/updatePasswordFromCurrentPassword`, postData);
    }

    getUserDetail() {
        return this._http.get(`${siteConfig.nodeAPIBaseUrl}users/getUserDetail`);
    }

    getUserList(httpParams = {}) {
        let params = new HttpParams();
        params = params.set('removecandid', '1');
        if (Object.keys(httpParams).length > 0) {
            Object.keys(httpParams).map(key => {
                params = params.set(key, httpParams[key]);
            });
        }

        return this._http.get(`${siteConfig.nodeAPIBaseUrl}users/getInternalUsersList`, {params});
    }

    postUserList(loadOptions = {}) {
        loadOptions['removecandid'] = 1;
        return this._http.post(`${siteConfig.nodeAPIBaseUrl}users/dashboardList`, loadOptions);
    }

    getReportingUsers(httpParams = {}) {
        let params = new HttpParams();
        if (Object.keys(httpParams).length > 0) {
            Object.keys(httpParams).map(key => {
                params = params.set(key, httpParams[key]);
            });
        }

        return this._http.get(`${siteConfig.nodeAPIBaseUrl}users/get-reporting-users`, {params});
    }

    getUserByID(id) {
        return this._http.get(`${siteConfig.nodeAPIBaseUrl}users/getuserbyid?userid=` + id);
    }

    getUserLoginHistory() {
        return this._http.get(`${siteConfig.nodeAPIBaseUrl}users/get-login-history`);
    }

    editUser(userId, postData) {
        return this._http.put(`${siteConfig.nodeAPIBaseUrl}users/updateUser/` + userId, postData);
    }

    addUser(userData) {
        return this._http.put(`${siteConfig.nodeAPIBaseUrl}users/create-user/`, userData);
    }

    deleteUser(userId) {
        const url = `${siteConfig.nodeAPIBaseUrl}users/deleteUser/${userId}`;
        return this._http.delete<any>(url);
    }

    getUsersByRole(roleId) {
        const url = `${siteConfig.nodeAPIBaseUrl}users/getUserByRole?roleid=${roleId}`;
        return this._http.get<any>(url);
    }

    getUsersPermissions() {
        const url = `${siteConfig.nodeAPIBaseUrl}rback/permissions`;
        return this._http.get<any>(url);
    }

    getTeamList() {
        return this._http.get(`${siteConfig.nodeAPIBaseUrl}users/teams`);
    }

    addTeam(body) {
        return this._http.post(`${siteConfig.nodeAPIBaseUrl}users/add-team`, body);
    }

    editTeam(id, body) {
        return this._http.put(`${siteConfig.nodeAPIBaseUrl}users/edit-team/${id}`, body);
    }

    deleteTeam(teamId) {
        const url = `${siteConfig.nodeAPIBaseUrl}users/delete-team/${teamId}`;
        return this._http.delete<any>(url);
    }

    getUsersByTeam(httpParams = {}) { // period, start_date, end_date, team_id
        let params = new HttpParams();
        if (Object.keys(httpParams).length > 0) {
            Object.keys(httpParams).map(key => {
                params = params.set(key, httpParams[key]);
            });
        }
        return this._http.get(`${siteConfig.nodeAPIBaseUrl}users/users-by-team`, {params});
    }

    getUsersActivityLog(httpParams = {}) { // period, start_date, end_date, team_id
        let params = new HttpParams();
        if (Object.keys(httpParams).length > 0) {
            Object.keys(httpParams).map(key => {
                params = params.set(key, httpParams[key]);
            });
        }
        return this._http.get(`${siteConfig.nodeAPIBaseUrl}users/users-activity-log-report`, {params});
    }

    getUsersNoActivityLog(httpParams = {}) { // period, start_date, end_date, team_id
        let params = new HttpParams();
        if (Object.keys(httpParams).length > 0) {
            Object.keys(httpParams).map(key => {
                params = params.set(key, httpParams[key]);
            });
        }
        return this._http.get(`${siteConfig.nodeAPIBaseUrl}users/users-no-activity-log-report`, {params});
    }
}
