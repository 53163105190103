import {Component, Inject, OnInit,AfterViewInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CalendarEvent} from 'angular-calendar';
import * as moment from 'moment';
import {CalendarEventModel} from '../event.model';
import {CommonService} from 'app/services/common/common.service';
import {SwalService} from '../../../../../services/swal.service';
import {CalendarService} from '../../../../../services/calendar/calendar.service';
import {isBefore} from 'date-fns';

@Component({
    selector: 'calendar-event-form-dialog',
    templateUrl: './event-form.component.html',
    styleUrls: ['./event-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CalendarEventFormDialogComponent implements OnInit, AfterViewInit {
    action: string;
    event: CalendarEvent;
    eventForm: FormGroup;
    dialogTitle: string;
    isAllDay = false;
    eventTypes = [];
    users = [];
    companies = [];
    // presetColors = MatColors.presets;
    contacts: any = [];
    hideFields = false;
    eventFormErrors: any = {};
    localTimeZoneOffset: any = null;
    showAddCompanyForm = false;
    showAddContactForm = false;
    companyFormData: any = {};
    contactFormData: any = {};
    oldSelectedContact: any;
    oldSelectedCompany: any;
    userPermissions: any[] = [];
    /**
     * Constructor
     *
     * @param {MatDialogRef<CalendarEventFormDialogComponent>} matDialogRef
     * @param commonService
     * @param _calendarService
     * @param swalService
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<CalendarEventFormDialogComponent>,
        private commonService: CommonService,
        private _calendarService: CalendarService,
        private swalService: SwalService,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    ) {
        this.eventTypes = _data.eventTypes;
        this.users = _data.users;
        if(this.users.length > 0 && this.users[0].id == 'all') {
            this.users.shift(); // Remove all from array
        }

        this.action = _data.action;
        this.event = _data.event;
        this.companies = _data.companies;
        this.userPermissions = _data.userPermissions;
        this.eventFormErrors = {
            eventType: {},
            company: {},
            contact: {},
            start: {},
            end: {},
        };
        this.localTimeZoneOffset = this.getLocalTimeZoneOffset();
        console.log(this.event);
        // this.getLocalTimeZoneOffset = this.getTimeZoneOffset.bind(this);
        //    this.getEventTypes();
        if (this.action === 'edit' || this.action === 'info') {
            this.dialogTitle = this.event.title;

            const company = this.companies.filter(res => {
                return res.id === this.event.meta.company;
            });

            this.contacts = company.length > 0 && company[0].contacts ? JSON.parse(company[0].contacts) : [];

        } else {
            this.dialogTitle = 'New Eventsss';
            const startDate = new Date(_data.date);
            const endDate = new Date(_data.date);
            startDate.setHours(9);
            endDate.setHours(18);
            this.event = new CalendarEventModel({
                start: startDate,
                end: endDate
            });
        }

        this.eventForm = this.createEventForm();
        if (this.event.meta.eventType) {
            this.eventTypeChanged(this.event.meta.eventType);
        }
        if (this.event.meta.company) {
            this.oldSelectedContact = this.event.meta.contact;
            this.oldSelectedCompany = this.event.meta.company;
            this.onCompanyChanged({value:this.event.meta.company});
        }

        this.eventForm.valueChanges.subscribe(() => {
            this.onEventFormValuesChanged();
        });
    }

    ngOnInit() {
        this.eventForm.controls['company'].valueChanges.subscribe(value => {
            console.log(value);
        });
    }

    ngAfterViewInit() {
        if( this.action === 'info') {
            this.eventForm.disable();
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the event form
     *
     * @returns {FormGroup}
     */
    createEventForm(): FormGroup {
        return new FormGroup({
            title: new FormControl(this.event.title),
            selectedUser: new FormControl(this.event.meta.user),
            role: new FormControl(this.event.meta.role),
            start: new FormControl(moment(this.event.start).local().format(moment.HTML5_FMT.DATETIME_LOCAL)),
            end: new FormControl(moment(this.event.end).local().format(moment.HTML5_FMT.DATETIME_LOCAL)),
            allDay: new FormControl(this.event.allDay),
            color: new FormControl(this.event.color),
            eventType: new FormControl(this.event.meta.eventType, Validators.required),
            company: new FormControl(this.event.meta.company),
            contact: new FormControl(this.event.meta.contact),
            comments: new FormControl(this.event.meta.comments),
            miles: new FormControl(this.event.meta.miles),
            location: new FormControl(this.event.meta.location),
            mealCost: new FormControl(this.event.meta.mealCost)
        });
    }

    onEventFormValuesChanged() {
        for (const field in this.eventFormErrors) {
            if (!this.eventFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.eventFormErrors[field] = {};

            // Get the control
            const control = this.eventForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.eventFormErrors[field] = control.errors;
            }
        }
    }

    changeAllDay(e) {
        this.isAllDay = e.checked;
    }

    eventTypeChanged(id) {
        //  const id = e.id;
        const eventAr = [340001, 340003, 340004, 340005, 340006, 340007];
        this.hideFields = !(eventAr.indexOf(Number(id)) > -1);

        if (this.hideFields) {
            this.eventForm.get('company').setValidators([Validators.required]);
            this.eventForm.get('contact').setValidators([Validators.required]);
        } else {
            this.eventForm.get('company').clearValidators();
            this.eventForm.get('company').updateValueAndValidity();
            this.eventForm.get('contact').clearValidators();
            this.eventForm.get('contact').updateValueAndValidity();
        }
    }

    onCompanyChanged(e) {
        if (!e.value) { return; }
        let contacts = this.companies.find(x => x.id === e.value).contacts;
        contacts = contacts.replace(/\\/g, '');
        this.contacts = JSON.parse(contacts);
        if(this.oldSelectedCompany === e.value) {
            this.eventForm.get('contact').setValue(this.oldSelectedContact);
        } else {
            this.eventForm.get('contact').setValue(null);
        }
    }

    getLocalTimeZoneOffset() {
        const jsOffset = new Date().getTimezoneOffset();
        let timeZoneOffset = jsOffset < 0 ? 'GMT+' : 'GMT-';
        timeZoneOffset += Math.floor(Math.abs(jsOffset) / 60);
        timeZoneOffset += ':' + Math.abs(jsOffset) % 60;
        return timeZoneOffset;
    }

    addCompany(form) {
        this._calendarService.createCompany(form.value).subscribe((response: any) => {

            if (response.code == 200) {
                response.data.contacts = JSON.stringify([]);
                this.companies.push(response.data);
                this.showAddCompanyForm = false;
                this.eventForm.controls['company'].setValue(response.data.id);
                const swalData = {
                    title: 'Company Created',
                    message: `Company is successfully created!`,
                    info: 'Company list is updated in form!'
                };
                this.swalService.success(swalData);
                form.resetForm();
            }

        }, (err: any) => {
            const swalData = {
                title: 'ERROR',
                message: (err && (err.error || err.message)) || `Error while creating company.`,
                info: 'Please try again.'
            };
            this.swalService.error(swalData);
        });

    }

    addContact(form) {
        form.value.companyId = this.eventForm.get('company').value;

        this._calendarService.createContact(form.value).subscribe((response: any) => {

            if (response.code == 200) {
               const selectedCompany = this.companies.find(x => x.id === form.value.companyId);
                const contacts = selectedCompany.contacts ? JSON.parse(selectedCompany.contacts) : [];
                contacts.push(response.data);
                this.companies.find(x => x.id === form.value.companyId).contacts = JSON.stringify(contacts);
                this.contacts.push(response.data);
                this.showAddContactForm = false;
                this.eventForm.controls['contact'].setValue(response.data.id);
                const swalData = {
                    title: 'Contact Created',
                    message: `Contact is successfully created!`,
                    info: 'Contact list is updated in form!'
                };
                this.swalService.success(swalData);

                 form.resetForm();
            }

        }, (err: any) => {
            const swalData = {
                title: 'ERROR',
                message: (err && (err.error || err.message)) || `Error while creating contact.`,
                info: 'Please try again.'
            };
            this.swalService.error(swalData);
        });
    }

    onStartDateChanged(e) {
        const startDate = new Date(this.eventForm.get('start').value);
        const endDate = new Date(this.eventForm.get('end').value);
        const result = isBefore(startDate, endDate );

        if(!result) {
            endDate.setDate(startDate.getDate());
            this.eventForm.get('end').setValue(moment(endDate).local().format(moment.HTML5_FMT.DATETIME_LOCAL));
        }
    }
}
