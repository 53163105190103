import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {SwalService} from '../../../../services/swal.service';
import {UsersService} from '../../../../services/users/users.service';
import {DxFormComponent} from 'devextreme-angular';
import {RolesService} from '../../../../services/roles/role.service';
import {CommonService} from '../../../../services/common/common.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export class DropDownType {
    id: number;
    name: string;
}

// tslint:disable-next-line:class-name
export class frmUserDataType {
    frmFirstName: string;
    frmLastName: string;
    frmEmail: string;
    frmMobile: string;
    frmPersonalMobile: string;
    frmPersonalEmail: string;
    frmCountryCode: string;
    frmPersonalCountryCode: string;
    frmReportingTo: number;
    frmPrimaryRole: any;
    frmSubLocation: number;
    frmLocation: number;
    frmDivision: number;
    frmStartDate: string;
    frmUserTarget: number;
    frmWidgetChatId: string;
    frmWidgetChatIdCreated: boolean;
    frmQbUserId: number;
    frmSignature: string;
    frmUserStatus: number;
    frmRemarks: string;
    frmTwilioSmsNumber: string;
    frmDocusignUser: string;
    frmDocusignPass: string;
    frmTermDate: string;
    frmTermReason: string;
    frmTermComment: string;
    frmSMSNumber: string;
    frmJobDivaId: any;
    frmVOIPNumber: any;
    frmExtNumber: any;

    frmCreateQbUser: boolean;
    frmIsActive = false;

    comm_user_type: string;
    comm_role: number;
    comm_default_role: number;
    comm_sales_plan: number;
    comm_recr_plan: number;
    create_qb_user = false;
    qb_user_reff_id: number;

    botId: string;
    frmTeam: string;
    callProvider: string;
    frmClickSendToken: string;

    frmCRP0: number;
    frmCRP1: number;
    frmCRP2: number;
    frmCRP3: number;
    frmCRP4: number;
    frmCRP5: number;
    frmCRP6: number;
    frmCRP7: number;
    frmCRP8: number;
    frmCRP9: number;

    frmCustomUserId:any;
    comm_plans_json:string;
}

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss'],
    providers: [UsersService],
    encapsulation: ViewEncapsulation.None
})
export class UserEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(DxFormComponent) dxUserForm: DxFormComponent;
    public frmUserData: frmUserDataType = new frmUserDataType;
    loadingVisible = false;
    phonePattern: any = /^\(\s*[02-9]\d{2}\)\s*\d{3}\s*-\s*\d{4}$/;
    // tslint:disable-next-line:max-line-length
    emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    phoneNumberOptions: any = {
        mask: '(X00) 000-0000',
        maskRules: {
            X: /[0-9]/
        },
        useMaskedValue: false,
        valueChangeEvent: 'blur',
        maskInvalidMessage: 'Please enter a correct US Mobile number'
    };
    terminationReasons = [];
    divisions: DropDownType[] = [];
    countryList: any = [];
    locationList: any = [];
    primaryRole: any = [];
    reportingTo: any = [];
    countryCodes: any = [];
    statusList: any = [];
    editMode = false;
    terminationDetails = false;
    emailInUse = false;
    emailInUseText = '';
    formActionValue = '';
    userId = 0;
    isFormSubmitted = false;
    isFormDataChanged = false;
    isApproved = false;
    currentUser: any;
    pageTitle = 'Add User';
    updatedUserData: any = null;
    qbUsers: any = [];
    teamList = [];
    callProviders: any[] = [
        {
            value: '3cx',
            displayExpr: '3CX'
        },
        {
            value: 'zoom',
            displayExpr: 'Zoom'
        },
    ];
    isEmailEdit = false;
    currentEmail: any;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private router: Router,
                private route: ActivatedRoute,
                private cdr: ChangeDetectorRef,
                private usersService: UsersService,
                private swalService: SwalService,
                private roleService: RolesService,
                private commonService: CommonService) {

        this.currentUser = JSON.parse(localStorage.getItem('userData'));
        const roles = this.currentUser.roles;
        const roleArr = roles.split(',');

        if (roleArr.indexOf('1') < 0 && roleArr.indexOf('2') < 0) {
            this.router.navigate(['/dashboard']);
        }

        this.route.params.subscribe(params => this.userId = params.id);
        this.onMobileNoChanged = this.onMobileNoChanged.bind(this);
        this.changeSelectBox = this.changeSelectBox.bind(this);
        this.isEmailExistCheck = this.isEmailExistCheck.bind(this);
        this.getTeamList();
        this.setDefaults();
        this.getReportingUsers();
        // this.getAllCountries();
        // this.getAllStatus();
        // this.getAllDivision();
        this.getAllRoles();
        this.getAllQbUsers();
        this.getCountryCodes();

        if (this.userId && this.userId > 0) {
            this.editMode = true;
            this.pageTitle = 'Edit User';

            this.loadingVisible = true;
            this.usersService.listUserObject.subscribe((result) => {
                if (result) {
                    this.frmUserData = result;
                    if (result.is_active === 0) {
                        this.terminationDetails = true;
                    }
                } else {
                    this.getUser(this.userId);
                }
            });
        }

    }

    canDeactivate(): boolean {
        return this.isFormSubmitted || !this.isFormDataChanged;
    }

    ngOnInit() {
        this.commonService.masterData
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {

                if (data.countries) {

                    data.countries.forEach((item) => {
                        this.countryList.push({
                            id: Number(item.value),
                            name: item.master_lookup_name
                        });
                    });

                    if (this.updatedUserData) {
                        this.frmUserData.frmLocation = (this.updatedUserData.location) ? Number(this.updatedUserData.location) : null;
                    }
                }

                if (data.locations) {
                    data.locations.forEach((item) => {
                        this.locationList.push({
                            id: Number(item.value),
                            name: item.master_lookup_name
                        });
                    });

                    if (this.updatedUserData) {
                        this.frmUserData.frmSubLocation = (this.updatedUserData.sub_location) ? Number(this.updatedUserData.sub_location) : null;
                    }
                }

                if (data.userStatus) {
                    data.userStatus.forEach((item) => {
                        this.statusList.push({
                            id: Number(item.value),
                            name: item.master_lookup_name
                        });
                    });

                    if (this.updatedUserData) {
                        this.frmUserData.frmUserStatus = (this.updatedUserData.user_status) ? Number(this.updatedUserData.user_status) : null;
                    }
                }

                if (data.division) {
                    data.division.forEach((item) => {
                        this.divisions.push({
                            id: Number(item.value),
                            name: item.master_lookup_name
                        });
                    });

                    if (this.updatedUserData) {
                        this.frmUserData.frmDivision = (this.updatedUserData.division) ? Number(this.updatedUserData.division) : null;
                    }
                }

            });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.isFormDataChanged = false;
        }, 500);
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    setDefaults() {
        this.frmUserData.frmFirstName = null;
        this.frmUserData.frmLastName = null;
        this.frmUserData.frmEmail = null;
        this.frmUserData.frmMobile = null;
        this.frmUserData.frmPersonalMobile = null;
        this.frmUserData.frmPersonalEmail = null;
        this.frmUserData.frmCountryCode = '+1';
        this.frmUserData.frmPersonalCountryCode = '+91';
        this.frmUserData.frmReportingTo = null;
        this.frmUserData.frmPrimaryRole = null;
        this.frmUserData.frmLocation = null;
        this.frmUserData.frmDivision = null;
        this.frmUserData.frmStartDate = null;
        this.frmUserData.frmUserTarget = 0;
        this.frmUserData.frmWidgetChatId = null;
        this.frmUserData.frmWidgetChatIdCreated = false;
        this.frmUserData.frmQbUserId = null;
        this.frmUserData.frmSignature = null;
        this.frmUserData.frmRemarks = null;
        this.frmUserData.frmDocusignUser = null;
        this.frmUserData.frmDocusignPass = null;
        this.frmUserData.frmTermDate = null;
        this.frmUserData.frmTermReason = null;
        this.frmUserData.frmTermComment = null;
        this.frmUserData.frmSMSNumber = null;
        this.frmUserData.frmJobDivaId = null;
        this.frmUserData.frmVOIPNumber = null;
        this.frmUserData.frmExtNumber = null;
        this.frmUserData.frmUserStatus = 1900002;
        this.frmUserData.frmIsActive = false;
        this.frmUserData.frmCreateQbUser = false;
        this.frmUserData.frmTeam = null;
        this.frmUserData.callProvider = 'zoom';
        this.frmUserData.frmClickSendToken = '';
        this.currentEmail = null;
    }

    onMobileNoChanged(e) {
        console.log(e);
    }

    formAction(event: string) {
        this.formActionValue = event;
    }

    getAllRoles() {
        this.roleService.getRoles()
            .subscribe(
                (result: any) => {
                    result.data.forEach((item) => {
                        if (item.is_internal == 1) {
                            this.primaryRole.push({
                                id: item.role_id,
                                name: item.name
                            });
                        }
                    });
                    if (this.updatedUserData) {
                        this.frmUserData.frmPrimaryRole = (this.updatedUserData.UserRoles) ?
                            this.updatedUserData.UserRoles.split(',').map(Number) : '';
                    }
                    // this.getTerminationReasons();
                },
                (error) => {
                    console.log('getAllUsersERROR', error);
                });
    }

    getAllStatus() {
        this.commonService.getMasterData('userStatus')
            .subscribe(
                (result: any) => {
                    result.data.forEach((item) => {
                        this.statusList.push({
                            id: Number(item.value),
                            name: item.master_lookup_name
                        });
                    });
                    if (this.updatedUserData) {
                        this.frmUserData.frmUserStatus = (this.updatedUserData.user_status) ?
                            Number(this.updatedUserData.user_status) : null;
                    }
                },
                (error) => {
                    console.log('getAllStatusERROR', error);
                });
    }

    getAllCountries() {
        /*debugger;
        this.commonService.getMasterData('countries')
            .subscribe(
                (result: any) => {
                    result.data.forEach((item) => {
                        this.locationList.push({
                            id: Number(item.value),
                            name: item.master_lookup_name
                        });
                    });

                    if(this.updatedUserData)
                        this.frmUserData.frmLocation = (this.updatedUserData.location) ? Number(this.updatedUserData.location) : null;

                    // this.getTerminationReasons();
                },
                (error) => {
                    console.log('getAllUsersERROR', error);
                });*/
    }

    getAllDivision() {
        this.commonService.getMasterData('division')
            .subscribe(
                (result: any) => {
                    result.data.forEach((item) => {
                        this.divisions.push({
                            id: Number(item.value),
                            name: item.master_lookup_name
                        });
                    });
                    if (this.updatedUserData) {
                        this.frmUserData.frmDivision = (this.updatedUserData.division) ? Number(this.updatedUserData.division) : null;
                    }

                    // this.getTerminationReasons();
                },
                (error) => {
                    console.log('getAllUsersERROR', error);
                });
    }

    getReportingUsers() {
        this.usersService.getReportingUsers()
            .subscribe(
                (result: any) => {
                    result.data.result.forEach((item) => {
                        this.reportingTo.push({
                            name: item.name,
                            id: Number(item.user_id)
                        });
                    });

                    if (this.updatedUserData) {
                        this.frmUserData.frmReportingTo = (this.updatedUserData.reports_to) ? Number(this.updatedUserData.reports_to) : null;
                    }

                    // console.log(this.reportingTo);
                    // this.getTerminationReasons();
                },
                (error) => {
                    console.log('getAllUsersERROR', error);
                });
    }

    getUser(id: any) {
        // debugger;
        this.usersService.getUserByID(id)
            .subscribe(
                (result: any) => {
                    // console.log("getUserByIdRESULT", result);
                    result = result.data;
                    if (result.is_active === 0) {
                        this.terminationDetails = true;
                    }
                    // console.log('frmUserData', this.frmUserData);
                    this.frmUserData.frmFirstName = result.first_name;
                    this.frmUserData.frmLastName = result.last_name;
                    this.frmUserData.frmMobile = result.mobile_no ? result.mobile_no.trim() : null;
                    this.frmUserData.frmPersonalMobile = result.personal_mobile_no ? result.personal_mobile_no.trim() : null;
                    this.frmUserData.frmCountryCode = result.country_code;
                    this.frmUserData.frmPersonalCountryCode = result.personal_country_code;
                    this.frmUserData.frmEmail = result.email;
                    this.frmUserData.frmPersonalEmail = result.personal_email;
                    this.frmUserData.frmReportingTo = result.reports_to;
                    // this.frmUserData.frmReportingTo = 1;
                    this.frmUserData.frmPrimaryRole = (result.UserRoles) ? result.UserRoles.split(',').map(Number) : '';
                    this.frmUserData.frmDocusignUser = result.docusign_user;
                    this.frmUserData.frmDocusignPass = result.docusign_password;
                    // this.frmUserData.frmStartDate = result.start_date;
                    // this.frmUserData.frmUserTarget = Number(result.user_joining_target);
                    // this.frmUserData.frmSignature = result.signature;
                    this.frmUserData.frmDivision = Number(result.division);
                    // this.frmUserData.frmRemarks = result.comment;
                    this.frmUserData.frmLocation = Number(result.location);
                    this.frmUserData.frmSubLocation = Number(result.sub_location);
                    // this.frmUserData.frmTermDate = result.termination_date;
                    // this.frmUserData.frmTermReason = result.termination_reason;
                    // this.frmUserData.frmTermComment = result.termination_comment;
                    this.frmUserData.frmTwilioSmsNumber = (result.sms_number_twillio ? result.sms_number_twillio.trim() : null) || undefined;
                    this.frmUserData.frmJobDivaId = result.jobdiva_id;
                    this.frmUserData.frmVOIPNumber = result.voip_number;
                    this.frmUserData.frmExtNumber = result.voip_ext;
                    this.frmUserData.frmUserTarget = result.user_joining_target;
                    this.frmUserData.frmWidgetChatId = result.wc_user_id;
                    this.frmUserData.frmWidgetChatIdCreated = result.wc_user_created || 0;
                    this.frmUserData.frmQbUserId = result.qb_user_reff_id;
                    this.frmUserData.frmSignature = result.signature;
                    this.frmUserData.frmUserStatus = result.user_status;
                    this.frmUserData.frmIsActive = result.is_active == 1;
                    this.frmUserData.frmTeam = result.team_id || null;
                    this.frmUserData.botId = result.bot_id;
                    this.frmUserData.callProvider = result.callProvider;
                    this.frmUserData.frmClickSendToken = result.clicksend_token;
                    this.pageTitle = `${result.first_name} ${result.last_name}`;
                    console.log('REPORTING TO', this.frmUserData);
                    this.updatedUserData = result;
                    this.currentEmail = this.frmUserData.frmEmail
                },
                (error) => {
                    this.loadingVisible = false;
                    console.log('getUserByIdERROR', error);
                }, () => {
                    this.loadingVisible = false;
                }
            );
    }

    enableEditing() {
        this.editMode = false;
        // this.cdr.detectChanges();
    }

    edit() {
        console.log(this.frmUserData, event);
        const editData = {
            user_id: this.userId,
            first_name: this.frmUserData.frmFirstName,
            last_name: this.frmUserData.frmLastName,
            division: this.frmUserData.frmDivision,
            email: this.frmUserData.frmEmail,
            personal_email: this.frmUserData.frmPersonalEmail,
            user_joining_target: this.frmUserData.frmUserTarget,
            signature: this.frmUserData.frmSignature,
            mobile_no: this.frmUserData.frmMobile,
            personal_mobile_no: this.frmUserData.frmPersonalMobile,
            country_code: this.frmUserData.frmCountryCode,
            personal_country_code: this.frmUserData.frmPersonalCountryCode,
            reports_to: this.frmUserData.frmReportingTo,
            docusign_user: this.frmUserData.frmDocusignUser,
            docusign_password: this.frmUserData.frmDocusignPass,
            role_id: this.frmUserData.frmPrimaryRole,
            qb_user_reff_id: this.frmUserData.frmQbUserId || 0,
            location: this.frmUserData.frmLocation,
            sub_location: this.frmUserData.frmSubLocation,
            updated_by: this.currentUser.id,
            sms_number_twillio: this.frmUserData.frmTwilioSmsNumber,
            wc_user_id: this.frmUserData.frmWidgetChatId,
            wc_user_created: this.frmUserData.frmWidgetChatIdCreated,
            jobdiva_id: this.frmUserData.frmJobDivaId,
            voip_number: this.frmUserData.frmVOIPNumber,
            voip_ext: this.frmUserData.frmExtNumber,
            user_status: this.frmUserData.frmUserStatus,
            is_active: this.frmUserData.frmIsActive,
            create_qb_user: this.frmUserData.frmCreateQbUser,
            bot_id: this.frmUserData.botId || '',
            team_id: this.frmUserData.frmTeam || '',
            callProvider: this.frmUserData.callProvider || '',
            clicksend_token: this.frmUserData.frmClickSendToken,
        };

        this.loadingVisible = true;
        this.usersService.editUser(this.userId, editData)
            .subscribe(
                (result: any) => {
                    if (result.code === 200) {
                        const swalData = {
                            title: 'Success',
                            message: 'User updated.',
                            info: ''
                        };
                        this.swalService.success(swalData);
                        this.router.navigate(['user/list']);
                    } else {
                        console.log(result);
                        const swalData = {
                            title: 'Error',
                            message: 'Error code ' + result.code || 'Something went wrong',
                            info: 'Something went wrong'
                        };
                        this.swalService.error(swalData);
                    }
                },
                (error) => {
                    this.loadingVisible = false;
                    console.log('editUserERROR', error);
                    const swalData = {
                        title: 'Error',
                        message: error.statusText || 'Something went wrong',
                        info: error.error.messages || 'Something went wrong'
                    };
                    this.swalService.error(swalData);
                    // this.swalService.error(error.error.error.message || 'Something went wrong');
                },
                () => {
                    this.loadingVisible = false;
                }
            );
    }

    checkBoxToggled(e) {
        this.frmUserData.frmIsActive = e.value;
    }

    createQbUserToggle(e) {
        this.frmUserData.frmCreateQbUser = e.value;
    }

    changeSelectBox(e) {
        const selector = e.element.dataset.name
        switch (selector) {
            case 'location' :
                this.frmUserData.frmLocation = e.selectedItem.id;
                break;
            case 'sub_location' :
                this.frmUserData.frmSubLocation = e.selectedItem.id;
                break;
            case 'reportingTo' :
                this.frmUserData.frmReportingTo = e.selectedItem.id;
                break;
            case 'division' :
                this.frmUserData.frmDivision = e.selectedItem.id;
                break;
            case 'userStatus' :
                this.frmUserData.frmUserStatus = e.selectedItem.id;
                break;
            case 'team' :
                this.frmUserData.frmTeam = e.selectedItem.id;
                break;
        }
    }

    save(event: any) {
        const userData = {
            first_name: this.frmUserData.frmFirstName,
            last_name: this.frmUserData.frmLastName,
            division: this.frmUserData.frmDivision,
            email: this.frmUserData.frmEmail,
            personal_email: this.frmUserData.frmPersonalEmail,
            user_joining_target: this.frmUserData.frmUserTarget,
            signature: this.frmUserData.frmSignature,
            mobile_no: this.frmUserData.frmMobile,
            personal_mobile_no: this.frmUserData.frmPersonalMobile,
            country_code: this.frmUserData.frmCountryCode,
            personal_country_code: this.frmUserData.frmPersonalCountryCode,
            reports_to: this.frmUserData.frmReportingTo,
            docusign_user: this.frmUserData.frmDocusignUser,
            docusign_password: this.frmUserData.frmDocusignPass,
            qb_user_reff_id: this.frmUserData.frmQbUserId || 0,
            role_id: this.frmUserData.frmPrimaryRole,
            location: this.frmUserData.frmLocation,
            sub_location: this.frmUserData.frmSubLocation,
            created_by: this.currentUser.id,
            updated_by: this.currentUser.id,
            sms_number_twillio: this.frmUserData.frmTwilioSmsNumber,
            jobdiva_id: this.frmUserData.frmJobDivaId,
            wc_user_id: this.frmUserData.frmWidgetChatId,
            voip_number: this.frmUserData.frmVOIPNumber,
            voip_ext: this.frmUserData.frmExtNumber,
            is_active: this.frmUserData.frmIsActive,
            user_status: this.frmUserData.frmUserStatus,
            create_qb_user: this.frmUserData.frmCreateQbUser,
            team_id: this.frmUserData.frmTeam || null,
            callProvider: this.frmUserData.callProvider || '3cx',
            clicksend_token: this.frmUserData.frmClickSendToken,
            is_internal_user: 1,
        };     

        this.loadingVisible = true;
        this.usersService.addUser(userData)
            .subscribe(
                (result) => {
                    console.log('addUserRESULT', result);
                    if (event === 'save') {
                        const swalData = {
                            title: 'Success',
                            message: 'User Created Successfully.',
                            info: ''
                        };
                        this.swalService.success(swalData);
                        this.router.navigate(['user/list']);
                    } else {
                        // window.location.reload();
                        // this.swalService.success('New user is added successfully.');
                        this.frmUserData = new frmUserDataType;
                    }

                },
                (error) => {
                    this.loadingVisible = false;
                    console.log('addUserERROR', error);
                    const swalData = {
                        title: 'Error',
                        message: error.error.message || 'Something went wrong',
                        info: error.error.error || 'Something went wrong'
                    };
                    this.swalService.error(swalData);
                }, () => {
                    this.loadingVisible = false;
                }
            );

    }

    cancel() {
        this.router.navigate(['user/list']);
    }

    onFormSubmit(event: any) {
        // console.log('onFormSubmit', event, this.frmUserData );
        this.isFormSubmitted = true;
        console.log(this.formActionValue);
        if (this.formActionValue === 'edit') {
            this.edit();
        } else if (this.formActionValue === 'save') {
            this.save('save');
        } else if (this.formActionValue === 'more') {
            this.save('more');
        }
    }

    form_fieldDataChanged(event: any) {
        this.isFormDataChanged = true;
        if (event.dataField === 'frmEmail' && this.emailPattern.test(this.frmUserData.frmEmail)) {
            const email = event.value;
            console.log('testingEmail');
            /*this.usersService.ifEmailInUse(email)
                .subscribe(
                    (result) => {
                        console.log("ifEmailInUseRESULT", result);
                        if (result.email_exists === true && !this.editMode) {
                            this.emailInUse = true;
                            this.emailInUseText = 'user with this email already exists';
                            const swalData = {
                                title: 'Email already in use',
                                message: 'User with this email already registered.',
                                info: 'Please, enter another email.'
                            };
                            this.swalService.warn(swalData);
                        } else {
                            this.emailInUse = false;
                            this.emailInUseText = '';
                        }
                    },
                    (error) => {
                        console.log("ifEmailInUseERROR", error);
                    }
                );*/

        }
    }

    reset() {
        this.dxUserForm.instance.resetValues();
    }

    isApprovedUser(e) {
        this.isApproved = e.value;
    }


    onCountryCodeChanged(event) {
        this.frmUserData.frmCountryCode = event.value;
    }
    onPersonalCountryCodeChanged(event) {
        this.frmUserData.frmPersonalCountryCode = event.value || '+91';
    }

    getCountryCodes() {
        this.commonService.getCountryCodes().then((response: any) => {
            // this.countryCodes = response;
            response.map(item => {
                this.countryCodes.push({
                    callCode: `+${item.callingCodes[0]}`,
                    name: item.name,
                    flag: `https://flagcdn.com/w20/${item.alpha2Code.toLowerCase()}.png`,//item.flag,
                    alpha2Code: item.alpha2Code
                });
            });
        }, err => {
            console.log(err);
        });
    }

    getAllQbUsers() {
        this.commonService.getAllQbUsers().subscribe((response: any) => {
            // this.countryCodes = response;
            this.qbUsers = response.data || [];
        }, err => {
            console.log(err);
        });
    }

    getTeamList() {
        this.usersService.getTeamList().subscribe((data: any) => {
            this.teamList = data.data;
        }, () => {
        });
    }

    onEmailChange = (event) => {
        this.isEmailEdit = this.currentEmail !== event.value;
    }

    isEmailExistCheck(event) {
        return new Promise((resolve, reject) => {
            this.usersService.isUserEmailExist(event.value).subscribe((res) => {
                if (this.isEmailEdit) {
                    resolve(!res.data.email_exists);
                } else {
                    resolve(true);
                }
            }, (err) => {
                console.log(err);
            });
        })
    }
}
