import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "textLengthFormatter"
})
export class TextLengthFormatterPipe implements PipeTransform {
    transform(value: any, length?: any): any {
        // console.log("value pipe", value, length, value.substring(0));
        return value && value.length && value.length > length
            ? value.substring(0, length) + "..."
            : value;
    }
}
