<div fxFlex fxLayout="column" fxLayoutAlign="center center">

    <div class="big-circle app-logo" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon class="s-64 s-md-128 mat-accent">chat</mat-icon>
    </div>

    <span class="app-title mt-48 mb-8" >
        Chat App
    </span>

    <span fxHide fxShow.gt-md class="app-message" >
        Select a contact to start a chat!
    </span>

    <button mat-raised-button fxHide.gt-md pulseMatSidenavToggler="chat-left-sidenav">
        Select a contact to start a chat!
    </button>

</div>
