<!-- CHAT -->
<div class="chat" fxFlex fxLayout="column">

    <!-- CHAT TOOLBAR -->
    <mat-toolbar class="chat-toolbar">

        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutAlign="start center">

                <!-- RESPONSIVE CHATS BUTTON-->
                <button mat-icon-button fxHide.gt-md class="responsive-chats-button mr-16"
                        fuseMatSidenavToggler="chat-left-sidenav"
                        aria-label="chats button">
                    <mat-icon>chat</mat-icon>
                </button>
                <!-- / RESPONSIVE CHATS BUTTON-->

                <!-- CHAT CONTACT-->
                <div class="chat-contact" fxLayout="row" fxLayoutAlign="start center"
                     fuseMatSidenavToggler="chat-right-sidenav" (click)="selectContact()">

                    <div class="avatar-wrapper">

                        <img [src]="contact.avatar || defaultAvatar"
                             class="avatar" [alt]="contact.name"/>

                        <mat-icon class="s-16 status"
                                  [ngClass]="contact.status">
                        </mat-icon>
                    </div>

                    <div class="chat-contact-name font-size-16">
                        {{contact.name}}
                    </div>

                    <a [href]="'mailto:'+contact.email">
                        <button mat-icon-button class="ml-20 chat-contact-icon pulse-blue font-size-14">
                            <mat-icon>email</mat-icon>
                        </button>
                    </a>
                    <div class="chat-contact-name pulse-blue font-size-14">
                        <a [href]="'mailto:'+contact.email">{{contact.email}}</a>
                    </div>

                    <a [href]="'tel:'+contact.country_code+contact.mobile_no" *ngIf="contact.mobile_no">
                        <button mat-icon-button class="ml-20 chat-contact-icon pulse-blue font-size-14"
                                *ngIf="contact.mobile_no">
                            <mat-icon>phone</mat-icon>
                        </button>
                    </a>
                    <div class="chat-contact-name pulse-blue font-size-14" *ngIf="contact.mobile_no">
                        <a [href]="'tel:'+contact.country_code+contact.mobile_no">{{contact.country_code + ' ' + contact.mobile_no}}</a>
                    </div>

                    <a *ngIf="contact.jobdiva_id" [href]="contact.jobDivaWithURL" target="_blank">
                        <img *ngIf="contact.jobdiva_id" src="/assets/images/source-images/jobDivaLogo.png"
                             class="jdIcon" [alt]="JD"/>
                    </a>
                    <!-- <div class="chat-contact-name pulse-blue font-size-14" *ngIf="contact.jobdiva_id">
                        <a [href]="contact.jobDivaWithURL" target="_blank">{{contact.jobdiva_id}}</a>
                    </div> -->

                </div>
                <!-- / CHAT CONTACT-->
            </div>

            <div>

                <a *ngIf="user.isUser == true" href="javascript:" aria-label="more" matTooltip="Edit Contact"
                   (click)="editContact()">
                    <!-- <mat-icon>more_vert</mat-icon> -->
                    <i class="fa fa-user-edit s-20" aria-hidden="true"></i>
                </a>
                <a *ngIf="user.isUser == true" href="javascript:" class="ml-20" aria-label="more"
                   matTooltip="Delete Contact" (click)="deleteContact()">
                    <!-- <mat-icon>more_vert</mat-icon> -->
                    <i class="far fa-trash-alt s-20" aria-hidden="true"></i>
                </a>


                <!-- <mat-menu #contactMenu="matMenu">
                    <button mat-menu-item (click)="editContact()">
                        Edit
                    </button>
                    <button mat-menu-item (click)="deleteContact()">
                        Delete
                    </button>
                </mat-menu> -->
            </div>

        </div>
    </mat-toolbar>
    <!-- / CHAT TOOLBAR -->

    <!-- CHAT CONTENT -->
    <div id="chat-content" style="background-color: white" fxFlex="1 1 auto" pulsePerfectScrollbar>

        <!-- CHAT MESSAGES -->
        <div class="chat-messages">

            <!-- MESSAGE -->
            <div *ngFor="let message of dialog; let i = index" class="message-row"
                 [ngClass]="{
                            'me': (message.who === user.id || message.ownerType == 1),
                            'contact': (message.who !== user.id && message.ownerType !== 1),
                            'first-of-group': isFirstMessageOfGroup(message, i),
                            'last-of-group': isLastMessageOfGroup(message, i)
                            }">

                <img *ngIf="shouldShowContactAvatar(message, i)"
                     [src]="contact.avatar || defaultAvatar"
                     class="avatar">

                <div class="bubble">
                    <div class="message">
                        {{message.message}}
                    </div>
                    <mat-icon *ngIf="showMessageType(message, i, 'sms')" class="messageTypeIcon s-16">
                        stay_current_portrait
                    </mat-icon>
                    <mat-icon *ngIf="showMessageType(message, i, 'text')" class="messageTypeIcon s-16">chat</mat-icon>
                    <mat-icon *ngIf="showMessageType(message, i, 'note')" class="messageTypeIcon s-16">notes</mat-icon>
                    <div class="time secondary-text ">

                        <div *ngIf="(message.who === user.id || message.ownerType == 1)" class="displayOwner">{{message.toWhomName}}
                        </div>

                        {{message.time | date:'short'}}
                        <span class="ml-4 sms-status" [ngClass]="{'delivered' : message.sms_delivered}">
                            <mat-icon class="s-16">{{message.sms_delivered ? 'done_all' : message.sms_status=='failed' ? 'error_outline' : 'done'}}</mat-icon>
                        </span>
                    </div>
                </div>

            </div>
            <!-- / MESSAGE -->

        </div>
        <!-- CHAT MESSAGES -->

    </div>
    <!-- / CHAT CONTENT -->

    <!-- CHAT FOOTER -->
    <div class="chat-footer" fxFlex="0 0 auto" fxLayout="column">

        <!-- REPLY FORM -->
        <div class="reply-form">

            <form #replyForm="ngForm"
                  (ngSubmit)="reply($event)"
                  (keydown.enter)="reply($event)"
                  fxLayout="column">

                <div class="message-type-selection w-50-p" fxLayout="row">

                    <mat-form-field *ngIf="user.isUser == true" class="w-50-p message-type">
                        <mat-select placeholder="Message Type" [(ngModel)]="selectedMessageType"
                                    name="selectedMessageType">
                            <mat-option *ngFor="let types of messageTypeList" [value]="types.value">
                                {{types.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="user.isUser == true" class="w-50-p pl-12 template-list">
                        <mat-select placeholder="Select Template" ngModel [(ngModel)]="templateType" name="templateType"
                                    (selectionChange)="setTemplateText($event.value)">
                            <mat-option *ngFor="let types of templateList" [value]="types.value">
                                {{types.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field class="message-text w-100-p" floatLabel="never" appearance="standard">
                    <textarea matInput #replyInput placeholder="Type your message"
                              [(ngModel)]='message' name="message" [rows]="1"></textarea>
                    </mat-form-field>

                    <!-- <button class="send-message-button" mat-icon-button type="submit" aria-label="Send message" title="Send Chat">
                        <mat-icon class="secondary-text">send</mat-icon>
                    </button> -->

                    <button class="send-message-button" mat-icon-button type="submit" aria-label="Send message">
                        <mat-icon class="secondary-text">send</mat-icon>
                    </button>
                </div>


                <!-- <button class="send-sms-button" mat-icon-button type="submit" (click)="submitSMS()" aria-label="Send SMS" title="Send SMS">
                    <mat-icon class="secondary-text">sms</mat-icon>
                </button> -->

            </form>

        </div>
        <!-- / REPLY FORM -->

    </div>
    <!-- / CHAT FOOTER-->

</div>
<!-- / CHAT -->

<!-- Edit Candidate POPUP -->
<dx-popup class="popup" [width]="650" [height]="300" [showTitle]="true" [title]="popupTitle"
          [dragEnabled]="false" [deferRendering]="false"
          [closeOnOutsideClick]="true"
          [(visible)]="editCandPopup">
    <div *dxTemplate="let data of 'content'">

        <form class="invite-form" (ngSubmit)="updateCandidate($event)">
            <div id="invite-form-container">
                <dx-form [(formData)]="candidateData" [readOnly]="false"
                         [showValidationSummary]="true"
                         [colCount]="2" labelLocation="top" validationGroup="editCandidateValidation">

                    <dxi-item itemType="group" [colCount]="12" cssClass="hasButton dxInputGroup">
                        <dxi-item dataField="frmCountryCode" editorType="dxSelectBox" cssClass="pr-0"
                                  [colSpan]="5" [editorOptions]="{
                            dataSource: countryCodes,
                            valueExpr: 'id',
                            displayExpr: 'name'
                        }" [template]="'countryCodeTemplate'">
                            <dxo-label text="Contact #"></dxo-label>
                            <dxi-validation-rule
                                type="required"
                                message="Phone Number is required">
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item dataField="frmContactPhone" [colSpan]="5" cssClass="px-0"
                                  [editorOptions]="{mask:'(000) 000-0000',
                                        useMaskedValue: false,
                                        maskInvalidMessage: 'Please enter a correct number' }">
                            <dxo-label text=" " [showColon]="false"></dxo-label>
                            <dxi-validation-rule
                                type="required"
                                message="Phone Number is required">
                            </dxi-validation-rule>

                        </dxi-item>
                        <dxi-item [colSpan]="2" editorType="dxButton" [editorOptions]="{
                                                          icon: 'search',
                                                          onClick: onContactPhoneChanged
                                                        }">
                        </dxi-item>
                    </dxi-item>

                    <dxi-item dataField="frmUserFirstName">
                        <dxo-label text="First Name"></dxo-label>
                        <dxi-validation-rule
                            type="required"
                            message="First Name is required">
                        </dxi-validation-rule>
                    </dxi-item>

                    <dxi-item dataField="frmUserLastName">
                        <dxo-label text="Last Name"></dxo-label>
                    </dxi-item>

                    <dxi-item dataField="frmContactEmail">
                        <dxo-label text="Email"></dxo-label>
                    </dxi-item>
                    <div *dxTemplate="let data of 'countryCodeTemplate'">

                        <dx-select-box [dataSource]="countryCodes" valueExpr="callCode" displayExpr="callCode"
                                       fieldTemplate="field" [searchExpr]="['name', 'alpha2Code', 'callCode']"
                                       (onValueChanged)="onCountryCodeChanged($event)"
                                       [value]="candidateData.frmCountryCode"
                                       [deferRendering]="false" [searchEnabled]="true">
                            <div *dxTemplate="let data2 of 'field'">
                                <div class="custom-item pl-16" fxLayout="row" fxLayoutAlign="start center">

                                    <img width="25" *ngIf="data2?.flag" [src]="data2?.flag"/>
                                    <dx-text-box class="product-name"
                                                 [value]="data2?.callCode"
                                    ></dx-text-box>
                                </div>

                            </div>
                            <div *dxTemplate="let data2 of 'item'">

                                <div class="custom-item" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">

                                    <img width="25px" [src]="data2.flag"/>
                                    <div class="product-name">
                                        <span>({{data2.callCode}}) </span>
                                        <span>{{data2.alpha2Code}}</span>
                                    </div>
                                </div>
                            </div>
                        </dx-select-box>
                    </div>
                    <!-- End -->
                </dx-form>
            </div>

            <div class="mt-20" fxLayout="row" fxLayoutAlign="space-between">

                <dx-button text="Cancel" class="pulse-btn pulse-btn-normal"
                           (onClick)="editCandPopup = false">
                </dx-button>

                <dx-button text="Save" [useSubmitBehavior]="true" validationGroup="editCandidateValidation"
                           class="pulse-btn pulse-btn-primary">
                </dx-button>

            </div>
        </form>
    </div>
</dx-popup>
