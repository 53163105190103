import { EmailListService } from "../../../../../services/emailSearch/emailList.service";
import { ListServiceService } from "../../../../../services/emailSearch/list-service.service";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import {
    EMAIL_INDEX_TYPE,
    ATTACHMENT_INDEX_TYPE
} from "../../Constants/constants.js";
@Component({
    selector: "app-side-panel",
    templateUrl: "./side-panel.component.html",
    styleUrls: ["./side-panel.component.scss"]
})
export class SidePanelComponent implements OnInit {
    @Output() public errorEvent = new EventEmitter();
    folderTypeFilter: any;
    indexTypeStates: any = {
        email: EMAIL_INDEX_TYPE,
        attachment: ATTACHMENT_INDEX_TYPE
    };
    subscribe: any;
    userMailFilters: any;
    asideFilter: any;
    panelMail: any = {
        name: "Users",
        active: true
    };
    panelFolderType: any = {
        name: "Folders",
        active: true
    };
    indexType: any;
    facetSubscribe: any;
    getMailForFilterAction: any;
    constructor(
        private listService: ListServiceService,
        private emailListService: EmailListService,
        private notification: NzNotificationService
    ) { }

    ngOnInit() {
        this.userMailFilters = this.emailListService.getFilterMails();
        this.indexType = this.listService.getIndexType();

        // this.userMailFilters = this.emailListService.getFilterMails();
        // console.log("userMailFilters", this.userMailFilters);
        this.folderTypeFilter = this.listService.getMailBoxList();
        this.asideFilter = this.listService.getAsideFilter();
    }
    checkAllValue(event, type) {
        this.listService.setAllFilters(event.target.checked, type);
        this.callSearchAction();
    }
    checkValue(event) {
        this.callSearchAction();
    }
    callSearchAction(callFacet = false) {
        this.listService.resetFrom();
        if (this.subscribe) {
            this.subscribe.unsubscribe();
        }
        this.subscribe = this.listService.fetchList(false).subscribe(
            response => {
                this.listService.setMailBoxList(response);
            },
            error => {
                this.errorEvent.emit("Oops! , Something went wrong");
            }
        );

        if (callFacet) {
            if (this.facetSubscribe) {
                this.facetSubscribe.unsubscribe();
            }
            this.facetSubscribe = this.listService.fetchFacetList().subscribe(
                response => {
                    this.listService.setFacetList(response);
                },
                error => {
                    this.errorEvent.emit("Oops! , Something went wrong");
                }
            );
        }
    }

    indexTypeChangeHandle() {
        this.listService.clearResults();
        this.listService.resetFrom();
        this.listService.clearFilters();
        this.callSearchAction(true);
    }
}
