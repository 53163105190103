<div class="page-layout simple fullwidth" pulsePerfectScrollbar>
  <div class="container mt-12">
    <div class="content mat-white-bg mat-elevation-z4 mb-20" fxLayout="column">
      <div class="content-wrapper">
        <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto" fxLayoutAlign="space-between center">
          <div class="pulse-main-heading">
            Job Portal - Extract
          </div>
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
            <dx-button
              icon="fa fa-th-list"
              text="All Jobs"
              class="pulse-btn has-icon"
              (onClick)="displayAllJobs()"
            ></dx-button>
          </div>
        </div>
        <div class="container">
          <!-- Form -->
          <div class="data-component">
            <div class="data-component-header">Initiate new Scrape Job</div>
              <form
                (submit)="submitScrapeJob($event)"
                validationGroup="scrapeJobValidationGroup">
                <div fxLayout="column" fxLayoutAlign="space-around" fxFlexFill>
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                    <div fxFlex="15" fxLayout="column">
                      <dx-select-box
                        placeholder="Scraper"
                        [items]="enabledScrapers"
                        [(value)]="selectedScraper"
                      >
                        <dx-validator>
                          <dxi-validation-rule type="required" message="required"
                          ></dxi-validation-rule>
                        </dx-validator>
                      </dx-select-box>
                    </div>
                    <div fxFlex="59" fxLayout="column">
                      <dx-text-box placeholder="Search URL" [(value)]="url">
                        <dx-validator>
                          <dxi-validation-rule type="required" message="required"
                          ></dxi-validation-rule>
                        </dx-validator>
                      </dx-text-box>
                    </div>
                    <div fxFlex="22" fxLayout="column">
                      <dx-tag-box
                        placeholder="Zip Codes"
                        [multiline]="false"
                        [searchEnabled]="true"
                        [showSelectionControls]="true"
                        [maxDisplayedTags]="2"
                        [showMultiTagOnly]="false"
                        [items]="zipCodeList"
                        [(value)]="selectedZipCodes"
                      >
                        <dx-validator>
                          <dxi-validation-rule type="required" message="required"
                          ></dxi-validation-rule>
                        </dx-validator>
                      </dx-tag-box>
                    </div>
                    <div fxFlex="4" fxLayout="column">
                      <dx-button
                        icon="chevronright"
                        [useSubmitBehavior]="true"
                      ></dx-button>
                    </div>
                  </div>
                </div>
              </form>
          </div>

          <!-- Scrape Jobs List -->
          <div class="data-component">
            <div class="data-component-header">Recent Scrape Jobs
              <div class="badge ng-star-inserted">
                {{ totalActiveScrapeJobs }} Active
              </div>
            </div>
            <dx-data-grid
              keyExpr="scrapeJobId"
              [dataSource]="allScrapeJobs"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showColumnLines]="false"
              [showBorders]="true"
              [columnAutoWidth]="true"
              [selectedRowKeys]="[]"
              (onSelectionChanged)="displayJobs($event)"
            >
              <dxo-paging [pageSize]="5"></dxo-paging>
              <dxo-selection mode="single"></dxo-selection>

              <dxi-column
                width="5%"
                dataField="scrapeJobId"
                dataType="number"
                alignment="center"
                caption="#"
              ></dxi-column>
              <dxi-column width="10%" dataField="scraper">
              </dxi-column>
              <dxi-column
                width="35%"
                dataField="searchUrl"
                cellTemplate="urlCellTemplate"
                caption="URL"
              ></dxi-column>
              <dxi-column width="10%" dataField="status" alignment="center">
              </dxi-column>
              <dxi-column
                width="20%"
                dataField="createdAt"
                dataType="datetime"
                alignment="center"
              ></dxi-column>
              <dxi-column
                width="20%"
                dataField="completedAt"
                dataType="datetime"
                alignment="center"
              ></dxi-column>
              <dxi-column
                width="10%"
                cellTemplate="progressCellTemplate"
                caption="Progress"
                alignment="center"
              ></dxi-column>

              <div *dxTemplate="let info of 'urlCellTemplate'">
                <a
                  [href]="info.data.searchUrl"
                  target="_blank"
                >{{info.data.searchUrl}}</a>
              </div>

              <div *dxTemplate="let info of 'progressCellTemplate'">
                <div *ngIf="info.data.status === 'Completed'">
                  {{info.data.progress}}
                </div>
                <div *ngIf="info.data.status === 'Processing'">
                  {{info.data.progress}}
                  <dx-button
                    icon="close"
                    (onClick)="abortScrapeJob(info.data.scrapeJobId)"
                  ></dx-button>
                </div>
                <div *ngIf="info.data.status === 'Aborted'">-</div>
                <div *ngIf="info.data.status === 'Errored'">-</div>
              </div>
            </dx-data-grid>
          </div>

          <!-- JobPortal Jobs List -->
          <div class="data-component" *ngIf="jobPortalJobs.length > 0">
            <div class="data-component-header">Jobs
              <div class="badge ng-star-inserted">
                {{ jobPortalJobs.length }}
              </div>
            </div>
            <dx-data-grid
              keyExpr="jobId"
              [dataSource]="jobPortalJobs"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showColumnLines]="false"
              [showBorders]="true"
              [columnAutoWidth]="true"
            >
              <dxo-filter-row 
                [visible]="true" 
              ></dxo-filter-row>
              <dxo-paging [pageSize]="20"></dxo-paging>
              <dxo-pager
                  [showPageSizeSelector]="true"
                  [allowedPageSizes]="[20, 30, 50]"
                  [showInfo]="true">
              </dxo-pager>

              <dxi-column dataField="source" alignment="center"></dxi-column>
              <dxi-column
                width="40%"
                dataField="title"
                cellTemplate="titleCellTemplate"
                caption="Title"
              ></dxi-column>
              <dxi-column
                dataField="companyName"
                cellTemplate="companyCellTemplate"
                caption="Company"
              ></dxi-column>
              <dxi-column width="13%" dataField="city">
              </dxi-column>
              <dxi-column width="5%" dataField="state">
              </dxi-column>
              <dxi-column width="7%" dataField="zipCode">
              </dxi-column>
              <dxi-column
                dataField="postedAt"
                dataType="datetime"
                alignment="center"
                sortOrder="asc"
              ></dxi-column>

              <div *dxTemplate="let info of 'titleCellTemplate'">
                <a
                  [href]="info.data.url"
                  target="_blank"
                >{{info.data.title}}</a>
                <div class="small-light-text">{{ info.data.description }}</div>
              </div>
              <div *dxTemplate="let info of 'companyCellTemplate'">
                <a
                  *ngIf="info.data.companyUrl; else plainText"
                  [href]="info.data.companyUrl"
                  target="_blank"
                >{{info.data.companyName}}</a>
                <ng-template #plainText>
                  <span>{{info.data.companyName}}</span>
                </ng-template>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
