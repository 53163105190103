<div class="page-layout simple fullwidth" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-20" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto" fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading">
                        Worker Search
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                        <dx-tag-box  [width]="'250px'" placeholder="Select Clients" [multiline]="false"
                        [searchEnabled]="true" [showSelectionControls]="true" [maxDisplayedTags]="2" valueExpr="businessEntityId" displayExpr="businessName"
                        [showMultiTagOnly]="false" [items]="vmsClientList" [(value)]="selectedClients" (onValueChanged)="refreshGridForClient($event)">
                        </dx-tag-box>
                        <dx-button icon="check" class="pulse-btn has-icon" (onClick)="searchWorker();"></dx-button>
                        <dx-button icon="revert" class="pulse-btn has-icon" (onClick)="clearFilter();"></dx-button>
                    </div>
                </div>
                <div class="p-30 pt-15 pb-15 grid-wrapper">
                    <div class="container workerContainer" fxLayout= "column" fxFlex="20">
                        <div style="background: #67799f; padding: 10px; color: white;position: absolute;
                        width: 226px;">
                            Workers<span class="badge ng-star-inserted workerbadge">{{ vmsWorkerSearchCount }}</span>
                        </div>
                        <div style="margin-top: 40px;"> 
                            <input type="text" class="search_text" [(ngModel)]="searchText" placeholder="Search...">
                            <div style="    margin-top: 60px;">
                              <div class="workerList" *ngFor="let i of vmsWorkerSearch | searchFilter : searchText" (click)="workerDetail(i)">
                                  <span>{{i.fullName}}</span>
                                  <span style="font-size: 12px; color: #a1a0a0;">{{i.id}}</span>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="container" fxLayout= "column" fxFlex="80">
                        <div *ngIf="vmsWorkerDetailToogle">
                            <div style="background: #67799f; padding: 11px; color: white;position: relative;left: 10px;width: 100%;" 
                            *ngIf="vmsWorkerDetails">Worker Details - {{vmsWorkerDetails.fullName}}</div>
                            <!-- Top Details -->
                            <div fxLayout="row" style="position: relative;left: 10px;">
                                <div fxFlex="40" fxLayout="column">
                                    <div style="background: #f2f2e9; padding: 10px;">
                                        <span>Top Details</span>
                                    </div>
                                    <div style="border: 1px solid #f2f2e9;"> 
                                        <div class="details">
                                            <span>Id: <h5>{{vmsWorkerDetails.id}}</h5><h5 *ngIf="!vmsWorkerDetails.id">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Status: <h5>{{vmsWorkerDetails.status}}</h5><h5 *ngIf="!vmsWorkerDetails.status">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Full Name: <h5>{{vmsWorkerDetails.fullName}}</h5><h5 *ngIf="!vmsWorkerDetails.fullName">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Site City: <h5>{{vmsWorkerDetails.siteCity}}</h5><h5 *ngIf="!vmsWorkerDetails.siteCity">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Site State: <h5>{{vmsWorkerDetails.siteState}}</h5><h5 *ngIf="!vmsWorkerDetails.siteState">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Site Country: <h5>{{vmsWorkerDetails.siteCountry}}</h5><h5 *ngIf="!vmsWorkerDetails.siteCountry">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Site Location: <h5>{{vmsWorkerDetails.siteLocation}}</h5><h5 *ngIf="!vmsWorkerDetails.siteLocation">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Job Posting ID: <h5>{{vmsWorkerDetails.jobPostingID}}</h5><h5 *ngIf="!vmsWorkerDetails.jobPostingID">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Job Posting L1: <h5>{{vmsWorkerDetails.jobPostingL1}}</h5><h5 *ngIf="!vmsWorkerDetails.jobPostingL1">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Job Posting L2: <h5>{{vmsWorkerDetails.jobPostingL2}}</h5><h5 *ngIf="!vmsWorkerDetails.jobPostingL2">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Job Posting L3: <h5>{{vmsWorkerDetails.jobPostingL3}}</h5><h5 *ngIf="!vmsWorkerDetails.jobPostingL3">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Job Posting L4: <h5>{{vmsWorkerDetails.jobPostingL4}}</h5><h5 *ngIf="!vmsWorkerDetails.jobPostingL4">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Supervisor ID: <h5>{{vmsWorkerDetails.supervisorID}}</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Supervisor Full Name: <h5>{{vmsWorkerDetails.supervisorFirstName}} {{vmsWorkerDetails.supervisorLastName}}</h5><h5 *ngIf="!vmsWorkerDetails.supervisorFirstName">-</h5></span>
                                        </div>
                                    </div>

                                    <!-- Current Work Order -->
                                    <div style="margin-top: 10px;background: #f2f2e9; padding: 10px;">
                                        <span>Current Work Order Accounting</span>
                                    </div>
                                    <div style="border: 1px solid #f2f2e9;"> 
                                        <div class="details">
                                            <span>Current Cost Center: <h5>{{vmsWorkerDetails.currentCostCenter}}</h5><h5 *ngIf="!vmsWorkerDetails.currentCostCenter">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>St Pay Rate: <h5>{{vmsWorkerDetails.stPayRate}}</h5><h5 *ngIf="!vmsWorkerDetails.stPayRate">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>St Bill Rate: <h5>{{vmsWorkerDetails.stBillRate}}</h5><h5 *ngIf="!vmsWorkerDetails.stBillRate">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Ot Pay Rate: <h5>{{vmsWorkerDetails.otPayRate}}</h5><h5 *ngIf="!vmsWorkerDetails.otPayRate">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Ot FST: <h5>{{vmsWorkerDetails.otFST}}</h5><h5 *ngIf="!vmsWorkerDetails.otFST">-</h5></span>
                                        </div>
                                    </div>
                                </div>
                                <div fxFlex="30" fxLayout="column" style="margin: 0px 10px;">
                                    <!-- Current Work Order Details -->
                                    <div style="background: #f2f2e9; padding: 10px;">
                                        <span>Current Work Order Details</span>
                                    </div>
                                    <div style="border: 1px solid #f2f2e9;"> 
                                        <div class="details">
                                            <span>Work Order Id: <h5>{{vmsWorkerDetails.workOrderID}}</h5><h5 *ngIf="!vmsWorkerDetails.workOrderID">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Work Order Start Date: <h5>{{vmsWorkerDetails.workOrderStartDate}}</h5><h5 *ngIf="!vmsWorkerDetails.workOrderStartDate">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Work Order End Date: <h5>{{vmsWorkerDetails.workOrderEndDate}}</h5><h5 *ngIf="!vmsWorkerDetails.workOrderEndDate">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Work Order Hours Per Day: <h5>{{vmsWorkerDetails.workOrderHoursPerDay}}</h5><h5 *ngIf="!vmsWorkerDetails.workOrderHoursPerDay">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Work Order Hours Per Week: <h5>{{vmsWorkerDetails.workOrderHoursPerWeek}}</h5><h5 *ngIf="!vmsWorkerDetails.workOrderHoursPerWeek">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Work Order Cost Center: <h5>{{vmsWorkerDetails.workOrderCostCenter}}</h5><h5 *ngIf="!vmsWorkerDetails.workOrderCostCenter">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Work Order Timesheet Frequency: <h5>{{vmsWorkerDetails.workOrderTimesheetFrequency}}</h5><h5 *ngIf="!vmsWorkerDetails.workOrderTimesheetFrequency">-</h5></span>
                                        </div>
                                    </div>
        
                                    <!-- Current Work Order Details -->
                                    <div style="background: #f2f2e9; padding: 10px;margin-top: 10px;">
                                        <span>Worker Details</span>
                                    </div>
                                    <div style="border: 1px solid #f2f2e9;"> 
                                        <div class="details">
                                            <span>Worker Owner Id: <h5>{{vmsWorkerDetails.workerOwnerID}}</h5><h5 *ngIf="!vmsWorkerDetails.workerOwnerID">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Location City: <h5>{{vmsWorkerDetails.locationCity}}</h5><h5 *ngIf="!vmsWorkerDetails.locationCity">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Location State: <h5>{{vmsWorkerDetails.locationState}}</h5><h5 *ngIf="!vmsWorkerDetails.locationState">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Location Country: <h5>{{vmsWorkerDetails.locationCountry}}</h5><h5 *ngIf="!vmsWorkerDetails.locationCountry">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Location: <h5>{{vmsWorkerDetails.locationLocation}}</h5><h5 *ngIf="!vmsWorkerDetails.locationLocation">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Worker Owner Full Name: <h5>{{vmsWorkerDetails.workerOwnerFirstName}} {{vmsWorkerDetails.workerOwnerLastName}}</h5><h5 *ngIf="!vmsWorkerDetails.workerOwnerFirstName">-</h5></span>
                                        </div>
                                    </div>
                                </div>
                                <div fxFlex="30" fxLayout="column">
                                    <!-- Personal Information -->
                                    <div style="background: #f2f2e9; padding: 10px;">
                                        <span>Personal Information</span>
                                    </div>
                                    <div style="border: 1px solid #f2f2e9;"> 
                                        <div class="details">
                                            <span>Username: <h5>{{vmsWorkerDetails.username}}</h5><h5 *ngIf="!vmsWorkerDetails.username">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Contact: <h5>{{vmsWorkerDetails.contact}}</h5><h5 *ngIf="!vmsWorkerDetails.contact">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Email: <h5>{{vmsWorkerDetails.email}}</h5><h5 *ngIf="!vmsWorkerDetails.email">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Phone Number: <h5>{{vmsWorkerDetails.phoneNumber}}</h5><h5 *ngIf="!vmsWorkerDetails.phoneNumber">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Fax Number: <h5>{{vmsWorkerDetails.faxNumber}}</h5><h5 *ngIf="!vmsWorkerDetails.faxNumber">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Mobile Number: <h5>{{vmsWorkerDetails.mobileNumber}}</h5><h5 *ngIf="!vmsWorkerDetails.mobileNumber">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>Address: <h5>{{vmsWorkerDetails.address}}</h5><h5 *ngIf="!vmsWorkerDetails.address">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Address 2: <h5>{{vmsWorkerDetails.address1}}</h5><h5 *ngIf="!vmsWorkerDetails.address1">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Address 3: <h5>{{vmsWorkerDetails.address2}}</h5><h5 *ngIf="!vmsWorkerDetails.address2">-</h5></span>
                                        </div>
                                        <div class="details">
                                            <span>City: <h5>{{vmsWorkerDetails.city}}</h5><h5 *ngIf="!vmsWorkerDetails.city">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>State: <h5>{{vmsWorkerDetails.state}}</h5><h5 *ngIf="!vmsWorkerDetails.state">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Country: <h5>{{vmsWorkerDetails.country}}</h5><h5 *ngIf="!vmsWorkerDetails.country">-</h5></span>
                                        </div>
                                        <div class="details">    
                                            <span>Zip: <h5>{{vmsWorkerDetails.zip}}</h5><h5 *ngIf="!vmsWorkerDetails.zip">-</h5></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>
            </div>
        </div>
    </div>
</div>