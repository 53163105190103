import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


import {siteConfig} from '../../siteSettings';

export class Count {
    userId: number;
    userName: string;
    twitter: string;
    facebook: string;
}

export class Item {
    ID: number;
    user_name: string;
    created_at: string;
    user_id: number;
    fb_message: string;
    tw_message: string;
    Count: Count[];
}

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('userToken')}`
    })
};

@Injectable()

export class SmbLogsService {

    constructor(
        private _http: HttpClient
    ) {
    }

    getAllLogsByUserId(userId) {
        let url = siteConfig.nodeAPIBaseUrl + 'smb_auth/getLogsByUser/' + userId;
        let data = this._http.get<any>(url, httpOptions);
        return data;
    }

    getPostDetailsByLogId(userId) {
        let url = siteConfig.nodeAPIBaseUrl + 'smb_auth/getPostDetails/' + userId;
        let data = this._http.get<any>(url, httpOptions);
        return data;
    }


}
