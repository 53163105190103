import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {JwtModule} from '@auth0/angular-jwt';
// import {DevExtremeModule} from 'devextreme-angular';
import {AppRoutingModule} from './app-routing.module';
import {JwtInterceptor} from './services/jwt.interceptor';
import {AppComponent} from './app.component';
import {HomeModule} from './home/home.module';
import {PulseMainModule} from './main/main.module';
import {PulseModule} from '@pulse/pulse.module';
import {PulseSharedModule} from '@pulse/shared.module';
import {PulseHeaderModule} from 'app/main/header/header.module';
import {PulseFooterModule} from 'app/main/footer/footer.module';
import {PulseContentModule} from 'app/main/content/content.module';
import {LoginService} from 'app/services/login.service';
import {UsersService} from './services/users/users.service';
import {SwalService} from './services/swal.service';
import {AuthGuardService} from './services/auth/auth-guard.service';
import {siteConfig} from './siteSettings';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import { DxLookupModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';

export function tokenGetter() {
    return localStorage.getItem('userToken');
}
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                authScheme: 'JWT ',
                whitelistedDomains: [siteConfig.baseApiHost],
                blacklistedRoutes: [`${siteConfig.nodeAPIBaseUrl}login`]
            }
        }),
        TranslateModule.forRoot(),
        HttpClientModule,
        PulseModule,
       // DevExtremeModule,
        PulseSharedModule,
        PulseMainModule,
        HomeModule,
        PulseHeaderModule,
        PulseContentModule,
        PulseFooterModule,
        AppRoutingModule,
        DxLookupModule,
        FormsModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        AuthGuardService,
        LoginService,
        UsersService,
        SwalService,
        NzNotificationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
