import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginService} from './services/login.service';

const routes: Routes = [
    {
        path: 'register',
        redirectTo: '/home/register'
    }, {
        path: 'forgot-password',
        redirectTo: '/home/forgot-password'
    },{
        path: 'reset-password',
        redirectTo: '/home/reset-password'
    },
    {
        path: 'login',
        redirectTo: '/home/login'
    },
    {
        path: 'eeo-detail/:consultantId',
        redirectTo: '/home/eeo-detail/:consultantId'
    },
    {
        path: '**',
        redirectTo: '/home'
    }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
