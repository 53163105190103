import {Component, HostBinding, HostListener, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {LoginService} from 'app/services/login.service';

@Component({
    selector: 'pulse-nav-horizontal-collapse',
    templateUrl: './nav-horizontal-collapse.component.html',
    styleUrls: ['./nav-horizontal-collapse.component.scss']
})
export class PulseNavHorizontalCollapseComponent implements OnDestroy {

    isOpen = false;
    isLoggedIn: boolean = false;
    itemVisible: boolean = true;
    @HostBinding('class') classes = 'nav-item nav-collapsable';
    @Input() item: any;

    @HostListener('mouseenter')
    open() {
        this.isOpen = true;
    }

    @HostListener('mouseleave')
    close() {
        this.isOpen = false;
    }

    constructor(
        private loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router
    ) {

    }

    ngOnInit() {
        this.loginService.isLoggedInObj.subscribe(value => {
            this.isLoggedIn = value;
            if (value) {
                this.item.visible = true;
                const roles = localStorage.getItem('roleId');
                const userRoleAr = roles ? roles.split(',') : [];
                if (this.item && this.item.visibleFor && this.item.visibleFor.length > 0) {
                    this.item.visible = userRoleAr.filter(x => this.item.visibleFor.includes(x)).length > 0;
                }
            }
        });
    }

    ngOnDestroy() {
    }
}
