import { Injectable, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
@Injectable({
    providedIn: "root"
})
export class NotificationService {
    constructor(private router: Router) {
        if (Notification.permission !== "granted") {
            Notification.requestPermission();
        }
    }

    sendBrowserNotification(message: string, route: string) {
        if (
            (!document.hasFocus() || this.router.url !== route) &&
            Notification.permission === "granted"
        ) {
            console.log("inside notification")
            var notification = new Notification("Aptask", {
                icon: "assets/images/logo/ApTask-Logo.png",
                body: message
            });

            notification.onclick = function() {
                window.focus();
            };
        }
        return !document.hasFocus() || this.router.url !== route
    }
}
