<a class="nav-link" *ngIf="item.url && item.visible && item.title !== 'Github'" [routerLink]="[item.url]"
   routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" matRipple>
    <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
    <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
    <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
          [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
        {{item.badge.title}}
    </span>
</a>

<a class="nav-link" *ngIf="item.title === 'Github'" href="http://source.aptask.com" target="_blank" matRipple>
    <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
    <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
    <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
          [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
{{item.badge.title}}
    </span>
</a>
