import {Component, HostBinding, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';


@Component({
    selector: 'pulse-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class PulseContentComponent implements OnDestroy {
    // @HostBinding('@routerTransitionUp') routeAnimationUp = false;
    // @HostBinding('@routerTransitionDown') routeAnimationDown = false;
    // @HostBinding('@routerTransitionRight') routeAnimationRight = false;
    // @HostBinding('@routerTransitionLeft') routeAnimationLeft = false;
    // @HostBinding('@routerTransitionFade') routeAnimationFade = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {

    }

    ngOnDestroy() {
        // this.onConfigChanged.unsubscribe();
    }
}
