<div class="page-layout simple fullwidth user-profile">

    <!-- container -->
    <div class="container mt-12" fxLayout="row" fxLayoutGap="20px">
        <div class="content mat-white-bg mat-elevation-z4 " pulsePerfectScrollbar>
            <div class="content-wrapper" fxLayout="column">
                <div class="page-title-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading">
                        User List <span class="badge ng-star-inserted">{{ totalClient }}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" >
                        <!--<dx-text-box class="mx-8" placeholder="Search..."
                                     (onValueChanged)="searchData($event)" [valueChangeEvent] = "'keyup'"></dx-text-box>-->

                        <dx-button icon="fa fa-filter" matTooltip="Toggle Filter" class="pulse-btn has-icon"
                                   (onClick)="showFilter = !showFilter">
                        </dx-button>
                        <a class="pulse-btn pulse-btn-primary has-icon" fxLayout="row" fxLayoutAlign="center"
                           [routerLink]="'/user/add'" aria-label="Add User">
                            <mat-icon>add</mat-icon>
                        </a>
                        <dx-button icon="fa fa-envelope"
                                   (onClick)="onEmailBoxOpen()"
                                   matTooltip="Send Emails"
                                   [disabled]="selectedCandidates.length <= 0"
                                   class="pulse-btn pulse-btn-primary p-0">
                        </dx-button>
                    </div>
                </div>
                <div id="user-list" class="p-30 pt-15 pb-15">
                    <div id="data-grid-demo">
                        <dx-data-grid
                            [noDataText]="'No data available...'"
                            #gridContainer
                            [dataSource]="userDataSource"
                            keyExpr ='user_id'
                            [(selectedRowKeys)]="selectedCandidates"
                            [showBorders]="true">
                            <!--<dxo-editing
                                mode="popup"
                                [allowUpdating]="true">
                                <dxo-popup
                                    title="Employee Info"
                                    [showTitle]="true"
                                    [width]="700"
                                    [height]="345"
                                    [position]="{ my: 'top', at: 'top', of: window }">
                                </dxo-popup>
                            </dxo-editing>-->

                            <!--<dxo-search-panel [visible]="true"
                                              [width]="240"
                                              [highlightSearchText]="true"
                                              placeholder="Search...">
                            </dxo-search-panel>-->
                            <dxo-sorting mode="multiple"></dxo-sorting>
                            <dxo-selection mode="multiple" [allowSelectAll]="true"
                                           [showCheckBoxesMode]="'always'"></dxo-selection>
                            <dxo-filter-row [visible]="showFilter"></dxo-filter-row>
                            <dxo-header-filter
                                [visible]="true"></dxo-header-filter>
                            <dxi-column dataField="name" caption="Name" cellTemplate="nameTemplate" [allowHeaderFiltering]="false"></dxi-column>
                            <dxi-column dataField="email"  caption="Email" cellTemplate="emailTemplate" [allowHeaderFiltering]="false"></dxi-column>
                            <dxi-column dataField="ReportTo" [allowHeaderFiltering]="false"></dxi-column>
                            <dxi-column dataField="PrimaryRole" [allowHeaderFiltering]="false"></dxi-column>
                            <dxi-column dataField="User_Status" caption="Status" [allowHeaderFiltering]="false"></dxi-column>
                            <dxi-column dataField="sms_number_twillio" caption="Sms Number" [allowHeaderFiltering]="false"></dxi-column>
                            <dxi-column dataField="voip_number" caption="Voip Number" [allowHeaderFiltering]="false"></dxi-column>
<!--                            <dxi-column dataField="voip_ext" caption="Voip Ext"></dxi-column>-->
                            <dxi-column dataField="location" caption="Location">
                            </dxi-column>
                            <dxi-column dataField="sub_location" caption="Sub Location">
                            </dxi-column>
                            <dxi-column [width]="80" cellTemplate="cellTemplate" caption="Actions"></dxi-column>
                            <dxo-remote-operations
                                [sorting]="true"
                                [paging]="true"
                                [filtering]="true"
                            >
                            </dxo-remote-operations>
                            <dxo-paging [pageSize]="15"></dxo-paging>
                            <dxo-pager
                                [showPageSizeSelector]="true"
                                [allowedPageSizes]="[15, 30, 50, 100]"
                                [showInfo]="true"
                            ></dxo-pager>
                                <div class="grid-menu" *dxTemplate="let cell of 'cellTemplate'">
                                <div >
                                    <dx-menu [dataSource]="menuItems"
                                             displayExpr="name"
                                             (onItemClick)="optionClick($event, cell)"></dx-menu>
                                     <!-- (onItemClick)="selectedAction($event.itemData)"></dx-menu> -->
                                </div>
                            </div>
                            <div *dxTemplate="let cell of 'nameTemplate'">
                                <a [routerLink]="'/user/edit/'+cell.data.user_id" >{{cell.data.name}}</a>
                            </div>
                            <div *dxTemplate="let cell of 'emailTemplate'">
                                <a [routerLink]="'/user/edit/'+cell.data.user_id" >{{cell.data.email}}</a>
                            </div>
                        </dx-data-grid>
<!--                        <div class="buttons-container" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start end">-->
<!--                            <dx-button text="Send Mail"-->
<!--                                       class="pulse-btn pulse-btn-primary"-->
<!--                                       (onClick)="onEmailBoxOpen()"-->
<!--                                       matTooltip="Send Emails"-->
<!--                                       [useSubmitBehavior]="false"-->
<!--                                       [disabled]="selectedCandidates.length <= 0">-->
<!--                            </dx-button>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- container -->
</div>

<!--mail out model-->
<dx-popup class="popup" [width]="700" [height]="500" [showTitle]="true" title="Send Mail"
          [dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="sendEmailPopup">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view>
            <div class="p-4">
                <form class="add-user-form" (submit)="sendMail($event)">
                    <div id="form-container" class="mb-20">
                        <dx-form id="form" [(formData)]="emailFormData" [readOnly]="false" [showColonAfterLabel]="true"
                                 [showValidationSummary]="true" #sendMailForm
                                 [colCount]="1" labelLocation="top" validationGroup="templateFormData">

                            <!-- ==================================================================== -->

                            <dxi-item itemType="group" [colCount]="2">
                                <dxi-item dataField="frmTo" editorType="dxTagBox" [colSpan]="2"  [editorOptions]="{
                                                  items: allEmailList,
                                                  valueExpr: 'email',
                                                    displayExpr: 'consultantName',
                                                    multiline: true,
                                                    disabled: true,
                                                  searchEnabled: false }">
                                    <dxo-label text="To"></dxo-label>
                                    <dxi-validation-rule type="required" message="To Email is required">
                                    </dxi-validation-rule>
                                </dxi-item>
                                <dxi-item dataField="frmEmailTemplate" [colSpan]="1" editorType="dxSelectBox"
                                          [editorOptions]="{  items: templateNameList,
                                                            valueExpr: 'template_id',
                                                            displayExpr: 'template_name',
                                                            searchEnabled: true ,
                                                            onSelectionChanged: getEmailTemplateContent}">
                                    <dxo-label text="Email Template List"></dxo-label>
                                    <!--<dxi-validation-rule type="required" message="Email Template Type is required">-->
                                    <!--</dxi-validation-rule>-->
                                </dxi-item>

                                <dxi-item dataField="frmCC" [colSpan]="1" editorType="dxTagBox" [editorOptions]="{  items: usersList,
                                                  valueExpr: 'Email',
                                                  displayExpr: 'FullName',
                                                  searchEnabled: true }">
                                    <dxo-label text="CC"></dxo-label>
                                </dxi-item>
                                <dxi-item dataField="frmPlaceHolder" [colSpan]="1" editorType="dxSelectBox"
                                          [editorOptions]="{ items: placeHolderType ,onSelectionChanged:updateEmailContent}">
                                    <dxo-label text="Merge Field"></dxo-label>
                                </dxi-item>
                            </dxi-item>
                            <dxi-item dataField="frmSubject" [colSpan]="2">
                                <dxo-label text="Subject"></dxo-label>
                                <dxi-validation-rule type="required" message="Subject is required">
                                </dxi-validation-rule>
                            </dxi-item>

                            <dxi-item dataField="frmEmailContent" editorType="dxTextArea"
                                      [editorOptions]="{ height: 200, elementAttr: {id: 'emailTextArea'} }">
                                <dxo-label text="Email content"></dxo-label>
                                <dxi-validation-rule type="required" message="Email content is required">
                                </dxi-validation-rule>
                            </dxi-item>

                            <dxi-item dataField="frmAddSignature" editorType="dxCheckBox"
                                      [editorOptions]="{text: 'Add Signature', onValueChanged: addRemoveSignature}">
                                <dxo-label text="Add Signature" [visible]="false"></dxo-label>
                            </dxi-item>

                            <dxi-item dataField="frmEmailSignature" editorType="dxTextArea"
                                      [editorOptions]="{ height: 60, disabled: !emailFormData.frmAddSignature}">
                                <dxo-label text="Signature" [visible]="false"></dxo-label>
                            </dxi-item>

                        </dx-form>

                    </div>
                    <div fxLayoutAlign="space-between" class="text-left">
                        <dx-button text="Cancel" validationGroup="templateFormData" class="pulse-btn pulse-btn-normal"
                                   (onClick)="onCancel()">
                        </dx-button>
                        <dx-button text="Send" validationGroup="templateFormData" [useSubmitBehavior]="true"
                                   class="pulse-btn pulse-btn-primary">
                        </dx-button>
                    </div>
                </form>
            </div>
        </dx-scroll-view>
    </div>
</dx-popup>
<!--mail out model end-->

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#user-list' }"
               [(visible)]="loadingVisible"
               [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>
