import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {CommonModule} from '@angular/common';

import { PulseContentComponent } from 'app/main/content/content.component';
import { PulseSampleModule } from './sample/sample.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { TouchPointModule } from './touch-point/touch-point.module';
import { RecordRtcModule } from './record-rtc/record-rtc.module';
import { RbacModule } from './rbac/rbac.module';
import { PiplModule } from './pipl/pipl.module';
import { ReportsModule } from './reports/reports.module';
import { LinkedinSearchModule } from './linkedin-search/linkedin-search.module';
import { GitHubSearchModule } from './git-hub-search/git-hub-search.module';
import { UserModule } from './user/user.module';
import { SmbModule } from './smb/smb.module';
import { OnboardingModule } from './onboarding/onboarding.module';
import { JobPortalExtractModule } from './job-portal/job-portal-extract.module';
import { GithubEmailModule } from './github/github-email.module';

import { QcommModule } from './qcomm/qcomm.module';
import { EmailSearchModule } from './email-search/email-search.module';
import { VmsModule } from './vms/vms.module';
import { FivePercentileModule } from './five-percentile/five-percentile.module';
import { AuthGuardService as AuthGuard } from '../../services/auth/auth-guard.service';
import { VedioAudioDialogModule } from 'app/home/vedio-audio-dialog/vedio-audio-dialog.module';
import { JitsiVedioAudioDialogModule } from 'app/home/jitsi-vedio-audio-dialog/jitsi-vedio-audio-dialog.module';
import {ScraperAccountModule} from './scraper-account/scraper-account.module';
import { CalculatorComponent } from './calculator/calculator.component';
import {
    DxButtonModule,
    DxDataGridModule,
    DxFormModule,
    DxLoadPanelModule,
    DxSelectBoxModule,
    DxTabsModule
} from "devextreme-angular";
import {FlexModule} from "@angular/flex-layout";
import {PulseDirectivesModule} from "../../../@pulse/directives/directives";
import {DxiColumnModule, DxiRowModule} from "devextreme-angular/ui/nested";
import {TeamModule} from './team/team.module';

const routes: any = [
    {
        path: 'power-bi',
        loadChildren: () => import('./power-bi/power-bi.module').then(m => m.PowerBiModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'payroll',
        loadChildren: () => import('./payroll/payroll.module').then(m => m.PayrollModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'aiengage',
        loadChildren: () => import('./ai-engage/ai-engage.module').then(m => m.AiEngageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'payroll-qb',
        loadChildren: () => import('./payroll-qb/payroll-qb.module').then(m => m.PayrollQbModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'update-qb-status',
        loadChildren: () => import('./update-qb-status/update-qb-status.module').then(m => m.UpdateQbStatusModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'calculator',
        loadChildren: () => import('./calculator/calculator.module').then(m => m.CalculatorModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'audit-trail',
        loadChildren: () => import('./audit-trail/audit-trail.module').then(m => m.AuditTrailModule),
        canActivate: [AuthGuard]
    },
];


@NgModule({
    declarations: [
        PulseContentComponent,
        CalculatorComponent
    ],
    imports: [
        RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'}),
        CommonModule,
        PulseSampleModule,
        DashboardModule,
        TouchPointModule,
        RecordRtcModule,
        RbacModule,
        PiplModule,
        LinkedinSearchModule,
        GitHubSearchModule,
        UserModule,
        SmbModule,
        ReportsModule,
        JobPortalExtractModule,
        GithubEmailModule,
        OnboardingModule,
        QcommModule,
        EmailSearchModule,
        VmsModule,
        // WebnewchatModule,
        FivePercentileModule,
        VedioAudioDialogModule,
        JitsiVedioAudioDialogModule,
        ScraperAccountModule,
        DxButtonModule,
        DxFormModule,
        FlexModule,
        DxSelectBoxModule,
        DxTabsModule,
        PulseDirectivesModule,
        DxiColumnModule,
        DxDataGridModule,
        DxLoadPanelModule,
        DxiRowModule,
        TeamModule
    ],
    exports: [
        PulseContentComponent
    ]
})

export class PulseContentModule {
}
