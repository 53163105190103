import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import {PulseSharedModule} from '@pulse/shared.module';

import {CandidateChatComponent} from './candidate-chat.component';

const routes: Routes = [{
    path: 'candidate-chat/:code',
    component: CandidateChatComponent
}];


@NgModule({
    declarations: [
        CandidateChatComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        PulseSharedModule
    ]
})
export class CandidateChatModule {
}
