import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {AuthGuardService as AuthGuard} from 'app/services/auth/auth-guard.service';
import {RouterModule} from '@angular/router';

const routes = [{
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
}];

@NgModule({
    declarations: [DashboardComponent],
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        CommonModule
    ]
})
export class DashboardModule {
}
