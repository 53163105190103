<div class="page-layout simple fullwidth" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">

        <div class="content mat-white-bg mat-elevation-z4" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto">
                    <div class="pulse-main-heading" fxFlex="50">
                        Pending Approvals
                    </div>
                </div>

                <div class="p-30 pt-15 pb-15 grid-wrapper">
                    <!-- Refer the below link for datagrid of devextreme -->
                    <!--https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/-->
                    <dx-data-grid [dataSource]="pendingApprovals" [showBorders]="true" keyExpr="event_id"
                                  [masterDetail]="{ enabled: true, template: 'detail' }">
                        <dxi-column dataField="created_at" dataType="datetime" caption="Date time"
                                    [format]="dxDateTimeFormat"></dxi-column>
                        <dxi-column dataField="applied_by" caption="Applied By"></dxi-column>
                        <!--<dxi-column dataField="title" caption="Subject"></dxi-column>-->
                        <dxi-column dataField="eventType" caption="Event Type"></dxi-column>
                        <dxi-column dataField="reports_to" caption="Reporting Manager"></dxi-column>
                        <dxi-column dataField="approved_at" dataType="datetime" caption="Approved At" [format]="dxDateTimeFormat"></dxi-column>
                        <dxi-column dataField="status" caption="Status" cellTemplate="statusTemplate"></dxi-column>
                        <!--<dxi-column [width]="80" cellTemplate="cellTemplate" caption="Actions"></dxi-column>-->
                     <div *dxTemplate="let cell of 'statusTemplate'">
                         <span class="text-capitalize eventStatus {{cell.value}}">{{cell.value}}</span>
                     </div>
                        <div *dxTemplate="let record of 'detail'">

                            <div class="mat-white-bg mat-elevation-z4 p-20 dx-fieldset my-0" fxLayout="column">
                                <div class="dx-fieldset-header">Event Details</div>
                                <div fxLayout="column" fxLayoutGap="15px">
                                    <div class="dx-fieldset my-0" fxLayout="row">
                                        <div class="dx-field">
                                            <div class="dx-field-label">Applied By:</div>
                                            <div class="dx-field-value-static">{{record.data.applied_by}}</div>
                                        </div>
                                        <div class="dx-field">
                                            <div class="dx-field-label">Event Type:</div>
                                            <div class="dx-field-value-static">{{record.data.eventType}}</div>
                                        </div>

                                        <div class="dx-field">
                                            <div class="dx-field-label">RM:</div>
                                            <div class="dx-field-value-static">
                                                {{record.data.reports_to || 'NA'}}
                                            </div>
                                        </div>
                                        <div class="dx-field">
                                            <div class="dx-field-label">Status:</div>
                                            <div class="dx-field-value-static text-capitalize eventStatus {{record.data.status}}">{{record.data.status || 'Pending'}}</div>
                                        </div>
                                    </div>
                                    <div class="dx-fieldset my-0" fxLayout="row">
                                        <div class="dx-field">
                                            <div class="dx-field-label">Is All Day:</div>
                                            <div class="dx-field-value-static">{{record.data.is_all_day ? 'Yes' :
                                                'No'}}
                                            </div>
                                        </div>
                                        <div class="dx-field">
                                            <div class="dx-field-label">Start Date:</div>
                                            <div class="dx-field-value-static">{{record.data.start | date:
                                                dateTimeFormat}}
                                            </div>
                                        </div>
                                        <div class="dx-field">
                                            <div class="dx-field-label">End Date:</div>
                                            <div class="dx-field-value-static">{{record.data.end | date:
                                                dateTimeFormat}}
                                            </div>
                                        </div>
                                        <div class="dx-field" *ngIf="record.data.company_name">
                                            <div class="dx-field-label">Company:</div>
                                            <div class="dx-field-value-static">{{record.data.company_name || 'NA'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dx-fieldset my-0" fxLayout="row">
                                        <div class="dx-field">
                                            <div class="dx-field-label">Approved By:</div>
                                            <div class="dx-field-value-static">{{record.data.approved_by || 'NA'}}</div>
                                        </div>
                                        <div class="dx-field">
                                            <div class="dx-field-label">Approved At:</div>
                                            <div class="dx-field-value-static">{{(record.data.approved_at | date:
                                                dateTimeFormat) || 'NA'}}
                                            </div>
                                        </div>
                                        <div class="dx-field" *ngIf="record.data.contactDetails">
                                            <div class="dx-field-label">Contact:</div>
                                            <div class="dx-field-value-static">
                                                {{record.data.contactDetails && record.data.contactDetails.first_name
                                                + ' ' + record.data.contactDetails.last_name || 'NA'}}
                                            </div>
                                        </div>
                                        <div class="dx-field" *ngIf="record.data.meal_cost">
                                            <div class="dx-field-label">Meal Cost:</div>
                                            <div class="dx-field-value-static">{{record.data.meal_cost || 'NA'}}</div>
                                        </div>

                                    </div>

                                    <div class="dx-fieldset my-0" fxLayout="row">
                                        <div class="dx-field" *ngIf="record.data.miles">
                                            <div class="dx-field-label">Miles:</div>
                                            <div class="dx-field-value-static">
                                                {{record.data.miles || 'NA'}}
                                            </div>
                                        </div>

                                        <div class="dx-field" *ngIf="record.data.comments">
                                            <div class="dx-field-label">Notes:</div>
                                            <div class="dx-field-value-static">{{record.data.comments || 'NA'}}</div>
                                        </div>
                                        <div class="dx-field w-50-p">
                                            <div class="dx-field-label">Remarks:</div>
                                            <div class="dx-field-value-static">{{record.data.remarks || 'NA'}}</div>
                                        </div>
                                    </div>

                                </div>
                                <div fxLayout="row" fxLayoutAlign="end end">
                                    <dx-button text="Respond" class="pulse-btn pulse-btn-primary"
                                               (onClick)="openUpdateEventPopup($event, record.data)">
                                    </dx-button>
                                </div>
                            </div>

                        </div>

                    </dx-data-grid>
                </div>
            </div>
        </div>

    </div>
    <!-- container -->

</div>

<dx-popup class="popup" [width]="400" [height]="260" [showTitle]="true" title="Update pending approval"
          [dragEnabled]="false"
          [closeOnOutsideClick]="true"
          [(visible)]="updateEventPopup">
    <div *dxTemplate="let data of 'content'">

        <dx-form [formData]="updateEventData" [colCount]="1" [labelLocation]="'top'" [showColonAfterLabel]="true">
            <dxi-item editorType="dxTextArea" dataField="remarks"
                      [editorOptions]="{placeholder: 'Write remark for rejection or approval.', height:'80px'}">
                <!--<dxi-validation-rule type="required" message="Remarks is required."></dxi-validation-rule>-->
            </dxi-item>
        </dx-form>
        <div class="mt-20" fxLayout="row" fxLayoutAlign="space-between center">
            <dx-button text="Cancel" class="pulse-btn pulse-btn-normal" (onClick)="onCancel($event)"></dx-button>
            <div fxLayout="row">
                <dx-button text="Reject" class="pulse-btn pulse-btn-primary"
                           (onClick)="updateRequest('reject')"></dx-button>
                <dx-button text="Approve" class="pulse-btn pulse-btn-secondary ml-8"
                           (onClick)="updateRequest('approve')"></dx-button>
            </div>
        </div>
    </div>
</dx-popup>
