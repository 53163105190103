import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { PulseSharedModule } from '@pulse/shared.module';
import { JitsiVedioAudioDialogComponent } from './jitsi-vedio-audio-dialog.component';

@NgModule({
    declarations: [
        JitsiVedioAudioDialogComponent
    ],
    imports: [

        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,

        PulseSharedModule
    ],
    exports: [
        JitsiVedioAudioDialogComponent
    ]
})
export class JitsiVedioAudioDialogModule {
}
