import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "textFormatter"
})
export class TextFormatterPipe implements PipeTransform {
  linkGenerate(inputText) {
    if (inputText) {
      var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      var replacedText = inputText.replace(
        replacePattern1,
        "<a style='overflow-wrap: break-word;' href='$1' target='_blank'>$1</a>"
      );

      //URLs starting with www. (without // before it, or it'd re-link the ones done above)
      var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      var replacedText = replacedText.replace(
        replacePattern2,
        "$1<a style='overflow-wrap: break-word;' href='http://$2' target='_blank'>$2</a>"
      );

      //Change email addresses to mailto:: links
      var replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
      var replacedText = replacedText.replace(
        replacePattern3,
        "<a style='overflow-wrap: break-word;' href='mailto:$1'>$1</a>"
      );
      return replacedText;
    } else {
      return inputText;
    }
  }
  hightLight(text, searchTextGlobal) {
    if (text) {
      let htmlText = text;
      let searchText = searchTextGlobal;
      var index = htmlText.toLowerCase().indexOf(searchText.toLowerCase());
      if (index >= 0 && searchTextGlobal) {
        htmlText =
            htmlText.substring(0, index) +
            "<mark class=highlighter>" +
            htmlText.substring(index, index + searchText.length) +
            "</mark>" +
            htmlText.substring(index + searchText.length);
        return htmlText;
      } else return htmlText;
    } else return text;
  }
  transform(value: any, searchTextGlobal: any): any {
    if (value) {
      if(searchTextGlobal){
        return this.hightLight(this.linkGenerate(value), searchTextGlobal);
      }else{
        return this.linkGenerate(value);
      }
    } else {
      return value;
    }
  }
}
