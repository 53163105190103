<div id="forms" class="page-layout simple fullwidth" fxLayout="column" fusePerfectScrollbar>
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto"
                     fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading" fxFlex="92">
                        Team List <span class="badge ng-star-inserted">{{ totalTeams }}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                        <dx-button icon="fa fa-filter" matTooltip="Toggle Filter" class="pulse-btn has-icon"
                                   (onClick)="showFilter = !showFilter">
                        </dx-button>
                        <dx-button icon="fa fa-plus" matTooltip="Add Team" class="pulse-btn has-icon"
                                   (onClick)="openAddModel()">
                        </dx-button>
                        <!--                        <a class="pulse-btn pulse-btn-primary has-icon" fxLayout="row" fxLayoutAlign="center"-->
                        <!--                           [routerLink]="'/team/add'" matTooltip="Add Team">-->
                        <!--                            <mat-icon>add</mat-icon>-->
                        <!--                        </a>-->
                    </div>
                </div>

                <div class="p-30 pt-15 pb-15 grid-wrapper">
                    <dx-data-grid
                        [dataSource]="teamDataSource"
                        [showRowLines]="true"
                        [showColumnLines]="false"
                        [showBorders]="true"
                        [rowAlternationEnabled]="true"
                        [allowColumnResizing]="true">
                        <dxo-sorting mode="multiple"></dxo-sorting>
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager
                            [showPageSizeSelector]="true"
                            [allowedPageSizes]="[10, 15, 25, 100, 500]"
                            [showInfo]="true">
                        </dxo-pager>

                        <dxi-column dataField="name" dataType="string" caption="Name"></dxi-column>
                        <dxi-column dataField="team_lead_id" dataType="string" caption="Team Lead"></dxi-column>
                        <dxi-column [allowSorting]="false" [allowFiltering]="false" [width]="80" dataField="Action"
                                    dataType="string"
                                    cellTemplate="cellTemplate"></dxi-column>

                        <dxo-filter-row [visible]="showFilter"></dxo-filter-row>

                        <div class="grid-menu" *dxTemplate="let cell of 'cellTemplate'">
                            <div>
                                <dx-menu [dataSource]="menuItems"
                                         displayExpr="name"
                                         (onItemClick)="optionClick($event, cell)"></dx-menu>
                            </div>
                        </div>
                    </dx-data-grid>
                </div>
            </div>
        </div>
    </div>
</div>

<dx-popup class="popup" [showTitle]="true" [width]="500" [height]="210"
          title="Add New Team" [dragEnabled]="false" [closeOnOutsideClick]="false"
          [(visible)]="addPopup">
    <dx-scroll-view direction="both" class="popup_scroll_container">
        <form class="popup_scroll_form" (submit)="onFormSubmit()">
            <div id="client-form-container">
                <dx-form #teamPopupForm [formData]="frmTeamData"
                         [readOnly]="false"
                         [showColonAfterLabel]="true" [showValidationSummary]="true"
                         [colCount]="1" labelLocation="top" validationGroup="contactData">
                    <dxi-item [colCount]="1" [colSpan]="12" itemType="group">
                        <dxi-item itemType="group" [colCount]="2">
                            <dxi-item dataField="frmName">
                                <dxo-label text="Name"></dxo-label>
                                <dxi-validation-rule
                                    type="required"
                                    message="Name is required">
                                </dxi-validation-rule>
                            </dxi-item>
                            <dxi-item dataField="frmLeadId"
                                      editorType="dxSelectBox"
                                      [editorOptions]="{
                                          items: userList,
                                          valueExpr: 'ID',
                                          displayExpr: 'Name',
                                          searchEnabled: true}">
                                <dxo-label text="Team Lead"></dxo-label>
                            </dxi-item>
                        </dxi-item>
                    </dxi-item>
                </dx-form>
            </div>
            <div class="actions-buttons my-8" fxLayout="row" fxLayoutAlign="space-between center">
                <dx-button
                    text="Cancel"
                    class="pulse-btn pulse-btn-normal"
                    (onClick)="addPopup = false"
                >
                </dx-button>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
                    <dx-button text="Save"
                               validationGroup="customerData"
                               class="pulse-btn pulse-btn-primary"
                               [useSubmitBehavior]="true">
                    </dx-button>
                </div>
            </div>
        </form>
    </dx-scroll-view>
</dx-popup>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#forms' }"
               [(visible)]="loadingVisible"
               [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>
