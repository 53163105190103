<div class="home-container" fxLayout="column">
    <div class="chat-screen">
        <!-- END OF SIDEBAR -->
        <div class="chat">
            <!-- BEGINING OF MESSAGE-->
            <div class="container fixed rev">
                <div class="chat-box" #scrollMe style="height: calc(100vh - 173px); width: 100%; overflow-y: auto; padding: 0px 10px;">
                    <div *ngFor="let dateBy of _Object.values(chatsByDate)">
                        <div class="date-display"><label>{{dateBy.date}}</label></div>
                        <div *ngFor="let item of dateBy.data" style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-{{(item.fromUserId === fromUserId && item.from_type === fromUserType) ? 'right' : 'left'}}">
                                <div class="message">
                                    <div *ngIf="!item.media">{{item.message}}</div>
                                    <audio *ngIf="item.media" preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source [src]="item.media.src" type="audio/wav">
                                    </audio>
                                    <div class="message-time {{item.media && 'audio-time'}}">
                                        <div style="font-size: 10px; margin-left: 10px;">{{_moment.utc(item.created_at).local().format(TIME_FORMAT)}}</div>
                                        <span style="margin-left: 5px" *ngIf="(item.fromUserId === fromUserId && item.from_type === fromUserType)">
                                            <i *ngIf="item.message_status == 0" class="far fa-clock status-icon" aria-hidden="true"></i>
                                            <i *ngIf="item.message_status == 1 || item.message_status == 2" class="fa fa-check status-icon {{item.message_status == 2 && 'status-read'}}" aria-hidden="true"></i>
                                            <i *ngIf="item.message_status == 2" class="fa fa-check status-icon status-read" style="margin-left: -5px" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--<div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right">
                                <div class="message">
                                    <div>TEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBoradTEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBorad
                                    </div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">10:25 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(9, 102, 194);">
                                            <span role="img" aria-label="check" class="anticon anticon-check">
                                                <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-11-12%3A44%3A24-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">6:14 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                            <span role="img" aria-label="check" class="anticon anticon-check">
                                                <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>

                    <!--<div>
                        <div class="date-display"><label>12-03-2021</label></div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-08%3A26%3A16-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 10px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">1:56 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);"><span role="img" aria-label="check" class="anticon anticon-check"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span></span></div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-09%3A54%3A05-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">3:24 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                            <span role="img" aria-label="check" class="anticon anticon-check">
                                                <svg width="8px" height="8px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 422.686 422.686" style="enable-background:new 0 0 422.686 422.686;" xml:space="preserve"><path style="fill:#010002;" d="M211.343,0C94.812,0,0,94.812,0,211.343c0,116.539,94.812,211.343,211.343,211.343 c116.539,0,211.343-94.804,211.343-211.343C422.686,94.812,327.882,0,211.343,0z M211.343,406.429 c-107.565,0-195.086-87.52-195.086-195.086s87.52-195.086,195.086-195.086s195.086,87.52,195.086,195.086 S318.908,406.429,211.343,406.429z"/><polygon style="fill:#010002;" points="214.854,62.322 198.597,62.322 198.597,219.472 301.643,219.472 301.643,203.214 214.854,203.214"/></svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>24-02-2021</label></div>
                        <div  style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-left">
                                <div class="message">
                                    <div>Ok cool</div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">9:56 PM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right">
                                <div class="message">
                                    <div>TEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBoradTEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBorad
                                    </div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">10:25 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(9, 102, 194);">
                                            <span role="img" aria-label="check" class="anticon anticon-check">
                                                <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-11-12%3A44%3A24-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">6:14 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                            <span role="img" aria-label="check" class="anticon anticon-check">
                                                <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>12-03-2021</label></div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-08%3A26%3A16-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 10px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">1:56 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);"><span role="img" aria-label="check" class="anticon anticon-check"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span></span></div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-09%3A54%3A05-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">3:24 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                            <span role="img" aria-label="check" class="anticon anticon-check">
                                                <svg width="8px" height="8px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 422.686 422.686" style="enable-background:new 0 0 422.686 422.686;" xml:space="preserve"><path style="fill:#010002;" d="M211.343,0C94.812,0,0,94.812,0,211.343c0,116.539,94.812,211.343,211.343,211.343 c116.539,0,211.343-94.804,211.343-211.343C422.686,94.812,327.882,0,211.343,0z M211.343,406.429 c-107.565,0-195.086-87.52-195.086-195.086s87.52-195.086,195.086-195.086s195.086,87.52,195.086,195.086 S318.908,406.429,211.343,406.429z"/><polygon style="fill:#010002;" points="214.854,62.322 198.597,62.322 198.597,219.472 301.643,219.472 301.643,203.214 214.854,203.214"/></svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>24-02-2021</label></div>
                        <div  style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-left">
                                <div class="message">
                                    <div>Ok cool</div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">9:56 PM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right">
                                <div class="message">
                                    <div>TEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBoradTEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBorad
                                    </div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">10:25 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(9, 102, 194);">
                                                <span role="img" aria-label="check" class="anticon anticon-check">
                                                    <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-11-12%3A44%3A24-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">6:14 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                                <span role="img" aria-label="check" class="anticon anticon-check">
                                                    <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>12-03-2021</label></div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-08%3A26%3A16-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 10px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">1:56 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);"><span role="img" aria-label="check" class="anticon anticon-check"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span></span></div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-09%3A54%3A05-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">3:24 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                            <span role="img" aria-label="check" class="anticon anticon-check">
                                                <svg width="8px" height="8px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 422.686 422.686" style="enable-background:new 0 0 422.686 422.686;" xml:space="preserve"><path style="fill:#010002;" d="M211.343,0C94.812,0,0,94.812,0,211.343c0,116.539,94.812,211.343,211.343,211.343 c116.539,0,211.343-94.804,211.343-211.343C422.686,94.812,327.882,0,211.343,0z M211.343,406.429 c-107.565,0-195.086-87.52-195.086-195.086s87.52-195.086,195.086-195.086s195.086,87.52,195.086,195.086 S318.908,406.429,211.343,406.429z"/><polygon style="fill:#010002;" points="214.854,62.322 198.597,62.322 198.597,219.472 301.643,219.472 301.643,203.214 214.854,203.214"/></svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>24-02-2021</label></div>
                        <div  style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-left">
                                <div class="message">
                                    <div>Ok cool</div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">9:56 PM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right">
                                <div class="message">
                                    <div>TEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBoradTEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBorad
                                    </div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">10:25 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(9, 102, 194);">
                                                <span role="img" aria-label="check" class="anticon anticon-check">
                                                    <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-11-12%3A44%3A24-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">6:14 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                                <span role="img" aria-label="check" class="anticon anticon-check">
                                                    <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>12-03-2021</label></div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-08%3A26%3A16-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 10px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">1:56 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);"><span role="img" aria-label="check" class="anticon anticon-check"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span></span></div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-09%3A54%3A05-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">3:24 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                            <span role="img" aria-label="check" class="anticon anticon-check">
                                                <svg width="8px" height="8px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 422.686 422.686" style="enable-background:new 0 0 422.686 422.686;" xml:space="preserve"><path style="fill:#010002;" d="M211.343,0C94.812,0,0,94.812,0,211.343c0,116.539,94.812,211.343,211.343,211.343 c116.539,0,211.343-94.804,211.343-211.343C422.686,94.812,327.882,0,211.343,0z M211.343,406.429 c-107.565,0-195.086-87.52-195.086-195.086s87.52-195.086,195.086-195.086s195.086,87.52,195.086,195.086 S318.908,406.429,211.343,406.429z"/><polygon style="fill:#010002;" points="214.854,62.322 198.597,62.322 198.597,219.472 301.643,219.472 301.643,203.214 214.854,203.214"/></svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>24-02-2021</label></div>
                        <div  style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-left">
                                <div class="message">
                                    <div>Ok cool</div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">9:56 PM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right">
                                <div class="message">
                                    <div>TEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBoradTEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBorad
                                    </div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">10:25 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(9, 102, 194);">
                                                <span role="img" aria-label="check" class="anticon anticon-check">
                                                    <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-11-12%3A44%3A24-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">6:14 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                                <span role="img" aria-label="check" class="anticon anticon-check">
                                                    <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>12-03-2021</label></div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-08%3A26%3A16-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 10px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">1:56 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);"><span role="img" aria-label="check" class="anticon anticon-check"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span></span></div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-09%3A54%3A05-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">3:24 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                            <span role="img" aria-label="check" class="anticon anticon-check">
                                                <svg width="8px" height="8px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 422.686 422.686" style="enable-background:new 0 0 422.686 422.686;" xml:space="preserve"><path style="fill:#010002;" d="M211.343,0C94.812,0,0,94.812,0,211.343c0,116.539,94.812,211.343,211.343,211.343 c116.539,0,211.343-94.804,211.343-211.343C422.686,94.812,327.882,0,211.343,0z M211.343,406.429 c-107.565,0-195.086-87.52-195.086-195.086s87.52-195.086,195.086-195.086s195.086,87.52,195.086,195.086 S318.908,406.429,211.343,406.429z"/><polygon style="fill:#010002;" points="214.854,62.322 198.597,62.322 198.597,219.472 301.643,219.472 301.643,203.214 214.854,203.214"/></svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>24-02-2021</label></div>
                        <div  style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-left">
                                <div class="message">
                                    <div>Ok cool</div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">9:56 PM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right">
                                <div class="message">
                                    <div>TEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBoradTEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBorad
                                    </div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">10:25 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(9, 102, 194);">
                                                <span role="img" aria-label="check" class="anticon anticon-check">
                                                    <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-11-12%3A44%3A24-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">6:14 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                                <span role="img" aria-label="check" class="anticon anticon-check">
                                                    <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>12-03-2021</label></div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-08%3A26%3A16-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 10px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">1:56 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);"><span role="img" aria-label="check" class="anticon anticon-check"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span></span></div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-09%3A54%3A05-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">3:24 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                            <span role="img" aria-label="check" class="anticon anticon-check">
                                                <svg width="8px" height="8px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 422.686 422.686" style="enable-background:new 0 0 422.686 422.686;" xml:space="preserve"><path style="fill:#010002;" d="M211.343,0C94.812,0,0,94.812,0,211.343c0,116.539,94.812,211.343,211.343,211.343 c116.539,0,211.343-94.804,211.343-211.343C422.686,94.812,327.882,0,211.343,0z M211.343,406.429 c-107.565,0-195.086-87.52-195.086-195.086s87.52-195.086,195.086-195.086s195.086,87.52,195.086,195.086 S318.908,406.429,211.343,406.429z"/><polygon style="fill:#010002;" points="214.854,62.322 198.597,62.322 198.597,219.472 301.643,219.472 301.643,203.214 214.854,203.214"/></svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>24-02-2021</label></div>
                        <div  style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-left">
                                <div class="message">
                                    <div>Ok cool</div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">9:56 PM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right">
                                <div class="message">
                                    <div>TEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBoradTEst SMS content1
                                        (732) 947-3480

                                        brijeshb@aptask.com

                                        (732) 947-3480

                                        BrijeshBorad
                                    </div>
                                    <div class="message-time">
                                        <div style="font-size: 10px; margin-left: 10px;">10:25 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(9, 102, 194);">
                                                <span role="img" aria-label="check" class="anticon anticon-check">
                                                    <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-11-12%3A44%3A24-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">6:14 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                                <span role="img" aria-label="check" class="anticon anticon-check">
                                                    <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span><span role="img" aria-label="check" class="anticon anticon-check" style="margin-left: -5px;"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="date-display"><label>12-03-2021</label></div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-08%3A26%3A16-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 10px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">1:56 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);"><span role="img" aria-label="check" class="anticon anticon-check"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span></span></div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both; display: table; width: 100%;">
                            <div class="chat-message-right audio-message">
                                <div class="message">
                                    <audio preload="auto" controls="" style="height: 26px; display: flex; margin-left: -5px;">
                                        <source src="https://pulse2videos.s3.amazonaws.com/ACE/sms-audio/brijeshb%40yopmail.com/2021-03-12-09%3A54%3A05-7325646620.mp3" type="audio/wav">
                                    </audio>
                                    <div class="message-time" style=" justify-content: flex-end; position: absolute; right: 11px; bottom: 7px;">
                                        <div style="font-size: 10px; margin-left: 10px;">3:24 PM</div>
                                        <span style="margin-left: 5px; font-size: 8px; color: rgb(62, 62, 62);">
                                            <span role="img" aria-label="check" class="anticon anticon-check">
                                                <svg width="8px" height="8px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 422.686 422.686" style="enable-background:new 0 0 422.686 422.686;" xml:space="preserve"><path style="fill:#010002;" d="M211.343,0C94.812,0,0,94.812,0,211.343c0,116.539,94.812,211.343,211.343,211.343 c116.539,0,211.343-94.804,211.343-211.343C422.686,94.812,327.882,0,211.343,0z M211.343,406.429 c-107.565,0-195.086-87.52-195.086-195.086s87.52-195.086,195.086-195.086s195.086,87.52,195.086,195.086 S318.908,406.429,211.343,406.429z"/><polygon style="fill:#010002;" points="214.854,62.322 198.597,62.322 198.597,219.472 301.643,219.472 301.643,203.214 214.854,203.214"/></svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->

                </div>

            </div>
            <!-- END OF MESSAGE -->

            <!-- BEGINING OF INPUT BOTTOM -->
            <footer>
                <div style="padding-top: 10px; padding-bottom: 10px; background-color: #f1f1f1; border-top: 1px solid #cccccc;">
                    <div style="margin: 10px; display: flex;">
                        <textarea [disabled]="textareaDisable" placeholder="{{recordingDuration ? 'Recording ' + recordingDuration : 'Message'}}" type="text" class="ant-input" [(ngModel)]="textMessage" style="width: calc(100% - 53px); padding: 8px 10px; min-height: 40px; height: 40px; font-size: 14px; border: 1px solid #d9d9d9;"></textarea>
                        <button *ngIf="textMessage && textMessage.trim()" [disabled]="sendDisable" type="button" (click)="sendMessage()" class="ant-btn audio-btn ant-btn-primary" style="margin-left: 10px; height: 40px; width: 42px;">
                            <img style="width: 20px;" src="assets/images/send-ico-white.svg">
                        </button>
                        <button *ngIf="!(textMessage || textMessage.trim())" type="button" (click)="startRecord()" class="ant-btn audio-btn ant-btn-primary" style="margin-left: 10px; height: 40px; width: 42px;">
                            <i *ngIf="!recording" class="fa fa-microphone" aria-hidden="true" style="font-size: 20px"></i>
                            <i *ngIf="recording" class="fa fa-stop" aria-hidden="true" style="font-size: 20px"></i>
                        </button>
                    </div>
                </div>
            </footer>
            <!-- END OF INPUT BOTTOM-->
        </div>
    </div>
</div>
