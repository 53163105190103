import {Component, OnInit} from '@angular/core';

import {PulseTranslationLoaderService} from '@pulse/services/translation-loader.service';

import {locale as english} from './i18n/en';
import {locale as turkish} from './i18n/tr';

@Component({
    selector: 'pulse-sample',
    templateUrl: './sample.component.html',
    styleUrls: ['./sample.component.scss']
})
export class PulseSampleComponent implements OnInit {
    formData = {
        firstName: '',
        lastName: '',
        email: '',
    };
    customers = [{
        ID: 1,
        Name: 'Person 1',
        CompanyName: 'Super Mart of the West',
        City: 'Bentonville',
        State: 'Arkansas'
    }, {
        ID: 2,
        Name: 'Person 2',
        CompanyName: 'Electronics Depot',
        City: 'Atlanta',
        State: 'Georgia'
    }];

    constructor(private pulseTranslationLoader: PulseTranslationLoaderService) {
        this.pulseTranslationLoader.loadTranslations(english, turkish);
    }

    ngOnInit() {
    }

    onFormSubmit = (e) => {
        console.log(this.formData);
    }
}
