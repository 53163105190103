import { CommonModule, registerLocaleData } from "@angular/common";
import { AuthGuardService as AuthGuard } from "app/services/auth/auth-guard.service";
import { RouterModule } from "@angular/router";
import { EmailSearchComponent } from "./email-search.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SidePanelComponent } from "./components/side-panel/side-panel.component";
// import { HomeComponent } from "./components/home/home.component";
import { ContentListingComponent } from "./components/content-listing/content-listing.component";
import { ContentPreviewComponent } from "./components/content-preview/content-preview.component";
import { SearchComponent } from "./components/search/search.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import en from "@angular/common/locales/en";
import { DateFormatterPipe } from "./pipes/date-formatter.pipe";
import { TextFormatterPipe } from "./pipes/text-formatter.pipe";
import { FileSizeFormatterPipe } from "./pipes/fileSize-formatter.pipe";
import { TextLengthFormatterPipe } from "./pipes/text-length-formatter.pipe";
import { EmailListService } from "../../../services/emailSearch/emailList.service";
import { ListServiceService } from "../../../services/emailSearch/list-service.service";
import { AngularSplitModule } from "angular-split";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {NzCollapseModule} from "ng-zorro-antd/collapse";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {NzPopoverModule} from "ng-zorro-antd/popover";


registerLocaleData(en);
const routes = [
    {
        path: "email-search",
        component: EmailSearchComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        EmailSearchComponent,
        SidePanelComponent,
        ContentListingComponent,
        ContentPreviewComponent,
        SearchComponent,
        DateFormatterPipe,
        TextFormatterPipe,
        FileSizeFormatterPipe,
        TextLengthFormatterPipe
    ],
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        CommonModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        AngularSplitModule.forRoot(),
        NzTableModule,
        NzCheckboxModule,
        NzCollapseModule,
        NzRadioModule,
        NzPopoverModule
    ],
    providers: [
        ListServiceService,
        EmailListService
    ]
})
export class EmailSearchModule {}
