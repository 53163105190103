import { NgModule } from '@angular/core';

import { PulsePerfectScrollbarDirective} from './pulse-perfect-scrollbar/pulse-perfect-scrollbar.directive';
import { PulseMatSidenavHelperDirective, PulsMatSidenavTogglerDirective} from './pulse-mat-sidenav/pulse-mat-sidenav.directive';

@NgModule({
    declarations: [
      PulsePerfectScrollbarDirective,
        PulseMatSidenavHelperDirective,
        PulsMatSidenavTogglerDirective
    ],
    imports     : [],
    exports     : [
      PulsePerfectScrollbarDirective,
        PulseMatSidenavHelperDirective,
        PulsMatSidenavTogglerDirective
    ]
})
export class PulseDirectivesModule
{
}
