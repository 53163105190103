import { Injectable } from "@angular/core";
import * as io from "socket.io-client";
import { HttpClient } from "@angular/common/http";
import {
    Router,
    ActivatedRoute,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ChatManager, TokenProvider } from "@pusher/chatkit-client";
import { PulseUtils } from "@pulse/utils";
import { siteConfig } from "../../siteSettings";
import { CommonService } from "../common/common.service";
import { NotificationService } from "@pulse/services/notification.service";
const PUSHER_INSTANCE_LOCATOR = siteConfig.pusherInstanceLocator;
export interface UserDetails {
    name: string;
    email: string;
    firstName: string;
    lastName: string;
}
export interface AdminDetails {
    first_name: string;
    email: string;
    last_name: string;
    mobile_no: string;
    voip_number: string
    name: string;
    photo: string;
    video_intro_url: string;
    signature: string;
    social_link: string;
    calendly_link: string;
}

export interface ChatRoom {
    created_at: Date;
    created_by_id: string;
    id: string;
    member_user_ids: string[];
    name: string;
    private: boolean;
    updated_at: Date;
}

export interface Message {
    [index: number]: {
        id: number;
        senderId: string;
        roomId: string;
        createdAt: Date;
        updatedAt: Date;
        text: string;
    };
}

export interface ErrorSchema {
    isError: boolean;
    message: string;
}

@Injectable({
    providedIn: "root"
})
export class MeetupService {
    name: string;
    messageLimit: number = 20;
    email: string;
    id: string;
    adminUserId: string;
    chatManager: any;
    messagesList: any[] = [];
    adminUserStatus: boolean = false;
    currentUserPusher: any;
    public adminDetails: BehaviorSubject<AdminDetails> = new BehaviorSubject<
        AdminDetails
    >({
        name: "...",
        email: "...",
        first_name: "...",
        last_name: "...",
        mobile_no: "...",
        photo: "...",
        video_intro_url: null,
        signature: "...",
        voip_number: "...",
        social_link: "...",
        calendly_link: "..."
    });
    public clientDetails: BehaviorSubject<UserDetails> = new BehaviorSubject<
        UserDetails
    >({ name: "", email: "", firstName: "", lastName: "" });
    public chatRoom: BehaviorSubject<ChatRoom> = new BehaviorSubject<ChatRoom>(
        null
    );
    public messageStatus = new BehaviorSubject(null);
    public chatStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    public messageList$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public clientInfoLoading$: BehaviorSubject<boolean> = new BehaviorSubject<
        boolean
    >(false);
    public adminUserStatus$: BehaviorSubject<boolean> = new BehaviorSubject<
        boolean
    >(false);
    public errorInSendingMessage$: BehaviorSubject<
        ErrorSchema
    > = new BehaviorSubject<ErrorSchema>({ isError: false, message: "" });
    public messageListLoading$: BehaviorSubject<boolean> = new BehaviorSubject<
        boolean
    >(false);
    public welcomeMessage$: BehaviorSubject<string> = new BehaviorSubject<
        string
    >(null);
    public errorOccured$: BehaviorSubject<boolean> = new BehaviorSubject<
        boolean
    >(false);
    public inComingCall$: BehaviorSubject<boolean> = new BehaviorSubject<
        boolean
    >(false);
    constructor(
        private _httpClient: HttpClient,
        private notificationService: NotificationService,
        private router: Router,
        private route: ActivatedRoute,
        private commonSerivce: CommonService
    ) {
        this.clientDetails.subscribe((data: UserDetails) => {
            if (data.name.length) {
                this.getChats(data).subscribe(
                    response => {
                        this.chatRoom.next(response);
                    },
                    error => {
                        this.errorOccured$.next(true);
                    }
                );
            }
        });
        this.chatRoom.subscribe((data: ChatRoom) => {
            if (data) {
                this.pusherInitiate();
            }
        });
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.id = route.paramMap.get("code");
        // debugger;
        this.setAdminUserId(this.id);
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getUserDetails(this.id)
                // this.getWelcomeMessage(this.id)
            ]).then(([details]) => {
                this.adminDetails.next(details);
                resolve();
            }, reject);
        });
    }

    getChats(data: UserDetails) {
        // resolve(ChatFakeDb.chats);
        const url = `${siteConfig.nodeAPIBaseUrl}pusher/session/load`;
        const payLoad = {
            name: data.name,
            email: data.email,
            pusherID: this.adminUserId,
            firstName: data.firstName,
            lastName: data.lastName
        };
        return this._httpClient.post(url, { ...payLoad }).pipe(
            map((response: any) => {
                return response;
            }),
            catchError(err => {
                throw err;
            })
        );
    }
    getUserDetails(id: string): Promise<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}pusher/getRecruiterDetails?pusherId=${id}`;
        // const url = `http://localhost:7050/api/2.0/pusher/getRecruiterDetails?pusherId=${id}`;
        return new Promise((resolve, reject) => {
            this._httpClient.get(url).subscribe(
                (data: any) => {
                    resolve(data);
                },
                err => {
                    debugger;
                }
            );
        });
    }
    setUserDetails(data: UserDetails) {
        this.clientDetails.next(data);
    }
    setAdminUserId(id: string) {
        this.adminUserId = id;
    }
    pusherInitiate() {
        this.chatManager = new ChatManager({
            userId: this.clientDetails.value.email,
            instanceLocator: PUSHER_INSTANCE_LOCATOR,
            tokenProvider: new TokenProvider({
                url: `${siteConfig.nodeAPIBaseUrl}pusher/session/auth`
            })
        });

        this.pusherConnect();
    }
    pusherConnect() {
        this.chatManager
            .connect({
                onRoomUpdated: room => {
                    let modifiedData = {
                        ...room,
                        presenceStore: room.userStore.presenceStore
                    };
                    this.adminUserStatus$.next(
                        this.getAdminUserStatus(modifiedData)
                    );
                }
            })
            .then(currentUser => {
                this.chatStatus$.next(true);
                this.currentUserPusher = currentUser;
                this.subscribeToRoom();
                this.fetchMessageUsingId(false);
            })
            .catch(err => {
                console.log(err, "Connection error");
            });
    }
    /* setMessagesToStore(messages: Message[]) {
        messages &&
            messages.forEach((message: any) => {
                if (
                    this.isToday(message.createdAt) &&
                    this.containsCustomMessage(this.messagesList) ==
                        -1
                ) {
                    this.messagesList.push(this.pushCustomDateHeader());
                }
                this.messagesList.push(message);
            });
        this.messageList$.next(this.messagesList);
    } */

    appendDayToMessage(message, list) {
        if (
            PulseUtils.isToday(message.createdAt) &&
            PulseUtils.containsCustomMessage(list) == -1
        ) {
            return true;
        } else {
            return false;
        }
    }

    updateMessageList(messageFromPusher) {
        let index = this.messagesList.findIndex(message => {
            return message.id === messageFromPusher.id;
        });
        if (index != -1) {
            this.messagesList[index] = messageFromPusher;
            this.messageList$.next(this.messagesList);
        } else {
            this.messagesList.push(messageFromPusher);
            this.messageList$.next(this.messagesList);
        }
    }

    setMessageToStore(message, fromPusher) {
        if (fromPusher) {
            this.updateMessageList(message);
        } else {
            if (this.appendDayToMessage(message, this.messagesList)) {
                this.messagesList.push(PulseUtils.pushCustomDateHeader());
            }
            this.messagesList.push(message);
            this.messageList$.next(this.messagesList);
        }
    }

    sendMailToRecruiter(message) {
        let payLoad = {
            to: this.adminDetails.value.email,
            // to: "kiran@factweavers.com",
            from: this.clientDetails.value.email,
            subject: `New Message from ${this.clientDetails.value.name}`,
            recruiterName: this.adminDetails.value.name,
            userName: this.clientDetails.value.name,
            message: message,
            link: `${siteConfig.baseApiHost}/webchat/chat/${this.clientDetails.value.email}`
        };
        return this.commonSerivce.sendEmailNotificationForChat(payLoad).pipe(
            map((response: any) => {
                return response;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    sendChatMessage(message: string) {
        var classRef = this;
        let newMessagePromise = {
            id: null,
            isLoadMessage: true,
            createdAt: new Date().toISOString(),
            message,
            classRef,
            senderId: this.clientDetails.value.email,
            messageIndex: this.messagesList.length,
            loading: true,
            error: false,
            parts: [{
                payload: {
                    type: "text/plain"
                }
            }],
            sendMessage: function () {
                new Promise((resolve, reject) => {
                    this.classRef.currentUserPusher
                        .sendSimpleMessage({
                            roomId: this.classRef.chatRoom.value.id,
                            text: message
                        })
                        .then(messageId => {
                            this.id = messageId;
                            this.loading = false;

                            this.classRef.errorInSendingMessage$.next({
                                isError: false,
                                message: message
                            });
                            if (!this.classRef.adminUserStatus$.value) {
                                this.classRef
                                    .sendMailToRecruiter(message)
                                    .subscribe(data => {

                                    });
                            }
                        })
                        .catch(err => {
                            this.loading = false;
                            this.error = true;
                            this.classRef.errorInSendingMessage$.next({
                                isError: true,
                                message: message
                            });
                            console.error(
                                `Error adding message to ${this.classRef.chatRoom.value.id}: ${err}`
                            );
                        });
                });
            }
        };
        newMessagePromise.sendMessage();
        this.setMessageToStore(newMessagePromise, false);
        this.messageStatus.next(new Date().getTime());
    }

    sendMultupartMessage() {

    }

    setClientInfoLoading() {
        this.clientInfoLoading$.next(true);
    }

    unSubscribeUser() {
        this.currentUserPusher.disconnect();
    }
    subscribeToRoom() {
        this.currentUserPusher.subscribeToRoomMultipart({
            roomId: this.chatRoom.value.id,
            hooks: {
                onMessage: message => {
                    this.notificationService.sendBrowserNotification(
                        `New Message from ${message.senderId}`,
                        this.router.url
                    );
                    setTimeout(() => {
                        this.setMessageToStore(message, true);
                        this.messageStatus.next(new Date().getTime());
                    }, 200);
                    if (this.checkIfIncomingCall(message)) {
                        this.inComingCall$.next(true)
                    }
                },
                onPresenceChanged: (state, user) => {
                    this.adminUserStatus$.next(this.getAdminUserStatus(user));
                }
            },
            messageLimit: 0
        });
    }
    checkIfIncomingCall(message) {
        let payload = message.parts[0].payload
        if (payload.type === "application/json") {
            let content = JSON.parse(payload.content)
            if (content.metadata === "callService" && content.status === "calling") {
                return true
            } else {
                return false
            }
        } else return false
    }
    getAdminUserStatus(userRoomObject) {
        let presenceStatus =
            (userRoomObject &&
                userRoomObject.presenceStore &&
                userRoomObject.presenceStore[this.adminUserId]) ||
            false;
        let customeAvailabilityStatus =
            (userRoomObject.customData &&
                userRoomObject.customData.showOnline) ||
            false;
        return presenceStatus === "online" && customeAvailabilityStatus;
    }
    fetchMessageUsingId(isFromScroll: boolean) {
        if (this.messagesList.length > 0 || !isFromScroll) {
            let params = {
                roomId: this.chatRoom.value.id,
                direction: "older",
                limit: this.messageLimit
            };
            if (isFromScroll) {
                let initialMessageId =
                    this.messagesList.length > 0
                        ? (this.messagesList[0] && this.messagesList[0].id) ||
                        this.messagesList[1].id ||
                        null
                        : null;
                params["initialId"] = initialMessageId;
            }
            this.messageListLoading$.next(true);
            this.currentUserPusher
                .fetchMultipartMessages(params)
                .then(messages => {
                    let newList = [...messages, ...this.messagesList];
                    newList.forEach((message, index) => {
                        if (this.appendDayToMessage(message, newList)) {
                            newList.splice(
                                index,
                                0,
                                PulseUtils.pushCustomDateHeader()
                            );
                        }
                    });
                    this.messagesList = [...newList];
                    this.messageList$.next(newList);
                    this.messageListLoading$.next(false);
                    if (!isFromScroll) {
                        this.messageStatus.next(new Date().getTime());
                    }
                })
                .catch(err => {
                    console.error(`Error fetching messages: ${err}`);
                });
        }
    }
    getWelcomeMessage(id): Promise<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}users/getChatWelcomeMessage?userChatId=${id}`;
        return new Promise((resolve, reject) => {
            return this._httpClient.get(url).subscribe((response: any) => {
                let message =
                    (response.data && response.data.welcomeChatMessage) ||
                    "Chat";
                resolve(message);
            });
        });
    }

    sendVerificationCode(email: string) {
        // const url = `${siteConfig.nodeAPIBaseUrl}users/sendVerificationCode?email=${email}`;
        const url = `${siteConfig.nodeAPIBaseUrl}webchat/generateAuthenticationCodeForCandidate?email=${email}`;
        return this._httpClient.get(url);
    }
    verifyCode(email: string, code: number) {
        // const url = `${siteConfig.nodeAPIBaseUrl}users/sendVerificationCode?email=${email}`;
        const url = `${siteConfig.nodeAPIBaseUrl}webchat/checkCandidateAuthenticationCode?email=${email}&authenticationCode=${code}`;
        return this._httpClient.get(url);
    }

    public get getClientDetails() {
        return this.clientDetails.value
    }

    sendMultipartMessage(jsonMessage) {
        let pusherUser = this.currentUserPusher;
        pusherUser.sendMultipartMessage({
            roomId: this.chatRoom.value.id,
            parts: [
                jsonMessage
            ],
        })
            .then(messageId => {
            })
            .catch(err => {
            })
    }

    createMediaDocument(fileDetails) {
        let mediaMessage =
        {
            type: "application/json",
            content: JSON.stringify({
                metadata: "document",
                type: fileDetails.type,
                url: fileDetails.location,
                name: fileDetails.name
            }),
        }
        this.sendMultipartMessage(mediaMessage);
    }
    // setUser
}
