import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef, ViewChild, ApplicationRef, ComponentFactoryResolver} from '@angular/core';
import {UsersService} from '../../../../services/users/users.service';
import {SwalService} from '../../../../services/swal.service';
import {RolesService} from '../../../../services/roles/role.service';
import {CommonService} from '../../../../services/common/common.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {frmUserDataType} from '../user-edit/user-edit.component';
import { promise } from 'protractor';



export class RolePlanElem {
    sequence:number;
    id:string;
    status:boolean;
    labelId:string;

    constructor(sequence:number, id:string, status:boolean, labelId:string){
        this.id=id;
        this.sequence=sequence;
        this.status=status;
        this.labelId=labelId;
    }
}


@Component({
    selector: 'app-user-commission',
    templateUrl: './user-commission.component.html',
    styleUrls: ['./user-commission.component.scss']
})
export class UserCommissionComponent implements OnInit, OnDestroy {

    public rolePlan1:boolean=false;

    private unsubscribe$: Subject<void> = new Subject<void>();
    frmUserData: frmUserDataType;
    frmUserCommissionData: any = {};
    
    commissionRole = [];
    commissionBasedRole = [];
    userId = 0;
    loadingVisible = false;
    userType = [
        {value: 0, name: 'Employee User'},
        {value: 1, name: 'Franchise User'},
    ];
    CommissionRoleObjects:any[]=[];
    QBPlansDataList:any[]=[];
    payType = [
        {value: 0, name: 'None'},
        {value: 1, name: 'Cash'},
        {value: 2, name: 'Accrual'},
    ];

    testTestValue:any='TestAyyanar';

    qbUsers = {};
    statusList = [];
    QBPlansData = [];
    masterData: any = {};

    rolePlanElems:any[]=[];
    frmUserCommissionDataDynamicvalue:false;

    @ViewChild('rolePlansContainer', {read: ViewContainerRef}) rolePlansContainer:ViewContainerRef;
    rolePlanRef:any;
    selectedCRoles:number[]=[];

    constructor(public usersService: UsersService,
                private router: Router,
                private route: ActivatedRoute,
                private swalService: SwalService,
                private roleService: RolesService,
                private commonService: CommonService,
                private rolePlansAppRef: ApplicationRef) {
        this.route.params.subscribe(params => this.userId = params.id);
        this.usersService.listUserObject.subscribe((result) => {
            if (result) {
                this.frmUserData = result;
                this.mapUserData(result);
                this.selectedCRoles=(result.comm_role!=null && result.comm_role!=undefined && result.comm_role.includes(','))?result.comm_role.split(",").map(Number):[];
            }
        });

        this.populateQbData = this.populateQbData.bind(this);
        
        this.rolePlanElems=[{id:1, status:false, elemId:"plan1"},{id:2, status:false, elemId:"plan2"},{id:3, status:false, elemId:"plan3"},{id:4, status:false, elemId:"plan4"},{id:5, status:false, elemId:"plan5"},{id:6, status:false, elemId:"plan6"}];

        this.createQbUserToggle(false)

    }

    ngOnInit() {




        this.getAllQBPlans();
        this.getAllQBUsers();

        
        
        // this.commonService.getCommissionRole()  // //This is the list of Roles belongs to commission:Ayyanar(25/01/2024)
        // .pipe(takeUntil(this.unsubscribe$))
        // .subscribe((result: any) => {
        //       this.commissionBasedRole =  result.data;
        //       this.CommissionRoleObjects=result.data; //.map(r=>r.role_id);  //this.selectedCRoles
        //     },
        //     (error) => {
        //         console.log('getcommissionBasedRoleERROR', error);
        //     }
        // );

        this.commonService.masterData
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
                this.masterData = data;
                if (this.masterData) {
                    if (this.masterData.commissionRole) {
                        this.commissionRole = this.masterData.commissionRole;
                    }
                }

                if (data.userStatus) {
                    data.userStatus.forEach((item) => {
                        this.statusList.push({
                            id: Number(item.value),
                            name: item.master_lookup_name
                        });
                    });
                }

            });


            let sdfsd=this.frmUserData;

            this.usersService.listUserObject.subscribe((response) => {
                if (response) {
                    this.frmUserData = response;
                    this.mapUserData(response);
                    console.log(`frmUserCommissionData.frmNewQbUser: ${this.frmUserCommissionData.frmNewQbUser}`);
                    this.selectedCRoles=(response.comm_role!=null && response.comm_role!=undefined && response.comm_role.includes(','))?response.comm_role.split(",").map(Number):[];
                }
            });
            

            this.commonService.getCommissionRole()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result: any) => {

                this.commissionBasedRole =  result.data;

                this.CommissionRoleObjects=result.data; 

                //console.log(`frmUserCommissionData.frmNewQbUser: ${this.frmUserCommissionData.frmNewQbUser}`);

                //console.log(this.frmUserCommissionData)
                //console.log(this.CommissionRoleObjects)

                let commissionPlansData=JSON.parse(this.frmUserData.comm_plans_json);

                for(let i=0; i<commissionPlansData.length;i++){
                    let propertyName=`frmPayType${i}`;
                    let planPropertyName=`frmCRP${i}`;
                    this.frmUserCommissionData[propertyName]=commissionPlansData[i].pay_type_id||0;
                    this.frmUserCommissionData[planPropertyName]=commissionPlansData[i].plan_id||0;
                    
                }

                },
                (error) => { 
                    console.log('getcommissionBasedRoleERROR', error);
                }
            );

    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createQbUserToggle(event) {
        if (event.value) {
            this.frmUserCommissionData.frmQbUser = 0;
        }
        this.frmUserCommissionData.frmNewQbUser = event.value;
    }

    fnGetPlanIdFromObject(index){

        let iReturnValue=0;

        if(index==0){
            iReturnValue=this.frmUserCommissionData.frmCRP0??0;
        } else if(index==1){
            iReturnValue=this.frmUserCommissionData.frmCRP1??0;
        }else if(index==2){
            iReturnValue=this.frmUserCommissionData.frmCRP2??0;
        }else if(index==3){
            iReturnValue=this.frmUserCommissionData.frmCRP3??0;
        }else if(index==4){
            iReturnValue=this.frmUserCommissionData.frmCRP4??0;
        }else if(index==5){
            iReturnValue=this.frmUserCommissionData.frmCRP5??0;
        }else if(index==6){
            iReturnValue=this.frmUserCommissionData.frmCRP6??0;
        }else if(index==7){
            iReturnValue=this.frmUserCommissionData.frmCRP7??0;
        }else if(index==8){
            iReturnValue=this.frmUserCommissionData.frmCRP8??0;
        }else if(index==9){
            iReturnValue=this.frmUserCommissionData.frmCRP9??0;
        }

        return iReturnValue;
    }


    public async comparePayBillRate() {
        let bReturnVlaue:boolean=false;
        const swalData = {
            title: 'Check Rates',
            message: `Pay rate is more than bill rate!`,
            info: 'You can also update rates later.'
        };

        this.swalService.prompt(swalData).then((response)=>{
            let test1=response;
            let test2='';
        });
        

    }


    public EditUser(finalData:any){
        this.usersService.editUser(this.userId, finalData)
        .subscribe(
            (result: any) => {
                if (result.code === 200) {
                    const swalData = {
                        title: 'Success',
                        message: 'User updated.',
                        info: ''
                    };
                    this.router.navigate(['user/list']);
                    this.swalService.success(swalData);
                } else {
                    console.log(result);
                    const swalData = {
                        title: 'Error',
                        message: 'Error code ' + result.code || 'Something went wrong',
                        info: 'Something went wrong'
                    };
                    this.swalService.error(swalData);
                }
            },
            (error) => {
                this.loadingVisible = false;
                console.log('editUserERROR', error);
                const swalData = {
                    title: 'Error',
                    message: error.statusText || 'Something went wrong',
                    info: error.error.messages || 'Something went wrong'
                };
                this.swalService.error(swalData);
                // this.swalService.error(error.error.error.message || 'Something went wrong');
            },
            () => {
                this.loadingVisible = false;
            }
        );
    }

    onFormSubmit(e) {



       let arrCommRoles = this.frmUserCommissionData.frmCommissionRole && Array.isArray(this.frmUserCommissionData.frmCommissionRole) ? this.frmUserCommissionData.frmCommissionRole : [];
        
        const commissionPlans:any[]=[];
        let IsPlanDetailInComplete=false;

        for(let iCount=0; iCount<this.CommissionRoleObjects.length;iCount++){

            let iPlanId=this.fnGetPlanIdFromObject(iCount);
            let iRoleId=this.CommissionRoleObjects[iCount].role_id as number;
            let planId=this.frmUserCommissionData['frmPayType'+iCount]||0;

            if(!IsPlanDetailInComplete){
                if(planId==0 && iPlanId>0){ IsPlanDetailInComplete=true;}
            }
            

            let crpObj={
                role_id:iRoleId,
                plan_id:iPlanId,
                pay_type_id:this.frmUserCommissionData['frmPayType'+iCount],
            }
            commissionPlans.push(crpObj);
            
        }  


        

        
 

        // let frmCRP0:number=this.frmUserCommissionData.frmCRP0??0;
        // let frmCRP1:number=this.frmUserCommissionData.frmCRP1??0;
        // let frmCRP2:number=this.frmUserCommissionData.frmCRP2??0;
        // let frmCRP3:number=this.frmUserCommissionData.frmCRP3??0;
        // let frmCRP4:number=this.frmUserCommissionData.frmCRP4??0;
        // let frmCRP5:number=this.frmUserCommissionData.frmCRP5??0;
        // let frmCRP6:number=this.frmUserCommissionData.frmCRP6??0;
        // let frmCRP7:number=this.frmUserCommissionData.frmCRP7??0;
        // let frmCRP8:number=this.frmUserCommissionData.frmCRP8??0;
        // let frmCRP9:number=this.frmUserCommissionData.frmCRP9??0;
        // let asdasd:number[]=this.frmUserCommissionData.frmCRP;
        // let  pay_type0:number= this.frmUserCommissionData.frmPayType0??0;
        // let  pay_type1:number= this.frmUserCommissionData.frmPayType1??0;
        // let  pay_type2:number= this.frmUserCommissionData.frmPayType2??0;
        // let  pay_type3:number= this.frmUserCommissionData.frmPayType3??0;
        // let  pay_type4:number= this.frmUserCommissionData.frmPayType4??0;
        // let  pay_type5:number= this.frmUserCommissionData.frmPayType5??0;
        // let  pay_type6:number= this.frmUserCommissionData.frmPayType6??0;
        // let  pay_type7:number= this.frmUserCommissionData.frmPayType7??0;
        // let  pay_type8:number= this.frmUserCommissionData.frmPayType8??0;
        // let  pay_type9:number= this.frmUserCommissionData.frmPayType9??0;





      
        const finalData = {
            user_id: this.userId,
            first_name: this.frmUserData.frmFirstName,
            last_name: this.frmUserData.frmLastName,
            division: this.frmUserData.frmDivision,
            email: this.frmUserData.frmEmail,
            user_joining_target: this.frmUserData.frmUserTarget,
            signature: this.frmUserData.frmSignature,
            mobile_no: this.frmUserData.frmMobile,
            country_code: this.frmUserData.frmCountryCode,
            reports_to: this.frmUserData.frmReportingTo,
            docusign_user: this.frmUserData.frmDocusignUser,
            docusign_password: this.frmUserData.frmDocusignPass,
            role_id: this.frmUserData.frmPrimaryRole,
            location: this.frmUserData.frmLocation,
            sms_number_twillio: this.frmUserData.frmTwilioSmsNumber,
            wc_user_id: this.frmUserData.frmWidgetChatId,
            wc_user_created: this.frmUserData.frmWidgetChatIdCreated,
            jobdiva_id: this.frmUserData.frmJobDivaId,
            voip_number: this.frmUserData.frmVOIPNumber,
            voip_ext: null,
            user_status: this.frmUserData.frmUserStatus,
            is_active: this.frmUserData.frmIsActive,

            qb_user_reff_id: this.frmUserCommissionData.frmQbUser,
            comm_user_type: this.frmUserCommissionData.frmUserType,
            
            frmCmrPlans:this.frmUserCommissionData.frmCmrPlans,
              
            // tslint:disable-next-line:max-line-length
            comm_role: this.frmUserCommissionData.frmCommissionRole && Array.isArray(this.frmUserCommissionData.frmCommissionRole) ? this.frmUserCommissionData.frmCommissionRole.join() : null,
            // tslint:disable-next-line:max-line-length
            comm_default_role: this.frmUserCommissionData.frmDefRoles,
            comm_sales_plan: this.frmUserCommissionData.frmDefSalesPlan,
            comm_recr_plan: this.frmUserCommissionData.frmDefRecPlan,
            create_qb_user: this.frmUserCommissionData.frmNewQbUser || false,
            comm_plans_json:JSON.stringify(commissionPlans),
        };

        this.loadingVisible = true;



        if(IsPlanDetailInComplete){
            
            const swalData = {
                title: 'Check Plan and Pay Type', message: ``, info: 'Plan details are incomplete!'  //You can also update rates later.
            };
    
            this.swalService.prompt(swalData).then((userSelections)=>{
                for(let userSelection in userSelections){
                    if(userSelection=="value" && userSelections["value"]==true){
                        this.EditUser(finalData);
                    }
                }
            });
         } else{
            this.EditUser(finalData);
         }
    }

    getAllQBPlans() {
        this.commonService.getAllQBPlans()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result: any) => {
                this.QBPlansData = result.data || [];
                this.QBPlansData.unshift({id:0, plan_name:'None'});
                this.QBPlansDataList.push(this.QBPlansData);
            }, error => {
                console.log('ERROR in QB plans:', error);
            });
    }

    getAllQBUsers() {
        this.commonService.getAllQbUsers()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result: any) => {
                this.qbUsers = result.data || [];
                
            }, error => {
                console.log('ERROR in QB Users:', error);
            });
    }

    mapUserData(result) {
        this.frmUserCommissionData.frmFirstName = result.first_name;
        this.frmUserCommissionData.frmLastName = result.last_name;
        this.frmUserCommissionData.frmUserName = result.email;
        this.frmUserCommissionData.frmUserStatus = result.user_status;
        this.frmUserCommissionData.frmUserType = result.comm_user_type || null;
        this.frmUserCommissionData.frmPayType = result.pay_type || null;

        this.frmUserCommissionData.frmCommissionRole = result.comm_role ? String(result.comm_role).split(',').map(Number) : null;
        this.frmUserCommissionData.frmDefRoles = Number(result.comm_default_role) || null;
        this.frmUserCommissionData.frmDefSalesPlan = result.comm_sales_plan || null;
        this.frmUserCommissionData.frmDefRecPlan = result.comm_recr_plan || null;

        this.frmUserCommissionData.frmNewQbUser = false;
        this.frmUserData.frmQbUserId = result.qb_user_reff_id;
        this.frmUserCommissionData.frmQbUser = result.qb_user_reff_id;
        // this.frmUserCommissionData.frmPayType0=2;
        // this.frmUserCommissionData.frmPayType1=2;
        // this.frmUserCommissionData.frmPayType2=2;

        // let CommissionRolePlans=JSON.parse(result.comm_plans_json);  //comm_plans_json
        // console.log(this.frmUserCommissionData);     //{plan_id: 0, role_id: 4, pay_type_id: 0}
        // for(let index=0; index<CommissionRolePlans.length;index++){
        //     this.frmUserCommissionData['frmCRP'+index]=CommissionRolePlans[index].plan_id;
        //     this.frmUserCommissionData['frmPayType'+index]=CommissionRolePlans[index].pay_type_id;
        // }

    }

    changeSelectBox(e, selector) {
        switch (selector) {
            case 'defSalesPlan' :
                this.frmUserCommissionData.frmDefSalesPlan = e.selectedItem.id;
                break;
            case 'defRecPlan' :
                this.frmUserCommissionData.frmDefRecPlan = e.selectedItem.id;
                break;
        }
    }  



    cancel() {
        this.router.navigate(['user/list']);
    }

    populateQbData(e) {
        const roleList = [];
        const qbUser = e.selectedItem;
        if (!qbUser) {
            return;
        }
        //if (!this.frmUserData.comm_user_type && qbUser.user_type || qbUser.user_type === 0) {  //Ayyanar:14-06-2024
            this.frmUserCommissionData.frmUserType = Number(qbUser.user_type);
        //}
        if (!this.frmUserData.comm_default_role && qbUser.user_default_role) {
            this.commissionRole.forEach((item) => {
               if (item.description == String(qbUser.user_default_role)) {
                   this.frmUserCommissionData.frmDefRoles = item.value;
               }
            });
        }
        if (!this.frmUserData.comm_role && qbUser.user_role && qbUser.user_role[0]) {
            const roleObj = Object.values(qbUser.user_role);
            this.frmUserCommissionData.frmCommissionRole = [];
            this.commissionRole.forEach((item) => {
                if (roleObj.indexOf(Number(item.description)) > -1 || roleObj.indexOf(item.description) > -1) {
                    roleList.push(item.value);
                    this.frmUserCommissionData.frmCommissionRole.push(Number(item.value));
                }
            });

           // this.frmUserCommissionData.frmCommissionRole = String(roleList).split(',').map(Number) ;
            console.log(this.frmUserCommissionData.frmCommissionRole);
        }

        if (!this.frmUserData.comm_sales_plan && qbUser.def_sales_plan && qbUser.def_sales_plan > 0) {
            this.frmUserCommissionData.frmDefSalesPlan = qbUser.def_sales_plan;
        }

        if (!this.frmUserData.comm_recr_plan && qbUser.def_recr_plan && qbUser.def_recr_plan > 0) {
            this.frmUserCommissionData.frmDefRecPlan = qbUser.def_recr_plan;
        }
    }

    isEnabled:boolean=false;



    

}

