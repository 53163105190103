import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';

import { JobPortalService } from 'app/services/job-portal/job_portal.service';

import * as _ from 'lodash';

const ADD_NEW_TEMPLATE_NAME = '-- Add Template --'

@Component({
  selector: 'github-email',
  templateUrl: './github-email.component.html',
  styleUrls: [ './github-email.component.scss' ],
  providers: [ JobPortalService ]
})
export class GithubEmailComponent implements OnInit {
  // form fields
  allTemplateNames = []
  selectedTemplateName: string = '';
  url: string = '';

  // popup fields
  isUpdate:boolean = false;
  emailTemplatePopupVisible: boolean = false;
  emailTemplateName: string = '';
  emailFrom: string = '';
  emailSubject: string = '';
  emailBody: string = '';

  // job data-grid fields
  totalActiveGithubJobs: number = 0;
  allGithubJobs = [];

  // service data
  allTemplates = [];

  constructor(private jobPortalService: JobPortalService) {}

  ngOnInit () {
    this.updateEmailTemplates()
    this.updateGithubJobs()
  }

  updateEmailTemplates () {
    this.jobPortalService.getAllEmailTemplates()
      .subscribe(data => {
        this.allTemplates = data;
        this.allTemplateNames = _.concat(
          [ ADD_NEW_TEMPLATE_NAME ], _.map(data, 'templateName'));
      })
  }

  updateGithubJobs () {
    let update = (githubJobId, data) => {
      let index = _.findIndex(this.allGithubJobs, { githubJobId })
      this.allGithubJobs[index] = _.merge(this.allGithubJobs[index], data)
    }

    this.jobPortalService.getAllGithubJobs().subscribe(data => {
      // delete progress jobs - to update the status
      this.allGithubJobs = _.filter(this.allGithubJobs,
        x => x.status !== 'Processing')

      // get just the new jobs
      let newActiveGithubJobs = []
      let githubJobs = _.differenceBy(data, this.allGithubJobs, 'githubJobId')
      githubJobs = _.map(githubJobs, x => {
        if (x.status === 'Processing') {
          x.progress = '0%'
          newActiveGithubJobs.push(x)
        } else if (x.status === 'Completed') {
          x.progress = '100%'
        }
        return x
      })

      // append just the new github jobs
      this.allGithubJobs = _.concat(this.allGithubJobs, githubJobs)

      // update total active github jobs
      this.totalActiveGithubJobs = _.filter(this.allGithubJobs,
        x => x.status === 'Processing').length;

      // for all new active github jobs, start following the progress
      for (let job of newActiveGithubJobs) {
        try {
          let progressStream = this.jobPortalService
            .githubProgress(job.githubJobId)
          progressStream.addEventListener('message', _event => {
            let { githubJobId, event, data } = JSON.parse(_event.data);

            if (event === 'percentage') {
              update(githubJobId, { progress: `${parseInt(data)}%` });
            } else if (event === 'error') {
              notify(data, 'error', 1000);
              update(githubJobId,
                { status: 'Errored', progress: '0%', error: data });
              progressStream.close();
            } else if (event === 'finish') {
              notify(`GithubJob #${job.githubJobId} Complete - ` +
                `${data} jobs extracted`, 'success', 1000);
            } else if (event === 'done') {
              progressStream.close();
              setTimeout(() => this.updateGithubJobs(), 1000);
            }
          })
        } catch (err) {
          notify(err, 'error' , 1000);
        }
      }
    }, err => {
      notify(err.error.error, 'error', 1000);
    })
  }

  submitGithubJob (event) {
    this.jobPortalService.sendEmailToGithubUsers(
      this.selectedTemplateName, this.url
    ).subscribe(
      data => {
        notify(`New GithubJob #${data.id} initiated`, 'success', 1000);
        this.updateGithubJobs();
      }, err => {
        notify(err.error.err, 'error', 1000);
      }
    )
    event.preventDefault();
  }

  abortGithubJob (githubJobId) {
    this.jobPortalService.githubAbort(githubJobId).subscribe(
      data => {
        notify(`GithubJob #${githubJobId} stopped`, 'success', 1000);
        this.updateGithubJobs();
      }, err => {
        notify(err.error.err, 'error', 1000);
      }
    )
  }

  saveEmailTemplate() {
    // save
    this.jobPortalService.addEmailTemplate(
      this.emailTemplateName, this.emailFrom,
      this.emailSubject, this.emailBody, this.isUpdate
    ).subscribe(data => {
      let status = this.isUpdate ? 'updated' : 'added'
      notify(`EmailTemplate "${data.name}" ${status}`, 'success', 1000);

      // refresh email templates
      this.updateEmailTemplates()
      this.selectedTemplateName = data.name

      // hide
      this.emailTemplatePopupVisible = false;
    }, err => {
      notify(err.error.err, 'error', 1000);
    })
  }

  showEmailTemplatePopup (editMode) {
    if (editMode === 'add') {
      this.isUpdate = false
      this.emailTemplateName = '';
      this.emailFrom = '';
      this.emailSubject = '';
      this.emailBody = '';
    } else if (editMode === 'edit') {
      this.isUpdate = true
      let template = _.find(this.allTemplates,
        { templateName: this.selectedTemplateName });
      this.emailTemplateName = template.templateName;
      this.emailFrom = template.from;
      this.emailSubject = template.subject;
      this.emailBody = template.body;
    } else {
      return
    }

    // show
    this.emailTemplatePopupVisible = true;
  }

  hideEmailTemplatePopup () {
    // clear data
    if (this.selectedTemplateName === ADD_NEW_TEMPLATE_NAME) {
      this.selectedTemplateName = ''
    }

    // hide
    this.emailTemplatePopupVisible = false;
  }

  emailTemplateNameUpdated () {
    if (this.selectedTemplateName === ADD_NEW_TEMPLATE_NAME) {
      this.showEmailTemplatePopup('add')
    }
  }
}
