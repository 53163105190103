import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { PulseSharedModule} from '@pulse/shared.module';

import {PulseFooterComponent} from 'app/main/footer/footer.component';

@NgModule({
    declarations: [
        PulseFooterComponent
    ],
    imports: [
        RouterModule,
        PulseSharedModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule
    ],
    exports: [
        PulseFooterComponent
    ]
})
export class PulseFooterModule {
}
