import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserEditComponent} from '../user-edit/user-edit.component';
import {UsersService} from '../../../../services/users/users.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-user-detail-main',
    templateUrl: './user-detail-main.component.html',
    styleUrls: ['./user-detail-main.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserDetailMainComponent implements OnInit {
    @ViewChild(UserEditComponent) dxUserFormComponent: UserEditComponent;
    userId = 0;
    slug = 'detail';
    pageTitle = 'New User';

    constructor(private route: ActivatedRoute,
                private usersService: UsersService) {
        this.route.params.subscribe(res => {
            this.userId = res.id || 0;
            this.slug = res.slug || 'detail';

            this.getUser(this.userId);
        });
    }

    ngOnInit() {
    }

    reset() {
        this.dxUserFormComponent.dxUserForm.instance.resetValues();
    }

    getUser(id: any) {
        // debugger;
        this.usersService.getUserByID(id)
            .subscribe(
                (result: any) => {
                    this.pageTitle = `${result.data.first_name} ${result.data.last_name}`;
                    this.usersService.listUserObject.next(result.data);
                },
                (error) => {
                    // this.loadingVisible = false;
                    console.log('getUserByIdERROR', error);
                }, () => {
                    // this.loadingVisible = false;
                }
            );
    }
}
