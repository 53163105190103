import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

// VMS List API
import { VmsServiceService } from 'app/services/vms-service/vms-service.service' ;

@Component({
  selector: 'app-worker-search',
  templateUrl: './worker-search.component.html',
  styleUrls: ['./worker-search.component.scss']
})
export class WorkerSearchComponent {
  vmsClientList;
  selectedCLient: any[] = [];
  vmsWorkerSearch: any[] = [];
  vmsWorkerDetails: any[] = [];
  vmsWorkerDetailToogle;
  vmsWorkerSearchCount = 0;
  searchText;
  selectedClients;

  constructor(private router: Router,
    private route: ActivatedRoute,    
    private vms_Name_List: VmsServiceService,
    private elementRef: ElementRef) { }

    ngOnInit() {
      this.loadClientList();
    }
    loadClientList(){
      // VMS Clients
      this.vms_Name_List.getVMSClients().subscribe(data => {
        this.vmsClientList = JSON.parse(JSON.stringify(data));
      });
    }

    refreshGridForClient(args) {
      this.selectedCLient= args['value'];
    }
  
    searchWorker(){
      this.vms_Name_List.searchVmsWorker(this.selectedCLient).subscribe(data => {
        this.vmsWorkerSearch = JSON.parse(JSON.stringify(data));
        this.vmsWorkerSearchCount = this.vmsWorkerSearch.length;
      });
    }
  
    workerDetail(detail){
      console.log(detail);
      this.vmsWorkerDetailToogle = detail['fullName'];
      this.vmsWorkerDetails = detail;
    }

    clearFilter(){}

}
