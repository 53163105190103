<div class="page-layout simple fullwidth user-profile">

    <!-- container -->
    <div class="container mt-12" fxLayout="row" fxLayoutGap="20px">

        <div class="p-30 pt-15 pb-15 mat-white-bg mat-elevation-z4 avatar-container" fxLayout="column"
             fxLayoutAlign="center center"
             fxFlex="30">
            <!--<div class="avatar-wrapper">-->
            <div class="avatar-thumb round">
                <img [src]="profileImageUrl || defaultImageUrl">
            </div>
            <div class="text-center">
                <h2>{{userName}}</h2>
                <h4 *ngIf="introUrl"><label>Video Intro: </label><a href="javascript:void(0)"
                                                                    (click)="showIntro()">{{introUrl}}</a>
                </h4>
            </div>
            <!--<div *ngIf="loginHistory" class="login-history-container" fxLayout="column" >
                <label class="p-8 mb-8">Login history</label>
                <div *ngFor="let singleItem of loginHistory" fxLayout="column" class="login-history p-8 mb-4">
                    <span>{{singleItem.time | date:"EEE dd LLLL, yyyy HH:mm:ss"}}</span>
                    <span class="">{{singleItem.requested_ip}}</span>
                </div>
            </div>-->
            <!--</div>-->
        </div>

        <div class="content mat-white-bg mat-elevation-z4 " fxFlex="70">
            <div class="content-wrapper" fxLayout="column">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 1 auto">
                    <div class="pulse-main-heading" fxFlex="50">
                        Profile Account
                    </div>
                </div>
                <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#profileTabPanel' }"
                               [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true"
                               [closeOnOutsideClick]="false">
                </dx-load-panel>
                <div id="profileTabPanel" class="form-wrapper" pulsePerfectScrollbar>
                    <form (submit)="onFormSubmit($event)">
                        <div class="form-container">
                            <dx-form [(formData)]="currentUser" [showColonAfterLabel]="true">

                                <dxi-item itemType="tabbed" [colSpan]="2">
                                    <dxi-tab title="Personal Info">
                                        <dxi-item itemType="group" [colSpan]="12">
                                            <form (ngSubmit)="saveProfile($event)">
                                                <dx-form [(formData)]="currentUser" [showValidationSummary]="true"
                                                         [showColonAfterLabel]="true"
                                                         validationGroup="userPersonalData">
                                                    <dxi-item itemType="group" [colSpan]="12" [colCount]="2">
                                                        <dxi-item dataField="first_name">
                                                            <dxo-label text="First Name"></dxo-label>
                                                            <dxi-validation-rule type="required"
                                                                                 message="First Name is required">
                                                            </dxi-validation-rule>
                                                        </dxi-item>
                                                        <dxi-item dataField="last_name">
                                                            <dxo-label text="Last Name"></dxo-label>
                                                            <dxi-validation-rule type="required"
                                                                                 message="Last Name is required">
                                                            </dxi-validation-rule>
                                                        </dxi-item>
                                                        <dxi-item dataField="mobile_no" [disabled]="true">
                                                            <dxo-label text="Mobile Number"></dxo-label>
                                                        </dxi-item>
                                                        <dxi-item dataField="email" [disabled]="true">
                                                            <dxo-label text="Email"></dxo-label>
                                                        </dxi-item>

                                                        <dxi-item dataField="voip_number" [disabled]="true">
                                                            <dxo-label text="VOIP Number"></dxo-label>
                                                        </dxi-item>
                                                        <dxi-item dataField="voip_ext" [disabled]="true">
                                                            <dxo-label text="VOIP Ext"></dxo-label>
                                                        </dxi-item>
                                                        <dxi-item dataField="reporting_manager" [disabled]="true">
                                                            <dxo-label text="Reporting Manager"></dxo-label>
                                                        </dxi-item>
                                                        <dxi-item dataField="sms_number_twillio" [disabled]="true">
                                                            <dxo-label text="SMS Number(Twillio)"></dxo-label>
                                                        </dxi-item>
                                                        <dxi-item dataField="social_link">
                                                            <dxo-label text="Social Link"></dxo-label>
                                                            <dxi-validation-rule type="required">
                                                            </dxi-validation-rule>
                                                        </dxi-item>
                                                        <dxi-item dataField="calendly_link">
                                                            <dxo-label text="Calendly Link"></dxo-label>
                                                            <dxi-validation-rule type="required">
                                                            </dxi-validation-rule>
                                                        </dxi-item>
                                                        <dxi-item itemType="group" [colSpan]="2"
                                                                  [template]="'chatURL'"></dxi-item>
                                                    </dxi-item>
                                                    <div *dxTemplate="let data of 'chatURL'">
                                                        <div class="py-12" fxLayout="row" fxLayoutGap="20px"
                                                             fxLayoutAlign="start center">
                                                            <label fxFlex="16">Chat URL:</label>
                                                            <div fxFlex="84" class="dx-texteditor dx-editor-outlined"
                                                                 fxLayout="row" fxLayoutAlign="space-between">
                                                                <dx-text-box class="border-none w-100-p" #chatURL
                                                                             [disabled]="true"
                                                                             [value]="data.formData.chatUrl"></dx-text-box>
                                                                <dx-button class="copy-btn border-none pulse-btn"
                                                                           (onClick)="copyLink(data.formData.chatUrl)"
                                                                           matTooltip="Click to copy"
                                                                           icon="far fa-copy">
                                                                </dx-button>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </dx-form>
                                                <div class="mt-20" fxLayout="row" fxLayoutAlign="space-between center">
                                                    <dx-button text="Cancel" class="pulse-btn pulse-btn-normal"
                                                               (onClick)="onCancel($event)">
                                                    </dx-button>

                                                    <dx-button text="Save" class="pulse-btn pulse-btn-primary"
                                                               validationGroup="userPersonalData"
                                                               [useSubmitBehavior]="true">
                                                    </dx-button>
                                                </div>
                                            </form>

                                        </dxi-item>
                                    </dxi-tab>
                                    <dxi-tab title="Change Avatar">
                                        <dxi-item itemType="group" [colSpan]="12">
                                            <dxi-item>
                                                <div class="change-avatar text-center" fxLayoutAlign="start center"
                                                     fxLayout="column">
                                                    <div class="image-preview avatar-thumb">
                                                        <img [src]="profilePreviewUrl || defaultImageUrl">
                                                    </div>
                                                    <div fxLayout="row" class="mt-20" fxLayoutAlign="center center">
                                                        <dx-file-uploader #profileUploader accept="image/*"
                                                                          class="pulse-btn pulse-btn-primary mr-12"
                                                                          selectButtonText="Select Image"
                                                                          labelText="Select Image"
                                                                          [(value)]="profileImage"
                                                                          [showFileList]="false" uploadMode="useForm"
                                                                          (onValueChanged)="uploadProfilePicture($event)">
                                                        </dx-file-uploader>
                                                        <dx-button text="Remove Image"
                                                                   class="pulse-btn pulse-btn-normal"
                                                                   (onClick)="removeProfileImage($event)">
                                                        </dx-button>
                                                    </div>

                                                </div>

                                            </dxi-item>
                                            <dxi-item>

                                                <div class="mt-20" fxLayout="row" fxLayoutGap="10px"
                                                     fxLayoutAlign="space-between">
                                                    <dx-button text="Cancel" class="pulse-btn pulse-btn-normal"
                                                               (onClick)="onCancel($event)">
                                                    </dx-button>
                                                    <dx-button text="Submit" class="pulse-btn pulse-btn-primary"
                                                               (onClick)="saveProfilePicture($event)">
                                                    </dx-button>


                                                </div>
                                            </dxi-item>
                                        </dxi-item>
                                    </dxi-tab>
                                    <dxi-tab title="Change Password">
                                        <dxi-item itemType="group" [colSpan]="12">
                                            <form (ngSubmit)="updatePassword($event)">
                                                <dx-form #changePasswordForm [showValidationSummary]="true"
                                                         [showColonAfterLabel]="true"
                                                         [(formData)]="currentUser" validationGroup="updatePassword">
                                                    <dxi-item dataField="frmCurrPwd"
                                                              [editorOptions]="{mode:currentPasswordMode, buttons: [{location:'after', name:'changeCurrentPasswordMode', options: {icon: 'small fa fa-eye'+(currentPasswordMode === 'text' ? '-slash' : ''), elementAttr:{class:'password-btn'}, onClick: changeCurrentPasswordInputMode}}]}">
                                                        <dxo-label text="Current Password"></dxo-label>
                                                        <dxi-validation-rule type="required"
                                                                             message="Current password is required">
                                                        </dxi-validation-rule>
                                                    </dxi-item>
                                                    <dxi-item dataField="frmNewPwd"
                                                              [editorOptions]="{mode: newPasswordMode, buttons: [{location:'after', name:'changeNewPasswordMode', options: {icon: 'small fa fa-eye'+(newPasswordMode === 'text' ? '-slash' : ''), elementAttr:{class:'password-btn'}, onClick: changeNewPasswordInputMode}}]}">
                                                        <dxo-label text="New Password"></dxo-label>
                                                        <dxi-validation-rule type="required"
                                                                             message="New password is required">
                                                        </dxi-validation-rule>
                                                        <dxi-validation-rule type="pattern"
                                                                             [pattern]="passwordPattern"
                                                                             message="New password must contain one uppercase, one digit and minimum eight character">
                                                        </dxi-validation-rule>

                                                    </dxi-item>

                                                    <dxi-item dataField="frmReNewPwd"
                                                              [editorOptions]="{mode:conNewPasswordMode, buttons: [{location:'after', name:'changeConNewPasswordMode', options: {icon: 'small fa fa-eye'+(conNewPasswordMode === 'text' ? '-slash' : ''), elementAttr:{class:'password-btn'}, onClick: changeConNewPasswordInputMode}}]}">
                                                        <dxo-label text="Re-type New Password"></dxo-label>
                                                        <dxi-validation-rule type="required"
                                                                             message="Re-type new password is required">
                                                        </dxi-validation-rule>
                                                        <dxi-validation-rule type="compare"
                                                                             [comparisonTarget]="passwordComparison"
                                                                             message="Password and Confirm Password do not match">
                                                        </dxi-validation-rule>
                                                    </dxi-item>
                                                </dx-form>
                                                <div class="mt-20" fxLayout="row" fxLayoutAlign="space-between center">
                                                    <dx-button text="Cancel" class="pulse-btn pulse-btn-normal"
                                                               (onClick)="changePasswordForm.instance.resetValues()">
                                                    </dx-button>
                                                    <dx-button text="Save" class="pulse-btn pulse-btn-primary"
                                                               [useSubmitBehavior]="true"
                                                               validationGroup="updatePassword">
                                                    </dx-button>
                                                </div>
                                            </form>

                                        </dxi-item>
                                    </dxi-tab>
                                    <dxi-tab title="Video Intro">
                                        <div class="p-30 pt-15 pb-15 content-area" fxLayout="column"
                                             fxLayoutAlign="center center">
                                            <div class="video-content" fxLayout="row"
                                                 fxLayoutAlign="space-between center">
                                                <video id="gum" #gum playsinline autoplay muted></video>
                                                <!--<video id="recorded" #recorded playsinline loop></video>-->
                                            </div>
                                            <div class="my-12" *ngIf="playButtonDisabled">
                                                <h2 class="my-0">{{displayTimer.minutes | number:
                                                    '2.0-0'}}:{{displayTimer.seconds | number: '2.0-0'}}</h2>
                                            </div>
                                            <div class="action-buttons" fxLayout="row">
                                                <button id="start" class="pulse-btn pulse-btn-normal"
                                                        (click)="startCamera()">
                                                    <i class="fa fa-video"></i>
                                                </button>
                                                <button id="record" class="pulse-btn pulse-btn-normal record"
                                                        (click)="startStopRec()"
                                                        [disabled]="!cameraStarted">
                                                    <i class="fa "
                                                       [ngClass]="{'fa-stop': isStarted, 'fa-circle': !isStarted}"></i>
                                                </button>

                                                <button class="pulse-btn pulse-btn-normal" (click)="playRec()"
                                                        [disabled]="playButtonDisabled">
                                                    <i class="fa fa-play"></i>
                                                </button>
                                                <!--<button class="pulse-btn pulse-btn-primary" id="play" >Play</button>-->
                                                <!--<button class="pulse-btn pulse-btn-normal" id="download" (click)="download()"-->
                                                <!--[disabled]="downloadButtonDisabled">-->
                                                <!--<i class="fa fa-save"></i>-->
                                                <!--</button>-->
                                            </div>
                                            <h4 *ngIf="introUrl"><label>Video Intro: </label><a
                                                href="javascript:void(0)"
                                                (click)="showIntro()">{{introUrl}}</a></h4>
                                        </div>

                                    </dxi-tab>
                                    <dxi-tab title="Login History">
                                        <div class="p-30 pt-15 pb-15 content-area" fxLayout="column"
                                        >
                                            <div *ngIf="loginHistory" class="login-history-container" fxLayout="column">
                                                <div class="dx-fieldset">
                                                    <!--<div class="dx-fieldset-header">Simple Field Set</div>-->
                                                    <div class="dx-field header">
                                                        <div class="dx-field-label">Time of attempt</div>
                                                        <div class="dx-field-value-static">Requested IP</div>
                                                    </div>
                                                    <div class="dx-field" *ngFor="let singleItem of loginHistory">
                                                        <div
                                                            class="dx-field-label">{{singleItem.time | date:"EEE dd LLLL, yyyy HH:mm:ss"}}</div>
                                                        <div
                                                            class="dx-field-value-static">{{singleItem.requested_ip}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </dxi-tab>
                                </dxi-item>
                            </dx-form>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- container -->
</div>

<dx-popup class="popup" [width]="500" [height]="350" [showTitle]="true" title="Video Intro" [dragEnabled]="false"
          [closeOnOutsideClick]="true" [(visible)]="popupVisible">

    <video controls autoplay #introElement>
        <source [src]="introUrl">
        <p>Your browser does not support the video element.</p>
    </video>

</dx-popup>
