import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthGuardService as AuthGuard} from '../../../services/auth/auth-guard.service';
import {AppConfig} from '../../../services/config/AppConfig';
import {LoginService} from "../../../services/login.service";

const routes: any = [
    {
        path: 'consultant',
        loadChildren: () => import('./consultant/consultant.module').then(m => m.ConsultantModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'client',
        loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
        canActivate: [AuthGuard]

    },
    {
        path: 'vendor',
        loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule),
        canActivate: [AuthGuard]

    },
    {
        path: 'admin-dashboard',
        loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
        canActivate: [AuthGuard]

    },
    {
        path: 'candidate-start',
        loadChildren: () => import('./candidate-start/candidate-start.module').then(m => m.CandidateStartModule),
        canActivate: [AuthGuard]

    },
    {
        path: 'admin-dashboard',
        loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
        canActivate: [AuthGuard]

    },
    {
        path: 'candidate-start',
        loadChildren: () => import('./candidate-start/candidate-start.module').then(m => m.CandidateStartModule),
        canActivate: [AuthGuard]

    },
    {
        path: 'email-templates',
        loadChildren: () => import('./email-template/email-template.module').then(m => m.EmailTemplateModule),
        canActivate: [AuthGuard]

    },
    {
        path: 'sms-templates',
        loadChildren: () => import('./sms-template/sms-template.module').then(m => m.SmsTemplateModule),
        canActivate: [AuthGuard]

    },
    {
        path: 'reports/add-drop-summary',
        loadChildren: () => import('./reports/add-drop-summary-report/add-drop-summary-report.module').then(m => m.AddDropSummaryReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/client-add-drop-summary',
        loadChildren: () => import('./reports/client-add-drop-summary-report/client-add-drop-summary-report.module').then(m => m.ClientAddDropSummaryReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/ae-rec-summary',
        loadChildren: () => import('./reports/ae-rec-summary-report/ae-rec-summary-report.module').then(m => m.AeRecSummaryReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/gp-target',
        loadChildren: () => import('./reports/gp-report/gp-report.module').then(m => m.GpReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/headcount-report',
        loadChildren: () => import('./reports/headcount-report/headcount-report.module').then(m => m.HeadcountReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/consultant-with-pnl',
        loadChildren: () => import('./reports/consultant-with-pnl/consultant-with-pnl.module').then(m => m.ConsultantWithPnlModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/backoffice-report',
        loadChildren: () => import('./reports/backoffice-report/backoffice-report.module').then(m => m.BackofficeReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/consultant-summary',
        loadChildren: () => import('./reports/consultant-summary/consultant-summary.module').then(m => m.ConsultantSummaryModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/active-gp-report',
        loadChildren: () => import('./reports/active-gp-report/active-gp-report.module').then(m => m.ActiveGpReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/consultant-contact-report',
        loadChildren: () => import('./reports/consultant-contact-report/consultant-contact-report.module').then(m => m.ConsultantContactReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/onboarding-status',
        loadChildren: () => import('./reports/onboarding-status/onboarding-status.module').then(m => m.OnboardingStatusModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/consultant-user-plan',
        loadChildren: () => import('./reports/consultant-user-plan/consultant-user-plan.module').then(m => m.ConsultantUserPlanModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'benefit/insurance-data',
        loadChildren: () => import('./manage-insurance-data/manage-insurance-data.module').then(m => m.ManageInsuranceDataModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'master-data',
        loadChildren: () => import('./master-data/master-data.module').then(m => m.MasterDataModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/consultant-with-no-start',
        loadChildren: () => import('./reports/consultant-with-no-start/consultant-with-no-start.module').then(m => m.ConsultantWithNoStartModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/team-performance-reports',
        loadChildren: () => import('./reports/team-performance-reports/team-performance-reports.module').then(m => m.TeamPerformanceReportsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'display/team-performance-reports',
        loadChildren: () => import('./reports/team-performance-report-display/team-performance-report-display.module').then(m => m.TeamPerformanceReportDisplayModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/consultant-onboarding-report',
        loadChildren: () => import('./reports/consultant-onboarding-report/consultant-onboarding-report.module').then(m => m.ConsultantOnboardingReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/recruiter-performance-report',
        loadChildren: () => import('./reports/recruiter-performance-report/recruiter-performance-report.module').then(m => m.RecruiterPerformanceReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/equipment-report',
        loadChildren: () => import('./reports/equipment-report/equipment-report.module').then(m => m.EquipmentReportModule),
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        CommonModule
    ],
    providers: [
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: (appConfig: AppConfig) =>
                () => appConfig.Init(),
            deps: [AppConfig, LoginService],
            multi: true
        }
    ]
})

export class OnboardingModule {
}
