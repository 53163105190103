<div id="forms" class="page-layout simple fullwidth" fxLayout="column" pulsePerfectScrollbar>
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper" id="sharePost">
                <div class="page-title-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading">
                        Social Logs
                    </div>
                    <button class="pulse-btn pulse-btn-primary has-icon" fxLayout="row" fxLayoutAlign="center"
                            (click)="goToSmbShare()" aria-label="Go to SMB Share">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>

                <div class="p-30 pt-15 pb-15 grid-wrapper">
                    <dx-data-grid id="gridContainer"
                                  [dataSource]="items"
                                  [columnAutoWidth]="false"
                                  [showBorders]="true"
                                  (onCellClick)="onCellClick($event)">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager
                            [showPageSizeSelector]="true"
                            [allowedPageSizes]="[10, 30, 50, 100]"
                            [showInfo]="true">
                        </dxo-pager>
                        <dxi-column
                            [width]="'40'"
                            caption="#"
                            dataField="ID"
                            dataType="number">
                        </dxi-column>
                        <dxi-column
                            [width]="'120'"
                            dataField="created_at"
                            caption="Date"
                            dataType="date"
                            format="MM-dd-yyyy">
                        </dxi-column>
                        <dxi-column
                            dataField="user_name"
                            caption="User name"
                            dataType="string">
                        </dxi-column>
                        <dxi-column
                            dataField="fb_message"
                            caption="LinkedIn Message"
                            dataType="string">
                        </dxi-column>
                        <dxi-column
                            dataField="tw_message"
                            caption="Twitter message"
                            dataType="string">
                        </dxi-column>
                        <dxi-column
                            [width]="'100'"
                            caption="Count"
                            dataType="number"
                            cellTemplate="countData">
                        </dxi-column>
                        <dxi-column [width]="'50'" dataField="Action" dataType="string" [allowFiltering]="false"
                                    cellTemplate="cellTemplate"></dxi-column>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>

                        <div *dxTemplate="let data of 'cellTemplate'">
                            <div>
                                <dx-menu [dataSource]="menuItems" displayExpr="name"
                                         (onItemClick)="optionClick($event)"></dx-menu>
                            </div>
                        </div>


                        <div *dxTemplate="let items of 'countData'">
                            <ul class='list-social'>
                                <!-- <li>Fb<div>{{items.data.fb_count}}</div></li> -->
                                <li>Tw
                                    <span>{{items.data.tw_count}}</span>
                                </li>
                                <li>Li
                                    <span>{{items.data.li_count}}</span>
                                </li>
                            </ul>
                        </div>
                    </dx-data-grid>

                </div>
            </div>
        </div>
    </div>

</div>

<dx-popup title="Logs"
          [(visible)]="isLogPopupVisible"
          [deferRendering]="false">
    <div *dxTemplate="let data of 'content'">
        <dx-data-grid id="test"
                      [dataSource]="logItems"
                      [showRowLines]="true"
                      [showBorders]="true"
                      [allowColumnResizing]="true">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[10, 30, 50, 100]"
                [showInfo]="true">
            </dxo-pager>
            <dxi-column [width]="200" dataField="userName"></dxi-column>
            <!--<dxi-column [width]="100" caption="Facebook" dataField="facebook" cellTemplate="facebook"></dxi-column>-->
            <dxi-column [width]="100" caption="Twitter" dataField="twitter" cellTemplate="twitter"></dxi-column>
            <dxi-column caption="LinkedIn" dataField="linkedIn" cellTemplate="linkedIn"></dxi-column>
            <dxo-filter-row [visible]="true"></dxo-filter-row>

            <div *dxTemplate="let logItems of 'linkedIn'">
                <div>
                    <dx-check-box
                        [readOnly]=true
                        [value]="logItems.data.linkedin">
                    </dx-check-box>
                    <a href="http://{{logItems.data.li_updateUrl}}"
                       target="_blank">{{logItems.data.li_updateUrl}}</a></div>
            </div>

            <div *dxTemplate="let logItems of 'twitter'">
                <div>
                    <dx-check-box
                        [readOnly]=true
                        [value]="logItems.data.twitter">
                    </dx-check-box>
                </div>
            </div>
        </dx-data-grid>

    </div>

</dx-popup>
