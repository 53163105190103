import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import {PulseMainComponent} from './main.component';
import {PulseHeaderModule} from './header/header.module';
import {PulseContentModule} from './content/content.module';
import {PulseFooterModule} from 'app/main/footer/footer.module';
import {LoginService} from '../services/login.service';
import {DxLoadPanelModule} from 'devextreme-angular';

@NgModule({
    declarations: [
        PulseMainComponent,
    ],
    imports: [
        RouterModule,
        MatSidenavModule,
        DxLoadPanelModule,
        PulseHeaderModule,
        PulseContentModule,
        PulseFooterModule
    ],
    exports: [
        PulseMainComponent
    ],
    providers: [LoginService]
})
export class PulseMainModule {
}
