<div class="page-layout simple fullwidth" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxLayoutAlign="space-between center"
                     fxFlex="1 0 auto">
                    <div class="pulse-main-heading" fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="logo-icon mr-12">today</mat-icon>
                        <span class="logo-text">Calendar</span>
                    <ng-container *ngIf="userPermissions.indexOf('touchpoint-administration') > -1">
                        <dx-select-box
                            *ngIf="currentUser.isAdmin || currentUser.isRM"
                            class="ml-12"
                            valueExpr="id"
                            displayExpr="name"
                            [dataSource]="allUsers"
                            (onValueChanged)="onUserChanged($event)"
                            placeholder="Select user"
                            [searchEnabled]="true">
                        </dx-select-box>
                        <dx-select-box
                            *ngIf="currentUser.isAdmin"
                            class="ml-12"
                            valueExpr="id"
                            displayExpr="name"
                            [dataSource]="allRoles"
                            placeholder="Select role"
                            (onValueChanged)="onRoleChanged($event)"
                            [searchEnabled]="true">
                        </dx-select-box>
                    </ng-container>

                    </div>
                    <div class="pulse-blue" fxLayout="row" fxLayoutAlign="center center">

                        <button mat-icon-button class="arrow" mwlCalendarPreviousView [view]="view"
                                [(viewDate)]="viewDate"
                                [excludeDays]="excludeDays" (viewDateChange)="selectedDay = {date:$event}"
                                aria-label="Previous">
                            <mat-icon>chevron_left</mat-icon>
                        </button>

                        <div class="title">
                            {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
                        </div>

                        <button mat-icon-button class="arrow" mwlCalendarNextView [view]="view"
                                [excludeDays]="excludeDays"
                                [(viewDate)]="viewDate" (viewDateChange)="selectedDay = {date:$event}"
                                aria-label="Next">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                    </div>
                    <!-- TOOLBAR -->
                    <div class="pulse-blue" fxLayout="row" fxLayoutAlign="start center">

                        <button mat-icon-button mwlCalendarToday [(viewDate)]="viewDate"
                                (viewDateChange)="selectedDay = {date:$event}"
                                aria-label="Today" matTooltip="Today">
                            <mat-icon>today</mat-icon>
                        </button>

                        <button mat-icon-button (click)="view='day'" [ngClass]="{'active': view =='day'}"
                                aria-label="Day" matTooltip="Day">
                            <mat-icon>view_day</mat-icon>
                        </button>

                        <button mat-icon-button (click)="view='week'" [ngClass]="{'active': view =='week'}"
                                aria-label="Week" matTooltip="Week">
                            <mat-icon>view_week</mat-icon>
                        </button>

                        <button mat-icon-button (click)="view='month'" [ngClass]="{'active': view =='month'}"
                                aria-label="Month" matTooltip="Month">
                            <mat-icon>view_module</mat-icon>
                        </button>

                        <button class="pulse-btn pulse-btn-primary has-icon" fxLayout="row" fxLayoutAlign="center"
                                (click)="addEvent()" aria-label="Add event">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="p-30 pt-15 pb-15 form-wrapper">
                    <div [ngSwitch]="view">
                        <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events"
                                                 [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
                                                 [excludeDays]="excludeDays"
                                                 (dayClicked)="dayClicked($event.day)"
                                                 (eventClicked)="editEvent('edit', $event.event)"
                                                 (eventTimesChanged)="eventTimesChanged($event)"
                                                 (beforeViewRender)="beforeMonthViewRender($event)">
                        </mwl-calendar-month-view>
                        <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate"
                                                (viewDateChange)="selectedDay = {date:$event}"
                                                [events]="events" [refresh]="refresh" [dayStartHour]="8"
                                                [dayEndHour]="19" [excludeDays]="excludeDays"
                                                (dayClicked)="dayClicked($event.day)"
                                                (eventClicked)="editEvent('edit', $event.event)"
                                                (eventTimesChanged)="eventTimesChanged($event)">
                        </mwl-calendar-week-view>
                        <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate"
                                               (viewDateChange)="selectedDay = {date:$event}"
                                               [events]="events" [refresh]="refresh" [dayStartHour]="8"
                                               [dayEndHour]="19" (dayClicked)="dayClicked($event)"
                                               (eventClicked)="editEvent('edit', $event.event)"
                                               (eventTimesChanged)="eventTimesChanged($event)">
                        </mwl-calendar-day-view>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!--<div id="calendar" class="page-layout simple fullwidth" pulsePerfectScrollbar>-->

<!--&lt;!&ndash; HEADER &ndash;&gt;-->
<!--<div class="header p-16 p-sm-24" [ngClass]="viewDate | date:'MMM'">-->

<!--<div class="header-content" fxLayout="column" fxLayoutAlign="space-between">-->

<!--<div class="header-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">-->

<!--<div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">-->
<!--<mat-icon class="logo-icon">-->
<!--today-->
<!--</mat-icon>-->
<!--<span class="logo-text">-->
<!--Calendar-->
<!--</span>-->
<!--</div>-->
<!--<div class="header-bottom" fxLayout="row" fxLayoutAlign="center center">-->

<!--<button mat-icon-button class="arrow"-->
<!--mwlCalendarPreviousView-->
<!--[view]="view"-->
<!--[(viewDate)]="viewDate"-->
<!--[excludeDays]="excludeDays"-->
<!--(viewDateChange)="selectedDay = {date:$event}"-->
<!--aria-label="Previous">-->
<!--<mat-icon>chevron_left</mat-icon>-->
<!--</button>-->

<!--<div class="title">-->
<!--{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}-->
<!--</div>-->

<!--<button mat-icon-button class="arrow"-->
<!--mwlCalendarNextView-->
<!--[view]="view"-->
<!--[excludeDays]="excludeDays"-->
<!--[(viewDate)]="viewDate"-->
<!--(viewDateChange)="selectedDay = {date:$event}"-->
<!--aria-label="Next">-->
<!--<mat-icon>chevron_right</mat-icon>-->
<!--</button>-->
<!--</div>-->
<!--&lt;!&ndash; TOOLBAR &ndash;&gt;-->
<!--<div class="toolbar" fxLayout="row" fxLayoutAlign="start center">-->

<!--<button mat-icon-button-->
<!--mwlCalendarToday-->
<!--[(viewDate)]="viewDate"-->
<!--(viewDateChange)="selectedDay = {date:$event}"-->
<!--aria-label="Today" matTooltip="Today">-->
<!--<mat-icon>today</mat-icon>-->
<!--</button>-->

<!--<button mat-icon-button (click)="view='day'" aria-label="Day" matTooltip="Day">-->
<!--<mat-icon>view_day</mat-icon>-->
<!--</button>-->

<!--<button mat-icon-button (click)="view='week'" aria-label="Week" matTooltip="Week">-->
<!--<mat-icon>view_week</mat-icon>-->
<!--</button>-->

<!--<button mat-icon-button (click)="view='month'" aria-label="Month" matTooltip="Month">-->
<!--<mat-icon>view_module</mat-icon>-->
<!--</button>-->
<!--</div>-->
<!--</div>-->
<!--&lt;!&ndash; / TOOLBAR &ndash;&gt;-->

<!--</div>-->

<!--&lt;!&ndash; ADD EVENT BUTTON &ndash;&gt;-->
<!--<button mat-fab class="add-event-button mat-accent" (click)="addEvent()" aria-label="Add event">-->
<!--<mat-icon>add</mat-icon>-->
<!--</button>-->
<!--&lt;!&ndash; / ADD EVENT BUTTON &ndash;&gt;-->
<!--</div>-->
<!--&lt;!&ndash; / HEADER &ndash;&gt;-->
<!--</div>-->
