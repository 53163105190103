import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-five-percentile',
  templateUrl: './five-percentile.component.html',
  styleUrls: ['./five-percentile.component.scss']
})
export class FivePercentileComponent implements OnInit {

  currentUser: any;
  frameUrl: any;
  constructor(private router: Router,
              public sanitizer: DomSanitizer) {
    this.currentUser = JSON.parse(localStorage.getItem('userData'));

    const roles = this.currentUser.roles;
    const roleArr = roles.split(',');

    if (roleArr.indexOf('1') < 0 && roleArr.indexOf('2') < 0 && roleArr.indexOf('17') < 0) {
      this.router.navigate(['/dashboard']);
    }

    this.frameUrl = `https://www.5percentile.com/pulse-auth?token=${localStorage.getItem('userToken')}&email=${this.currentUser.email}`;
    this.frameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.frameUrl);
  }

  ngOnInit() {
  }

}
