import {Component} from '@angular/core';

import {CommonService} from "app/services/common/common.service";

@Component({
    selector: 'pulse-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class PulseFooterComponent {

    buildNumber: string = "31ec324a";
    buildDate: string = "02.03.2022 9:30 AM";
    versionNumber: string = "2.5.2";
    currentYear: number = new Date().getFullYear();

    constructor(private commonService: CommonService) {

        this.commonService.getSystemReleaseInfo()
        .subscribe(data => {

            this.buildNumber= data['data']['buildNumber'];
            this.buildDate= data['data']['buildDate'];
            this.versionNumber= data['data']['versionNumber'];

        });

    }
}
