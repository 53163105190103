import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PulseSplashScreenService} from '@pulse/services/splash-screen.service';
import { PulseTranslationLoaderService} from '@pulse/services/translation-loader.service';
import {Title} from '@angular/platform-browser';
import {siteConfig} from './siteSettings';

@Component({
  selector: 'pulse-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pulse';

    constructor(
        private translate: TranslateService,
        private pulseSplashScreen: PulseSplashScreenService,
        private pulseTranslationLoader: PulseTranslationLoaderService,
        private titleService: Title
    ) {
        // Set the default language
        this.translate.setDefaultLang('en');
        // Use a language
        this.translate.use('en');
        this.titleService.setTitle(`${siteConfig.companyName} - Pulse`);
    }
}
