import { Injectable } from "@angular/core";
import { ChatManager, TokenProvider } from "@pusher/chatkit-client";
import { siteConfig } from "../../siteSettings";
import { OnDestroy, OnInit } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { NotificationService } from '@pulse/services/notification.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
@Injectable({
    providedIn: "root"
})
export class PusherService {
    private chatManager: any;
    public privateRooms: any = [];
    public roomsList: any = [];
    public chatRoomInitiation$ = new BehaviorSubject<any>(true);
    public currentPusherUser$ = new BehaviorSubject<any>(null);
    public pusherUpdate$ = new BehaviorSubject<any>(null);
    private loggedInUser: any;
    public isSubscribingToAllRoom$ = new BehaviorSubject<boolean>(true)
    constructor(private commonService: CommonService, private notificationService: NotificationService, private router: Router, private _httpClient: HttpClient) {
    }

    setLoggedInUser() {
        this.loggedInUser = JSON.parse(localStorage.getItem("userData"))
    }

    loadChat(wc_user_id) {
        if (!wc_user_id) {
            this.chatRoomInitiation$.next(false);
            return;
        }
        const chatManager = new ChatManager({
            userId: wc_user_id,
            instanceLocator: siteConfig.pusherInstanceLocator,
            tokenProvider: new TokenProvider({
                url: siteConfig.nodeAPIBaseUrl + "pusher/session/auth"
            })
        });

        chatManager.connect({
            onAddedToRoom: room => {
                this.pusherUpdate$.next({ type: "newUser", data: room })
                if (!this.privateRooms[room.id]) {
                    this.privateRooms[room.id] = room;
                }
                this.notificationService.sendBrowserNotification(
                    `New user ${room.name}`,
                    this.router.url
                );
                this.subscribeToRoom(room)
            }
        }).then(currentUser => {
            currentUser.rooms.forEach(room => {
                this.roomsList.push(room);
                if (!this.privateRooms[room.id]) {
                    this.privateRooms[room.id] = room;
                }
            });
            this.currentPusherUser$.next(currentUser);
            this.chatRoomInitiation$.next(false);
            if (this.roomsList.length > 0) {
                this.subscribeToEachRoom(this.roomsList);
            } else {
                this.isSubscribingToAllRoom$.next(false);
            }
        });
    }
    getPusherUserInstance() {
        return this.currentPusherUser$;
    }
    unSubscribeFromPusher() {
        this.currentPusherUser$.value.disconnect()
    }

    async subscribeToEachRoom(list) {
        for (let i = 0; i < list.length; i++) {
            try {
                let response = await this.subscribeToRoom(list[i])
            } catch (error) {
                console.log("Error for subscribing user", list[i], error);
            }
            // console.log("subricption detail", list[i]);
        }
        this.isSubscribingToAllRoom$.next(false);
    }

    subscribeToRoom(room) {
        return new Promise<void>((resolve, reject) => {
            this.currentPusherUser$.value.subscribeToRoomMultipart({
                roomId: room.id,
                hooks: {
                    onMessage: message => {
                        let sendMail = this.notificationService.sendBrowserNotification(
                            `New Message from ${message.senderId}`,
                            this.router.url
                        );
                        this.pusherUpdate$.next({ type: 'newMessage', data: message })
                        if (sendMail) {
                            this
                                .sendMailToRecruiter(
                                    room,
                                    message.senderId,
                                    message
                                )
                                .subscribe(response => {
                                    /* console.log(
                                        "TCL: ChatChatsSidenavComponent -> response",
                                        response
                                    ); */
                                });
                        }
                    }
                },
                messageLimit: 0
            }).then(resp => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    }

    sendMailToRecruiter(roomStore, clientMail, message) {
        let payLoad = {
            to: this.loggedInUser.email,
            from: roomStore.createdByUserId,
            subject: `New Message from ${roomStore.userStore.users[clientMail].name}`,
            recruiterName: `${this.loggedInUser.first_name} ${this.loggedInUser.last_name}`,
            userName: roomStore.userStore.users[clientMail].name,
            message: this.findTextMessage(message.parts),
            link: `${siteConfig.baseApiHost}/webchat/chat/${roomStore.createdByUserId} `
        };
        return this.commonService.sendEmailNotificationForChat(payLoad).pipe(
            map((response: any) => {
                return response;
            }),
            catchError(err => {
                throw err;
            })
        );
    }
    findTextMessage(parts) {
        let index = parts.findIndex(part => part.partType == "inline");
        return index != -1 ? parts[index].payload.content : "";
    }
}
