<div  id="forms" class="page-layout simple fullwidth" fxLayout="column" pulsePerfectScrollbar>
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading">
                        {{currentUser}}
                    </div>
                    <button class="pulse-btn pulse-btn-primary has-icon" fxLayout="row" fxLayoutAlign="center"
                            (click)="goToSmbShare()" aria-label="Go to SMB Share">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>

                <div class="p-30 grid-wrapper _auth">
                    <!-- <div class="form-group" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
                        <div class="wdt-15">
                            <dx-button (click)="facebookSignIn()" type="button" text="Facebook" class="btn btn-fb"></dx-button>
                        </div>
                        <div>
                            <div class="dx-field flex">
                                <dx-check-box [value]="data.fb_status"
                                    [readOnly]="true"></dx-check-box>
                            </div>
                        </div>
                        <div>
                            <div class="dx-field">
                               <dx-button
                                text="Deauthorize"
                                type="button"
                                 (click)="deAuthorized('fb')"
                                [useSubmitBehavior]="true"
                                [visible]="data.fb_status"
                                class="pulse-btn pulse-btn-primary pull-right">
                            </dx-button>
                            </div>
                        </div>
                    </div> -->
                    <div class="form-group" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
                        <div class="wdt-15">
                            <dx-button (click)="socialSignIn('linkedin')" type="button" text="Linkedin" class="btn btn-li"></dx-button>
                        </div>
                        <div>
                            <div class="dx-field flex">
                                <dx-check-box [value]="data.li_status"
                                    [readOnly]="true"></dx-check-box>
                            </div>
                        </div>
                        <div>
                            <div class="dx-field">
                                <dx-button
                                text="Deauthorize"
                                type="button"
                                (click)="deAuthorized('li')"
                                [useSubmitBehavior]="true"
                                [visible]="data.li_status"
                                class="pulse-btn pulse-btn-primary pull-right">
                            </dx-button>
                            </div>
                        </div>
                    </div>
                    <div fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
                        <div class="wdt-15">
                            <dx-button (click)="twitterLogin()" type="button" text="Twitter" class="btn btn-tw"></dx-button>
                        </div>
                        <div>
                            <div class="dx-field flex">
                                <dx-check-box [value]="data.tw_status"
                                    [readOnly]="true"></dx-check-box>
                            </div>
                        </div>
                        <div>
                            <div class="dx-field">
                                <dx-button
                                text="Deauthorize"
                                type="button"
                                (click)="deAuthorized('tw')"
                                [useSubmitBehavior]="true"
                                [visible]="data.tw_status"
                                class="pulse-btn pulse-btn-primary pull-right">
                            </dx-button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
