import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PulseSharedModule } from '@pulse/shared.module';
import { HomePageModule } from 'app/home/homepage/homepage.module';
import { LoginService } from '../services/login.service';

import { AccountVerificationComponent } from './account-verification/account-verification.component';
import {EeoDetailsComponent} from './eeo-details/eeo-details.component';
import {CandidateChatComponent} from './candidate-chat/candidate-chat.component';
import {EeoDetailsModule} from './eeo-details/eeo-details.module';
import {CandidateChatModule} from './candidate-chat/candidate-chat.module';
import {EquipmentReleaseModule} from './equipment-release/equipment-release.module';
import { ConsultantService } from '../services/consultant/consultant.service';

import { MeetupService } from 'app/services/meetup/meetup.service';
import { VedioAudioDialogComponent } from "./vedio-audio-dialog/vedio-audio-dialog.component";
import { JitsiVedioAudioDialogComponent } from "./jitsi-vedio-audio-dialog/jitsi-vedio-audio-dialog.component";
import { VedioAudioDialogModule } from './vedio-audio-dialog/vedio-audio-dialog.module';
import { JitsiVedioAudioDialogModule } from './jitsi-vedio-audio-dialog/jitsi-vedio-audio-dialog.module';

const routes: any = [
    {
        path: 'home/forgot-password',
        loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
        resolve: [LoginService]
    },
    {
        path: 'home/reset-password',
        loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
        resolve: [LoginService]
    },
    {
        path: 'home/login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        resolve: [LoginService]
    },
    {
        path: 'home/register',
        loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
        resolve: [LoginService]
    },
    {
        path: 'account-verification/:code',
        component: AccountVerificationComponent,
    },
    {
        path: 'eeo-detail/:consultantId',
        component: EeoDetailsComponent,
    },
    {
        path: 'candidate-chat/:code',
        component: CandidateChatComponent,
    },
    {
        path: 'meetup/:code',
        loadChildren: () => import('./meetup/meetup.module').then(m => m.MeetupModule),
        resolve: [MeetupService]
    },
    {
        path: 'bot/:recruiterId/:chatId',
        loadChildren: () => import('./bot/bot.module').then(m => m.BotModule),
    }
];

@NgModule({
    declarations: [AccountVerificationComponent],
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        MatProgressBarModule,
        MatSidenavModule,
        PulseSharedModule,
        HomePageModule,
        EeoDetailsModule,
        CandidateChatModule,
        EquipmentReleaseModule,
        VedioAudioDialogModule,
        JitsiVedioAudioDialogModule
    ],
    exports: [],
    providers: [
        LoginService,
        MeetupService
    ]
})
export class HomeModule {
}
