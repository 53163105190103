<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0" pulsePerfectScrollbar>

        <form name="eventForm" [formGroup]="eventForm" class="event-form w-100-p" fxLayout="column" fxFlex>
            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row" *ngIf="userPermissions.indexOf('touchpoint-administration') > -1">
                <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
                    <mat-label>Select User</mat-label>
                    <mat-select formControlName="selectedUser" [(value)]="event.meta.user" >
                        <mat-option *ngFor="let user of users" [value]="user.id">
                            {{user.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--<mat-form-field class="pr-sm-8" fxFlex="50" >-->
                    <!--<mat-select formControlName="role" [(value)]="event.meta.role" placeholder="Select role">-->
                        <!--<mat-option value="2">Admin</mat-option>-->
                        <!--<mat-option value="3">Recruiter</mat-option>-->
                    <!--</mat-select>-->
                <!--</mat-form-field>-->

            </div>

            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row" *ngIf="!showAddCompanyForm && !showAddContactForm">

                <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
                    <mat-label>Select Event</mat-label>
                    <mat-select  formControlName="eventType" required matInput
                                [(value)]="event.meta.eventType"
                                 [disabled]="showAddCompanyForm"
                                (selectionChange)="eventTypeChanged($event.value)">
                        <mat-option *ngFor="let eventType of eventTypes" [value]="eventType.value">
                            {{eventType.master_lookup_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50" *ngIf="hideFields">
                    <mat-label>Select Company</mat-label>
                    <mat-select formControlName="company" [(value)]="event.meta.company"
                                required matInput
                                [disabled]="showAddCompanyForm"
                                (selectionChange)="onCompanyChanged($event)">
                        <mat-option *ngFor="let company of companies" [value]="company.id">
                            {{company.name}}
                        </mat-option>

                    </mat-select>
                    <mat-hint *ngIf="action !== 'info'">
                        <a href="javascript:" (click)="showAddCompanyForm = true" *ngIf="!showAddContactForm">Add Company</a>
                    </mat-hint>
                </mat-form-field>

            </div>

            <!-- Add company Form start -->
            <div fxFlex="1 0 auto" *ngIf="showAddCompanyForm">
                <form name="companyForm" fxLayout="column" #companyForm="ngForm">
                    <h3>Add Company</h3>

                    <div fxLayout="row" >
                        <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
                            <mat-label>Company Name</mat-label>
                            <input matInput required
                                   name="companyName" [ngModel]="companyFormData.name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Website</mat-label>
                            <input matInput name="website" [ngModel]="companyFormData.website">
                        </mat-form-field>
                    </div>

                    <div class="pb-20" fxLayout="row" fxLayoutAlign="space-between center">
                        <button class="pulse-btn pulse-btn-normal" (click)="showAddCompanyForm = false">Cancel</button>
                        <button class="pulse-btn pulse-btn-primary" [disabled]="companyForm.invalid" (click)="addCompany(companyForm)">Submit</button>
                    </div>
                </form>
            </div>
            <!-- Add company Form over -->

            <div fxFlex="1 0 auto" fxLayout="row" fxLayout.gt-xs="row"
                 *ngIf="hideFields && !showAddCompanyForm && !showAddContactForm">
                <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
                    <mat-label>Location</mat-label>
                    <input matInput
                           name="location"
                           formControlName="location">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Contact</mat-label>

                    <mat-select formControlName="contact" [(value)]="event.meta.contact" required
                                placeholder="Select contact" [disabled]="contacts && contacts.length <= 0">
                        <mat-option *ngFor="let contact of contacts" [value]="contact.id">
                            {{contact.first_name + ' ' + contact.last_name}}
                        </mat-option>

                    </mat-select>
                    <mat-hint *ngIf="action !== 'info' && eventForm.get('company').value">
                        <a href="javascript:" (click)="showAddContactForm = true" >Add Contact</a>
                    </mat-hint>
                </mat-form-field>
            </div>

            <!--  Add Contact Form start -->
            <div fxFlex="1 0 auto" *ngIf="showAddContactForm">
                <form name="contactForm" fxLayout="column" #contactForm="ngForm">
                    <h3>Add Contact</h3>

                    <div fxLayout="row" >
                        <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
                            <mat-label>First Name</mat-label>
                            <input matInput required
                                   name="contactFirstName" [(ngModel)]="contactFormData.firstName">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Last Name</mat-label>
                            <input matInput required
                                   name="contactLastName" [(ngModel)]="contactFormData.lastName">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" >
                        <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
                            <mat-label>Job Title</mat-label>
                            <input matInput
                                   name="contactJobTitle" [(ngModel)]="contactFormData.jobTitle">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Email</mat-label>
                            <input matInput required email
                                   name="contactEmail" [(ngModel)]="contactFormData.email">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" >
                        <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
                            <mat-label>Phone</mat-label>
                            <input matInput
                                   name="contactPhone" [(ngModel)]="contactFormData.phone">
                        </mat-form-field>

                    </div>
                    <div class="pb-20" fxLayout="row" fxLayoutAlign="space-between center">
                        <button class="pulse-btn pulse-btn-normal" (click)="showAddContactForm = false">Cancel</button>
                        <button class="pulse-btn pulse-btn-primary" (click)="addContact(contactForm)">Submit</button>
                    </div>
                </form>
            </div>
            <!--  Add Contact Form over -->

            <div class="pb-32" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center" *ngIf="!showAddCompanyForm && !showAddContactForm">

                <mat-slide-toggle name="allDay" formControlName="allDay"
                                  (change)="changeAllDay($event)" fxFlex="50" aria-label="All day">
                    All Day
                </mat-slide-toggle>
                <label>Timezone: {{localTimeZoneOffset}} </label>
            </div>

            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row" *ngIf="!showAddCompanyForm && !showAddContactForm">

                <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
                    <mat-label>Start date</mat-label>
                    <input matInput formControlName="start" type="datetime-local" required (change)="onStartDateChanged($event)"
                           autocomplete="false">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>End date</mat-label>
                    <input matInput formControlName="end" type="datetime-local" required [min]="eventForm.get('start').value"
                           autocomplete="false">
                </mat-form-field>

            </div>

            <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row" *ngIf="hideFields && !showAddCompanyForm && !showAddContactForm">
                <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="50">
                    <mat-label>Meal Cost</mat-label>
                    <input matInput
                           name="mealCost"
                           formControlName="mealCost">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Miles</mat-label>
                    <input matInput
                           name="miles"
                           formControlName="miles">
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline" class="w-100-p" *ngIf="!showAddCompanyForm && !showAddContactForm">
                <mat-label>Notes</mat-label>
                <textarea matInput
                          formControlName="comments"
                          mat-maxlength="250"
                          max-rows="4">
                </textarea>
            </mat-form-field>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center"
    *ngIf="!showAddCompanyForm && !showAddContactForm">

        <button  class="mr-8 pulse-btn pulse-btn-normal"
                 (click)="matDialogRef.close()" aria-label="Cancel">
            Cancel
        </button>
        <!--<button *ngIf="action ==='edit'"-->
                <!--class="mr-8 pulse-btn pulse-btn-primary"-->
                <!--(click)="matDialogRef.close(['delete',eventForm])"-->
                <!--aria-label="Delete"-->
                <!--matTooltip="Delete">-->
            <!--DELETE-->
        <!--</button>-->

        <button *ngIf="action !== 'edit'"
                mat-button color="primary"
                class="save-button pulse-btn pulse-btn-primary"
                (click)="matDialogRef.close([eventForm, contacts])"
                [disabled]="eventForm.invalid"
                aria-label="Submit">
            Submit
        </button>

        <button *ngIf="action === 'edit'"
                mat-button color="primary"
                class="save-button pulse-btn pulse-btn-primary"
                (click)="matDialogRef.close([eventForm, contacts])"
                [disabled]="eventForm.invalid"
                aria-label="Update">
            Update
        </button>

    </div>
</div>
