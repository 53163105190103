import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import { PulseSharedModule } from '@pulse/shared.module';

import {CanDeactivateGuard} from 'app/services/can-deactivate/can-deactivate.guard';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { DxDataGridModule,
  DxSelectBoxModule,
  DxTextAreaModule,
  DxFormModule,
  DxPopupModule,
  DxButtonModule,
  DxTemplateModule,
  DxCheckBoxModule,
  DxScrollViewModule,DxTooltipModule,
  DxMenuModule,DxDateBoxModule,
  DxRadioGroupModule, DxTagBoxModule, DxValidatorModule, DxHtmlEditorModule,DxTextBoxModule  } from "devextreme-angular";  
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { WorkerUpdateComponent } from './worker/worker-update/worker-update.component';
import { WorkerSearchComponent } from './worker/worker-search/worker-search.component';
import { TimesheetUpdateComponent } from './timesheet/timesheet-update/timesheet-update.component';
import { TimesheetSearchComponent } from './timesheet/timesheet-search/timesheet-search.component';
import { VmsadminComponent } from './vmsadmin/vmsadmin.component';

import { VmsServiceService } from 'app/services/vms-service/vms-service.service' ;

import {DatePipe} from '@angular/common'

const routes: Routes = [
  {
      path: 'vms/worker/worker-update',
      component: WorkerUpdateComponent,
      canDeactivate: [CanDeactivateGuard]
  },
  {
      path: 'vms/worker/worker-search',
      component: WorkerSearchComponent,
      canDeactivate: [CanDeactivateGuard]
  },
  {
      path: 'vms/timesheet/timesheet-update',
      component: TimesheetUpdateComponent,
      canDeactivate: [CanDeactivateGuard]
  },
  {
      path: 'vms/timesheet/timesheet-search',
      component: TimesheetSearchComponent,
      canDeactivate: [CanDeactivateGuard]
  },
  {
      path: 'vms/vmsAdminPanel',
      component: VmsadminComponent,
      canDeactivate: [CanDeactivateGuard]
  }
];

@NgModule({
  declarations: [
    WorkerUpdateComponent,
    WorkerSearchComponent,
    TimesheetUpdateComponent,
    TimesheetSearchComponent,
    VmsadminComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    PulseSharedModule,
    DxDataGridModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxFormModule,
    DxPopupModule,
    DxButtonModule,
    DxTemplateModule,
    DxCheckBoxModule,
    DxScrollViewModule,
    DxTooltipModule,
    DxMenuModule,
    DxDateBoxModule,
    DxRadioGroupModule, 
    DxTagBoxModule, 
    DxValidatorModule, 
    DxHtmlEditorModule,
    DxTextBoxModule,
    PdfViewerModule,
    RoundProgressModule
  ],
  providers: [VmsServiceService, DatePipe]
})
export class VmsModule { }
