import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { PulseSharedModule } from '@pulse/shared.module';

import {
  DxTemplateModule,
  DxTagBoxModule,
  DxTextBoxModule,
  DxSelectBoxModule,
  DxFormModule,
  DxButtonModule,
  DxDataGridModule,
  DxValidatorModule
} from 'devextreme-angular';

import { JobPortalExtractComponent } from './job-portal-extract.component';

import { JobPortalService } from 'app/services/job-portal/job_portal.service';

const routes: Routes = [{
  path: 'jobportal/extract',
  component: JobPortalExtractComponent
}];

@NgModule({
  declarations: [
    JobPortalExtractComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    BrowserModule,
    PulseSharedModule,
    DxTemplateModule,
    DxTagBoxModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxFormModule,
    DxButtonModule,
    DxDataGridModule,
    DxValidatorModule
  ],
  exports: [
    JobPortalExtractComponent
  ]
})

export class JobPortalExtractModule {}
