import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {TranslateModule} from '@ngx-translate/core';

import {PulseSharedModule} from '@pulse/shared.module';

import {PulseSampleComponent} from './sample.component';
import {DxFormModule, DxButtonModule, DxDataGridModule} from 'devextreme-angular';

import {AuthGuardService as AuthGuard} from 'app/services/auth/auth-guard.service';

const routes = [
    {
        path: 'sample',
        component: PulseSampleComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        PulseSampleComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        PulseSharedModule,

        DxFormModule,
        DxButtonModule,
        DxDataGridModule
    ],
    exports: [
        PulseSampleComponent
    ]
})

export class PulseSampleModule {
}
