<mat-toolbar class="p-0" *ngIf="showHeader">

    <mat-progress-bar *ngIf="showLoadingBar" class="loading-bar" color="accent" mode="indeterminate"></mat-progress-bar>

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center" class="toolbar-wrapper container">

        <div fxFlex="35" fxLayout="row" fxLayoutAlign="start center">

            <div fxLayout="row" fxLayoutAlign="start center">
                <a class="logo" [routerLink]="isLoggedIn ? ((isAdmin) ? '/admin-dashboard' : '/dashboard') : '/home'"
                   *ngIf="!href.includes('/candidate-chat')">
                    <img class="logo-icon" [src]="siteConfig.logo" draggable="false">
                </a>

                <a class="logo" [routerLink]="isLoggedIn ? ((isAdmin) ? '/admin-dashboard' : '/dashboard') : '/home'"
                   *ngIf="href.includes('/candidate-chat')">
                    <img class="logo-icon" [src]="siteConfig.logo" draggable="false">
                </a>
            </div>

            <div class="main-left-nav navbar-horizontal ml-20" *ngIf="isLoggedIn" fxFlex="1 0 auto" fxLayout="row">
                <pulse-navigation [navigation]="leftNavigation"></pulse-navigation>
            </div>
        </div>

        <div fxFlex="65" fxLayout="row" [fxLayoutAlign]="isLoggedIn ? 'space-between center' : 'flex-end center'">
            <div [fxLayoutAlign]="'display-flex'">
                <div class="global-search" *ngIf="isLoggedIn && searchBoxVisible">
                    <dx-select-box [dataSource]="selectBoxDataSource" displayExpr="NamewithEmail" (onValueChanged)="searchConsultant()"
                                   valueExpr="ConsultantID" placeholder="Type minimum 3 characters to search"
                                   [(ngModel)]='searchedConsultantId' [minSearchLength]="3"
                                   [searchEnabled]="true" [showClearButton]="true">
                    </dx-select-box>

                </div>
                <div class="main-right-nav" *ngIf="isLoggedIn">
                    <ul class="right-nav-wrapper">
                        <ng-container *ngFor="let item of rightNavigation">
                            <li class="nav-item" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
                                <a [routerLink]="[item.url]"  class="nav-link" matRipple matTooltip="{{item.title}}" >
                                    <i class="{{item.icon}}"></i>
                                </a>
                            </li>
                        </ng-container>
                        <li class="nav-item dropdown">
                            <a class="nav-link notification-icon" matRipple matTooltip="Notification" [ngClass]="{'new': newMessageReceived}"
                               (click)="notificationClick()">
                                <i class="fa fa-bell"></i>
                            </a>
                            <div class="dropdown-content" [hidden]="!showNotifications">
                                <ng-container *ngIf="notifications.length > 0; else noNotifications">
                                    <a *ngFor="let notify of notifications" [routerLink]="['/webchat/chat']"
                                       [queryParams]="{'user': notify.contactId || notify.senderId }"
                                       (click)="removeClickedNotification(notify)" class="notification-row" fxLayout="row">
                                        <span class="icon-part mr-4">
                                            <i class="fas fa-comment"></i>
                                        </span>
                                        <div class="msg-part" fxLayout="column" fxFlex="1 1 auto">
                                            <span>
                                                <strong>{{notify.name}}</strong> wants to chat with you.
                                            </span>
                                            <span class="time-part">
                                                <span class="date-time-format"> {{today | date:'h:mm a z'}} </span>
                                            </span>
                                        </div>

                                    </a>
                                </ng-container>

                                <ng-template #noNotifications>
                                    <a href="javascript:" class="notification-row"> No new
                                        notifications.  {{today | date:'h:mm a z'}} </a>
                                </ng-template>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <a *ngIf="!isLoggedIn && isLoginButtonVisible && !href.includes('/candidate-chat')"
                   [routerLink]="'login'" class="pulse-btn pulse-btn-primary mr-8">Login</a>
                <!--
                            <a *ngIf="isLoggedIn" class="newhire-btn" matTooltip="Add New Hire" [routerLink]="['newhire']">
                                <mat-icon>add</mat-icon>
                            </a>-->
                <button *ngIf="isLoggedIn" mat-button class="user-button">
                    <div fxLayout="row" fxLayoutAlign="flex-end center">
                        <div class="username-info  mr-20">
                            <span class="username" fxHide fxShow.gt-sm>
                                <strong>{{currentUser.fullName}}</strong>
                            </span >
                            <br/>
                            <span class="usertype" fxHide fxShow.gt-sm>{{currentUser.displayRoleName}}</span>
                            <!--<span class="usertype" fxHide fxShow.gt-sm *ngIf="adminFlag!=true">User</span>-->
                        </div>
                        <!--<img src="assets/icons/icon-user.svg"> -->
                        <div class="avatar-thumb"><img [src]="currentUser.avatar || defaultAvatarUrl" width="40" height="40"></div>
                        <mat-icon class="s-24" [matMenuTriggerFor]="userMenu" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>

                <mat-menu #userMenu="matMenu" [overlapTrigger]="false" backdropClass="user-dropdown" xPosition="before" class="user-button-dropdown" >

                    <a mat-menu-item routerLink="/my-profile">
                        <mat-icon>account_circle</mat-icon>
                        <span>My Profile</span>
                    </a>

                    <a *ngIf="isLoggedIn" mat-menu-item [routerLink]="['smbauth']">
                        <mat-icon>cast_connected</mat-icon>
                        <span>SMB</span>
                    </a>

                    <!--<button mat-menu-item class="">-->
                    <!--<mat-icon>mail</mat-icon>-->
                    <!--<span>Inbox</span>-->
                    <!--</button>-->

                    <button mat-menu-item (click)="logout()" class="">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Logout</span>
                    </button>

                </mat-menu>
            </div>
            <!--    <div *ngIf="isLoggedIn" class="toolbar-separator"></div>

                 <!--<fuse-search-bar *ngIf="isLoggedIn" (onInput)="search($event)"></fuse-search-bar>-->

            <!--<div *ngIf="isLoggedIn" class="toolbar-separator"></div>-->

            <!--<button *ngIf="isLoggedIn" mat-button fxHide fxShow.gt-xs-->
            <!--class="language-button"-->
            <!--[matMenuTriggerFor]="languageMenu">-->
            <!--<div fxLayout="row" fxLayoutAlign="center center">-->
            <!--<img class="flag mr-8" [src]="'assets/images/flags/'+selectedLanguage.flag+'.png'">-->
            <!--<span class="iso text-uppercase">{{selectedLanguage.id}}</span>-->
            <!--</div>-->
            <!--</button>-->

            <!--     <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                     <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                         <div fxLayout="row" fxLayoutAlign="start center">
                             <img class="flag mr-16" [src]="'assets/images/flags/'+lang.flag+'.png'">
                             <span class="iso">{{lang.title}}</span>
                         </div>
                     </button>

                 </mat-menu>

                 <div *ngIf="isLoggedIn" class="toolbar-separator" fxHide fxShow.gt-xs></div>
     -->
            <!-- <button mat-icon-button
                    class="mat-icon-button quick-panel-toggle-button"
                    fuseMatSidenavToggler="quick-panel"
                    aria-label="Toggle quick panel">
                <mat-icon class="icon">format_list_bulleted</mat-icon>
            </button> -->

        </div>
    </div>
</mat-toolbar>
