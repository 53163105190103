import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {siteConfig} from '../../siteSettings';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

      constructor(private _http: HttpClient) { }

    getSMSReport(postData) {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/consolidateSMSReport`;
        return this._http.post(url, postData);
    }

    getHeadCountReport(): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/reportOne`;
        return this._http.get<any>(url);
    }

    getConsultantReport(params: any): Observable<any> {
        /*let url = `${siteConfig.nodeAPIBaseUrl}reports/reportBOConsultants`;
        return this._http.get<any>(url, params);*/

        const url = `${siteConfig.nodeAPIBaseUrl}reports/reportBOConsultants?job_status_id=${params.job_status_id}` +
            `&pay_term=${params.pay_term}&start_date_from=${params.start_date_from}&start_date_to=${params.start_date_to}` +
            `&start_status=${params.start_status}&terms=${params.terms}`;
        return this._http.get<any>(url);
    }

    getConsultantWithPNLData(): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/reportBOConsultantsWithPNL`;
        return this._http.get<any>(url);
    }

    getGPTargetReportData(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/reportGPTarget?userID=${data.userId}&dateForm=${data.dateFrom}&dateTo=${data.dateTo}&includeFutureData=${data.includeFutureData}&clientId=${data.clientId}&userRole=${data.userRole}`;
        return this._http.get<any>(url);
    }

    getAddDropReportData(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/reportAddDrop?userID=${data.userId}&dateForm=${data.dateFrom}&dateTo=${data.dateTo}&includeFutureData=${data.includeFutureData}&payrollLoad=${data.payrollLoad}`;
        return this._http.get<any>(url);
    }

    getClientAddDropReportData(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/reportClientAddDrop?userID=${data.userId}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}&includeFutureData=${data.includeFutureData}&payrollLoad=${data.payrollLoad}`;
        return this._http.get<any>(url);
    }

    getAeRecSummaryReportData(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/reportAERECSummery?roleID=${data.roleId}&dateForm=${data.dateFrom}&dateTo=${data.dateTo}&userStatus=${data.userStatus}&userId=${data.userId}&status=${data.status}`;
        return this._http.get<any>(url);
    }

    getGPReportConsultantDetails(type, data): Observable<any> {
        let queryString = data.reportName ? `&reportName=${data.reportName}` : '';
        queryString += data.dateTo ? `&dateTo=${data.dateTo}` : '';
        queryString += data.clientId ? `&clientId=${data.clientId}` : '';
        const url = `${siteConfig.nodeAPIBaseUrl}reports/reportGPTargetConsultantDetails?reportType=${type}&userID=${data.userId}&dateForm=${data.dateFrom}&includeFutureData=${data.includeFutureData}${queryString}&userRole=${data.userRole}`;
        return this._http.get<any>(url);
    }

    getClientReportConsultantDetails(type, data): Observable<any> {
        let queryString = data.reportName ? `&reportName=${data.reportName}` : '';
        queryString += data.dateTo ? `&dateTo=${data.dateTo}` : '';
        queryString += data.clientId ? `&clientId=${data.clientId}` : '';
        const url = `${siteConfig.nodeAPIBaseUrl}reports/reportClientConsultantDetails?reportType=${type}&userID=${data.userId}&dateFrom=${data.dateFrom}&includeFutureData=${data.includeFutureData}${queryString}`;
        return this._http.get<any>(url);
    }

    getActiveGPReport(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/reportActiveGP`;
        return this._http.post<any>(url, data);
    }

    getActiveConsultant(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/report-active-consultant`;
        return this._http.post<any>(url, data);
    }

    getOnboardingStatus(hasFullAccess): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/report-onboarding-status?hasFullAccess=${hasFullAccess}`;
        return this._http.get<any>(url);
    }

    getConsultantUserPlan(): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/report-consultant-user-plan`;
        return this._http.get<any>(url);
    }

    updateOnboardingStatusReport(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/update-report-onboarding-status`;
        return this._http.post<any>(url, data);
    }

    getNoStartConsultantReport(): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/report-consultant-no-start`;
        return this._http.get<any>(url);
    }

    getOnboardConsultantReport(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/report-onboard-consultant?period=${data.period}&no_start=${data.no_start}&start_date=${data.start_date}&end_date=${data.end_date}&job_status=${data.job_status}`;
        return this._http.get<any>(url, data);
    }

    getRecruiterPerformanceReport(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/recruiter-performance-report?period=${data.period}&last_n_months=${data.last_n_months}&max_placements=${data.max_placements}&start_date=${data.start_date}&end_date=${data.end_date}`;
        return this._http.get<any>(url, data);
    }

    getRecruiterPerformanceReportJD(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/recruiter-performance-report-job-diva?period=${data.period}&last_n_months=${data.last_n_months}&max_placements=${data.max_placements}&start_date=${data.start_date}&end_date=${data.end_date}`;
        return this._http.get<any>(url, data);
    }

    getRecruiterPerformanceCandidates(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/recruiter-performance-candidates?user_id=${data.user_id}&last_n_months=${data.last_n_months}&candidate_type=${data.candidate_type}`;
        return this._http.get<any>(url, data);
    }

    getEquipmentReportData(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/reportEquipment?clientId=${data.clientId}&type=${data.type}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}&status=${data.status}&jobStatus=${data.jobStatus}`;
        return this._http.get<any>(url);
    }

    /*=================================
                LOGS REPORT
    =================================*/
    getLoginLogsReport(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}user-activity-log/login-log?date=${data.date}`;
        return this._http.get<any>(url, data);
    }

    getActivityLogReport(data): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}user-activity-log/activity-log?user_id=${data.user_id}&period=${data.period}&isLoginLog=${data.isLoginLog}`;
        return this._http.get<any>(url, data);
    }

    getDynamicReport(fields): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/dynamic-report`;
        return this._http.post<any>(url, fields);
    }

    saveDynamicReportSearchHistory(body): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/dynamic-report-search-history`;
        return this._http.post<any>(url, body);
    }

    removeDynamicReportSearchHistory(id): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/remove-dynamic-report-search-history/${id}`;
        return this._http.delete<any>(url);
    }

    getDynamicReportSearchHistory(): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/get-dynamic-report-search-history`;
        return this._http.get<any>(url);
    }

    updateDynamicReportSearchHistory(id, body): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/update-dynamic-report-search-history/${id}`;
        return this._http.put<any>(url, body);
    }

    getDynamicReportCountDetails(fields): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/dynamic-report-count-details`;
        return this._http.post<any>(url, fields);
    }

    userActivityLogs(data: any): Observable<any> {
        const url = `${siteConfig.nodeAPIBaseUrl}reports/user-api-logs`;
        return this._http.post<any>(url, data);
    }

}
