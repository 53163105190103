export const environment = {
    firebase: {
      apiKey: "AIzaSyBbqji9p5OkMp4Jvr6YXatTQ4nRutcLC1c",
      authDomain: "aspsmb-1651e.firebaseapp.com",
      databaseURL: "https://aspsmb-1651e.firebaseio.com",
      projectId: "aspsmb-1651e",
      storageBucket: "aspsmb-1651e.appspot.com",
      messagingSenderId: "644213638554"
    },
    li_token_link: {
        link: "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=81isnkinlb13gy&redirect_uri=https%3A%2F%2Fpulse.aptask.com%2Fsmbauth&state=987654321&scope=w_member_social,r_basicprofile,r_liteprofile"
        // link: "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=818b4svu5fe5k5&redirect_uri=http%3A%2F%2Flocalhost:4200%2Fsmbauth&state=987654321&scope=w_member_social,r_basicprofile,r_liteprofile"
    },
    li_credential: {
      client_id: '81isnkinlb13gy',
      client_secreat: 'SviGGf9dO0KG9Deh'
    }
  };

