import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DxDataGridComponent } from "devextreme-angular";

// VMS List API
import { VmsServiceService } from 'app/services/vms-service/vms-service.service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-vmsadmin',
  templateUrl: './vmsadmin.component.html',
  styleUrls: ['./vmsadmin.component.scss']
})
export class VmsadminComponent {

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  // constants
  DAYS = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday',
    'Thursday', 'Friday', 'Saturday' ];
  SMS_MAXLENGTH = 200;
  SMS_DEFAULT_MESSAGE = 'Reminder: Timesheet for period ' +
    '"[[timesheet_fromdate]]-[[timesheet_todate]]" has status ' +
    '"[[timesheet_status]]". Please follow it up and get it cleared.';
  EMAIL_DEFAULT_SUBJECT = '[AUTO] Timesheet Reminder - ' +
    '[[timesheet_fromdate]] - [[timesheet_todate]]';
  EMAIL_DEFAULT_MESSAGE = '<p>[[name]],</p><p><br></p>' +
    '<p>Your timesheet for period <strong>[[timesheet_fromdate]] - ' +
    '[[timesheet_todate]]</strong> has status <strong ' +
    'style="color: rgb(255, 0, 0);">[[timesheet_status]]</strong></p>' +
    '<p><br></p><p>Please follow it up and get it cleared.</p>';

  // popup controls
  schedulePopupVisible: boolean = false;
  smsTemplatePopupVisible: boolean = false;
  emailTemplatePopupVisible: boolean = false;

  // schedule-popup data
  clientList = [];
  timesheetStatusList = [];
  schedules = [];

  // schedule-popup state
  schedulePopupMode: string = 'add';
  schedulePopupFormAction: string = 'addSchedule';

  selectedID: any;
  selectedClients: string;
  selectedRecurrence: string;
  executionTime: string;
  selectedStatus: string;
  sendEmail: boolean = false;
  sendSMS: boolean = false;

  // email-popup state
  _emailSubject: string = '';
  emailSubject: string = '';
  _emailBody: string = '';
  emailBody: string = '';

  // sms-popup state
  _smsBody: string = '';
  smsBody: string = '';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private vmsService: VmsServiceService,
    public datePipe: DatePipe) { }

  ngOnInit() {
    this.vmsService.getVMSClients().subscribe(data => {
      this.clientList = _.map(data, x => x.businessName);
    });

    this.vmsService.getAllTimesheetStatuses().subscribe(data => {
      this.timesheetStatusList = data;
    });

    this.loadAllTimesheetSchedules();
  }

  loadAllTimesheetSchedules() {
    this.vmsService.getAllTimesheetSchedules().subscribe(data => {
      this.schedules = _.map(data, this.unformatScheduleObject);
    }, err => {
      console.error('Error: ' + err.error.error);
      this.schedules = [];
    });
  }

  onSchedulePopupFormSubmit(args) {
    this[this.schedulePopupFormAction]();
  }

  showSMSTemplatePopup(args) {
    if (typeof args === 'boolean') {
        this.smsTemplatePopupVisible = args;
    } else if (args.event) {
      this.smsTemplatePopupVisible = args.value;
    }

    if (this.smsTemplatePopupVisible) {
      this._smsBody = this.smsBody || this.SMS_DEFAULT_MESSAGE;
    }
  }

  saveSMSTemplate() {
    this.smsTemplatePopupVisible = false;
    this.smsBody = this._smsBody;
  }

  restoreSMSTemplate() {
    this.smsTemplatePopupVisible = false;

    if (this.smsBody === '') {
      this.sendSMS = false;
    }
  }

  showEmailTemplatePopup(args) {
    if (typeof args === 'boolean') {
      this.emailTemplatePopupVisible = args;
    } else if (args.event) {
      this.emailTemplatePopupVisible = args.value;
    }

    if (this.emailTemplatePopupVisible) {
      this._emailSubject = this.emailSubject || this.EMAIL_DEFAULT_SUBJECT;
      this._emailBody = this.emailBody || this.EMAIL_DEFAULT_MESSAGE;
    }
  }

  saveEmailTemplate() {
    this.emailTemplatePopupVisible = false;
    this.emailBody = this._emailBody;
    this.emailSubject = this._emailSubject;
  }

  restoreEmailTemplate() {
    this.emailTemplatePopupVisible = false;

    if (this.emailBody === '' || this.emailSubject === '') {
      this.sendEmail = false;
    }
  }

  addSchedule() {
    this.selectedID = null;
    let schedule = this.getCurrentScheduleItem();
    let _schedule = this.formatScheduleObject(schedule);
    this.vmsService.addTimesheetSchedule(_schedule, false).subscribe(data => {
      schedule.id = data.id;
      this.schedules.push(schedule);
    }, err => {
      console.error('Error: ' + err.error);
    });
    this.schedulePopupVisible = false;
  }

  updateSchedule() {
    let index = _.findIndex(this.schedules, { 'id': this.selectedID });
    let schedule = this.getCurrentScheduleItem();
    let _schedule = this.formatScheduleObject(schedule);
    this.vmsService.addTimesheetSchedule(_schedule, true).subscribe(data => {
      this.schedules[index] = schedule;
    }, err => {
      console.error('Error: ' + err.error);
    });
    this.schedulePopupVisible = false;
  }

  deleteSchedule() {
    let index = _.findIndex(this.schedules, { 'id': this.selectedID });
    this.vmsService.deleteTimesheetSchedule(this.selectedID).subscribe(data => {
      this.schedules.splice(index, 1);
    }, err => {
      console.error('Error: ' + err.error);
    });
    this.schedulePopupVisible = false;
  }

  getCurrentScheduleItem() {
    return {
      id: this.selectedID,
      clients: this.selectedClients,
      recurrence: this.selectedRecurrence,
      executionTime: this.executionTime,
      statusTrigger: this.selectedStatus,
      sendEmail: this.sendEmail,
      emailSubject: this.emailSubject,
      emailBody: this.emailBody,
      sendSMS: this.sendSMS,
      smsBody: this.smsBody
    };
  }

  addModeSchedulePopupShow() {
    // show schedule-popup
    this.schedulePopupMode = 'add';
    this.schedulePopupVisible = true;
  }

  editModeSchedulePopupShow(args) {
    let data = args.selectedRowsData.length && args.selectedRowsData[0];
    if (data) {
      // set popup state
      this.selectedID = data.id;
      this.selectedClients = data.clients;
      this.selectedRecurrence = data.recurrence;
      this.executionTime = data.executionTime;
      this.selectedStatus = data.statusTrigger;
      this.sendEmail = data.sendEmail;
      this.emailSubject = data.emailSubject;
      this.emailBody = data.emailBody;
      this.sendSMS = data.sendSMS;
      this.smsBody = data.smsBody;

      // show schedule-popup
      this.schedulePopupMode = 'edit';
      this.schedulePopupVisible = true;
    }
  }

  clearSelection() {
    // restore popup state
    this.selectedID = null;
    this.selectedClients = null;
    this.selectedRecurrence = null;
    this.executionTime = null;
    this.selectedStatus = null;
    this.sendEmail = false;
    this.emailSubject = '';
    this.emailBody = '';
    this.sendSMS = false;
    this.smsBody = '';

    // restore state of datagrid
    this.dataGrid.instance.clearSelection();
  }

  getTime(args) {
    if (args.value) {
      return moment(args.value).format('hh:mm a').toUpperCase();
    }
  }

  formatScheduleObject(schedule) {
    // possible bug in component dx-html-editor - workaround
    // all "<br>" tags gets auto wrapped in "<p>" tag
    // if we dont replace it before sending it to the server
    // the emails sent will have unusual unexpected line spacings
    schedule.emailBody = schedule.emailBody.replace(/<p><br><\/p>/g, '<br>');
    return schedule;
  }

  unformatScheduleObject(schedule) {
    // possible bug in component dx-html-editor - workaround
    // all "<br>" tags gets auto wrapped in "<p>" tag
    // if we dont replace it before displaying in the editor, we'll face
    // ExpressionChangedAfterItHasBeenCheckedError
    schedule.emailBody = schedule.emailBody.replace(/<br>/g, '<p><br></p>');
    return schedule;
  }

}
