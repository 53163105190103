import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RecordRtcComponent} from './record-rtc.component';
import {RouterModule} from '@angular/router';
import {PulseSharedModule} from '@pulse/shared.module';
import {AuthGuardService as AuthGuard} from '../../../services/auth/auth-guard.service';

const routes = [
    {
        path: 'record-rtc',
        component: RecordRtcComponent,
        canActivate: [AuthGuard]
    }
];
@NgModule({
    declarations: [RecordRtcComponent],
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        PulseSharedModule,
        CommonModule
    ],
    exports: [RecordRtcComponent]
})
export class RecordRtcModule {
}
