import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, forkJoin, Subject, Observable} from 'rxjs';

import {siteConfig} from '../../siteSettings';

export class consultantDet {
    constructor(public constID: number, public constName: string, public clientName: string, public AEName: string) {
    }
}

export class consultantList {
    constructor(public constID: number, public ClientName: string, public ConsultantFullName: string,
                public AEName: string, public RecruiterName: string, public bill_rate: number, public pay_rate: number,
                public actual_term_date: Date, public ConsultantFullTimeStatus: any) {
    }
}

export class fileArrayDef {
    constructor(public fileName: string, public fileUrl: string) {
    }
}

export class Customer {
    first_name: string;
    ClientName: string;
    Action: string;
}


@Injectable()
export class ConsultantService {
    onPnlDataUpdated: BehaviorSubject<any>;
    consultantMainData: BehaviorSubject<any>;
    consultantId$: BehaviorSubject<number>;
    consultantExtData: BehaviorSubject<any>;
    logCountData: BehaviorSubject<any>;
    equipmentsCountData: BehaviorSubject<any>;
    RateObservable = new BehaviorSubject(
        {
            billRate: 0,
            billRateCurrency: '',
            billRateType: '',
            payRate: 0,
            payRateCurrency: '',
            payRateType: '',
        });
    isFranchiseTabEnabled: BehaviorSubject<boolean>;
    isPnlDataUpdated: BehaviorSubject<boolean>;

    constructor(private _http: HttpClient) {
        this.onPnlDataUpdated = new BehaviorSubject(null);
        this.consultantMainData = new BehaviorSubject(null);
        this.consultantExtData = new BehaviorSubject(null);
        this.consultantId$ = new BehaviorSubject(0);
        this.logCountData = new BehaviorSubject(null);
        this.isFranchiseTabEnabled = new BehaviorSubject<boolean>(false);
        this.isPnlDataUpdated = new BehaviorSubject<boolean>(false);
        this.equipmentsCountData = new BehaviorSubject(0);
    }

    getConsultantByID(consultantID: number, details = 0): Observable<consultantDet[]> {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/getConsultantDetailsByID?consultant_id=${consultantID}&details=${details}`;
        return this._http.get<consultantDet[]>(url);
    }

    getManageRateDetails(consultantId: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/getManageRateDetails/${consultantId}`;
        return this._http.get(url);
    }

    saveConsultantData(reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/save`;
        return this._http.post(url, reqBody);
    }

    copyConsultantData(reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/copy`;
        return this._http.post(url, reqBody);
    }

    updateConsultantData(reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/update`;
        return this._http.post(url, reqBody);
    }

    updateConsultantQBData(reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/update-consultant-qb-data`;
        return this._http.post(url, reqBody);
    }

    getCountForData(fldName: string, fldValue: string) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/getcountforadmin?fldname=${fldName}&fldvalue=${fldValue}`;
        return this._http.get(url);
    }

    getConsultantByEmail(consultantEmail: string): Observable<consultantDet[]> {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/get?fldname=email&fldvalue=${consultantEmail}`;
        return this._http.get<consultantDet[]>(url);
    }

    getConsultantByDocusignStatus(docusignStatus: string): Observable<consultantDet[]> {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/get?fldname=docusign_status&fldvalue=${docusignStatus}`;
        return this._http.get<consultantDet[]>(url);
    }

    getAllConsultants(): Observable<consultantList[]> {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/get?fldname=first_name&fldvalue=*`;
        return this._http.get<consultantList[]>(url);
    }

    getConsultant() {
        return {
            oldConsultantEmail: '',
            oldConsultantSaveStatus: '',
            currentSaveStatus: '',
            CandidateEmail: '',
            AlternateEmail: '',
            ConsultantFullName: '',
            frmExtId: null,
            frmOnboardingStatus: '',
            frmDocusignStatus: '',
            frmMainStatus: null,
            frmSubStatus: null,
            frmFirstName: '',
            frmLastName: '',
            frmMobile: '',
            frmHomePhone: '',
            frmSSN: '',
            frmDOB: '',
            frmGender: '',
            frmAddressOne: '',
            frmAddressTwo: '',
            frmCity: '',
            frmCountry: 1100001,
            frmState: null,
            frmJobID: null,
            frmJobTitle: '',
            frmWorkAuthorization: null,
            frmZipCode: '',
            frmExpirationDate: '',
            frmPayTerm: '',
            frmSourcedFrom: null,
            frmSourcedFromOther: '',
            frmClientID: null,
            frmClientName: null,
            frmAEID: null,
            frmAeName: '',
            frmRecruiterID: null,
            frmRecruiterName: '',
            frmTeamLeadID: null,
            frmSpendHR: null,
            frmHRValue: null,
            frmTentStartDate: '',
            frmEstTermDate: '',
            frmWorkInCity: '',
            frmWorkInAddress: '',
            frmWorkZipCode: null,
            frmActualStartDate: '',
            frmActualTermDate: '',
            frmStatusID: null,
            frmSubStatusID: null,
            frmJobCategory: null,
            frmVendorID: null,
            frmLiveInStateID: null,
            frmWorkInStateID: null,
            frmBillRate: null,
            frmBillRateCurrency: 500001,
            frmBillRateType: 600002,
            frmPayRate: null,
            frmPayRateCurrency: 500001,
            frmPayRateType: 600002,
            frmHireRightID: null,
            frmReportingManager: '',
            frmBackGroundEmail: '',
            frmRemarks: '',
            frmSalary: '',
            frmCurrency: 500001,
            frmOtherCost: '',
            frmFulltimeFeesPercent: 0,
            frmFulltimeFees: 0,
            frmButDraft: 'Draft',
            frmButSave: 'Save',
            createdBy: '',
            createdAt: '',
            updatedBy: '',
            updatedAt: '',
            updatedByName: '',
            createdByName: '',
            frmWorkAuthorizationFromOther: '',
            frmIsVerified: false,
            consultantFullTimeStatus: '',
            frmTempSSN: ''
        };

    }

    deleteConsultant(reqBody: any): Observable<consultantDet[]> {
        const url = siteConfig.nodeAPIBaseUrl + 'consultant/delete';
        return this._http.post<consultantDet[]>(url, reqBody);
    }

    // UpdateConsultantData(body: any) {
    //   let url = siteConfig.nodeAPIBaseUrl + 'consultants';
    //   return this._http.patch(url, body);
    // }
    // UpdateConsultantData(body: any, ConId: number) {
    //     const url = siteConfig.nodeAPIBaseUrl + 'consultants/' + ConId;
    //     return this._http.patch(url, body);
    // }

    searchConsultant(param) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/getOnlyConsultantsForTopSearch?consultantSrchParam=${param}`;
        return this._http.get(url);
    }

    SaveBusinessEntity(body: any) {
        const url = siteConfig.nodeAPIBaseUrl + 'business_entities';
        return this._http.post(url, body);
    }

    updateBusinessEntityByID(businessId, postData) {
        const url = siteConfig.nodeAPIBaseUrl + 'business_entities/' + businessId;
        return this._http.post(url, postData);
    }

    getAllConsultantsForGid(jobTypeID: string, statusID: any): Observable<consultantList[]> {
        const url = siteConfig.nodeAPIBaseUrl + 'consultant/getConsultantForGrid?jobTypeValue=' + jobTypeID + '&statusValue=' + statusID;
        return this._http.get<consultantList[]>(url);
    }

    saveConsultantPnlData(reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/pnl/add`;
        return this._http.post(url, reqBody);
    }

    updateConsultantPnlData(constPnlId: number, reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/pnl/update/${constPnlId}`;
        return this._http.post(url, reqBody);
    }

    saveConsultantFranchiseData(reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/franchise/add`;
        return this._http.post(url, reqBody);
    }

    updateConsultantFranchiseData(constFranchiseId: number, reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/franchise/update/${constFranchiseId}`;
        return this._http.post(url, reqBody);
    }

    saveConsultantPayrollDetails(reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/payroll/add`;
        return this._http.post(url, reqBody);
    }

    saveConsultantADPDetails(consultantId: any, body: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}adp/applicant/${consultantId}`;
        return this._http.post(url, body);
    }

    saveConsultantADPDepositDetails(consultantId: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}adp/applicantDeposit/${consultantId}`;
        return this._http.post(url, null);
    }

    saveConsultantPayrollDeposit(reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/payroll/addDeposit`;
        return this._http.post(url, reqBody);
    }

    saveConsultantPayrollBenefit(reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/payroll/addPayrollBenefit`;
        return this._http.post(url, reqBody);
    }

    updateConsultantPayrollBenefit(constPayrollId: number, reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/payroll/updatePayrollBenefit/${constPayrollId}`;
        return this._http.post(url, reqBody);
    }

    removeConsultantPayrollDeposit(constPayrollDepositId: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/payroll/removePayrollDeposit/${constPayrollDepositId}`;
        return this._http.delete(url);
    }

    removeConsultantPayrollBenefit(constPayrollBenefitId: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/payroll/removePayrollBenefit/${constPayrollBenefitId}`;
        return this._http.delete(url);
    }

    getConsultantPayrollBenefit(constExtId: number) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/payroll/getPayrollBenefit/${constExtId}`;
        return this._http.get(url);
    }

    updateConsultantPayrollDetails(constPayrollId: number, reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/payroll/update/${constPayrollId}`;
        return this._http.post(url, reqBody);
    }

    updateConsultantPayrollDeposit(constPayrollId: number, reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/payroll/updateDeposit/${constPayrollId}`;
        return this._http.post(url, reqBody);
    }

    getConsultantPayrollDeposit(constExtId: number) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/payroll/getDeposit/${constExtId}`;
        return this._http.get(url);
    }

    getADPData(consultantId: number) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/payroll/getPayrollADPData/${consultantId}`;
        return this._http.get(url);
    }

    saveConsultantExtDetails(reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/ext/add`;
        return this._http.post(url, reqBody);
    }

    updateConsultantExtDetails(constExtId: number, reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/ext/update/${constExtId}`;
        return this._http.post(url, reqBody);
    }

    getConsultantDocsByID(constId: number) {
        const batch = [];
        const dsUrl = `${siteConfig.nodeAPIBaseUrl}docusign/getEnvelopeDocumentByConsultantID?consultant_id=${constId}`;
        const upUrl = `${siteConfig.nodeAPIBaseUrl}documents/con${constId}/files`;
        // batch.push(this._http.get(dsUrl));
        //  batch.push(this._http.get(upUrl));
        return this._http.get(dsUrl);
        // return forkJoin(batch);
    }

    downloadFiles(files: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/downloadFiles`;
        return this._http.post(url, files);
    }

    uploadConsultantDoc(constId: number, reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/uploadDoc/${constId}`;
        return this._http.post(url, reqBody);
    }

    getConsultantDocsByIDFromLocalUpload(constId: number): Observable<fileArrayDef[]> {
        const url = `${siteConfig.nodeAPIBaseUrl}documents/con${constId}/files`;
        return this._http.get<fileArrayDef[]>(url);
    }

    // downloadDocusignDocument(envelopID: string, documentID: string) {
    //     const url = `${siteConfig.nodeAPIBaseUrl}docusignlbs/downloadDocumentByEnvID?envelopID=${envelopID}&documentID=${documentID}`;
    //     return this._http.get(url, {
    //         responseType: 'blob',
    //         headers: headersOptions
    //     }, );
    // }
    //
    downloadDocusignDocument(envelopID: string, documentID: string) {
        const httpOptions: any = {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const url = `${siteConfig.nodeAPIBaseUrl}docusign/downloadDocumentByEnvID?envelopID=${envelopID}&documentID=${documentID}`;
        return this._http.get(url, httpOptions);
    }

    deleteConsultantDoc(fileId) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/documents/${fileId}`;
        return this._http.delete(url);
    }

    getConsultantStatusDetails(consultantId: number) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/getStatusDetails?consultantID=${consultantId}`;
        return this._http.get(url);
    }

    sendDocsEmailToConsutant(reqBody: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/send-email-consultants`;
        return this._http.post(url, reqBody);
    }

    getOnlyConsultant() {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/getOnlyConsultants/`;
        return this._http.get(url);
    }

    ratesUpdateDetails(body: any) {
        // const url = siteConfig.nodeAPIBaseUrl + 'rates_update_details';
        // return this._http.patch(url, body);
        const url = siteConfig.nodeAPIBaseUrl + 'consulant_update_with_other_task/rates_update';
        return this._http.post(url, body);
    }

    deleteTran(noteId) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant_transactions/` + noteId;
        return this._http.delete(url);
    }

    updateConsultantPNLByBackendJobs() {
        const url = siteConfig.nodeAPIBaseUrl + 'backendJobs/updateConsultantPNL';
        return this._http.get(url);
    }

    getSendEeoDetails(constID) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/getEEOPerviousInformation?consultantID=${constID}`;
        return this._http.get(url);
    }

    sendEeoNotification(postData) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/sendEEONotificationEmail`;
        return this._http.post(url, postData);
    }

    saveEeoDetails(postData) {
        const url = `${siteConfig.nodeAPIBaseUrl}eeoAnswer`;
        return this._http.post(url, postData);
    }

    getEEOQuestion() {
        const url = `${siteConfig.nodeAPIBaseUrl}getEEOQuestionAnswer`;
        return this._http.get(url);
    }

    checkEEO(constID) {
        const url = `${siteConfig.nodeAPIBaseUrl}getConsultantsEEOConformation?consultantID=${constID}`;
        return this._http.get(url);
    }

    saveAdditionalDetails(reqBody) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/consultantSaveAdditionalDetails`;
        return this._http.post(url, reqBody);
    }

    getCustomUsersWithRole(constID) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/consultantGetAdditionalDetails?consultantID=${constID}`;
        return this._http.get(url);
    }

    getAdditionalResource(constID) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/getAdditionalResource?consultantID=${constID}`;
        return this._http.get(url);
    }

    getAdditionalPNLDetails(constID) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/getAdditionalPNLDetails?consultantID=${constID}&usertype=1`;
        return this._http.get(url);
    }

    saveAdditionalPNLDetails(reqBody) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/saveAdditionalPNLDetails`;
        return this._http.post(url, reqBody);
    }


    deleteCustomUser(reqBody: any) {
        const httpOptions: any = {body: reqBody};
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/consultantDeleteAdditionalDetails`;
        return this._http.delete(url, httpOptions);
    }

    UpdateConsultantData(body: any, ConId: number) {
        let url = siteConfig.nodeAPIBaseUrl + 'consulant_update_with_other_task/dates_update/';
        return this._http.post(url, body);
    }

    UpdatePNLCalculationData(consultantID: number) {
        let url = siteConfig.nodeAPIBaseUrl + 'consultant/pnl/updatePNLCalculation/' + consultantID;
        return this._http.get(url);
    }

    updateQbItemCommission(postData) {
        let url = siteConfig.nodeAPIBaseUrl + 'consultant/pnl/update-qb-item-commission';
        return this._http.post(url, postData);
    }

    updateConsultantStatusById(body: any, consultantID: number) {
        const url = siteConfig.nodeAPIBaseUrl + 'consultant/update-consultant-status-by-id/' + consultantID;
        return this._http.post(url, body);
    }

    getAllVmsConsultants(s) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/get-all-vms-consultant?s=` + s;
        return this._http.get(url);
    }

    getVmsConsultantById(id) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/get-vms-consultant-by-id?id=` + id;
        return this._http.get(url);
    }

    getConsultantEquipments(consultantID) {
        const url = siteConfig.nodeAPIBaseUrl + 'consultant-equipment/list/' + consultantID;
        return this._http.get(url);
    }

    getCountOfConsultantEquipments(consultantID) {
        const url = siteConfig.nodeAPIBaseUrl + 'consultant-equipment/list/' + consultantID + '?count=true';
        return this._http.get(url);
    }

    addConsultantEquipment(postData) {
        const url = siteConfig.nodeAPIBaseUrl + 'consultant-equipment/add';
        return this._http.post(url, postData);
    }

    getConsultantEmailedEquipments(consultantID) {
        const url = siteConfig.nodeAPIBaseUrl + 'get-consultant-mailed-equipment/' + consultantID;
        return this._http.get(url);
    }

    saveEquipmentSignature(postData) {
        const url = `${siteConfig.nodeAPIBaseUrl}equipment-signature`;
        return this._http.post(url, postData);
    }

    updateProductById(data: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant-equipment/update-equipment-by-id`;
        return this._http.post(url, data);
    }

    updateProductByConsultantId(data: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant-equipment/update-equipment-by-consultant-id`;
        return this._http.post(url, data);
    }

    unAssignProduct(eqId, productId) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant-equipment/unassign-equipment/${eqId}/${productId}`;
        return this._http.get(url);
    }

    sendEquipmentEmail(data: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant-equipment/send-mail/${data.consultant_id}`;
        return this._http.post(url, data);
    }

    getConsultantDetailForAceTracker(id) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/consultant-detail-for-ace-tracker?id=` + id;
        return this._http.get(url);
    }
    getCountryLawsById(data: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/get-country-laws/${data.countryId}`;
        return this._http.get(url, data);
    }

    saveConsultantZealDetails(consultantId: any) {
        const url = `${siteConfig.nodeAPIBaseUrl}zeal/employees/${consultantId}`;
        return this._http.post(url, {});
    }

}
