<div class="page-layout simple fullwidth" pulsePerfectScrollbar>
  <div class="container mt-12">
    <div class="content mat-white-bg mat-elevation-z4 mb-20" fxLayout="column">
      <div class="content-wrapper">
        <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto" fxLayoutAlign="space-between center">
          <div class="pulse-main-heading">
            Github - eMail
          </div>
        </div>
        <div class="container">
          <!-- Form -->
          <div class="data-component">
            <div class="data-component-header">Initiate new Github Job</div>
            <form
              (submit)="submitGithubJob($event)"
              validationGroup="githubJobValidationGroup"
            >
              <div fxLayout="column" fxLayoutAlign="space-around" fxFlexFill>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                  <div fxFlex="20" fxLayout="column">
                    <dx-select-box
                      placeholder="Template"
                      [items]="allTemplateNames"
                      [(value)]="selectedTemplateName"
                      (onSelectionChanged)="emailTemplateNameUpdated()"
                    >
                      <dx-validator>
                        <dxi-validation-rule type="required" message="required"
                        ></dxi-validation-rule>
                      </dx-validator>
                    </dx-select-box>
                  </div>
                  <div fxFlex="4" fxLayout="column" *ngIf="selectedTemplateName">
                    <dx-button
                      icon="edit"
                      (onClick)="showEmailTemplatePopup('edit')"
                    ></dx-button>
                  </div>
                  <div fxFlex fxLayout="column">
                    <dx-text-box placeholder="Search URL" [(value)]="url">
                      <dx-validator>
                        <dxi-validation-rule type="required" message="required"
                        ></dxi-validation-rule>
                      </dx-validator>
                    </dx-text-box>
                  </div>
                  <div fxFlex="4" fxLayout="column">
                    <dx-button
                      icon="chevronright"
                      validationGroup="githubJobValidationGroup"
                      [useSubmitBehavior]="true"
                    ></dx-button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Github Jobs List -->
          <div class="data-component">
            <div class="data-component-header">Recent Github Jobs
              <div class="badge ng-star-inserted">
                {{ totalActiveGithubJobs }} Active
              </div>
            </div>
            <dx-data-grid
              keyExpr="githubJobId"
              [dataSource]="allGithubJobs"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showColumnLines]="false"
              [showBorders]="true"
              [columnAutoWidth]="true"
              [selectedRowKeys]="[]"
            >
              <dxo-paging [pageSize]="25"></dxo-paging>

              <dxi-column
                width="5%"
                dataField="githubJobId"
                dataType="number"
                alignment="center"
                caption="#"
              ></dxi-column>
              <dxi-column
                width="10%"
                dataField="templateName"
                caption="Template"
              ></dxi-column>
              <dxi-column
                width="30%"
                dataField="searchUrl"
                cellTemplate="urlCellTemplate"
                caption="URL"
              ></dxi-column>
              <dxi-column
                width="10%"
                dataField="emailsSent"
                dataType="number"
                alignment="center"
              ></dxi-column>
              <dxi-column width="10%" dataField="status" alignment="center">
              </dxi-column>
              <dxi-column
                width="15%"
                dataField="createdAt"
                dataType="datetime"
                alignment="center"
              ></dxi-column>
              <dxi-column
                width="15%"
                dataField="completedAt"
                dataType="datetime"
                alignment="center"
              ></dxi-column>
              <dxi-column
                width="10%"
                cellTemplate="progressCellTemplate"
                caption="Progress"
                alignment="center"
              ></dxi-column>

              <div *dxTemplate="let info of 'urlCellTemplate'">
                <a
                  [href]="info.data.searchUrl"
                  target="_blank"
                >{{info.data.searchUrl}}</a>
              </div>

              <div *dxTemplate="let info of 'progressCellTemplate'">
                <div *ngIf="info.data.status === 'Completed'">
                  {{info.data.progress}}
                </div>
                <div *ngIf="info.data.status === 'Processing'">
                  {{info.data.progress}}
                  <dx-button
                    icon="close"
                    (onClick)="abortGithubJob(info.data.githubJobId)"
                  ></dx-button>
                </div>
                <div *ngIf="info.data.status === 'Aborted'">-</div>
                <div *ngIf="info.data.status === 'Errored'">-</div>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- EMail Template PopUp -->
  <dx-popup
    title="eMail Template"
    [width]="800"
    [height]="500"
    [showTitle]="true"
    [showCloseButton]="false"
    [(visible)]="emailTemplatePopupVisible"
  >
    <form
      (submit)="saveEmailTemplate()"
      validationGroup="emailTemplateValidationGroup"
    >
      <div fxLayout="column" fxLayoutGap="10px" fxFlexFill>
        <div fxFlex="8">
          <div fxLayout="row" fxLayoutGap="10px" class="v-flex-center">
            <div fxFlex="20">Template Name</div>
            <div fxFlex>
              <dx-text-box valueChangeEvent="keyup" [(value)]="emailTemplateName">
                <dx-validator>
                  <dxi-validation-rule type="required" message="required"
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
        </div>
        <div fxFlex="8">
          <div fxLayout="row" fxLayoutGap="10px" class="v-flex-center">
            <div fxFlex="20">From</div>
            <div fxFlex>
              <dx-text-box valueChangeEvent="keyup" [(value)]="emailFrom" mode="email">
                <dx-validator>
                  <dxi-validation-rule type="required" message="required"
                  ></dxi-validation-rule>
                  <dxi-validation-rule
                    type="pattern"
                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    message="must be a valid email"
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
        </div>
        <div fxFlex="8">
          <div fxLayout="row" fxLayoutGap="10px" class="v-flex-center">
            <div fxFlex="20">Subject</div>
            <div fxFlex>
              <dx-text-box valueChangeEvent="keyup" [(value)]="emailSubject">
                <dx-validator>
                  <dxi-validation-rule type="required" message="required"
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
        </div>
        <div fxFlex="78">
          <div fxLayout="column" fxLayoutGap="5px" fxFlexFill>
            <div>Message</div>
            <dx-html-editor valueChangeEvent="keyup" [(value)]="emailBody">
              <dxo-toolbar>
                <dxi-item formatName="undo"></dxi-item>
                <dxi-item formatName="redo"></dxi-item>
                <dxi-item formatName="size"
                  [formatValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"
                ></dxi-item>
                <dxi-item formatName="header"
                  [formatValues]="[false, 1, 2, 3, 4, 5]"
                ></dxi-item>
                <dxi-item formatName="bold"></dxi-item>
                <dxi-item formatName="italic"></dxi-item>
                <dxi-item formatName="strike"></dxi-item>
                <dxi-item formatName="underline"></dxi-item>
                <dxi-item formatName="color"></dxi-item>
                <dxi-item formatName="background"></dxi-item>
                <dxi-item formatName="alignLeft"></dxi-item>
                <dxi-item formatName="alignCenter"></dxi-item>
                <dxi-item formatName="alignRight"></dxi-item>
                <dxi-item formatName="alignJustify"></dxi-item>
                <dxi-item formatName="orderedList"></dxi-item>
                <dxi-item formatName="bulletList"></dxi-item>
                <dxi-item formatName="link"></dxi-item>
                <dxi-item formatName="codeBlock"></dxi-item>
                <dxi-item formatName="blockquote"></dxi-item>
              </dxo-toolbar>
            </dx-html-editor>
          </div>
        </div>
        <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
          <dx-button
            icon="check"
            text="DONE"
            [disabled]="!emailBody"
            validationGroup="emailTemplateValidationGroup"
            [useSubmitBehavior]="true"
          ></dx-button>
          <dx-button
            icon="close"
            text="CANCEL"
            (onClick)="hideEmailTemplatePopup()"
          ></dx-button>
        </div>
      </div>
    </form>
  </dx-popup>
</div>
