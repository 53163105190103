<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
    <div class="container mt-12">
        <!-- CONTENT -->
        <div class="mat-white-bg" fxLayout="column">
            <div class="content-wrapper" fxLayout="column">
                <div class="pb-15" id="addUserForm">
                    <form (submit)="onFormSubmit($event)" class="add-user-form">
                        <div id="form-container" class="mb-20">
                            <dx-form id="form"
                                     [formData]="frmUserDatesData"
                                     [readOnly]="false"
                                     [showColonAfterLabel]="true"
                                     [showValidationSummary]="true"
                                     [colCount]="4"
                                     labelLocation="top"
                                     validationGroup="userDateData">

                                <dxi-item dataField="frmStartDate" editorType="dxDateBox"
                                          [editorOptions]="{displayFormat:'MM/dd/yyyy', useMaskBehavior: true, type:'date'}">
                                    <dxo-label text="Start Date"></dxo-label>
                                    <dxi-validation-rule type="required" message="Date is required">
                                    </dxi-validation-rule>
                                </dxi-item>

                                <dxi-item dataField="frmEndDate" editorType="dxDateBox"
                                          [editorOptions]="{displayFormat:'MM/dd/yyyy',useMaskBehavior: true, type:'date'}">
                                    <dxo-label text="End Date"></dxo-label>
                                    <dxi-validation-rule type="required" message="Date is required">
                                    </dxi-validation-rule>
                                </dxi-item>

                            </dx-form>
                        </div>

                        <div class="actions-buttons" fxLayout="row" fxLayoutAlign="space-between center">
                            <dx-button
                                text="Cancel"
                                class="pulse-btn pulse-btn-normal"
                                (onClick)="cancel()"
                            >
                            </dx-button>
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
                                <dx-button text="Save"
                                           validationGroup="userDateData"
                                           class="pulse-btn pulse-btn-primary"
                                           [useSubmitBehavior]="true" [disabled]="!frmUserData">
                                </dx-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
