import { Component, OnInit } from '@angular/core';
import { RolesService }  from 'app/services/roles/role.service'
import { FormBuilder,FormsModule, ReactiveFormsModule, FormGroup , Validators   } from '@angular/forms';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { SwalService } from '../../../../services/swal.service';



@Component({
    selector: 'app-role-add',
    templateUrl: './role-add.component.html',
    styleUrls: ['./role-add.component.scss']
})


export class RoleAddComponent implements OnInit {

  role = {
      role_id:"",
      name: "",
      description: "",
      is_active: false,
  }

  action;

  buttonOptions: any = {
    text: "Save",
    type: "success",
    useSubmitBehavior: true
  };

  constructor(private route: ActivatedRoute,
    private router:Router,
    private roleService: RolesService,
    private _formBuilder:FormBuilder,
    private swalService: SwalService
  ) {
  }

   resetData(){
    this.role={
      role_id :"",
      name:"",
      description:"",
      is_active:false
    };
  }

  ngOnInit() {
    this.route.paramMap.subscribe((param:ParamMap)=>{
      //we can fetch the role object by calling api
      console.log(param.get('id'));
      this.action = (param.get('id') == null);
      if(!this.action) {
        let role  = this.roleService.getRole();
        if(!role) this.router.navigate(['/role/list']);
        else this.role = {
          role_id :role.role_id,
          name: role.name,
          description: role.description,
          is_active: (role.is_active == 1)};
      }
      console.log(this.action);
    })
  }

  onFormSubmit = function(e) {
    console.log(e);
    console.log(this.role);
    let swalData;
    if(this.action){
      this.roleService.createRole(this.role).subscribe(data=>{
        let self= this;
        swalData = {
          title: 'Success',
          message: `Role added successfully!`,
          info: ''
        };
        this.swalService.success(swalData).then(function (result) {
          self.router.navigate(['/role/list']);
        });
      },error=>{
        console.log(error);
        swalData = {
          title: 'Error',
          message: `Unable to add role !`,
          info: error.statusText
        };
        this.swalService.error(swalData)
      });
    }else{
      this.roleService.updateRole(this.role).subscribe(data=>{
        let self= this;
        swalData = {
          title: 'Success',
          message: `Role updated successfully!`,
          info: ''
        };
        this.swalService.success(swalData).then(function (result) {
          self.router.navigate(['/role/list']);
        });
      },error=>{
        console.log(error);
        swalData = {
          title: 'Error',
          message: `Unable to update role !`,
          info: error.statusText
        };
        this.swalService.error(swalData)
      });
    }
}

cancelForm = () => {
  this.router.navigate(['/role/list']);
}

}
