<div id="main-navigation" class="nav horizontal" [fxLayoutAlign]="'flex-end'">

    <ng-container *ngFor="let item of navigation">

        <pulse-nav-horizontal-collapse *ngIf="item.type=='group'" [item]="item"></pulse-nav-horizontal-collapse>
        <pulse-nav-horizontal-item *ngIf="item.type=='item'" [item]="item"></pulse-nav-horizontal-item>

    </ng-container>

</div>
