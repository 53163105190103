<div id="forms" class="page-layout simple fullwidth"
     fxLayout="column">

    <!-- CONTENT -->
    <div class="container mt-12">

        <!-- REACTIVE FORM EXAMPLE -->

        <div class=" mat-white-bg mat-elevation-z4 " fxLayout="column">

            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap"
                     fxFlex="1 0 auto" fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading" >{{pageTitle}}</div>

                    <dx-button text="Reset" *ngIf="!userId"
                               (onClick)="reset()"
                               class="pulse-btn pulse-btn-normal">
                    </dx-button>
                </div>

                <div class="user-detail-view">
                    <nav mat-tab-nav-bar fxLayoutAlign="start">
                        <a mat-tab-link
                           [routerLink]="'/user/edit/details/'+userId"
                           routerLinkActive="active">
                            Details
                        </a>

                        <a mat-tab-link
                           [routerLink]="'/user/edit/onboarding/'+userId"
                           routerLinkActive="active">
                            Onboarding
                        </a>

                        <a mat-tab-link
                           [routerLink]="'/user/edit/payroll/'+userId"
                           routerLinkActive="active">
                            Payroll
                        </a>

                        <a mat-tab-link
                           [routerLink]="'/user/edit/commission/'+userId"
                           routerLinkActive="active">
                            Commission
                        </a>

                        <a mat-tab-link
                           [routerLink]="'/user/edit/dates/'+userId"
                           routerLinkActive="active">
                            Dates
                        </a>
                    </nav>

                    <div class="tab-content">
                        <ng-container *ngIf="slug === 'details'">
                            <app-user-edit></app-user-edit>
                        </ng-container>
                        <ng-container *ngIf="slug === 'onboarding'">
                            <app-user-onboarding></app-user-onboarding>
                        </ng-container>
                        <ng-container *ngIf="slug === 'payroll'">
                            <app-user-payroll></app-user-payroll>
                        </ng-container>
                        <ng-container *ngIf="slug === 'commission'">
                            <app-user-commission></app-user-commission>
                        </ng-container>
                        <ng-container *ngIf="slug === 'dates'">
                            <app-user-dates></app-user-dates>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
