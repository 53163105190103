<div class="page-layout simple fullwidth people-search" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">
        <div class="content" fxLayout="column">
            <div class="content-wrapper">
                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <div class="mat-white-bg mat-elevation-z4" fxFlex="20" fxLayout="column">
                        <div class="page-title-wrapper pipl" fxLayout="row wrap" fxLayoutAlign="space-between center" >
                            <div class="pulse-main-heading mr-16" >
                                People Search
                            </div>
                            <dx-button icon="fa fa-sync-alt" class="pulse-btn"
                                       (click)="ResetData()">
                            </dx-button>
                        </div>
                        <div class="p-16">
                            <form (submit)="onFormSubmit($event)" fxLayout="row">
                                <div class="form-container w-100-p">
                                    <dx-form [formData]="formData" [showColonAfterLabel]="true" #searchForm
                                             [showValidationSummary]="true" class="pipl-search-form"
                                             labelLocation="top" validationGroup="customerData">

                                        <!--<dxi-item dataField="source_type" editorType="dxSelectBox"
                                                  [editorOptions]="{   items: sources, displayExpr: 'name', valueExpr: 'id'}">
                                            <dxi-validation-rule type="required" message="Source is required">
                                            </dxi-validation-rule>
                                        </dxi-item>-->

                                        <dxi-item itemType="group" [colSpan]="12" [colCount]="8" >
                                            <dxi-item dataField="source_type" [colSpan]="7"
                                                      editorType="dxTagBox"
                                                      [editorOptions]="{
                                                    items: sources,
                                                    valueExpr: 'id',
                                                    displayExpr: 'name',
                                                    searchEnabled: true,
                                                    showSelectionControls:true,
                                                    onValueChanged: setBoxOptions
                                                     }">
                                                <dxo-label text="Source Type"></dxo-label>
                                                <dxi-validation-rule
                                                        type="required"
                                                        message="Source is required">
                                                </dxi-validation-rule>
                                            </dxi-item>
                                            <dxi-item editorType="dxButton" [colSpan]="5" cssClass="hasButton no-label"
                                                      [editorOptions]="{
                                                      icon:'fa fa-search',
                                                      cssClass:'pulse-btn pulse-btn-primary',
                                                      useSubmitBehavior: true,
                                                      elementAttr: { 'class': 'pulse-btn pulse-btn-primary'}
                                                      }"
                                            ></dxi-item>
                                            <!--<dx-button icon="fa fa-search" text="" class="mt-12 pulse-btn pulse-btn-primary"
                                                       [useSubmitBehavior]="true">
                                            </dx-button>-->
                                        </dxi-item>

                                        <!--<dxi-item itemType="group" [colSpan]="12" [colCount]="8">
                                            <dxi-item dataField="plan" editorType="dxSelectBox" [colSpan]="7"
                                                      [editorOptions]="{ items: plans }">
                                            </dxi-item>

                                            <dxi-item itemType="simple" dataField="is_premium" editorType="dxCheckBox"
                                                      cssClass="text-nowrap">
                                                <dxo-label [visible]="true">
                                                </dxo-label>
                                            </dxi-item>
                                        </dxi-item>-->


                                        <dxi-item dataField="first_name" [visible]="!linkedinOnly">
                                            <dxo-label text="First Name"></dxo-label>
                                        </dxi-item>
                                        <dxi-item dataField="last_name" [visible]="!linkedinOnly">
                                            <dxo-label text="Last Name"></dxo-label>
                                        </dxi-item>
                                        <dxi-item dataField="middle_name" [visible]="!linkedinOnly && !lusaFields">
                                            <dxo-label text="Middle Name"></dxo-label>
                                        </dxi-item>
                                          <dxi-item dataField="email" [visible]="!linkedinOnly && !lusaFields">
                                            <dxo-label text="Email"></dxo-label>
                                        </dxi-item>
                                        <dxi-item dataField="linkedin_url" [visible]="!lusaFields">
                                            <dxo-label text="Linkedin Url"></dxo-label>
                                        </dxi-item>
                                        <dxi-item dataField="company" [visible]="!linkedinOnly">
                                            <dxo-label text="Company"></dxo-label>
                                        </dxi-item>
                                        <dxi-item dataField="location" [visible]="!linkedinOnly && !lusaFields">
                                            <dxo-label text="Location"></dxo-label>
                                        </dxi-item>

                                    </dx-form>

                                    <dx-button text="Search" class="mt-12 pulse-btn pulse-btn-primary"
                                               [useSubmitBehavior]="true">
                                    </dx-button>

                                </div>
                                <!--<div fxFlex="10" fxLayoutAlign="end end">

                                </div>-->
                            </form>
                        </div>
                    </div>
                    <div class="mat-white-bg mat-elevation-z4" fxFlex="35" fxLayout="column" fxLayoutAlign="stretch">
                        <div class="page-title-wrapper pipl" fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="pulse-main-heading mr-16" >
                                People List
                            </div>
                            <div *ngIf="personData">{{personData?.length}} record(s) found</div>
                        </div>

                        <div fxLayout="column" pulsePerfectScrollbar fxLayoutAlign="space-between">
                            <div *ngIf="personData && personData.length > 0"
                                 class="row-1 p-16 pipl-checkbox-block mat-white-bg "
                                 fxLayout="column" fxFlex="100" fxLayoutGap="10px">
                                <div class="w-100-p" fxLayout="row" fxLayoutGap="10px">

                                    <div fxFlex="90" class="checkbox-container" fxLayout="row wrap" fxLayoutGap="4px">
                                        <ng-container *ngFor="let source of sources">
                                            <div class="single-source mb-12" fxFlex="32" *ngIf="source.id != 350000" >

                                                    <dx-check-box class="mx-8" [value]="source.selected" (onValueChanged)="sourceCheckChange($event, source.id)"
                                                                  [disabled]="source.disabled" [elementAttr]="{ id: source.id }">
                                                    </dx-check-box>
                                                    <img class="source-img"
                                                         src="/assets/images/source-images/{{source.name.toLowerCase()}}.png"
                                                         alt="{{source.name}}"
                                                         height="30"/>

                                            </div>
                                        </ng-container>
                                    </div>
                                    <div fxFlex="10" fxLayoutAlign="start start">
                                        <dx-button class="pulse-btn pulse-btn-primary"
                                                   icon="fa fa-search"
                                                   [useSubmitBehavior]="true"
                                                   (onClick)="searchPeople()">
                                        </dx-button>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let person of personData">
                                <div (click)="showProfileDetail(person)" *ngIf="person.email || person.phone"
                                     class="row-1 mb-16 p-16 pipl-single-block  "
                                     fxLayout="row" fxFlex="100" fxLayoutGap="10px">

                                    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="20">
                                        <div class="avatar-container round">
                                            <img width="100" height="100"    class="avatar-thumb"
                                                 src="{{person?.picture_url || 'assets/images/avatars/profile.jpg'}}"/>
                                            <div class="avatar accent" *ngIf="!person?.picture_url">
                                                {{person?.first_name}}
                                            </div>
                                        </div>
                                        <span class="source mt-12" *ngIf="source"></span>
                                        <div class="add_to_jobdiva_btn" *ngIf="person?.source != 'jobdiva'">
                                            <span class="jd_button" (click)="addToJobDiva(person)" title="Add to Jobdiva"><i class="fa fa-plus-circle"></i>Jobdiva</span>
                                        </div>
                                    </div>
                                    <div fxLayout="column" class="w-100-p">
                                        <div class="list-info">

                                            <div fxLayout="row" fxLayoutAlign="space-between">
                                                <h4 class="name my-4">{{person?.first_name}} {{person?.middle_name}}
                                                    {{person?.last_name}}</h4>
                                                <span>
                                                <img class="source-img"
                                                     src="/assets/images/source-images/{{(person?.source == 'Database' ? 'aptask' : person?.source.toLowerCase())}}.png"
                                                     alt="{{person?.source}}"
                                                     height="50"/>
                                            </span>
                                            </div>
                                            <div fxLayout="row" *ngIf="person?.email && person?.email != null">
                                                <div>
                                                    <i class="fa fa-envelope font-size-12"></i>
                                                </div>
                                                <span class="email">{{person.email}}</span>
                                            </div>
                                            <div fxLayout="row" *ngIf="person?.phone && person?.phone != null">
                                                <div>
                                                    <i class="fa fa-mobile font-size-12"></i>
                                                </div>
                                                <span class="phone">{{person.phone}}</span>
                                            </div>
                                        </div>

                                        <div fxLayout="row wrap" class="social-profile"
                                             *ngIf="person?.social_profiles && person.social_profiles.length > 0">
                                            <ng-container *ngFor="let socialProfile of person.social_profiles">
                                                <a target="_blank" *ngIf="socialIcon[socialProfile['@domain']]"
                                                   [href]="socialProfile.url">
                                                    <i class="fab {{socialIcon[socialProfile['@domain']]}} "></i>
                                                </a>
                                            </ng-container>
                                        </div>
                                        <div fxLayout="row" class="list-block email-list my-8"
                                             *ngIf="person?.emails && person.emails.length > 0">
                                            <!--<ng-container
                                                *ngFor="let singleEmail of (person.emails | slice:person.emails.length - 3) ">
                                                <div>
                                                    <i class="fa fa-envelope"></i>
                                                    <a [href]="'mailto:'+singleEmail.address">
                                                        {{singleEmail.address}}
                                                    </a>
                                                </div>

                                            </ng-container>-->

                                            <div>
                                                <i class="fa fa-envelope"></i>
                                            </div>
                                            <ul>
                                                <li *ngFor="let singleEmail of (person.emails | slice:person.emails.length - 3)"
                                                    class="list-items">
                                                    <a [href]="'mailto:'+singleEmail.address">
                                                        {{singleEmail.address}}
                                                    </a>
                                                </li>
                                            </ul>

                                        </div>
                                        <div fxLayout="row" class="list-block phone-list my-8"
                                             *ngIf="person?.phones && person.phones.length > 0">
                                            <!--<ng-container
                                                *ngFor="let singlePhone of (person.phones | slice:person.phones.length - 3)">
                                                <div>
                                                    <i class="fa fa-phone"></i>

                                                    <a [href]="'tel:'+singlePhone.display_international">
                                                        {{singlePhone.display_international}}
                                                    </a>
                                                </div>
                                            </ng-container>-->

                                            <div>
                                                <i class="fa fa-phone"></i>
                                            </div>
                                            <ul>
                                                <li *ngFor="let singlePhone of (person.phones | slice:person.phones.length - 3)"
                                                    class="list-items">
                                                    <a [href]="'tel:'+singlePhone.number">
                                                        {{singlePhone.number}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="employment list-block" fxLayout="row"
                                             *ngIf="person && person.employment &&  person.employment.length > 0">
                                            <div>
                                                <i class="fa fa-briefcase font-size-12"></i>
                                            </div>
                                            <ul>
                                                <li *ngFor="let employment of (person.employment | slice:person.employment.length - 2)"
                                                    class="list-items">

                                                <span *ngIf="employment.organization">{{employment.title}} @
                                                    {{employment.organization}}
                                                    ({{employment.date_range?.start | date:'yyyy'}} -
                                                    {{employment.date_range?.end | date:'yyyy'}} )
                                                </span>
                                                    <span *ngIf="employment.industry">{{employment.display}} @
                                                    {{employment.industry}}
                                                    ({{employment.date_range?.start | date:'yyyy'}} -
                                                    {{employment.date_range?.end | date:'yyyy'}} )
                                                </span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="educations list-block" fxLayout="row"
                                             *ngIf="person && person.educations &&  person.educations.length > 0">
                                            <div>
                                                <i class="fa fa-graduation-cap"></i>
                                            </div>
                                            <ul>
                                                <li *ngFor="let educations of (person.educations | slice:person.educations.length - 2)"
                                                    class="list-items">
                                                <span *ngIf="educations.degree">{{educations.degree}} @
                                                    {{educations.school}}
                                                    ({{educations.date_range?.start | date:'yyyy'}} -
                                                    {{educations.date_range?.end | date:'yyyy'}} )
                                                </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="personData == null"
                                 class="row-1 p-16 pipl-single-block mat-white-bg "
                                 fxLayout="row" fxFlex="100" fxLayoutGap="10px">
                                <div class="no-data w-100-p" fxLayout="column" fxLayoutAlign="center center">
                                    <i class="fa fa-users"></i>
                                    <p>Start searching people from left panel.</p>
                                </div>
                            </div>
                            <div *ngIf="personData && personData.length == 0"
                                 class="row-1 p-16 pipl-single-block mat-white-bg "
                                 fxLayout="row" fxFlex="100" fxLayoutGap="10px">
                                <div class="no-data w-100-p" fxLayout="column" fxLayoutAlign="center center">
                                    <i class="fa fa-users"></i>
                                    <p>No records found.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mat-elevation-z4 mat-white-bg" fxFlex="45" fxLayout="column" >
                        <div class="page-title-wrapper pipl" fxLayout="row wrap" fxLayoutAlign="start center">
                            <div class="pulse-main-heading mr-16" >
                                People Detail
                            </div>
                        </div>

                        <div fxLayout="column" class="w-100-p" pulsePerfectScrollbar fxLayoutAlign="start">
                            <div *ngIf="singlePerson" class="pipl-single-block w-100-p" fxLayout="column" fxLayoutGap="20px" >
                                <dx-form>
                                    <dxi-item itemType="tabbed" >
                                        <dxo-tab-panel-options [deferRendering]="false" [onInitialized]="onTabInitialized"></dxo-tab-panel-options>

                                        <dxi-tab icon="user" title=" ">
                                            <dxi-item>
                                                <div class="avatar-container round detail-avatar">
                                                    <img class="avatar-thumb"
                                                         [src]="singlePerson.picture_url || 'assets/images/avatars/profile.jpg'">
                                                    <div class="avatar accent" *ngIf="!person?.picture_url">
                                                        {{singlePerson?.first_name}}
                                                    </div>
                                                </div>

                                                <div class="person-title">
                                                    <h3 class="my-0">{{singlePerson.display_name}}</h3>
                                                </div>
                                            </dxi-item>
                                            <dxi-item>
                                                <div fxLayout="column" class="email-list"
                                                     *ngIf="singlePerson?.emails && singlePerson.emails.length > 0">
                                                    <ng-container *ngFor="let singleEmail of singlePerson.emails ">
                                                        <div>
                                                            <i class="fa fa-envelope font-size-12"></i>
                                                            <a [href]="'mailto:'+singleEmail.address">
                                                                {{singleEmail.address}}
                                                            </a>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div fxLayout="column" class="phone-list"
                                                     *ngIf="singlePerson?.phones && singlePerson.phones.length > 0">
                                                    <ng-container *ngFor="let singlePhone of singlePerson.phones">
                                                        <div>
                                                            <i class="fa fa-phone font-size-12"></i>

                                                            <a [href]="'tel:'+singlePhone.number">
                                                                {{singlePhone.number}}
                                                            </a>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="employment list-block" fxLayout="row"
                                                     *ngIf="singlePerson && singlePerson.employment &&  singlePerson.employment.length > 0">
                                                    <!--<div>-->
                                                    <!--<i class="fa fa-briefcase font-size-12"></i>-->
                                                    <!--</div>-->
                                                    <ul>
                                                        <li *ngFor="let employment of singlePerson.employment"
                                                            class="list-items"
                                                            fxLayout="row">
                                                            <ng-container
                                                                *ngIf="employment.organization || employment.industry">
                                                                <i class="fa fa-briefcase font-size-12"></i>
                                                                <div>
                                                    <span *ngIf="employment.organization">{{employment.title}} @
                                                        {{employment.organization}}
                                                        ({{employment.date_range?.start | date:'yyyy'}} -
                                                        {{employment.date_range?.end | date:'yyyy'}})
                                                    </span>
                                                                    <span *ngIf="employment.industry">{{employment.display}} @
                                                        {{employment.industry}}
                                                        ({{employment.date_range?.start | date:'yyyy'}} -
                                                        {{employment.date_range?.end | date:'yyyy'}})
                                                    </span>
                                                                </div>

                                                            </ng-container>

                                                        </li>
                                                    </ul>
                                                </div>

                                                <div class="educations list-block" fxLayout="row"
                                                     *ngIf="singlePerson && singlePerson.educations &&  singlePerson.educations.length > 0">
                                                    <!--<div>-->
                                                    <!--<i class="fa fa-graduation-cap"></i>-->
                                                    <!--</div>-->
                                                    <ul>
                                                        <li *ngFor="let educations of singlePerson.educations"
                                                            class="list-items"
                                                            fxLayout="row">
                                                            <ng-container *ngIf="educations.degree">
                                                                <i class="fa fa-graduation-cap"></i>
                                                                <span>
                                                    {{educations.degree}} @ {{educations.school}}
                                                    ({{educations.date_range?.start | date:'yyyy'}} -
                                                    {{educations.date_range?.end | date:'yyyy'}})
                                                </span>
                                                            </ng-container>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div fxLayout="row wrap" class="social-profile"
                                                     *ngIf="singlePerson?.social_profiles && singlePerson.social_profiles.length > 0">
                                                    <ng-container
                                                        *ngFor="let socialProfile of singlePerson.social_profiles">
                                                        <a target="_blank" *ngIf="socialIcon[socialProfile['@domain']]"
                                                           fxLayout="center center"
                                                           [href]="socialProfile.url">
                                                            <i class="fab {{socialIcon[socialProfile['@domain']]}}"></i>
                                                        </a>
                                                    </ng-container>
                                                </div>
                                            </dxi-item>
                                        </dxi-tab>
                                        <dxi-tab icon="fab fa-twitter" title=" " *ngIf="twitterUrl">
                                            <dxi-item pulsePerfectScrollbar>
                                                <ngx-twitter-timeline
                                                    [data]="{sourceType: 'url', url: twitterUrl}"
                                                    [opts]="{tweetLimit: 10}"
                                                ></ngx-twitter-timeline>
                                            </dxi-item>
                                        </dxi-tab>
                                        <!--<dxi-tab icon="fab fa-linkedin" title=" ">
                                            <dxi-item itemType="group">
                                                <iframe src="https://www.linkedin.com/biz/tajhaslani/feed?start=0&v2=true"></iframe>
                                            </dxi-item>
                                        </dxi-tab>-->
                                    </dxi-item>
                                </dx-form>
                            </div>
                            <div *ngIf="!singlePerson"
                                 class="row-1 p-16 mat-elevation-z4 pipl-single-block mat-white-bg "
                                 fxLayout="row" fxFlex="100" fxLayoutGap="10px">
                                <div class="no-data w-100-p" fxLayout="column" fxLayoutAlign="center center">
                                    <i class="fa fa-user"></i>
                                    <p>User detail will available here</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- container -->
</div>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#profile-list' }"
               [(visible)]="loadingVisible"
               [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>
