import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common/common.service';
import { SwalService } from 'app/services/swal.service';

@Component({
  selector: 'app-git-hub-search',
  templateUrl: './git-hub-search.component.html',
  styleUrls: ['./git-hub-search.component.scss']
})
export class GitHubSearchComponent implements OnInit {

    formData: any = {};
    customers: any = [];
    templates: any = [];

    constructor(private commonService:CommonService,private swalService: SwalService) {
    }

    ngOnInit() {
        //this.getTemplatesByType();
    }

    // getTemplatesByType() {
    //   let swalData ={};
    //   this.commonService.getTemplatesByType("email")
    //     .subscribe(
    //       response => {
    //       this.templates  = response['data'];
    //        console.log('this.templates', this.templates);
    //        },
    //       error => {
    //         swalData = {
    //           title: 'Error',
    //           message: `Unable to fetch templates !`,
    //           info: 'Server error'
    //         };
    //         this.swalService.error(swalData)
    //        });
    // }

    // getTemplateById(id) {
    //     let swalData ={};
    //     this.commonService.getTemplateById(id)
    //       .subscribe(
    //         response => {
    //          let res  = response['data'];
    //          if(res.length==1){
    //             this.populateEmailData(res[0]);
    //          }
    //          console.log('selected template response : ', res);
    //          },
    //         error => {
    //           swalData = {
    //             title: 'Error',
    //             message: `Unable to fetch template details !`,
    //             info: 'Server error'
    //           };
    //           this.swalService.error(swalData)
    //          });
    // }

    populateEmailData(data){
        this.formData.subject = data.template_subject;
        this.formData.emailText = data.template_content;
    }

    // onTemplateChanged = (e) => {
    //     console.log(e);
    //     this.getTemplateById(e.selectedItem.template_id);
    // }

    onFormSubmit(e) {
        console.log(this.formData);
        console.log(e);
    }

    searchEmailOnGithub = () => {
        console.log("on click");
    }
}
