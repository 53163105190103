import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {RolesService} from '../../../../services/roles/role.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DxTreeViewComponent} from 'devextreme-angular';
import {SwalService} from '../../../../services/swal.service';

@Component({
    selector: 'app-role-permissions',
    templateUrl: './role-permissions.component.html',
    styleUrls: ['./role-permissions.component.scss']
})

export class RolePermissionsComponent implements OnInit {

    @ViewChildren(DxTreeViewComponent) permissionTree: QueryList<DxTreeViewComponent>;
    @ViewChild('pagePermissionTree') pagePermissionTree: DxTreeViewComponent;
    currentRole: any;
    public permissions: any = {
        LEFT: [],
        RIGHT: []
    };
    public newPermissions: any = [];
    public pagePermission: any = [];

    roleId = 0;

    public tabList: any = [{
        'ID': 1,
        'tabName': 'Top Left Menu',
        'child': 'LEFT',
    }, {
        'ID': 2,
        'tabName': 'Top Right Menu',
        'child': 'RIGHT',
    }];

    accordions: any = [{'ID': 1}];
    pageAccordions: any = [{'ID': 1}];

    constructor(private rolesService: RolesService,
                private route: ActivatedRoute,
                private router: Router,
                private swalService: SwalService) {
        this.route.params.subscribe(params => {
            this.roleId = params.roleId || 0;
            this.currentRole = params.name || '';
        });
        (this.tabList).forEach(tab => {
            const queryParams = {
                roleId: this.roleId,
                parentId: 0,
                navigationType: tab.child,
                type: 'item', // item
            };
            this.getPermissionTree(queryParams);
        });

        this.getPageLevelPermissionData({roleId: this.roleId});
    }

    ngOnInit() {

    }

    getPermissionTree(queryParams) {
        const navigationType = queryParams.navigationType;
        this.rolesService.getRolePermission(queryParams).toPromise().then((subPermissions: any) => {
            this.permissions[navigationType] = subPermissions.data;

            if (subPermissions.data.length > 0) {
                (subPermissions.data).forEach(permissionValue => {
                    if (permissionValue.permission_flag && permissionValue.permission_flag) {
                        this.newPermissions[permissionValue.permission_id] = permissionValue;
                    }
                });
            }
            /*if(this.permissionTree){
                this.permissionTree.map((tree) => {
                    tree.instance.expandAll();
                });
            }*/
        });
    }

    getPageLevelPermissionData(queryParams) {
        this.rolesService.getPageLevelPermission(queryParams).toPromise().then((subPermissions: any) => {
            this.pagePermission = subPermissions.data;
        });
    }

    selectionChanged(e) {
        const value = e.node;

        

        // this.permissionTree.map((tree) => {
        //     return tree.instance.selectItem(value.itemData.parentID);
        // });
    }

    appendArray(value) {
        this.newPermissions[value.itemData.permission_id] = value.itemData;
        if (value.children && value.children.length > 0) {
            (value.children).forEach(child => {
                this.appendArray(child);
            });
        }
    }

    saveRolePermissions(event) {
        this.permissionTree.map((tree) => {
            tree.instance.getNodes().forEach(node => {
                this.appendArray(node);
            });
        });

        this.pagePermissionTree.instance.getNodes().forEach(node => {
            this.appendArray(node);
        });

        const newPermissions = (this.newPermissions).filter((item) => {
            return item.permission_flag || (item.hasOwnProperty('permission_flag') && item.permission_flag === undefined);
        });

        const postData = {
            roleId: this.roleId,
            permissions: newPermissions
        };

        this.rolesService.saveRolePermissions(postData).subscribe(response => {
                const swalData = {
                    title: 'Success',
                    message: `Permissions updated successfully!`,
                    info: ''
                };
                this.swalService.success(swalData).then(() => {
                    this.router.navigate(['/role/list']);
                });
            },
            err => {
                console.log(err);
            });

    }

    savePageLevelPermissions(e) {
        const pagePermissions = [];
        let allPermissions = '';
        let currentRole=this.currentRole;
        let commissionRoles:string[]=['Account Executive','Recruiter','Team Lead','Referral','Recruiting Manager'];

        this.pagePermissionTree.instance.getNodes().forEach((node: any) => {
            const SelectedLength = node.items.filter(item => item.selected).length;
            if (node.items.filter(item => item.selected).length > 0 && (SelectedLength === node.items.length)) {
                pagePermissions.push(node.key);
            }
            allPermissions += (allPermissions == '') ? node.key : ',' + node.key;

            if(node.items.length>0){
                node.items.forEach((permission) => {
                    if (permission.selected) {
                        pagePermissions.push(permission.key);
                    }
                    allPermissions += (allPermissions == '') ? permission.key : ',' + permission.key;
                });
            } else{


                if(commissionRoles.includes(currentRole) && node.text=='Commissions'){
                    node.selected=true; //Commission is always selected for AE,REC,TL,REF,RM
                }

                if (node.selected) {
                    pagePermissions.push(node.key);
                }
            }

            
        });

        const postData = {
            roleId: this.roleId,
            permissions: pagePermissions,
            allPermissions: allPermissions
        };

        this.rolesService.savePagePermissions(postData).subscribe(response => {
                const swalData = {
                    title: 'Success',
                    message: `Permissions updated successfully!`,
                    info: ''
                };
                this.swalService.success(swalData).then(() => {
                    this.router.navigate(['/role/list']);
                });
            },
            err => {
                console.log(err);
            });
    }

    onCancel(e) {
        this.router.navigate(['/role/list']);
    }
}
