import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { CamelCaseToSpacePipe } from './camelCaseToSpace.pipe';
import { SearchFilterPipe } from './searchFilter.pipe';
import { TextTrimPipe } from './textTrim.pipe';
import { FormatMessagePipe } from './format-message.pipe';
import {ReplaceStringPipe} from './replaceString.pipe';
import {ConsoleLogPipe} from './console.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        CamelCaseToSpacePipe,
        SearchFilterPipe,
        TextTrimPipe,
        FormatMessagePipe,
        ReplaceStringPipe,
        ConsoleLogPipe
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        CamelCaseToSpacePipe,
        SearchFilterPipe,
        TextTrimPipe,
        FormatMessagePipe,
        ReplaceStringPipe,
        ConsoleLogPipe
    ]
})
export class PulsePipesModule {
}
