import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PulsePerfectScrollbarDirective} from '@pulse/directives/pulse-perfect-scrollbar/pulse-perfect-scrollbar.directive';

import {ChatService} from 'app/services/chat/chat.service';
import {SwalService} from '../../../../../services/swal.service';
import {CommonService} from '../../../../../services/common/common.service';

export interface messageTypeDeff {
    value: string;
    viewValue: string;
}

export interface templateListDeff {
    tmplId: string;
    tmplName: string;
}

@Component({
    selector: 'chat-view',
    templateUrl: './chat-view.component.html',
    styleUrls: ['./chat-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatViewComponent implements OnInit, OnDestroy, AfterViewInit {
    user: any;
    chat: any;
    dialog: any;
    contact: any;
    replyInput: any;
    selectedChat: any;
    loggedInUserDetails: any;
    messageType: string;
    defaultAvatar = '/assets/images/avatars/profile.jpg';
    editCandPopup = false;
    candidateData: any = {};
    countryCodes = [];
    messageTypeList: messageTypeDeff[] = [
        { value: 'sms', viewValue: 'SMS' },
        { value: 'text', viewValue: 'Chat' },
        { value: 'note', viewValue: 'Note' }
    ];
    selectedMessageType: string;
    templateType: string;
    selectedChatObj: any = {};
    templateList = [];
    popupTitle = 'Edit Candidate';
    @ViewChild(PulsePerfectScrollbarDirective)
    directiveScroll: PulsePerfectScrollbarDirective;

    @ViewChildren('replyInput')
    replyInputField;

    @ViewChild('replyForm')
    replyForm: NgForm;
    message: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     * @param swalService
     * @param commonService
     */
    constructor(
        private _chatService: ChatService,
        private swalService: SwalService,
        private commonService: CommonService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        //this.replyForm.form.value.selectedMessageType = "sms";
        this.onContactPhoneChanged = this.onContactPhoneChanged.bind(this);
        //initialize Socket Service
        this._chatService.getJoinInformationFromSocket().subscribe(data=> {
            //console.log("Socket working");
            //console.log(data);
        })

        this._chatService.getNewMessageInformationFromSocket().subscribe(data=> {

            if(data['pageScroll']==1){
                //debugger;
                this.scrollToBottom();
            }

            // Update the Last message and time
            let chatList = this.user.chatList;
            for (let i = 0; i < chatList.length ; i++) {

                if ( chatList[i].contactId == data.message.created_by ) {
                    let lastMsg = data.message.message;
                    this.user.chatList[i].lastMessage = lastMsg.substring(0,20) + "..";
                    this.user.chatList[i].lastMessageTime = new Date();
                }
            }
        })

        this._chatService.getTemplateList().subscribe((result) => {

            let templateResp = result.data;

            templateResp.forEach(element => {

                this.templateList.push({
                    "value": element.template_content,
                    "text": element.template_subject
                });
            });
        },
            (error) => {
                console.log("getRolesERROR", error)
            })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.loggedInUserDetails = JSON.parse(localStorage.getItem('userData'));

        this.user = this._chatService.user;
        this.selectedChatObj = this._chatService.selectedChatObj;
        this.selectedMessageType = this.selectedChatObj.isInternal ? 'text' : 'sms';
        const countryCodes = this._chatService.countryCodes;

        countryCodes.map(item => {
            this.countryCodes.push({
                callCode: `+${item.callingCodes[0]}`,
                name: item.name,
                flag: item.flag,
                alpha2Code: item.alpha2Code
            });
        })
        this._chatService.onChatSelected
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(chatData => {
            if (chatData) {
                this.selectedChat = chatData;
                this.contact = chatData.contact;
                this.dialog = chatData.dialog;
                this.selectedMessageType = this.selectedChat.isInternal ? 'text' : 'sms';
                this.readyToReply();
            }
        });

        this._chatService.smsStatusUpdateReceivedFromSocket().subscribe(data=> {

            for (let i = 0; i < this.dialog.length ; i++) {

                if ( this.dialog[i].sms_sid == data.message.sms_sid ) {
                    this.dialog[i].sms_delivered = data.message.sms_delivered;
                    this.dialog[i].sms_status = data.message.sms_status;
                }
            }
        });
    }

    /**
     *
     * @param tmplText
     */
    setTemplateText(tmplText): void {
        this.message = tmplText;
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this.replyInput = this.replyInputField.first.nativeElement;
        this.readyToReply();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    shouldShowContactAvatar(message, i): boolean {
        return (
            message.who === this.contact.user_id &&
            ((this.dialog[i + 1] && this.dialog[i + 1].who !== this.contact.user_id) || !this.dialog[i + 1])
        );
    }

    /**
     *
     * @param messageType
     * @param i
     */
    showMessageType(message, i, messageType): boolean {
        // console.log( this.dialog );
        return (
            messageType == this.dialog[i].messageType
        );
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i): boolean {
        return (i === 0 || this.dialog[i - 1] && this.dialog[i - 1].who !== message.who);
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i): boolean {
        return (i === this.dialog.length - 1 || this.dialog[i + 1] && this.dialog[i + 1].who !== message.who);
    }

    /**
     * Select contact
     */
    selectContact(): void {
        console.log(this.contact);
        this._chatService.selectContact(this.contact);
    }

    editContact(): void {

        this.editCandPopup = true;
        this.candidateData = {
            frmUserFirstName: this.contact.first_name,
            frmUserLastName: this.contact.last_name,
            frmContactPhone: this.contact.mobile_no,
            frmContactEmail: this.contact.email,
            frmCountryCode: this.contact.country_code || '+1',
            frmJobDivaId: this.contact.jobdiva_id
        };
        console.log(this.candidateData);
        this.popupTitle = "Edit Candidate" + (this.candidateData.frmJobDivaId ? ` - ${this.candidateData.frmJobDivaId}` : '');
        this._chatService.selectContact(this.contact);
    }

    updateCandidate(e): void {
        const postData = {
            first_name: this.candidateData.frmUserFirstName,
            last_name: this.candidateData.frmUserLastName,
            mobile_no: this.candidateData.frmContactPhone,
            email: this.candidateData.frmContactEmail,
            country_code: this.candidateData.frmCountryCode,
            jobdiva_id: this.candidateData.frmJobDivaId || null
        };

        this._chatService.updateCandidateData(this.contact.user_id, postData).then((result:any) => {

            if (result.code === 200) {
                this._chatService.getUser().then(user => {
                    this._chatService.onUserUpdated.next(user);
                });
                Object.assign(this.contact, postData);
                this.contact.name = `${this.contact.first_name} ${this.contact.last_name}`;

                const swalData1 = {
                    title: 'Contact updated successfully',
                    message: `Contact is successfully updated!`,
                    info: ''
                };
                this.swalService.success(swalData1).then(value => {
                    this.editCandPopup = false;
                });
            }
        }).catch(err => {
            console.error('ERROR:', err);
            const swalData = {
                title: 'Error!',
                message: err && (err.message || err.error) || 'Something went wrong.',
                info: 'Contact is not updated!'
            };
            this.swalService.error(swalData);
        });

    }

    deleteContact(): void {
        const swalData = {
            title: 'Delete Contact?',
            message: `Are you sure you want to delete contact : ${this.contact.name}?`,
            info: 'Once it is deleted then it cannot be reverted!'
        };

        this.swalService.delete(swalData).then((res) => {
            if (res.value) {

                this._chatService.deleteContact(this.contact.user_id).subscribe((result: any) => {

                    if (result.code === 200) {
                        this._chatService.getUser().then(user => {
                            this._chatService.onUserUpdated.next(user);
                        });
                        this._chatService.onChatSelected.next(null);
                        const swalData1 = {
                            title: 'Contact Deleted',
                            message: `Contact is successfully deleted!`,
                            info: 'Now it can not be reverted!'
                        };
                        this.swalService.success(swalData1);
                    }
                }, err => {
                    const swalData1 = {
                        title: 'Error!',
                        message: err && (err.message || err.error) || 'Something went wrong.',
                        info: 'Contact is not deleted!'
                    };
                    this.swalService.error(swalData1);
                });
            }

        });

    }

    /**
     * Ready to reply
     */
    readyToReply(): void {
        setTimeout(() => {
            this.focusReplyInput();
            this.scrollToBottom();
        });
    }

    /**
     * Focus to the reply input
     */
    focusReplyInput(): void {
        setTimeout(() => {
            this.replyInput.focus();
        });
    }

    /**
     * Scroll to the bottom
     *
     * @param {number} speed
     */
    scrollToBottom(speed?: number): void {
        speed = speed || 400;
        if (this.directiveScroll) {
            this.directiveScroll.update();

            setTimeout(() => {
                this.directiveScroll.scrollToBottom(0, speed);
            });
        }
    }

    /**
     * Reply
     */
    reply(event): void {

        event.preventDefault();

        if (!this.replyForm.form.value.message) {
            return;
        }

        let msgType = this.replyForm.form.value.selectedMessageType == '' ? 'text' : this.replyForm.form.value.selectedMessageType;
        if( msgType == "" || msgType == undefined ){
            msgType = 'text';
        }

        let messageText = this.replyForm.form.value.message;
        let contantDetails = this.contact;
        let userDetails = this.user;

        // Converting the message with template data
        messageText = messageText.replace(new RegExp('{{candidate_first_name}}', 'g'), contantDetails.first_name);
        messageText = messageText.replace(new RegExp('{{candidate_last_name}}', 'g'), contantDetails.last_name);
        messageText = messageText.replace(new RegExp('{{candidate_email}}', 'g'), contantDetails.email);

        messageText = messageText.replace(new RegExp('{{recruiter_first_name}}', 'g'), userDetails.first_name);
        messageText = messageText.replace(new RegExp('{{recruiter_last_name}}', 'g'), userDetails.last_name);
        messageText = messageText.replace(new RegExp('{{recruiter_email}}', 'g'), userDetails.email);
        messageText = messageText.replace(new RegExp('{{recruiter_phone}}', 'g'), userDetails.voip_number || userDetails.mobile_no);
        const data = {
            message: {
                conversation_id: this.selectedChat.conversation_id,
                message: messageText,
                type: msgType,
                sms_number: '',
                messageType: msgType,
                attachment: {}, // response of "api/1.0/attach" api
                country_code: this.contact.country_code || '+1',
                mobile: this.contact.mobile_no,
                created_by: this.loggedInUserDetails.id,
                isUser: true,
                smsFormNumber: userDetails.sms_number_twillio,
                sms_status : "",
                sms_delivered: false
            },
        };

        // console.log("====Chat Data======");
        // console.log(data);

        // Update the server
        this._chatService.updateDialog(data.message).then(response => {

            console.log("Chat response");
            console.log(response);

            // Message
            const message = {
                who: this.user.id,
                message: messageText,
                messageType: msgType,
                time: new Date().toISOString(),
                sms_delivered: false,
                sms_status : "",
                sms_sid: response.sms_sid,
                toWhomName : this.user.first_name + ' ' + this.user.last_name,
            };

            // Add the message to the chat
            this.dialog.push(message);

            // Reset the reply form
            //this.replyForm.reset();

            this.readyToReply();

            this.selectedMessageType = this.selectedChat.isInternal==true ? 'text' : 'sms';
            this.message = "";
            this.templateType = "";
        });
    }
    onCountryCodeChanged(event) {
        this.candidateData.frmCountryCode = event.value;
    }

    onContactPhoneChanged(event) {
        console.log(event);
        if (!this.candidateData.frmContactPhone) {
            return;
        }
        const mobileNo = this.candidateData.frmContactPhone.replace(/[- )(]/g, '');
        if (mobileNo.length !== 10) {
            return;
        }

        this.commonService.searchCandidateByEmailOnJD(mobileNo).subscribe((response: any) => {
            console.log(response);
            if (Number(response.code) === 200 && response.data) {
                this.candidateData.frmContactEmail = response.data.Email;
                this.candidateData.frmUserFirstName = response.data.FirstName;
                this.candidateData.frmUserLastName = response.data.LastName;
                this.candidateData.frmJobDivaId = response.data.CandID;
                this.popupTitle += ` - ${this.candidateData.frmJobDivaId}`;
            }
        });

    }

}
