import {Component, OnInit} from '@angular/core';
import {ScraperAccountsService} from 'app/services/scraper-accounts/scraper-accounts.service';
import {TeamsService} from 'app/services/teams/teams.service';
import {ParamMap, ActivatedRoute, Router} from '@angular/router';
import {SwalService} from '../../../../services/swal.service';
import {forkJoin} from "rxjs";


@Component({
    selector: 'scraper-account-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})


export class FormComponent implements OnInit {

    formData = {
        name: '',
        id: '',
        username: '',
        password: '',
        teamId: [],
    };

    teamArray = [];
    accountList = [
        {display: 'Monster', value: 'Monster'},
        {display: 'Dice', value: 'Dice'},
        {display: 'Career Builder', value: 'CB'}
    ];

    action = false;

    buttonOptions: any = {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true
    };

    constructor(private route: ActivatedRoute,
                private router: Router,
                private scraperAccountService: ScraperAccountsService,
                private teamsService: TeamsService,
                private swalService: SwalService
    ) {
    }

    resetData() {
        this.formData = {
            name: '',
            id: '',
            username: '',
            password: '',
            teamId: [],
        };
    }

    ngOnInit() {
        this.route.paramMap.subscribe((param: ParamMap) => {
            // we can fetch the role object by calling api

            this.action = (param.get('id') == null);
            if (!this.action) {
                this.getData(param.get('id'));
            } else {
                this.getTeam();
            }
            console.log(this.action);
        });
    }

    getTeam() {
        this.teamsService.getTeams().subscribe((teamsRes: any) => {
            if (teamsRes.data) {
                teamsRes.data.forEach(item => {
                    this.teamArray.push({
                        display: item.name,
                        value: item.id
                    });
                });
            }
        });
    }

    getData(id) {
        forkJoin([
            this.teamsService.getTeams(),
            this.scraperAccountService.getById(id)
        ]).subscribe(([teams, formData]) => {
            const teamsRes: any = teams;
            const formDataRes: any = formData;

            if (teamsRes.data) {
                teamsRes.data.forEach(item => {
                    this.teamArray.push({
                        display: item.name,
                        value: item.id
                    });
                });
            }

            if (!formDataRes && !formDataRes.data.length) {
                // this.router.navigate(['/scraper-account']);
            } else {
                this.formData = {
                    id: formDataRes.data[0].id,
                    name: formDataRes.data[0].name,
                    username: formDataRes.data[0].username,
                    password: formDataRes.data[0].password,
                    teamId: (formDataRes.data[0].teamId || [])
                };
            }
        });
    }

    onFormSubmit(e) {
        let swalData;
        if (this.action) {
            const sendData = [{
                name: this.formData.name,
                username: this.formData.username,
                password: this.formData.password,
                inUse: 0,
                usingByUserID: null,
                loginFailedReason: null,
                teamId: this.formData.teamId,
            }]
            this.scraperAccountService.add(sendData).subscribe(data => {
                const self = this;
                swalData = {
                    title: 'Success',
                    message: `Scraper account added successfully!`,
                    info: ''
                };
                this.swalService.success(swalData).then(function (result) {
                    self.router.navigate(['/scraper-account']);
                });
            }, error => {
                console.log(error);
                swalData = {
                    title: 'Error',
                    message: `Unable to add Scraper account !`,
                    info: error.statusText
                };
                this.swalService.error(swalData);
            });
        } else {
            this.scraperAccountService.update(this.formData.id, this.formData).subscribe(data => {
                const self = this;
                swalData = {
                    title: 'Success',
                    message: `Scraper account updated successfully!`,
                    info: ''
                };
                this.swalService.success(swalData).then(function (result) {
                    self.router.navigate(['/scraper-account']);
                });
            }, error => {
                console.log(error);
                swalData = {
                    title: 'Error',
                    message: `Unable to update Scraper account !`,
                    info: error.statusText
                };
                this.swalService.error(swalData);
            });
        }
    }

    cancelForm = () => {
        this.router.navigate(['/scraper-account']);
    }

}
