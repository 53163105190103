import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { siteConfig } from 'app/siteSettings';
import { countryCodes } from '../country.codes';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private _listners = new Subject<any>();
    showLoadPanel: BehaviorSubject<any>;
    masterData: BehaviorSubject<any>;
    businessEntities: BehaviorSubject<any>;
    masterDataCats: any = siteConfig.masterDataCategories;

    businessEntitiesTypes: any = siteConfig.businessEntitiesTypes;

    updateConsultantAssignMem: BehaviorSubject<any>;

    constructor(private _http: HttpClient) {
        this.masterData = new BehaviorSubject({});
        this.showLoadPanel = new BehaviorSubject(false);
        this.businessEntities = new BehaviorSubject({});

        this.updateConsultantAssignMem = new BehaviorSubject(false);
    }

    _listners$ = this._listners.asObservable();

    fireEvent(event: string) {
        this._listners.next(event);
    }

    getUserIpAddress() {
        return this._http.get('https://api.ipify.org/?format=json');
    }

    getAllMasterData() {
        const masterCatIds = Object.values(this.masterDataCats).join(',');
        const url = `${siteConfig.nodeAPIBaseUrl}common/getmasterdatabycatid?catid=${masterCatIds}`;
        this._http.get(url).subscribe((data: any) => {
            const masterData = {};
            if (data.code == 200 && data.data.length > 0) {
                Object.keys(this.masterDataCats).map(key => {
                    masterData[key] = data.data.filter(item => item.mas_cat_id === this.masterDataCats[key]);
                });
            }
            //console.log(masterData);
            this.masterData.next(masterData);
        }, error1 => {
            console.log('Error while retrieving masterdata =>', error1);
        });

        /*  forkJoin(batchObservables).subscribe((data: any) => {
              const masterData = {};
              Object.keys(this.masterDataCats).map((key, index) => {
                  if (Number(data[index].code) === 200) {
                      masterData[key] = data[index].data;
                  } else {
                      masterData[key] = [];
                  }
              });
              this.masterData.next(masterData);
          });*/
    }

    getAllBusinessEntities() {
        const batchObservables = [];
        Object.keys(this.businessEntitiesTypes).map(key => {
            batchObservables.push(this.getBusinessEntityDetails(key));
        });
        forkJoin(batchObservables).subscribe((data: any) => {
            const businessEntities = {};
            Object.keys(this.businessEntitiesTypes).map((key, index) => {
                if (Number(data[index].code) === 200) {
                    businessEntities[key] = data[index].data;
                } else {
                    businessEntities[key] = [];
                }
            });
            this.businessEntities.next(businessEntities);
        });
    }

    getMasterData(type) {
        const dataType = this.masterDataCats[type];
        const url = `${siteConfig.nodeAPIBaseUrl}common/getmasterdatabycatid?catid=${dataType}`;
        return this._http.get(url);
    }

    getAllUsers() {
        const url = `${siteConfig.nodeAPIBaseUrl}users/list?from=all`;
        return this._http.get(url);
    }

    getAllInternalUsers() {
        const url = `${siteConfig.nodeAPIBaseUrl}users/get-common-internal-users`;
        return this._http.get(url);
    }

    getAllQbUsers() {
        const url = `${siteConfig.nodeAPIBaseUrl}common/get-qb-users`;
        return this._http.get(url);
    }

    getAllRoles() {
        const url = `${siteConfig.nodeAPIBaseUrl}role/list`;
        return this._http.get(url);
    }

    getCommissionRole () {
        const url = `${siteConfig.nodeAPIBaseUrl}role/get-commision-role`;
        return this._http.get(url);
    }

    getAllUsersByRole() {
        const url = `${siteConfig.nodeAPIBaseUrl}users/getUsersByParentRole`;
        return this._http.get(url);
    }

    getCountryCodes() {
        return new Promise((resolve, reject) => {
            resolve(countryCodes);
        });
    }

    searchCandidateByEmailOnJD(phone) {
        const url = `${siteConfig.nodeAPIBaseUrl}jobdiva/searchCandidateByEmail?srchData=${phone}&srchType=phone`;
        return this._http.get(url);
    }

    getAllBusinessEntityByType(entityType = 3) {
        const url = `${siteConfig.nodeAPIBaseUrl}common/getbusinessentitybyname?entitytype=${entityType}&entityname=*`;
        return this._http.get(url);
    }

    getBusinessContactByID(id: number) {
        const url = siteConfig.nodeAPIBaseUrl + 'business_entities/' + id;
        return this._http.get(url);
    }

    updateBusinessEntityByID(vendorId, postData) {
        const url = siteConfig.nodeAPIBaseUrl + 'business_entities/' + vendorId;
        return this._http.post(url, postData);
    }

    addBusinessEntity(postData) {
        const url = siteConfig.nodeAPIBaseUrl + 'business_entities/';
        return this._http.post(url, postData);
    }

    //Add by ESt  
    addNewBusinessEntity(postData) {
        const url = siteConfig.nodeAPIBaseUrl + 'business_entities/add/';
        return this._http.post(url, postData);
    }

    addBusinessContacts(postData) {
        const url = siteConfig.nodeAPIBaseUrl + 'business_contacts/addwithuser/';
        return this._http.post(url, postData);
    }

    updateBusinessContacts(postData) {
        // const url = siteConfig.nodeAPIBaseUrl + 'business_contacts/deleteandinsert/'+entityId;
        const url = siteConfig.nodeAPIBaseUrl + 'business_contacts/updateandinsert/';
        return this._http.post(url, postData);
    }

    getBuContactsByEntityId(id) {
        const url = siteConfig.nodeAPIBaseUrl + 'business_contacts/business_entity_id/' + id;
        return this._http.get(url);
    }

    getQBItems() {
        const url = siteConfig.nodeAPIBaseUrl + 'common/getAllQBItems';
        return this._http.get<any>(url);
    }

    getQBItemByID(id: string) {
        const url = siteConfig.nodeAPIBaseUrl + 'common/get-qb-item/' + id;
        return this._http.get<any>(url);
    }

    searchQbItem(s: string) {
        const url = siteConfig.nodeAPIBaseUrl + 'common/search-qb-item?s=' + s;
        return this._http.get<any>(url);
    }

    getBusinessEntityDetails(type) {
        const dataType = this.businessEntitiesTypes[type];
        const url = `${siteConfig.nodeAPIBaseUrl}common/getbusinessentitybyname?entitytype=${dataType}&entityname=*`;
        return this._http.get(url);
    }

    getDataFromJobDiva(email) {
        const url = `${siteConfig.nodeAPIBaseUrl}jobdiva/searchCandidateByEmail?srchData=${email}&srchType=email`;
        return this._http.get(url);
    }

    getDataFromJobDivaByJobRef(JobId) {
        const url = `${siteConfig.nodeAPIBaseUrl}jobdiva/searchJobByJobRef?jobRefId=${JobId}`;
        return this._http.get(url);
    }

    getClientStatus(name) {
        let url = siteConfig.nodeAPIBaseUrl + 'common/getbusinessentitybyname?entitytype=2&entityname=' + name;
        return this._http.get<any>(url);
    }

    getDataFromUser(email) {
        const url = `${siteConfig.nodeAPIBaseUrl}users/fineOne?filter=email&filtervalue=${email}`;
        return this._http.get<any>(url);
    }

    // Method for checking existing vendor
    getVendorNameStatus(name) {
        let url = siteConfig.nodeAPIBaseUrl + 'common/getbusinessentitybyname?entitytype=3&entityname=' + name;
        return this._http.get<any>(url);

    }

    getStatusFromMaster(Id) {
        const resData = this._http.get(`${siteConfig.nodeAPIBaseUrl}common/getmasterdatabycatid?catid=${Id}`);
        return resData;
    }

    getSubStatusFromMaster(lookupId) {
        const resData = this._http.get(`${siteConfig.nodeAPIBaseUrl}common/getmasterdatabyparentlookup?lookupvalue=${lookupId}`);
        return resData;
    }

    updateStatusFromMaster(body: any) {
        let url = siteConfig.nodeAPIBaseUrl + 'consultants/updateStatus';
        let clnt = this._http.post<any>(url, body);
        return clnt;
    }

    getAllAE() {
        const resData = this._http.get(`${siteConfig.nodeAPIBaseUrl}common/getaebyname?username=*`);
        return resData;
    }

    getAllRec() {
        const resData = this._http.get(`${siteConfig.nodeAPIBaseUrl}common/getrecruiterbyname?username=*`);
        return resData;
    }

    getAllTeamLead() {
        const resData = this._http.get(`${siteConfig.nodeAPIBaseUrl}common/getleadsbyname?username=*`);
        return resData;
    }

    getAllCurrency() {
        const resData = this._http.get(`${siteConfig.nodeAPIBaseUrl}common/getmasterdatabycatid?catid=5`);

        return resData;
    }

    getAllRateType() {
        const resData = this._http.get(`${siteConfig.nodeAPIBaseUrl}common/getmasterdatabycatid?catid=6`);
        return resData;
    }

    getAllClient() {
        const resData = this._http.get(`${siteConfig.nodeAPIBaseUrl}common/getbusinessentitybyname?entitytype=2&entityname=*`);
        return resData;
    }

    getSystemReleaseInfo() {
        const resData = this._http.get(`${siteConfig.nodeAPIBaseUrl}system/releaseinfo`);
        return resData;
    }

    getAllQBPlans() {
        const url = `${siteConfig.nodeAPIBaseUrl}common/get-qb-plans`;
        return this._http.get(url);
    }

    getAllQBPlanRanges() {
        const url = `${siteConfig.nodeAPIBaseUrl}common/get-qb-plan-ranges`;
        return this._http.get(url);
    }

    scheduleEmails(reqBody) {
        const url = `${siteConfig.nodeAPIBaseUrl}common/schedule-emails`;
        return this._http.post(url, reqBody);
    }

    sendEmailNotificationForChat(reqBody) {
        let url = `${siteConfig.nodeAPIBaseUrl}users/sendEmailNotification`;
        return this._http.post(url, { ...reqBody })
    }

    uploadChatDocument(file) {
        let url = `${siteConfig.nodeAPIBaseUrl}webchat/uploadChatAttachments`;
        // let url = `${siteConfig.nodeAPIBaseUrl}`;
        return this._http.post(url, file)
    }

    sendChatAudio(data) {
        return this._http.post(`${siteConfig.nodeAPIBaseUrl}candidate/send-audio-message`, data);
    }

}
