import {NgModule, Component, ViewChild, enableProdMode, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {PulseTranslationLoaderService} from '@pulse/services/translation-loader.service';

import {locale as english} from './i18n/en';
import {locale as turkish} from './i18n/tr';

import {SmbauthService} from '../../../../services/smbauth/smbauth.service';

import {SwalService} from '../../../../services/swal.service';

import { environment } from '../../../../environment';
import {
    LinkedInService
} from 'angular-linkedin-sdk';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-smb-auth',
    templateUrl: './smb-auth.component.html',
    styleUrls: ['./smb-auth.component.scss'],
    providers: [SmbauthService]
})
export class SmbAuthComponent  implements OnInit {
    items: any = [];
    currentUser: string;
    data: any = [];
    status: any;
    apiKey: any = [];
    detail: any = [];
    userData:any ;
    //@ViewChild(SmbAuthFormComponent) subAuthForm: SmbAuthFormComponent;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private fuseTranslationLoader: PulseTranslationLoaderService,
        private swalService: SwalService,
        private _linkedInService: LinkedInService,
        private smbauthService: SmbauthService
    ) {
        // super();
        this.fuseTranslationLoader.loadTranslations(english, turkish);
    }

    socialSignIn(socialPlatform: string) {
        var state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        window.open(environment.li_token_link.link,"_self");
        var tester = window.location.href;
        var url_string = window.location.href; //window.location.href
        var url = new URL(url_string);
        var c = url.searchParams.get("code");
    }

    //this is new
    twitterLogin():void{
        this.smbauthService.twitterLogin(this.userData.id).subscribe(
            (result) => {
                window.open(result,"_self");
            },
            (error) => {
                console.log("twitterLogin", error);
            }
        );
    }
 
    goToSmbShare(){
         this.router.navigate(['smbshare']);
    }
    ngOnInit() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        var tester = window.location.href;
        var url_string = window.location.href; //window.location.href
        var url = new URL(url_string);
        var c = url.searchParams.get("code");
        var s = url.searchParams.get("state");

        var ot = url.searchParams.get("oauth_token");
        var ov = url.searchParams.get("oauth_verifier");

        if (c !== null && s !== null) {
            let result = {code: c, state: s,user_id: this.userData.id};
            this.smbauthService.linkedIn(result).subscribe(
                (result) => {
                    console.log("linkedInRESULT", result);
                    this.router.navigate(['smbauth']);
                    this.getDataByUserId();
                    var swalData = {
                        title: 'Success',
                        message: `Authorized successfully!`,
                        info: ''
                      };
                      this.swalService.success(swalData).then(function (result) {
                      
                    });
                },
                (error) => {
                    console.log("linkedInERROR", error);
                    this.router.navigate(['smbauth']);
                }
            );
        }else if (ot !== null && ov !== null) {
            let result = {oauth_token: ot, oauth_verifier: ov,id: this.userData.id};
            this.smbauthService.twitter(result).subscribe(
                (result) => {
                    console.log("twitter result", result);
                    this.router.navigate(['smbauth']);
                    this.getDataByUserId();
                    var swalData = {
                        title: 'Success',
                        message: `Authorized successfully!`,
                        info: ''
                      };
                      this.swalService.success(swalData).then(function (result) {
                      
                    });
                },
                (error) => {
                    console.log("twitter error", error);

                }
            );
        }
        this.items = [{Name: "Authorized", ID: false},
        {Name: "Deauthorized", ID: true}];
        this.currentUser = this.userData.first_name + " " + this.userData.last_name;
        this.getDataByUserId();
    }
    getDataByUserId() {
        this.smbauthService.getDataByUserId(this.userData.id).subscribe(
            (result) => {

                if( result.length ==0 ){
                    this.data = {
                        'fb_status' : 0,
                        'li_status' : 0,
                        'tw_status' : 0
                    };
                    return this.data;
                }else{
                    return this.data = result[0];
                }
            },
            (error) => {
                console.log("getDataByUserIdERROR", error);

            }
        );
    }
    deAuthorized(data: string) {
        if (data == 'fb') {
            this.status = {user_id: this.userData.id, fb_status: '0'};
        } else if (data == 'li') {
            this.status = {user_id: this.userData.id, li_status: '0'};
        } else {
            this.status = {user_id: this.userData.id, tw_status: '0'};
        }

        this.smbauthService.deAthorized(this.status).subscribe(
            (result) => {
                this.getDataByUserId();
                var swalData = {
                    title: 'Success',
                    message: `Deauthorized successfully!`,
                    info: ''
                  };
                  this.swalService.success(swalData).then(function (result) {
                  
                });
            },
            (error) => {
                console.log("deAuthorizedERROR", error);
                var swalData = {
                    title: 'Error',
                    message: `Deauthorization unsuccessfull!`,
                    info: ''
                  };
                  this.swalService.error(swalData);
            }
        );
    }
    ngAfterViewInit() {

    }

    updateNewHireType($event) {

    }

    redirectTo(path) {

    }

}
