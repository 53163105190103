import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormGroup} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {startOfDay, isSameDay, isSameMonth} from 'date-fns';
import {CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay} from 'angular-calendar';

import {CalendarService} from 'app/services/calendar/calendar.service';
import {CalendarEventModel} from './event.model';
import {CalendarEventFormDialogComponent} from './event-form/event-form.component';
import {CommonService} from 'app/services/common/common.service';
import {LoginService} from 'app/services/login.service';
import {RolesService} from '../../../../services/roles/role.service';
import {SwalService} from '../../../../services/swal.service';
import {UsersService} from '../../../../services/users/users.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-my-calendar',
    templateUrl: './my-calendar.component.html',
    styleUrls: ['./my-calendar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MyCalendarComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();
    actions: CalendarEventAction[];
    activeDayIsOpen: boolean;
    dialogRef: any;
    events: CalendarEvent[];
    refresh: Subject<any> = new Subject();
    selectedDay: any;
    view: string;
    viewDate: Date;
    eventTypes = [];
    currentUser: any;
    excludeDays: number[] = [0, 6];
    allUsers: any = [];
    allRoles: any = [];
    selectBoxDataSource = ['Item 1', 'Item 2', 'Item 3'];
    filters = {
        userId: null,
        roleId: null,
        isAdmin: false,
        isRM: false
    };
    companies: any = [];
    userPermissions: any[] = [];
    masterData: any = {};

    constructor(
        private _matDialog: MatDialog,
        private _calendarService: CalendarService,
        private commonService: CommonService,
        private loginService: LoginService,
        private roleService: RolesService,
        private usersService: UsersService,
        private swalService: SwalService
    ) {
        // Set the defaults
        this.view = 'week';
        this.viewDate = new Date();
        this.activeDayIsOpen = true;
        this.selectedDay = {date: startOfDay(new Date())};
        // this.getEventTypes();
        //  this.getAllUsers();
        // this.getAllRoles();
        this.getCompanies();
        // this.getUserPermission();
        this.loginService.isLoggedInObj.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
            if (value) {
                this.currentUser = JSON.parse(localStorage.getItem('userData'));
                const rolesAr = this.currentUser.roles ? this.currentUser.roles.split(',').map(item => Number(item)) : [];
                if (rolesAr.indexOf(1) > -1 || rolesAr.indexOf(2) > -1 || rolesAr.indexOf(7) > -1) {
                    this.currentUser.isAdmin = true;
                    this.getAllUsers();
                    this.getAllRoles();
                }
                if (rolesAr.indexOf(4) > -1) {
                    this.currentUser.isRM = true;
                    this.getAllUsers();
                }
            }
        });
        this.actions = [
            {
                label: '<i class="material-icons s-16">edit</i>',
                onClick: ({event}: {event: CalendarEvent}): void => {
                    this.editEvent('edit', event);
                }
            },
            {
                label: '<i class="material-icons s-16">delete</i>',
                onClick: ({event}: {event: CalendarEvent}): void => {
                    this.deleteEvent(event);
                }
            }
        ];

        /**
         * Get events from service/server
         */

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._calendarService.onEventsUpdated
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(events => {
            this.setEvents();
            this.refresh.next();
        });
        this.usersService.userPermissionsRetrieved$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((permissions: any) => {
                permissions.map(item => {
                    this.userPermissions.push(item.permission);
                });
                this.setEvents();
            });
        this.commonService.masterData
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
                this.masterData = data;
                this.eventTypes = data.eventTypes;
            });
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set events
     */
    setEvents(): void {

        this.events = this._calendarService.events.map(item => {
            let actions = this.actions;

            if(item.user_id !== this.currentUser.id && this.userPermissions.indexOf('touchpoint-administration') < 0 ) {
                 actions = [
                    {
                        label: '<i class="material-icons s-24">info</i>',
                        onClick: ({event}: {event: CalendarEvent}): void => {
                            this.editEvent('info', event);
                        }
                    }
                ];
            }
            const event = {
                start: item.start,
                end: item.end,
                title: item.title,
                color: {
                    primary: item.color,
                    secondary: item.color
                },
                // actions?: CalendarEventAction[];
                allDay: item.is_all_day,
                draggable: false,
                meta: {
                    eventType: item.event_type,
                    eventID: item.event_id,
                    location: item.location,
                    company: item.company_id,
                    contact: item.contact_id,
                    comments: item.comments,
                    mealCost: item.meal_cost,
                    user: item.user_id,
                    miles: item.miles
                },
                actions: actions
            };
            item = event;
            return new CalendarEventModel(item);
        });
        // console.log(this.events);
    }

    /**
     * Before View Renderer
     *
     * @param {any} header
     * @param {any} body
     */
    beforeMonthViewRender({header, body}): void {
        /**
         * Get the selected day
         */
        const _selectedDay = body.find((_day) => {
            return _day.date.getTime() === this.selectedDay.date.getTime();
        });

        if (_selectedDay) {
            /**
             * Set selected day style
             * @type {string}
             */
            _selectedDay.cssClass = 'cal-selected';
        }

    }

    /**
     * Day clicked
     *
     * @param {MonthViewDay} day
     */
    dayClicked(day: CalendarMonthViewDay): void {
        const date: Date = day.date;
        const events: CalendarEvent[] = day.events;

        if (isSameMonth(date, this.viewDate)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
                this.viewDate = date;
            }
        }
        this.selectedDay = day;
        this.refresh.next();
        if (events.length <= 0) {
            this.addEvent();
        }

    }

    /**
     * Event times changed
     * Event dropped or resized
     *
     * @param {CalendarEvent} event
     * @param {Date} newStart
     * @param {Date} newEnd
     */
    eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
        event.start = newStart;
        event.end = newEnd;
        console.warn('Dropped or resized', event);
        const eventTypeName = this.getNameFromJsonArray(event.meta.eventType, this.eventTypes);
        const startDate = new Date(event.start);
        const endDate = new Date(event.end);

        if (event.allDay) {
            startDate.setHours(9);
            endDate.setHours(18);
        }
        const postData = {
            event_id: event.meta.eventID,
            user: this.currentUser,
            user_id: this.currentUser.id,
            company_id: event.meta.company ? Number(event.meta.company) : null,
            contact_id: event.meta.contact ? Number(event.meta.contact) : null,
            event_type: event.meta.eventType ? event.meta.eventType : null,
            //   event_type_name: event.meta.name,
            title: `${this.currentUser.first_name} ${this.currentUser.last_name}: ${eventTypeName}`,
            is_all_day: !!event.allDay,
            start: event.allDay ? startDate : new Date(event.start),
            end: event.allDay ? endDate : new Date(event.end),
            comments: event.meta.comments,
            // is_approved: newEvent.eventType,
            // approved_by: newEvent.eventType,
            color: '#e7ab4c',
            meal_cost: event.meta.mealCost,
            miles: event.meta.miles,
            location: event.meta.location
        };
        this._calendarService.updateEvents(postData).then(result => {
            this.setEvents();
            this.refresh.next(true);
        });

        this.refresh.next(true);
    }

    /**
     * Delete Event
     *
     * @param event
     */
    deleteEvent(event): void {
        // this.confirmDialogRef = this._matDialog.open(PulseConfirmDialogComponent, {
        //     disableClose: false
        // });

        const swalData = {
            title: 'Delete Event',
            message: `Are you sure you want to delete event : ${event.title}?`,
            info: 'Once it is deleted then it cannot be reverted!'
        };
        //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
        this.swalService.delete(swalData).then((res) => {
            if (res.value) {

                this._calendarService.deleteEvent(event.meta.eventID).then(result => {

                    if (result['code'] === 200) {
                        this.filterEvents();
                        const swalData1 = {
                            title: 'Event Deleted',
                            message: `Event is successfully deleted!`,
                            info: 'Now it can not be reverted!'
                        };
                        this.swalService.success(swalData1);
                    }
                });
            }

        });

    }

    /**
     * Edit Event
     *
     * @param {string} action
     * @param {CalendarEvent} event
     */
    editEvent(action: string, event: CalendarEvent): void {
        if (this.currentUser.id !== event.meta.user) {
          //  return;
        }

        this.dialogRef = this._matDialog.open(CalendarEventFormDialogComponent, {
            panelClass: 'event-form-dialog',
            data: {
                event: event,
                action: action,
                eventTypes: this.eventTypes,
                users: this.allUsers,
                userPermissions: this.userPermissions,
                companies: this.companies
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                //  const actionType: string = response[0];
                const formData: FormGroup = response[0];

                // this.events[eventIndex] = Object.assign(this.events[eventIndex], formData.getRawValue());
                const newEvent = formData.getRawValue();
                console.log(newEvent);
                const eventTypeName = this.getNameFromJsonArray(newEvent.eventType, this.eventTypes);
                let companyName = null;
                let contactName = null;
                if (newEvent.company) {
                    companyName = this.getNameFromJsonArray(newEvent.company, this.companies);
                }
                if (newEvent.contact) {
                    contactName = this.getNameFromJsonArray(newEvent.contact, response[1]);
                }

                const startDate = new Date(newEvent.start);
                const endDate = new Date(newEvent.end);

                if (newEvent.allDay) {
                    startDate.setHours(9);
                    endDate.setHours(18);
                }
                let selectedUser = this.currentUser;

                if(this.userPermissions.indexOf('touchpoint-administration') > -1) {
                    selectedUser = this.allUsers.find(user => user.id == newEvent.selectedUser);
                }


                const postData = {
                    event_id: event.meta.eventID,
                    user: selectedUser,
                    user_id: selectedUser.id,
                    company_id: newEvent.company ? Number(newEvent.company) : null,
                    contact_id: newEvent.contact,
                    company_name: companyName,
                    contact_name: contactName,
                    event_type: newEvent.eventType,
                    event_type_name: eventTypeName,
                    title: `${selectedUser.first_name} ${selectedUser.last_name}: ${eventTypeName}`,
                    is_all_day: !!newEvent.allDay,
                    start: newEvent.allDay ? startDate : new Date(newEvent.start),
                    end: newEvent.allDay ? endDate : new Date(newEvent.end),
                    comments: newEvent.comments,
                    // is_approved: newEvent.eventType,
                    // approved_by: newEvent.eventType,
                    color: '#e7ab4c',
                    meal_cost: newEvent.mealCost,
                    miles: newEvent.miles,
                    location: newEvent.location
                };
                this._calendarService.updateEvents(postData).then(result => {
                    this.setEvents();
                    this.refresh.next(true);
                });


            });
    }

    /**
     * Add Event
     */
    addEvent(): void {

        this.dialogRef = this._matDialog.open(CalendarEventFormDialogComponent, {
            panelClass: 'event-form-dialog',
            data: {
                action: 'new',
                date: this.selectedDay.date,
                eventTypes: this.eventTypes,
                users: this.allUsers,
                userPermissions: this.userPermissions,
                companies: this.companies
            }
        });
        this.dialogRef.afterClosed()
            .subscribe((response: any) => {
                if (!response) {
                    return;
                }
                console.log(response);

                const newEvent = response[0].getRawValue();

                const eventTypeName = this.getNameFromJsonArray(newEvent.eventType, this.eventTypes);
                let companyName = null;
                let contactName = null;
                if (newEvent.company) {
                    companyName = this.getNameFromJsonArray(newEvent.company, this.companies);
                }
                if (newEvent.contact) {
                    contactName = this.getNameFromJsonArray(newEvent.contact, response[1]);
                }

                const startDate = new Date(newEvent.start);
                const endDate = new Date(newEvent.end);

                if (newEvent.allDay) {
                    startDate.setHours(9);
                    endDate.setHours(18);
                }

                let selectedUser = this.currentUser;

                if(this.userPermissions.indexOf('touchpoint-administration') > -1) {
                    selectedUser = this.allUsers.find(user => user.id == newEvent.selectedUser);
                }
                const postData = {
                    user: selectedUser,
                    user_id: selectedUser.id,
                    company_id: newEvent.company ? Number(newEvent.company) : null,
                    contact_id: newEvent.contact,
                    company_name: companyName,
                    contact_name: contactName,
                    event_type: newEvent.eventType,
                    event_type_name: eventTypeName,
                    title: `${selectedUser.first_name} ${selectedUser.last_name}: ${eventTypeName}`,
                    is_all_day: !!newEvent.allDay,
                    start: newEvent.allDay ? startDate : new Date(newEvent.start),
                    end: newEvent.allDay ? endDate : new Date(newEvent.end),
                    comments: newEvent.comments,
                    // is_approved: newEvent.eventType,
                    // approved_by: newEvent.eventType,
                    color: '#e7ab4c',
                    meal_cost: newEvent.mealCost,
                    miles: newEvent.miles,
                    location: newEvent.location,
                    status: 'pending'
                };

                console.log(postData);

                this._calendarService.createEvent(postData).then(result => {
                    this.setEvents();
                    this.refresh.next(true);
                });
            });
    }

    getEventTypes() {
        this.commonService.getMasterData('eventTypes').subscribe(response => {
            this.eventTypes = [];
            const result = response['data'];
            for (let i = 0; i < result.length; i++) {
                this.eventTypes.push({
                    id: Number(result[i].value),
                    name: result[i].master_lookup_name
                });
            }
        });
    }

    getAllUsers() {
        this.commonService.getAllUsersByRole().subscribe(response => {
            this.allUsers = [];
            const result = response['data'];
            this.allUsers.push({
                id: 'all',
                name: 'All' // `${this.currentUser.first_name} ${this.currentUser.last_name}`
            });
            this.allUsers.push({
                id: this.currentUser.id,
                name: 'Me', // `${this.currentUser.first_name} ${this.currentUser.last_name}`
                first_name: this.currentUser.first_name,
                last_name: this.currentUser.last_name,
            });
            for (let i = 0; i < result.length; i++) {
                if (this.currentUser.id === Number(result[i].user_id)) {
                    continue;
                }
                this.allUsers.push({
                    id: Number(result[i].user_id),
                    name: `${result[i].first_name} ${result[i].last_name}`,
                    first_name: result[i].first_name,
                    last_name: result[i].last_name
                });
            }
        });
    }

    getAllRoles() {
        this.roleService.getRoles().subscribe(response => {
            this.allRoles = [];
            const result = response['data'];
            this.allRoles.push({
                id: 'all',
                name: 'All'
            });
            for (let i = 0; i < result.length; i++) {
                this.allRoles.push({
                    id: Number(result[i].role_id),
                    name: result[i].name
                });
            }
        });
    }

    onUserChanged(event: any) {
        this.filters.userId = event.value != 'all' ? event.value : null;
        this.filterEvents();
    }

    onRoleChanged(event: any) {
        this.filters.roleId = event.value != 'all' ? event.value : null;
        if(event.value != 'all') {
            this.getUsersFromSelectedRole(this.filters.roleId);
        } else {
            this.filters.userId = null
            this.getAllUsers()
        }
        this.filterEvents();
    }

    getUsersFromSelectedRole(roleId) {
        this.usersService.getUsersByRole(roleId).subscribe((response: any) => {
            if (response['code'] == 200) {
                const result = response['data']['result'];

                this.allUsers = [];
                this.allUsers.push({
                    id: 'all',
                    name: 'All'
                });
                result.map(user => {
                    this.allUsers.push({
                        id: user.user_id,
                        name: `${user.first_name} ${user.last_name}`
                    });
                });
                this.filters.userId = 'all';

            }
        });
    }
    filterEvents() {
        this.filters.isAdmin = this.currentUser.isAdmin;
        this.filters.isRM = this.currentUser.isRM;
        this._calendarService.getEvents(this.filters).then(resp => {
            console.log(resp);
        }).catch(err => {
            console.log(err);
        });
    }

    getCompanies() {
        let result: any;
        this._calendarService.getAllCompanies().subscribe(response => {
            result = response;
            this.companies = [];
            for (let i = 0; i < result.length; i++) {
                this.companies.push({
                    id: Number(response[i].id),
                    name: response[i].name,
                    contacts: response[i].contacts
                });
            }
        });
    }

    /**
     *
     * @param id
     * @param jsonAr
     * @returns {any}
     */
    getNameFromJsonArray(id, jsonAr) {
        if (!jsonAr || jsonAr.length <= 0) {
            return false;
        }
        let name = '';
        for (const value of jsonAr) {
            if (Array.isArray(id) && id.indexOf(value.id) > -1) {
                name += `${value.name},`;
            } else if (Number(value.id) === Number(id)) {
                name = value.name;
                break;
            }
        }

        if (!name) {
            for (const value of jsonAr) {
                if (Array.isArray(id) && id.indexOf(value.value) > -1) {
                    name += `${value.master_lookup_name},`;
                } else if (Number(value.value) === Number(id)) {
                    name = value.master_lookup_name;
                    break;
                }
            }
        }

        if (!name) {
            for (const value of jsonAr) {
                if (Array.isArray(id) && id.indexOf(value.id) > -1) {
                    name += `${value.first_name} ${value.last_name},`;
                } else if (String(value.id) === String(id)) {
                    name = `${value.first_name} ${value.last_name}`;
                    break;
                }
            }
        }
        return name.replace(/(^,)|(,$)/g, '');
    }
}
