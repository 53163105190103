<div class="page-layout simple fullwidth" pulsePerfectScrollbar>
  <div class="container mt-12">
    <div class="content mat-white-bg mat-elevation-z4 mb-20" fxLayout="column">
      <div class="content-wrapper">
        <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto" fxLayoutAlign="space-between center">
          <div class="pulse-main-heading">
            Timesheet Auto-Retrieve Schedules
            <span class="badge ng-star-inserted">{{ schedules.length }}</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
            <dx-button icon="add" class="pulse-btn has-icon" (onClick)="addModeSchedulePopupShow();"></dx-button>
          </div>
        </div>
        <div class="container">
          <dx-data-grid
            keyExpr="id"
            [dataSource]="schedules"
            [hoverStateEnabled]="true"
            [showRowLines]="true"
            [showColumnLines]="false"
            [showBorders]="true"
            [columnAutoWidth]="true"
            [selectedRowKeys]="[]"
            (onSelectionChanged)="editModeSchedulePopupShow($event)"
          >
            <dxo-selection mode="single"></dxo-selection>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[10, 30, 50, 100]"
              [showInfo]="true">
            </dxo-pager>

            <dxi-column width="35%" dataField="clients"></dxi-column>
            <dxi-column width="15%" dataField="recurrence"></dxi-column>
            <dxi-column width="10%"
              caption="Time"
              dataField="executionTime"
              [customizeText]="getTime"
            ></dxi-column>
            <dxi-column width="15%" dataField="statusTrigger"></dxi-column>
            <dxi-column width="10%" dataField="sendEmail" caption="eMail">
            </dxi-column>
            <dxi-column width="10%" dataField="sendSMS" caption="SMS">
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>

  <!-- Schedule PopUp -->
  <dx-popup
    [title]="schedulePopupMode === 'add' ? 'New Schedule' : 'Edit Schedule'"
    width="65%" minWidth="400" maxWidth="650px"
    maxHeight="350px"
    [showTitle]="true"
    [showCloseButton]="true"
    [closeOnOutsideClick]="true"
    [(visible)]="schedulePopupVisible"
    (onHidden)="clearSelection()"
  >
    <form (submit)="onSchedulePopupFormSubmit($event)" validationGroup="scheduleFormVlidationGroup">
      <div fxLayout="column" fxLayoutAlign="space-around" fxFlexFill>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
          <div fxFlex fxLayout="column" fxLayoutGap="10px">
            <div class="header">Schedule</div>
            <div>
              <dx-tag-box
                placeholder="Clients"
                [multiline]="false"
                [searchEnabled]="true"
                [showSelectionControls]="true"
                [maxDisplayedTags]="2"
                [showMultiTagOnly]="false"
                [items]="clientList"
                [(value)]="selectedClients"
              >
                
                  <dxi-validation-rule type="required" message="required"
                  ></dxi-validation-rule>
                
              </dx-tag-box>
            </div>
            <div>
              <dx-tag-box
                placeholder="Recurrence"
                [multiline]="false"
                [searchEnabled]="true"
                [showSelectionControls]="true"
                [maxDisplayedTags]="2"
                [showMultiTagOnly]="false"
                [items]="DAYS"
                [(value)]="selectedRecurrence"
              >
                  <dxi-validation-rule type="required" message="required"
                  ></dxi-validation-rule>
              </dx-tag-box>
            </div>
            <div>
              <dx-date-box
                class="width100"
                type="time"
                placeholder="Time"
                [useMaskBehavior]="true"
                [(value)]="executionTime"
              >
                  <dxi-validation-rule type="required" message="required"
                  ></dxi-validation-rule>
              </dx-date-box>
            </div>
          </div>
          <div fxFlex fxLayout="column" fxLayoutGap="10px">
            <div class="header">Trigger / Action</div>
            <div>
              <dx-tag-box class="searchBox"
                placeholder="Timesheet Status Trigger"
                [showSelectionControls]="true"
                [maxDisplayedTags]="2"
                [showMultiTagOnly]="false"
                [items]="timesheetStatusList"
                [(value)]="selectedStatus"
              ></dx-tag-box>
            </div>
            <div fxFlex>
              <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start">
                <div>
                  <dx-check-box
                    class="pt-8"
                    text="Send eMail"
                    [(value)]="sendEmail"
                    (onValueChanged)="showEmailTemplatePopup($event)"
                  ></dx-check-box>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutGap="5px"
                  class="edit-template-link"
                  *ngIf="sendEmail && emailSubject && emailBody"
                  (click)="showEmailTemplatePopup(true)"
                >
                  <div>Template</div>
                  <i class="material-icons">edit</i>
                </div>
              </div>
            </div>
            <div fxFlex>
              <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start">
                <div>
                  <dx-check-box
                    class="pt-8"
                    text="Send SMS"
                    [(value)]="sendSMS"
                    (onValueChanged)="showSMSTemplatePopup($event)"
                  ></dx-check-box>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutGap="5px"
                  class="edit-template-link"
                  *ngIf="sendSMS && smsBody !== ''"
                  (click)="showSMSTemplatePopup(true)"
                >
                  <div>Template</div>
                  <i class="material-icons">edit</i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
          <div *ngIf="schedulePopupMode === 'add'; then addMode else editMode"></div>
          <ng-template #addMode>
            <dx-button
              icon="add"
              text="ADD SCHEDULE"
              validationGroup="scheduleFormValidationGroup"
              [useSubmitBehavior]="true"
              (onClick)="schedulePopupFormAction='addSchedule'"
            ></dx-button>
          </ng-template>
          <ng-template #editMode>
            <div>
              <dx-button
                icon="check"
                text="UPDATE"
                validationGroup="scheduleFormValidationGroup"
                [useSubmitBehavior]="true"
                (onClick)="schedulePopupFormAction='updateSchedule'"
              ></dx-button>
            </div>
            <div>
              <dx-button
                icon="trash"
                text="DELETE"
                validationGroup="scheduleFormValidationGroup"
                [useSubmitBehavior]="true"
                (onClick)="schedulePopupFormAction='deleteSchedule'"
              ></dx-button>
            </div>
          </ng-template>
        </div>
      </div>
    </form>
  </dx-popup>

  <!-- SMS Template PopUp -->
  <dx-popup
    title="SMS Template"
    [width]="500"
    [height]="300"
    [showTitle]="true"
    [showCloseButton]="false"
    [(visible)]="smsTemplatePopupVisible"
  >
    <div fxLayout="column" fxLayoutGap="10px" fxFlexFill>
      <div>Message ({{ _smsBody.length + '/' + SMS_MAXLENGTH }})</div>
      <dx-text-area
        valueChangeEvent="keyup"
        [height]="150"
        [maxLength]="SMS_MAXLENGTH"
        [(value)]="_smsBody"
      ></dx-text-area>
      <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
        <dx-button
          icon="check"
          text="DONE"
          [disabled]="_smsBody === ''"
          (onClick)="saveSMSTemplate()"
        ></dx-button>
        <dx-button icon="close" text="CANCEL" (onClick)="restoreSMSTemplate()">
        </dx-button>
      </div>
    </div>
  </dx-popup>

  <!-- EMail Template PopUp -->
  <dx-popup
    title="eMail Template"
    [width]="800"
    [height]="500"
    [showTitle]="true"
    [showCloseButton]="false"
    [(visible)]="emailTemplatePopupVisible"
  >
    <div fxLayout="column" fxLayoutGap="10px" fxFlexFill>
      <div fxFlex="8">
        <div fxLayout="row" fxLayoutGap="10px">
          <div fxLayout="row" class="v-flex-center">Subject</div>
          <div fxFlex>
            <dx-text-box valueChangeEvent="keyup" [(value)]="_emailSubject">
            </dx-text-box>
          </div>
        </div>
      </div>
      <div fxFlex="84">
        <div fxLayout="column" fxLayoutGap="5px" fxFlexFill>
          <div>Message</div>
          <dx-html-editor valueChangeEvent="keyup" [(value)]="_emailBody">
            <dxo-toolbar>
              <dxi-item formatName="undo"></dxi-item>
              <dxi-item formatName="redo"></dxi-item>
              <dxi-item formatName="size"
                [formatValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"
              ></dxi-item>
              <dxi-item formatName="header"
                [formatValues]="[false, 1, 2, 3, 4, 5]"
              ></dxi-item>
              <dxi-item formatName="bold"></dxi-item>
              <dxi-item formatName="italic"></dxi-item>
              <dxi-item formatName="strike"></dxi-item>
              <dxi-item formatName="underline"></dxi-item>
              <dxi-item formatName="color"></dxi-item>
              <dxi-item formatName="background"></dxi-item>
              <dxi-item formatName="alignLeft"></dxi-item>
              <dxi-item formatName="alignCenter"></dxi-item>
              <dxi-item formatName="alignRight"></dxi-item>
              <dxi-item formatName="alignJustify"></dxi-item>
              <dxi-item formatName="orderedList"></dxi-item>
              <dxi-item formatName="bulletList"></dxi-item>
              <dxi-item formatName="link"></dxi-item>
              <dxi-item formatName="codeBlock"></dxi-item>
              <dxi-item formatName="blockquote"></dxi-item>
            </dxo-toolbar>
          </dx-html-editor>
        </div>
      </div>
      <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
        <dx-button
          icon="check"
          text="DONE"
          [disabled]="_emailSubject === '' || _emailBody === ''"
          (onClick)="saveEmailTemplate()"
        ></dx-button>
        <dx-button
          icon="close"
          text="CANCEL"
          (onClick)="restoreEmailTemplate()"
        ></dx-button>
      </div>
    </div>
  </dx-popup>
</div>
