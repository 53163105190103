<div
    class="page-layout simple fullwidth"
    pulsePerfectScrollbar
>
    <!-- container -->
    <div class="container mt-12">
        <div
            class="content mat-white-bg mat-elevation-z4 mb-24"
            fxLayout="column"
        >
            <div class="content-wrapper">

                <div class="p-20 pt-15 pb-15 grid-wrapper">
                    <div class="outer-wrapper">
                        <div class="mailsearch_wrapper">
                            <app-search (errorEvent)="errorEventHandler($event)"></app-search>
                            <!-- <app-control-navigation></app-control-navigation> -->
                            <main [ngClass]="toggleFlag.set ? 'mailsearch collaspe' : 'mailsearch' ">
                                <aside
                                    *ngIf="!emailLoading"
                                    class="mailsearch__sidebar"
                                >
                                    <app-side-panel (errorEvent)="errorEventHandler($event)"></app-side-panel>
                                </aside>
                                <as-split direction="horizontal">
                                    <as-split-area size="50">
                                        <section class="mail-list">
                                            <app-content-listing (errorEvent)="errorEventHandler($event)"></app-content-listing>
                                        </section>
                                    </as-split-area>
                                    <as-split-area size="50">
                                        <section class="mail-preview">
                                            <app-content-preview></app-content-preview>
                                        </section>
                                    </as-split-area>
                                </as-split>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- container -->
</div>
