import {AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {SignaturePad} from 'angular2-signaturepad/signature-pad';
import {FormGroup} from '@angular/forms';
import {ConsultantService} from '../../services/consultant/consultant.service';
import {ActivatedRoute} from '@angular/router';
import {siteConfig} from '../../siteSettings';
import * as moment from 'moment';
import {SwalService} from '../../services/swal.service';

@Component({
    selector: 'app-equipment-release',
    templateUrl: './equipment-release.component.html',
    styleUrls: ['./equipment-release.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EquipmentReleaseComponent implements OnInit, AfterViewInit {

    @ViewChild(SignaturePad) public signaturePad: SignaturePad;
    public form: FormGroup;
    currDate = moment().local().format('MM/DD/YYYY HH:mm:ss');
    signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor\
        canvasWidth: 500,
        canvasHeight: 300
    };
    public _signature: any = null;
    consultantName = '';
    localLogsDate = '';
    consultantID = 0;
    equipmentList = [];

    isCaptchaResponse = false;
    captchaResponse: any = '';
    captcha = siteConfig.captchaSiteKey;
    formSigned = false;
    eqIdList = [];

    public resolved(captchaResponse: string) {
        this.isCaptchaResponse = true;
        this.captchaResponse = captchaResponse;
        console.log(`Resolved captcha with response: ${captchaResponse}`);
    }

    constructor(private route: ActivatedRoute,
                private swalService: SwalService,
                public consultantService: ConsultantService) {
        this.route.params.subscribe(res => this.consultantID = res.consultantId || this.consultantID);
        this.getEquipmentData();
    }

    ngOnInit() {
    }

    public ngAfterViewInit() {
        this.setOptions();
        this.signaturePad.set('minWidth', 5);
        this.signaturePad.clear();
    }

    submitEquipmentSign(e) {
        e.preventDefault();
        if (this.isCaptchaResponse) {
            const postData = {
                consultantID: Number(this.consultantID),
                idList: this.eqIdList,
                signature: this.signaturePad.toDataURL()
            };
            this.consultantService.saveEquipmentSignature(postData).subscribe(result => {
                if (result['data'].changedRows) {
                    const swalData = {
                        title: 'Success',
                        message: 'Signature Saved',
                        info: ''
                    };
                    this.swalService.success(swalData);
                    this.formSigned = true;
                }
            }, error => {
                this.isCaptchaResponse = false;
                this.captchaResponse = '';
                const swalData = {
                    title: 'Error',
                    message: error.error && error.error.message || 'Something went wrong.',
                    info: 'Please try again.'
                };

                this.swalService.error(swalData);
            });
        } else {
            const swalData = {
                title: 'Pulse',
                message: 'Please Validate Captcha',
                info: ''
            };
            this.swalService.warn(swalData);
            return false;
        }
    }

    getEquipmentData() {
        this.consultantService.getConsultantEmailedEquipments(this.consultantID).subscribe(data => {
            const resp = data;
            if (resp['code'] === 200) {
                this.equipmentList = resp['data'];
                if (resp['data'].length) {
                    this.consultantName = resp['data'][0]['consultant_name'];
                    resp['data'].map((item) => {
                        this.eqIdList.push(item.eq_id);
                    });
                } else {
                    this.formSigned = true;
                }
            }
        });
    }

    /*===== SIGNATURE PLUGIN FUNCTION ====*/
    public setOptions() {
        this.signaturePad.set('dotSize', 1);
        this.signaturePad.set('penColor', 'rgb(0,0,0)');
    }

    get signature(): any {
        return this._signature;
    }

    set signature(value: any) {
        this._signature = value;
    }

    public writeValue(value: any): void {
        if (!value) {
            return;
        }
        this._signature = value;
        this.signaturePad.fromDataURL(this.signature);
    }

    public drawComplete(): void {
        this.signature = this.signaturePad.toDataURL('assets/image/jpeg', 0.5);
    }

    /*======================================== END SignaturePad ========================================*/
}
