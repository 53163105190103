<div class="page-layout simple fullwidth" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">

        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto">
                    <div class="pulse-main-heading" fxFlex="50">
                        Permission list for {{currentRole}}
                    </div>
                </div>

                <div class="p-30 pt-15 pb-15 grid-wrapper">
                    <div id="accordion">
                        <dx-accordion
                            #accordion
                            [dataSource]="accordions"
                            [collapsible]="true"
                            [multiple]="false"
                            [animationDuration]="300"
                            [deferRendering]="false"
                            [selectedItems]="[accordions[0]]">
                            <div *dxTemplate="let accordion of 'title'">
                                <span>Menu permission</span>
                            </div>
                            <div *dxTemplate="let accordion of 'item'" >
                                <div class="" fxLayout="column">
                                    <div fxLayout="row" >
                                        <div fxFlex="50">
                                            <h2>Top Left Menu</h2>
                                            <dx-tree-view #permissionTree
                                                          dataStructure="plain"
                                                          parentIdExpr="parentID"
                                                          keyExpr="permission_id"
                                                          displayExpr="title"
                                                          [items]="permissions['LEFT']"
                                                          selectedExpr="permission_flag"
                                                          [selectNodesRecursive]="true"
                                                          [rootValue]="1"
                                                          [noDataText]="'Loading...'"
                                                          showCheckBoxesMode="normal"
                                                          (onItemSelectionChanged)="selectionChanged($event)"
                                            >
                                            </dx-tree-view>
                                        </div>
                                        <div fxFlex="50">
                                            <h2>Top Right Menu</h2>

                                            <dx-tree-view #permissionTree
                                                          dataStructure="plain"
                                                          parentIdExpr="parentID"
                                                          keyExpr="permission_id"
                                                          displayExpr="title"
                                                          [items]="permissions['RIGHT']"
                                                          selectedExpr="permission_flag"
                                                          [selectNodesRecursive]="true"
                                                          [rootValue]="1"
                                                          [noDataText]="'Loading...'"
                                                          showCheckBoxesMode="normal"
                                                          (onItemSelectionChanged)="selectionChanged($event)"
                                            >
                                            </dx-tree-view>
                                        </div>
                                    </div>
                                    <div fxFlex="100" class="mt-20 " fxLayout="row" fxLayoutAlign="space-between center">
                                        <dx-button text="Cancel"
                                                   class="pulse-btn pulse-btn-normal"
                                                   (onClick)="onCancel($event)"
                                        >
                                        </dx-button>
                                        <dx-button text="Save Permissions"
                                                   class="pulse-btn pulse-btn-primary"
                                                   (onClick)="saveRolePermissions($event)"
                                        >
                                        </dx-button>
                                    </div>
                                </div>
                            </div>
                        </dx-accordion>
                        <dx-accordion
                            #accordion
                            [dataSource]="pageAccordions"
                            [collapsible]="true"
                            [multiple]="false"
                            [animationDuration]="300"
                            [deferRendering]="false"
                            [selectedItems]="[pageAccordions[0]]">
                            <div *dxTemplate="let accordion of 'title'">
                                <span>Page Level permission</span>
                            </div>
                            <div *dxTemplate="let accordion of 'item'" >
                                <div class="" fxLayout="column">
                                    <div fxLayout="row" >
                                        <div fxFlex="50">
                                            <dx-tree-view #pagePermissionTree
                                                          dataStructure="plain"
                                                          parentIdExpr="parentID"
                                                          keyExpr="permission_id"
                                                          displayExpr="title"
                                                          [items]="pagePermission"
                                                          selectedExpr="permission_flag"
                                                          [selectNodesRecursive]="true"
                                                          [rootValue]="1"
                                                          [noDataText]="'Loading...'"
                                                          showCheckBoxesMode="normal"
                                                          (onItemSelectionChanged)="selectionChanged($event)"
                                            >
                                            </dx-tree-view>
                                        </div>
                                     </div>
                                    <div fxFlex="100" class="mt-20 " fxLayout="row" fxLayoutAlign="space-between center">
                                         <dx-button text="Cancel"
                                                   class="pulse-btn pulse-btn-normal"
                                                   (onClick)="onCancel($event)"
                                        >
                                        </dx-button>
                                        <dx-button text="Save Permissions"
                                                   class="pulse-btn pulse-btn-primary"
                                                   (onClick)="savePageLevelPermissions($event)"
                                        >
                                        </dx-button>
                                    </div>
                                </div>
                            </div>
                        </dx-accordion>
                    </div>

                </div>

            </div>
        </div>

    </div>
    <!-- container -->

</div>
