import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoleAddComponent} from './role-add/role-add.component';
import {RoleListComponent} from './role-list/role-list.component';
import {RouterModule} from '@angular/router';
import {AuthGuardService as AuthGuard} from 'app/services/auth/auth-guard.service';
import {RolePermissionsComponent} from "./role-permissions/role-permissions.component";
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PulseSharedModule} from '@pulse/shared.module'
import {
    DxDataGridModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxFormModule,
    DxPopupModule,
    DxButtonModule,
    DxTemplateModule,
    DxMenuModule,
    DxSwitchModule,
    DxCheckBoxModule,
    DxTreeViewModule, DxListModule,
    DxAccordionModule,
} from 'devextreme-angular';

const routes = [
    {
        path: 'role/add',
        component: RoleAddComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'role/edit/:id',
        component: RoleAddComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'role/list',
        component: RoleListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'role-permissions/:roleId',
        component: RolePermissionsComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [RoleAddComponent, RoleListComponent, RolePermissionsComponent],
    imports: [
        PulseSharedModule,
        DxDataGridModule,
        DxSelectBoxModule,
        DxTextAreaModule,
        DxFormModule,
        DxPopupModule,
        DxButtonModule,
        DxTemplateModule,
        DxMenuModule,
        DxSwitchModule,
        DxCheckBoxModule,
        DxTreeViewModule,
        DxListModule,
        DxAccordionModule,
        DxTemplateModule,
        BrowserModule,
        DxDataGridModule,
        ReactiveFormsModule,
        FormsModule,
        PulseSharedModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
    ]
})
export class RbacModule {
}
