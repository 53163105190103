import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {TranslateModule} from '@ngx-translate/core';

import {
    DxRadioGroupModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxFileUploaderModule,
    DxFormModule,
    DxMenuModule, DxPopupModule
} from 'devextreme-angular';
import {BrowserModule} from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {PulseSharedModule} from '@pulse/shared.module';
import {AuthGuardService as AuthGuard} from 'app/services/auth/auth-guard.service';

import {environment} from '../../../environment';
import {LinkedInSdkModule} from 'angular-linkedin-sdk';
import {SmbAuthComponent} from './smb-auth/smb-auth.component';

import {SmbLogComponent} from './smb-log/smb-log.component';

import {SmbShareComponent} from './smb-share/smb-share.component';

const routes = [
    {
        path: 'smbauth',
        component: SmbAuthComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'smbauth/:candid',
        component: SmbAuthComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'smb-logs',
        component: SmbLogComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'smb-logs/:candid',
        component: SmbLogComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'smbshare',
        component: SmbShareComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'smbshare/:candid',
        component: SmbShareComponent,
        canActivate: [AuthGuard]
        //    canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    declarations: [
        SmbAuthComponent, SmbShareComponent, SmbLogComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        BrowserModule,
        DxButtonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxFileUploaderModule,
        DxFormModule,
        DxMenuModule,
        DxPopupModule,
        MatTabsModule,
        MatFormFieldModule,
        MatCardModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatListModule,
        MatIconModule,
        MatDividerModule,
        MatTooltipModule,
        PulseSharedModule,
        DxRadioGroupModule,
        LinkedInSdkModule

    ],
    providers: [
        {
            provide: 'apiKey', useValue: environment.li_credential.client_id
        }
    ]
    //   ,

    // exports: [
    //     SmbAuthComponent.SmbLogComponent,SmbShareComponent
    // ]
})


export class SmbModule {
}
