import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {TranslateModule} from '@ngx-translate/core';

import {PulseSharedModule} from '@pulse/shared.module';

import {LinkedinSearchComponent} from './linkedin-search.component';
import {DxFormModule, DxButtonModule, DxDataGridModule} from 'devextreme-angular';

import {AuthGuardService as AuthGuard} from 'app/services/auth/auth-guard.service';

const routes = [
  {
    path: 'linkedin-search',
    component: LinkedinSearchComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    LinkedinSearchComponent
  ],
  imports: [
    RouterModule.forChild(routes),

    TranslateModule,

    PulseSharedModule,

    DxFormModule,
    DxButtonModule,
    DxDataGridModule
  ],
  exports: [
    LinkedinSearchComponent
  ]
})

export class LinkedinSearchModule {
}