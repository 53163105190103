import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

// import { fuseAnimations } from '@fuse/animations';
import {PulseMatSidenavHelperService} from '@pulse/directives/pulse-mat-sidenav/pulse-mat-sidenav.service';

import {ChatService} from 'app/services/chat/chat.service';
import {SwalService} from 'app/services/swal.service';
import {CommonService} from '../../../../../../../services/common/common.service';
import {DxFormComponent} from 'devextreme-angular';

@Component({
    selector: 'chat-chats-sidenav',
    templateUrl: './chats.component.html',
    styleUrls: ['./chats.component.scss'],
    encapsulation: ViewEncapsulation.None,
    // animations   : fuseAnimations
})
export class ChatChatsSidenavComponent implements OnInit, OnDestroy {
    @ViewChild('sendInviteDxForm') sendInviteDxForm: DxFormComponent;
    @ViewChild('quickSMSForm') quickSMSForm: DxFormComponent;
    chats: any[];
    chatSearch: any;
    contacts: any[];
    searchText: string = null;
    user: any;
    selectedChat: any;
    invitePopup: boolean = false;
    
    smsPopup: boolean = false;
    sendSMSFormData: any = {};
    uploadedCSV: any = [];
    templateList: any = [];

    loggedInUserDetails: any;
    budgetCount: number = 0;
    applyBudgetUserID: number = 0;
    defaultAvatar: string = '/assets/images/avatars/profile.jpg';
    socketID: number = 0;
    socketStatus: string = '';
    countryCodes: any = [];
    inviteData = {
        frmUserFirstName: '',
        frmUserLastName: '',
        frmContactPhone: '',
        frmContactEmail: '',
        frmCountryCode: '+1',
        frmJobDivaId: '',
    };
    popupTitle= 'Invite to the Candidate';
    isContactActive: number = 0;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     * @param _swalService
     * @param commonService
     * @param {PulseMatSidenavHelperService} _pulseMatSidenavHelperService
     * @param {ObservableMedia} _observableMedia
     */
    constructor(
        private _chatService: ChatService,
        private _swalService: SwalService,
        private commonService: CommonService,
        private _pulseMatSidenavHelperService: PulseMatSidenavHelperService,
        public _observableMedia: MediaObserver
    ) {
        this.loggedInUserDetails = JSON.parse(localStorage.getItem('userData'));
        this.onContactPhoneChanged = this.onContactPhoneChanged.bind(this);
        // this.contactPhoneExist = this.contactPhoneExist.bind(this);
        //initialize Socket Service
        this._chatService.applyUserStatusFromSocket().subscribe(data => {

            this.socketID = data.userID;
            this.socketStatus = data.userStatus;
        });

        this.onTemplateChanged = this.onTemplateChanged.bind(this);

        this._chatService.chageBadgeCountFromSocket().subscribe(data => {

            // console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
            // console.log(data);
            // console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
            // console.log(data);

            // debugger;

            if (data.message.candidate_id == data.message.created_by) {
                this.budgetCount = data.message.userUnReadCount;
            } else {
                this.budgetCount = data.message.candidateUnReadCount;
            }

            this.applyBudgetUserID = data.message.conversation_id[0];

            let userChatList = this.user.chatList;
            for (let i = 0; i < userChatList.length; i++) {

                if (this.applyBudgetUserID == userChatList[i].conversation_id) {
                    this.user.chatList[i].unread = this.budgetCount;
                }
            }
        });

        this.getCountryCodes();

        // Set the defaults
        this.chatSearch = {
            name: ''
        };
        this.searchText = '';

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.user = this._chatService.user;
        this.chats = this._chatService.chats;
        this.contacts = this._chatService.contacts;

        this.isContactActive = 0;

        this._chatService.onChatsUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(updatedChats => {
                this.chats = updatedChats;
            });

        this._chatService.onUserUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(updatedUser => {
                this.user = updatedUser;
            });

        this._chatService.getTemplateList().subscribe((result) => {

            let templateResp = result.data;
            templateResp.forEach(element => {

                this.templateList.push({
                    "value": element.template_content,
                    "text": element.template_subject
                });
            });
        })
    
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get chat
     *
     * @param contact
     */
    getChat(contact): void {

        // let contact = 0;
        // if (chat.contactId == undefined) {
        //     contact = chat.user_id;
        // } else {
        //     contact = chat.contactId;
        // }

        // chat.unread = 0;

        this.isContactActive = contact;
        this.budgetCount = 0;
        this.applyBudgetUserID = contact;

        this._chatService.getChat(contact);

        for (let i = 0; i < this.chats.length; i++) {
            if (contact != this.chats[i].id) {
                continue;
            }
            this.selectedChat = this.chats[i];
        }

        let userChatList = this.user.chatList;
        for (let i = 0; i < userChatList.length; i++) {

            if (contact == userChatList[i].contactId) {
                this.user.chatList[i].unread = 0;
            }
        }

        if (!this._observableMedia.isActive('gt-md')) {
            this._pulseMatSidenavHelperService.getSidenav('chat-left-sidenav').toggle();
        }
    }

    /**
     * Invite the user
     *
     */

    enableInvitePopup(visibleFlag): void {
        this.invitePopup = visibleFlag === 0;
    }

    sendInvite(e): void {

        let userMobile = this.inviteData.frmContactPhone;
        userMobile = userMobile.replace(/-/g, '');
        userMobile = userMobile.replace(/\(/g, '');
        userMobile = userMobile.replace(/\)/g, '');
        userMobile = userMobile.replace(/ /g, '');

        let userEmail = this.inviteData.frmContactEmail;
        if (!userEmail) {
            userEmail = userMobile.slice(-10) + '@yopmail.com';
        }

        const finalInviteData = {
            customer_id: 1,
            user_id: this.loggedInUserDetails.id,
            user_first_name: this.inviteData.frmUserFirstName,
            user_last_name: this.inviteData.frmUserLastName,
            user_email: userEmail.trim(),
            user_mobile: this.inviteData.frmContactPhone,
            user_country_code: this.inviteData.frmCountryCode,
            jobDivaId: this.inviteData.frmJobDivaId,
            inviteType: "invite",
            message: "Hi There, I am using Pulse2!"
        };

        this._chatService.sendInvite(finalInviteData)
        .subscribe(data => {

            if(data['message']=="401"){

                const swalData = {
                    title: 'Invite conformation!',
                    message: data['data'],
                    info: ""
                };
                this._swalService.error(swalData);

            }else{

                const swalData = {
                    title: 'Invite conformation!',
                    message: "Invitation sent successfully",
                    info: "User will be added shortly in your Chat list!"
                };
                this._swalService.success(swalData);

                data = data['data'];

                let chatDataNew = {
                    conversation_id: data['conversation_id'],
                    id: data['contactId'],
                    dialog: data['conversation']
                };

                let chatListItemNew = {
                    avatar: data['avatar'],
                    contactId: data['contactId'],
                    id: data['id'],
                    lastMessage: data['lastMessage'],
                    lastMessageTime: data['lastMessageTime'],
                    name: data['name'],
                    status: data['status'],
                    unread: 0,
                    messageType: 'sms'
                };

                let newUserDetails = data['newUserDetails'];

                this._chatService.createNewChatNew(chatDataNew, chatListItemNew, newUserDetails, 1);

                this.inviteData.frmUserFirstName = "";
                this.inviteData.frmUserLastName = "";
                this.inviteData.frmContactPhone = "";
            }

        });

        this.invitePopup = false;
    }

    enableSMSPopup(visibleFlag): void {
        this.smsPopup = visibleFlag === 0;
    }

    onTemplateChanged(e) {
        this.sendSMSFormData.frmSMSMessage = e.value;
    }

    sendQuickSMS(e): void{

        // Manupulating the Phone number
        let userMobile = this.sendSMSFormData.frmPhoneNumbers;
        userMobile = userMobile.replace(/-/g, '');
        userMobile = userMobile.replace(/\(/g, '');
        userMobile = userMobile.replace(/\)/g, '');
        userMobile = userMobile.replace(/ /g, '');
        let userEmail = userMobile.slice(-10) + '@noemail.com';
        let lastTelDigit = userMobile.slice(-10);
        let mobileNumberArray = userMobile.split(lastTelDigit);
        let countryCode = mobileNumberArray[0];

        // Manupulating the Message content
        let messageText = this.sendSMSFormData.frmSMSMessage;
        let userDetails = this.user;

        // Converting the message with template data
        messageText = messageText.replace(new RegExp('{{candidate_first_name}}', 'g'), lastTelDigit);
        messageText = messageText.replace(new RegExp('{{candidate_last_name}}', 'g'), "");
        messageText = messageText.replace(new RegExp('{{candidate_email}}', 'g'), userEmail);

        messageText = messageText.replace(new RegExp('{{recruiter_first_name}}', 'g'), userDetails.first_name);
        messageText = messageText.replace(new RegExp('{{recruiter_last_name}}', 'g'), userDetails.last_name);
        messageText = messageText.replace(new RegExp('{{recruiter_email}}', 'g'), userDetails.email);
        messageText = messageText.replace(new RegExp('{{recruiter_phone}}', 'g'), userDetails.mobile_no);

        const finalInviteData = {
            customer_id: 1,
            user_id: this.loggedInUserDetails.id,
            user_first_name: lastTelDigit,
            user_last_name: "",
            user_email: userEmail.trim(),
            user_mobile: lastTelDigit,
            country_code: countryCode,
            inviteType: "DirectSMS",
            message: messageText,
            jobDivaId: null,
            smsFormNumber: userDetails.sms_number_twillio
        };

        //console.log(finalInviteData);

        this._chatService.sendInvite(finalInviteData)
        .subscribe(data => {

            if(data['message']=="401"){

                const swalData = {
                    title: 'SMS conformation!',
                    message: data['data'],
                    info: ""
                };
                this._swalService.error(swalData);

            }else{

                const swalData = {
                    title: 'SMS conformation!',
                    message: "Message sent successfully",
                    info: "User will be added shortly in your Chat list!"
                };
                this._swalService.success(swalData);

                data = data['data'];

                let chatDataNew = {
                    conversation_id: data['conversation_id'],
                    id: data['contactId'],
                    dialog: data['conversation']
                };

                let chatListItemNew = {
                    avatar: data['avatar'],
                    contactId: data['contactId'],
                    id: data['id'],
                    lastMessage: data['lastMessage'],
                    lastMessageTime: data['lastMessageTime'],
                    name: data['name'],
                    status: data['status'],
                    unread: 0,
                    messageType: 'sms'
                };

                let newUserDetails = data['newUserDetails'];

                this._chatService.createNewChatNew(chatDataNew, chatListItemNew, newUserDetails, 1);
                this.quickSMSForm.instance.resetValues();
                this.smsPopup = false;
                // this.sendSMSFormData.frmPhoneNumbers = "";
                // this.sendSMSFormData.frmSMSMessage = "";
            }

        });
    }


    /**
     * Set user status
     *
     * @param status
     */
    setUserStatus(status): void {
        console.log('===================');
        this._chatService.setUserStatusNew(status);
    }

    /**
     * Change left sidenav view
     *
     * @param view
     */
    changeLeftSidenavView(view): void {
        this._chatService.onLeftSidenavViewChanged.next(view);
    }

    /**
     * Logout
     */
    logout(): void {
        console.log('logout triggered');
    }

    getCountryCodes() {
        this.commonService.getCountryCodes().then((response: any) => {
            // this.countryCodes = response;
            response.map(item => {
                this.countryCodes.push({
                    callCode: `+${item.callingCodes[0]}`,
                    name: item.name,
                    flag: item.flag,
                    alpha2Code: item.alpha2Code
                });
            });
            console.log(response);
        }, err => {
            console.log(err);
        });
    }

    onCountryCodeChanged(event) {
        this.inviteData.frmCountryCode = event.value;
    }

    onContactPhoneChanged(event) {
        console.log(event);
        if (!this.inviteData.frmContactPhone) {
            return;
        }
        const mobileNo = this.inviteData.frmContactPhone.replace(/[- )(]/g, '');
        if (mobileNo.length !== 10) {
            return;
        }

        this.commonService.searchCandidateByEmailOnJD(mobileNo).subscribe((response: any) => {
            console.log(response);
            if (Number(response.code) === 200 && response.data) {
                this.inviteData.frmContactEmail = response.data.Email;
                this.inviteData.frmUserFirstName = response.data.FirstName;
                this.inviteData.frmUserLastName = response.data.LastName;
                this.inviteData.frmJobDivaId = response.data.CandID;
                this.popupTitle += ` - ${this.inviteData.frmJobDivaId}`;
            }
        });

    }

    contactPhoneExist(params) {
        console.log(params);
        if (!params.value) {
            return;
        }

        this.commonService.searchCandidateByEmailOnJD(params.value).subscribe((response: any) => {
            console.log(response);
            if (response.code !== 200) {
                params.rule.isValid = false;
                params.rule.message = 'Phone number already exists';
            }
            params.validator.validate();
        });
        return false;
    }
}
