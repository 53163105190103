import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SwalService} from '../../../../services/swal.service';

import {SmbshareService} from '../../../../services/smbshare/smbshare.service';

export class frmShareDataType {
    frmFacebook_message: string;
    frmTwitter_message: string;
}

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-smb-share',
    templateUrl: './smb-share.component.html',
    styleUrls: ['./smb-share.component.scss'],
    providers: [SmbshareService]
})
export class SmbShareComponent implements OnInit {
    public frmShareData: frmShareDataType = new frmShareDataType;
    items: any = [];
    data: any = [];
    files: File | FileList;
    fileUpload: any = [];
    isFormSubmitted: boolean = false;
    selectAll: boolean = false;
    selectAllFB: boolean = false;
    selectAllTW: boolean = false;
    selectAllLI: boolean = false;
    selectTW: boolean = false;
    selectFB: boolean = false;
    selectLI: boolean = false;
    isDocFileSelected: boolean = false;
    formActionValue = '';
    frmUploadDocData: any = {};
    disabled: boolean = false;
    base64String: any;
    uplodedDocs: any = [];
    fileToUpload: File = null;
    userData: any = [];
    showFilterRow: boolean = true;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private swalService: SwalService,
                private smbshareService: SmbshareService
    ) {
        //super();
        // this.fuseTranslationLoader.loadTranslations(english, turkish);
        this.setDefaults();
    }

    // @ViewChild(SmbAuthFormComponent) subAuthForm: SmbAuthFormComponent;
    canDeactivate(): boolean {
        return true;
    }

    setDefaults() {
        this.frmUploadDocData = {
            frmUploadDocName: null,
            frmUploadDocDescription: null
        };
    }

    facebookPost(): void {
        var token = {user_id: this.userData.id};
        this.smbshareService.sharePost(token).subscribe(
            (result) => {
                console.log('facebookPostRESULT', result);

            },
            (error) => {
                console.log('facebookPostERROR', error);

            }
        );

    }

    formAction(event: string) {
        this.formActionValue = event;
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

    checkBoxChange(event, info, option) {
        if (typeof option == 'string') {
            if (option == 'all') {
                this.selectAll = this.selectAll ? false : true;
                this.data.forEach((data) => {
                    if (data.facebook) {
                        data.selectFB = this.selectAll;
                    }
                    if (data.linkedin) {
                        data.selectLI = this.selectAll;
                    }
                    if (data.twitter) {
                        data.selectTW = this.selectAll;
                    }
                });
            } else {
                if (option == 'allFacebook') {
                    this.selectAllFB = this.selectAllFB ? false : true;
                    this.data.forEach((data) => {
                        if (data.facebook) {
                            data.selectFB = this.selectAllFB;
                        }
                    });
                }
                if (option == 'allTwitter') {
                    this.selectAllTW = this.selectAllTW ? false : true;
                    this.data.forEach((data) => {
                        if (data.twitter) {
                            data.selectTW = this.selectAllTW;
                        }
                    });
                }
                if (option == 'allLinkedin') {
                    this.selectAllLI = this.selectAllLI ? false : true;
                    this.data.forEach((data) => {
                        if (data.linkedin) {
                            data.selectLI = this.selectAllLI;
                        }
                    });
                }
            }
        }
    }

    checkboxSingle(event, info, category) {
        if (category == 'fb') {
            info.data.selectFB = event.value;
        } else if (category == 'tw') {
            info.data.selectTW = event.value;
        } else if (category == 'li') {
            info.data.selectLI = event.value;
        }
    }

    onFormSubmit(event: any) {

        if (this.frmShareData.frmFacebook_message == '' && this.frmShareData.frmTwitter_message == '') {
            const swalData = {
                title: 'Enter the message',
                message: 'Oops! Seems like you didn\'t enter the message.',
                info: 'Please enter the message.'
            };
            this.swalService.error(swalData);
            this.isFormSubmitted = false;
            return;
        }
        let senders = {users: []};
        let fb = 0;
        let li = 0;
        let tw = 0;
        this.data.forEach((data) => {
            if (!data.selectFB && !data.selectLI && !data.selectTW) {
                return;
            }
            senders.users.push({
                userName: data.Name,
                userId: data.user_id,
                facebook: data.selectFB,
                twitter: data.selectTW,
                linkedin: data.selectLI
            });
            if (data.selectFB == true) {
                fb++;
            }
            if (data.selectTW == true) {
                li++;
            }
            if (data.selectLI == true) {
                tw++;
            }
        });
        if ((fb == 0 && li == 0 && tw == 0)) {
            const swalData = {
                title: 'Select the Senders',
                message: 'Oops! Seems like you didn\'t select the senders.',
                info: 'Please select the senders.'
            };
            this.swalService.error(swalData);
            this.isFormSubmitted = false;
            return;
        }
        console.log('senders', senders);
        let shareData = {
            'message': this.frmShareData.frmFacebook_message,
            'twitterMessage': this.frmShareData.frmTwitter_message,
            'currentUserId': this.userData.id,
        };
        shareData['senders'] = senders;
        const formData: FormData = new FormData();
        formData.append('shareData', JSON.stringify(shareData));
        if (this.fileToUpload != null) {
            formData.append('file', this.fileToUpload, this.fileToUpload.name);
        }
        this.smbshareService.sharePost(formData).subscribe(
            (result) => {
                //location.reload();
                var swalData = {
                    title: 'Success',
                    message: `Post Shared successfully!`,
                    info: ''
                };
                this.swalService.success(swalData).then(function (result) {
                    location.reload();
                });
            },
            (error) => {
                const swalData = {
                    title: 'Error',
                    message: 'Something went wrong',
                    info: 'Something went wrong'
                };
                this.swalService.error(swalData);
            }
        );
    }

    ngOnInit() {
        this.frmShareData.frmFacebook_message = '';
        this.frmShareData.frmTwitter_message = '';
        this.getAllUserToken();
        this.userData = JSON.parse(localStorage.getItem('userData'));
    }

    goToSmbLogs() {
        this.router.navigate(['smb-logs']);
    }

    getAllUserToken() {
        this.smbshareService.getAllUserToken().subscribe(
            (result) => {
                let finalData = [];
                result.forEach((e, index) => {
                    const temporaryObj = {
                        Name: e.name,
                        ID: index,
                        user_id: e.user_id,
                        facebook: e.fb_status,
                        selectFB: false,
                        linkedin: e.li_status,
                        selectLI: false,
                        twitter: e.tw_status,
                        selectTW: false,
                    };
                    finalData.push(temporaryObj);
                });
                this.data = finalData;
            },
            (error) => {
                console.log('getAllUserYTokenERROR', error);

            }
        );
    }

    reset() {
        this.frmShareData.frmFacebook_message = null;
        this.frmShareData.frmTwitter_message = null;
    }

    copyDown = () => {
        //console.log("click down");
        this.frmShareData.frmTwitter_message = this.frmShareData.frmFacebook_message;
    };

    copyUp = () => {
        //console.log("click up");
        this.frmShareData.frmFacebook_message = this.frmShareData.frmTwitter_message;
    };

}
