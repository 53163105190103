import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {LoginService} from 'app/services/login.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(public loginService: LoginService, public router: Router) {
    }

    canActivate(): boolean {
        if (!this.loginService.isAuthenticated()) {
            this.loginService.logout();
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }

}
