<div class="page-layout simple fullwidth pulse-report" pulsePerfectScrollbar>
  <!-- container -->
  <div class="container mt-12">
    <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
      <div class="content-wrapper">
        <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto">
          <div class="pulse-main-heading" fxFlex="50">
            5 Percentile
          </div>
        </div>
        <div class="grid-wrapper">
          <iframe allowFullScreen="true" frameborder="0"
                  height="600"
                  [src]="frameUrl" width="100%">
          </iframe>
        </div>
      </div>
    </div>
  </div>
  <!-- container -->
</div>
