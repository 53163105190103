import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, findIndex } from "rxjs/operators";
import { Router } from "@angular/router";
import { siteConfig } from "../../siteSettings";
import { USER_ENTITLEMENT } from "../../../app/main/content/email-search/Constants/constants.js";
@Injectable({
    providedIn: "root"
})
export class EmailListService {
    email: any = {
        ownerMail: JSON.parse(localStorage.getItem("userData")).email || "", // use login email
        guestMail: [], // mail list modified to filter type structure
        guestFilterList: [] // mail list for reference
    };

    constructor(private http: HttpClient, private router: Router) {}

    load() {
        return this.http
            .get(
                siteConfig.nodeAPIBaseUrl +
                    USER_ENTITLEMENT +
                    this.email.ownerMail
            )
            .pipe(
                map((response: any) => {
                    let data = (response.data && response.data.emails) || [];
                    if (
                        response.data &&
                        response.data.emails &&
                        response.data.emails.length != 0
                    ) {
                        let ownerMailFlag = response.data.emails.findIndex(
                            email => email == this.email.ownerMail
                        );
                        if (ownerMailFlag == -1) {
                            data.push(this.email.ownerMail);
                        }
                    }
                    return data;
                })
            );
    }
    getFilterMails() {
        return this.email.guestFilterList;
    }
    getMailsList() {
        return this.email.guestMail;
    }
    setMails(val: any) {
        this.email.guestMail = val;
        this.email.guestFilterList = val.map(mail => {
            return { mail, checked: false };
        });
        console.log("setMails", this.email);
    }
    getMailForFilter() {
        let mailForFilter = [];
        mailForFilter = this.email.guestFilterList.filter(
            value => value.checked == true
        );
        if (mailForFilter.length != 0) {
            return this.email.guestFilterList
                .filter(value => value.checked == true)
                .map(mail => mail.mail);
        } else {
            let mails = [];
            this.email.guestFilterList.map(mail => {
                mails.push(mail.mail);
            });
            return this.email.guestFilterList.map(mail => mail.mail);
        }
    }
    resetMailFilter() {
        this.email.guestFilterList.map(mail => (mail.checked = false));
    }
    logout() {
        this.email.guestMail = [];
        this.email.guestFilterList = [];
        localStorage.removeItem("userMail");
        this.router.navigate(["login"]);
        location.reload();
    }
    setAllMailStatus(flag) {
        this.email.guestFilterList.map(value => (value.checked = flag));
    }
}
