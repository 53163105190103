<div class="page-layout simple fullwidth" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading" >
                        Chat Report
                    </div>
                    <div >
                        <form (ngSubmit)="getSMSReport()" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" >
                            <dx-form #dxFilterForm [formData]="dateFilterData" [colCount]="2" validationGroup="filterGroup">
                                <dxi-item dataField="fromDate" editorType="dxDateBox"
                                [editorOptions]="{placeholder: 'MM-DD-YYYY', displayFormat: dxDateFormat}">
                                    <dxo-label text="From"></dxo-label>

                                </dxi-item>
                                <dxi-item dataField="toDate"  editorType="dxDateBox"
                                          [editorOptions]="{placeholder: 'MM-DD-YYYY', displayFormat: dxDateFormat,
                                                    min: dateFilterData.fromDate}">
                                    <dxo-label text="To"></dxo-label>

                                </dxi-item>
                            </dx-form>
                            <div fxLayout="row" fxLayoutGap="10px">

                                <dx-button class="pulse-btn pulse-btn-primary" validationGroup="filterGroup"
                                           [useSubmitBehavior]="true" icon="fa fa-search"></dx-button>
                                <button class="pulse-btn pulse-btn-normal px-4" (click)="resetFilterForm($event)">
                                    <i class="dx-icon dx-icon-refresh s-20"></i>
                                </button>
                            </div>
                        </form>

                    </div>
                    <div fxLayout="row" fxLayoutGap="10px" >
                        <dx-button icon="fa fa-filter" class="pulse-btn pulse-btn-primary has-icon"
                                aria-label="Show filter" (onClick)="showFilters =!showFilters">
                        </dx-button>

                        <dx-button icon="fa fa-file-excel" class="pulse-btn pulse-btn-primary has-icon"
                                   aria-label="Export to excel" (onClick)="exportToExcel($event)">
                        </dx-button>
                    </div>
                </div>
                <div class="p-30 pt-15 pb-15 content-area" fxLayout="column" fxLayoutAlign="center center">
                    <dx-data-grid #dxChatReportGrid [dataSource]="chatReportData" [showBorders]="true" keyExpr="userID">
                        <dxo-filter-row [visible]="showFilters"></dxo-filter-row>
                        <dxi-column dataField="userName" ></dxi-column>
                        <!--<dxi-column dataField="title" caption="Subject"></dxi-column>-->
                        <dxi-column dataField="SMSSent" caption="SMS Sent" ></dxi-column>
                        <dxi-column dataField="SMSReceived" caption="SMS Received"></dxi-column>
                        <dxi-column dataField="SMSDelivered" caption="SMS Delivered"></dxi-column>
                        <dxi-column dataField="SMSNotDelivered" caption="SMS Not Delivered" ></dxi-column>
                        <!--<dxi-column [width]="80" cellTemplate="cellTemplate" caption="Actions"></dxi-column>-->
                    </dx-data-grid>
                </div>
            </div>
        </div>
    </div>
</div>



