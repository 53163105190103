import {environment} from 'environments/environment';

let chatServer = 'https://acechat.aptask.com';
let baseApiHost = 'https://pulse-commissions-backend.estdev.cloud';
let pulse3APIHost ='';  // 'https://pulse-api-dev.apps.recruit.aptask.com';
let socketNetworkName ='https://pulse-commissions-backend.estdev.cloud/';  // 'https://pulse2.aptask.net/';
  
// let chatServer = 'https://acechat.aptask.com'; 
// let baseApiHost = 'http://localhost:7050';     
// let pulse3APIHost = 'https://pulse-api-dev.apps.recruit.aptask.com';  
// let socketNetworkName = 'https://pulse2.aptask.net/';  
    
// let chatServer = 'https://acechat.aptask.com';
// let baseApiHost = 'http://pulse-commissions.local:7050';
// let pulse3APIHost = 'https://pulse-api-dev.apps.recruit.aptask.com';
// let socketNetworkName = 'https://pulse2.aptask.net/';

// if (environment.name === 'prod') {
//     chatServer = 'https://acechat.aptask.com';
//     baseApiHost = 'https://pulse.aptask.com';
//     pulse3APIHost = 'https://api.prod.aptask.com';  
//     socketNetworkName = 'https://smssocket.aptask.com/';
// } else if (environment.name === 'dev') {
//     chatServer = 'https://acechat.aptask.com';
//     baseApiHost ='http://localhost:7050'; // 'https://pulse-commissions-backend.estdev.cloud';  // 'https://pulse2.aptask.net';
//     pulse3APIHost = 'https://pulse-api-dev.apps.recruit.aptask.com';
//     socketNetworkName ='https://pulse-commissions-backend.estdev.cloud';  // 'https://pulse2.aptask.net/"';
// }

export const siteConfig = {
    chatServer: chatServer,
    baseApiHost: `${baseApiHost}`,
    nodeAPIBaseUrl: `${baseApiHost}/api/2.0/`,
    pulse3APIUrl: `${pulse3APIHost}/api/v1/`,
    logo: '/assets/images/logo/ApTask-Logo.png',
    companyName: 'Aptask',
    companyDomain: 'aptask.com',
    companyWebsite: 'https://www.aptask.com',
    masterDataCategories: {
        workAuths: 1,
        jobStatus: 2,
        jobSubStatus: 3,
        sourceFrom: 4,
        currencyData: 5,
        rateTypes: 6,
        paymentTerms: 8,
        paymentModes: 9,
        timesheetTemplateOptions: 10,
        countries: 11,
        states: 12,
        userStatus: 19,
        division: 20,
        OTEligibleData: 22,
        companyCodes: 23,
        homeDepartments: 24,
        federalStatuses: 25,
        stateStatus: 26,
        accountTypes: 27,
        consultantMainStatus: 28,
        consultantSubStatus: 29,
        pnlClasses: 30,
        startReasons: 31,
        eeq: 32,
        locations: 33,
        eventTypes: 34,
        contactSources: 35,
        insurancePlan: 37,
        insurancePlanFor: 38,
        insurancePlanType: 39,
        equipment: 40,
        commissionRole: 41,
        vmsNames: 42,
        calculator: 43,
        locality: 44,
        ADPConfiguration: 45,
        customerType: 46,
        zealStateStatus: 47,
    },
    businessEntitiesTypes: {
        clients: 2,
        vendors: 3
    },
    socketNetworkName: socketNetworkName,
    defaultPayLoadForW2: 18,
    defaultPayLoadForFT: 0,
    defaultFranchiseW2Load: 20,
    defaultFranchiseC2CLoad: 10,
    webChatUrl: 'https://pulse.aptask.com/meetup/',
    captchaSiteKey: '6Le4a28UAAAAABlYy6xoxq6oCkorsXK1ibsO3qNm',
    pusherInstanceLocator: 'v1:us1:aa8fbf89-060b-4521-940d-c8214656d63a',
    meetupServer: 'jitsi.aptask.com',
    useCustomCallService: false,
    pupServer: 'https://monsterscrapper1.aptask.net/',
    leaderBoardUrl: 'http://vicidial.aptask.com/vicidial/leaderboardv2/'
};
