import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { siteConfig } from '../../siteSettings';

import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
const EventSource = NativeEventSource || EventSourcePolyfill;

// constants
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': `JWT ${localStorage.getItem('userToken')}`
  })
}

const baseURL = `${siteConfig.nodeAPIBaseUrl}jobportal`;
const ZIP_CODE_LIST = [
  '02108 - Boston, MA',
  '10001 - New York, NY',
  '19019 - Philly, PA',
  '20001 - Washington, DC',
  '21117 - Baltimore, MD',
  '23450 - Virginia Beach, VA',
  '27601 - Raleigh, NC',
  '28201 - Charlotte, NC',
  '29615 - Greenville, SC',
  '30301 - Atlanta , GA',
  '32201 - Jacksonville, FL',
  '33130 - Miami, FL',
  '37201 - Nashville, TN',
  '38101 - Memphis, TN',
  '40201 - Louisville-Jefferson County, KY',
  '43201 - Columbus, OH',
  '44113 - Cleveland, OH',
  '46201 - Indianapolis, IN',
  '48201 - Detroit, MI',
  '53202 - Milwaukee, WI',
  '55401 - Minneapolis, MN',
  '60606 - Chicago, IL',
  '64101 - Kansas City, MO',
  '67202 - Wichita, KS',
  '68101 - Omaha , NE',
  '70112 - New Orleans, LA',
  '73101 - Oklohoma, OK',
  '74103 - Tulsa, OK',
  '75201 - Dallas, TX',
  '76001 - Arlington, TX',
  '76101 - Fort Worth, TX',
  '77001 - Houston, TX',
  '78201 - San Antonio, TX',
  '78701 - Austin, TX',
  '79901 - El Paso, TX',
  '80201 - Denver, CO',
  '80901 - Colorado Springs, CO',
  '85001 - Phoenix, AZ',
  '85201 - Mesa, AZ',
  '85701 - Tucson, AZ',
  '87101 - Albuquerque, NM',
  '89101 - Las Vegas, NV',
  '90042 - Los Angeles, CA',
  '90801 - Long Beach, CA',
  '92093 - San Deigo, CA',
  '93701 - Fresno, CA',
  '94101 - San Francisco, CA',
  '94203 - Sacramento, CA',
  '94601 - Oakland, CA',
  '95101 - San Jose, CA',
  '97201 - Portland, OR',
  '98101 - Seattle, WA'
]

@Injectable()
export class JobPortalService {

  constructor(private _http: HttpClient) {}

  getZipCodeList() {
    return ZIP_CODE_LIST
  }

  // email-template routes
  getAllEmailTemplates() {
    let endpoint = `${baseURL}/email-template`;
    return this._http.get<any>(endpoint, httpOptions);
  }

  addEmailTemplate(templateName, from, subject, body, update) {
    let endpoint = `${baseURL}/email-template/add`;
    let _body = { templateName, from, subject, body, update };
    return this._http.post<any>(endpoint, _body, httpOptions);
  }

  deleteEmailTemplate (templateName) {
    let endpoint = `${baseURL}/email-template/${templateName}`;
    return this._http.delete<any>(endpoint, httpOptions);
  }

  // github-job routes
  getAllGithubJobs() {
    let endpoint = `${baseURL}/github/jobs`;
    return this._http.get<any>(endpoint, httpOptions);
  }

  sendEmailToGithubUsers(templateName, url) {
    let endpoint = `${baseURL}/github/new`;
    let body = { templateName, url };
    return this._http.post<any>(endpoint, body, httpOptions);
  }

  githubProgress(githubJobId) {
    let endpoint = `${baseURL}/github/${githubJobId}/progress`;
    return new EventSource(endpoint);
  }

  githubAbort(githubJobId) {
    let endpoint = `${baseURL}/github/${githubJobId}/abort`;
    return this._http.delete<any>(endpoint, httpOptions);
  }

  // scrape-job routes
  getAllJobPortalNames() {
    let endpoint = `${baseURL}/`;
    return this._http.get<any>(endpoint, httpOptions);
  }

  getAllScrapeJobs() {
    let endpoint = `${baseURL}/scrape/jobs`;
    return this._http.get<any>(endpoint, httpOptions);
  }

  scrape(scraperName, zipCodes, url) {
    let endpoint = `${baseURL}/scrape/new`;
    let body = { scraperName, zipCodes, url };
    return this._http.post<any>(endpoint, body, httpOptions);
  }

  scrapeProgress(scrapeJobId) {
    let endpoint = `${baseURL}/scrape/${scrapeJobId}/progress`;
    return new EventSource(endpoint);
  }

  scrapeAbort(scrapeJobId) {
    let endpoint = `${baseURL}/scrape/${scrapeJobId}/abort`;
    return this._http.delete<any>(endpoint, httpOptions);
  }

  getScrapeResults(scrapeJobId) {
    let endpoint = `${baseURL}/scrape/${scrapeJobId}/results`;
    return this._http.get<any>(endpoint, httpOptions);
  }
}
