<div class="page-layout simple fullwidth" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-20" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto" fxLayoutAlign="space-between center">
                    <div class="pulse-main-heading">
                        Worker Update
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                        <dx-tag-box  [width]="'450px'" placeholder="Select Clients" [multiline]="false"
                        [searchEnabled]="true" [showSelectionControls]="true" [maxDisplayedTags]="2" valueExpr="businessEntityId" displayExpr="businessName"
                        [showMultiTagOnly]="false" [items]="vmsClientList" [(value)]="selectedClients" (onValueChanged)="refreshGridForClient($event)">
                        </dx-tag-box>
                        <dx-button icon="check" class="pulse-btn has-icon" (onClick)="updateWorker();"></dx-button>
                        <dx-button icon="revert" class="pulse-btn has-icon" (onClick)="clearFilter();"></dx-button>
                    </div>
                </div>
                <div class="p-30 pt-15 pb-15 grid-wrapper">                    
                    <table #tab id="tab">
                        <tr>
                            <th>Client</th>
                            <th>Total Existing</th>
                            <th>Created</th>
                            <th>Updated</th>
                            <th>Error</th>
                            <th>progress</th>                         
                        </tr>
                        <tr *ngFor="let k of objectKeys(progressobj)">
                            <td>{{progressobj[k].businessName}}</td>
                            <td>{{progressobj[k].TotalExisting}}</td>
                            <td>{{progressobj[k].Created}}</td>
                            <td>{{progressobj[k].Updated}}</td>
                            <td>{{progressobj[k].Errored}}</td>
                            <td>
                                <div class="progress-wrapper">
                                    <round-progress [current]="progressobj[k].Progrss" [stroke]="4" [radius]="25" [max]="100">                            
                                    </round-progress>
                                    <div class="text">{{progressobj[k].Progrss | number : '1.1-1'}}</div>
                                </div>
                            </td>                                              
                        </tr>
                    </table>
                </div>

                <!-- Error popup -->
                <dx-popup title="Error" [width]="300"
                [height]="150" [(visible)]="isPopupVisible" style="width: 50% !important;height: 150px;">
                    <div *dxTemplate="let data of 'content'">
                        <div fxLayout="column" style="    padding-bottom: 15px;text-align: center;">
                            <span>Unable to complete login</span>
                        </div>
                        <div fxLayout="column">
                            <dx-button text="Close" class="dateButton" (onClick)="buttonClicked()"></dx-button>
                        </div>
                    </div>
                </dx-popup>
            </div>
        </div>
    </div>
</div>