import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TeamListComponent} from './team-list/team-list.component';
import {RouterModule, Routes} from '@angular/router';
import {PulseSharedModule} from '../../../../@pulse/shared.module';
import {
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule, DxFileUploaderModule,
    DxFormModule,
    DxPopupModule, DxRadioGroupModule, DxScrollViewModule, DxTextAreaModule,
    DxSelectBoxModule,
    DxTextBoxModule, DxMenuModule
} from 'devextreme-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import {ConsultantService} from '../../../services/consultant/consultant.service';
import {MatIconModule} from '@angular/material/icon';
import {AuthGuardService as AuthGuard} from '../../../services/auth/auth-guard.service';

const routes: Routes = [
    {
        path: 'team',
        component: TeamListComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [TeamListComponent],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        PulseSharedModule,
        DxFormModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxButtonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxPopupModule,
        DxScrollViewModule,
        DxRadioGroupModule,
        MatTooltipModule,
        DxFileUploaderModule,
        DxTextAreaModule,
        MatIconModule,
        DxMenuModule
    ],
    providers: [
        ConsultantService
    ]
})
export class TeamModule {
}
