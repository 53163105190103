<div class="page-layout blank" pulsePerfectScrollbar fxLayout="column">
    <div class="eeo-toolbar">
        <img src="assets/images/ASP-Logo.svg" alt="ApTask - Pulse">
    </div>
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-24 p-30 pt-0" fxLayout="column">
            <div class="content-wrapper" *ngIf="!isFormSubmitted && checkEEORequest">
                <h1 class="text-center text-bold">Employee Questionnaire for Self-Identification of Race/Ethnicity</h1>
                <p>
                    This employer is subject to certain nondiscrimination and affirmative action recordkeeping and reporting requirements which
                    require the employer to invite employees to voluntarily self-identify their race/ethnicity. Submission
                    of this information is voluntary and refusal to provide it will not subject you to any adverse treatment.
                    The information obtained will be kept confidential and may only be used in accordance with the provisions
                    of applicable federal laws, executive orders, and regulations, including those which require the information
                    to be summarized and reported to the Federal Government for civil rights enforcement purposes.
                </p>
                <p>
                    If you choose not to self-identify your race/ethnicity at this time, the federal government requires this employer to determine
                    this information by visual survey and/or other available information.
                </p>
                <p>
                    For civil rights monitoring and enforcement purposes only, all race/ethnicity information will be collected and reported
                    in the seven categories identified below. The definitions for each category have been established by
                    the federal government. If you choose to voluntarily self-identify, you may mark only one of the boxes
                    presented below.

                </p>
            </div>
            <div class="content-wrapper" *ngIf="!isFormSubmitted && checkEEORequest">
                <h1 class="text-bold mb-0">Invitation to self-identify</h1>
                <p>Please answer the following question</p>
                <div class="question-wrapper">
                    <h3 class="text-bold question">
                        What is your race/ethnicity? Please mark the one box that describes the race/ethnicity category with which you primarily
                        identify.
                    </h3>
                    <form (submit)="formSubmit($event)" [formGroup]="form">
                        <div class="form-container mb-20">
                            <dx-form [formData]="frmEeoData" validationGroup="customerData" [showValidationSummary]="true">
                                <dxi-item dataField="frmAnswer" editorType="dxRadioGroup" [editorOptions]="{ dataSource: radioOptions, valueExpr: 'value', itemTemplate: 'listItem'}">
                                    <dxo-label [visible]="false">Answers</dxo-label>
                                    <dxi-validation-rule type="required" message="Please select at least one option"></dxi-validation-rule>
                                </dxi-item>
                                <div *dxTemplate="let itemData of 'listItem'">
                                    <strong>{{itemData.description}}</strong><span *ngIf="itemData.description">:</span> {{itemData.master_lookup_name}}
                                </div>
                            </dx-form>

                        </div>

                        <p class="text-bold">
                            <strong>Anti-Discrimination Notice:</strong>
                            <br/> It is an unlawful employment practice for an employer to fail or refuse to hire or discharge
                            any individual, or otherwise to discriminate against any individual with respect to that individual’s
                            terms and conditions of employment, because of such individual’s race, color, religion, sex,
                            or national origin.
                        </p>
                        <div>
                            <strong>Name: {{consultantName}}</strong><br>
                            <strong>Date : {{localLogsDate}}</strong>

                        </div>
                        <div><br><br></div>
                        <p class="text-bold">Please Sign Below Box</p>
                        <div class="sig-container mb-12" fxLayout="row" fxLayoutAlign="start start">
                                <signature-pad class="mat-elevation-z2 mr-12" [options]="signaturePadOptions" (onBeginEvent)="drawBegin()" (onEndEvent)="drawComplete()"></signature-pad>
                                <button type="button" class="pulse-btn pulse-btn-primary" (click)="signaturePad.clear()" >Clear Signature</button>
                        </div>
                        <div>
                            <re-captcha (resolved)="resolved($event)" siteKey={{captcha}}></re-captcha>
                        </div>
                        <div class="text-center" fxLayout="row" fxLayoutAlign="start start">
                            <dx-button text="Save" type="success" validationGroup="customerData" [useSubmitBehavior]="true" class="pulse-btn pulse-btn-primary">
                            </dx-button>
                        </div>
                    </form>

                </div>
            </div>

            <div class="content-wrapper" *ngIf="isFormSubmitted">
                <div class="message-box success">
                    Answer is successfully saved.
                </div>
            </div>
            <div class="content-wrapper" *ngIf="!checkEEORequest">
                <div class="message-box success">
                    Your request is already submitted!
                </div>
            </div>
        </div>

    </div>
</div>
