import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Router} from '@angular/router';

import {LoginService} from '../../services/login.service';
import {siteConfig} from '../../siteSettings';

@Component({
    selector: 'pulse-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss']
   // animations: fuseAnimations
})
export class HomePageComponent implements OnInit {
    loginForm: FormGroup;
    loginFormErrors: any;
    loginIsValid = false;
    response: any;
    siteConfig: any = siteConfig;
    constructor(private router: Router,
                // private fuseConfig: FuseConfigService,
                private formBuilder: FormBuilder,
                private loginService: LoginService
    ) {
        // this.fuseConfig.setConfig({
        //     layout: {
        //         navigation: 'none',
        //         toolbar   : 'above',
        //         footer    : 'above',
        //         mode      : 'fullwidth'
        //     }
        // });
    }

    ngOnInit() {

    }


}
