import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { ListServiceService } from "../../../../../services/emailSearch/list-service.service";
import { EmailListService } from "../../../../../services/emailSearch/emailList.service";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
    selector: "app-content-listing",
    templateUrl: "./content-listing.component.html",
    styleUrls: ["./content-listing.component.scss"]
})
export class ContentListingComponent implements OnInit {
    @Output() public errorEvent = new EventEmitter();
    constructor(
        private listService: ListServiceService,
        private EmailListService: EmailListService,
        private notification: NzNotificationService
    ) {}
    listOfData: any = [];
    showRowSelection: boolean = true;
    searchTextGlobal: string;
    dateRange = [];
    subscribe: any;
    facetSubscribe: any;
    selectedPreview: any;
    emailTableHeaders: any;
    indexType: any;
    ngOnInit() {
        this.selectedPreview = this.listService.getSelectedForPreview();
        this.emailTableHeaders = this.listService.getTableHeaders();
        this.indexType = this.listService.getIndexType();
        // console.log(
        //     "date_range",
        //     this.emailTableHeaders
        // );
        this.listOfData = this.listService.getMailBoxList();
        this.searchTextGlobal = this.listService.getMailBoxList();
    }
    currentPageDataChange(page: number) {
        // console.log(page);
        this.listService.fetchList(page).subscribe(response => {
            this.listService.setMailBoxList(response);
        });
    }
    onRowClick(data: any) {
        this.listService.setSelectedForPreview(data);
    }

    onColumnSearch(field) {
        if (this.subscribe) {
            this.subscribe.unsubscribe();
        }
        this.subscribe = this.listService.fetchList(false).subscribe(
            response => {
                this.listService.setMailBoxList(response);
            },
            error => {
                this.errorEvent.emit("Oops! , Something went wrong");
            }
        );
        if (this.facetSubscribe) {
            this.facetSubscribe.unsubscribe();
        }
        this.facetSubscribe = this.listService.fetchFacetList().subscribe(
            response => {
                this.listService.setFacetList(response);
            },
            error => {
                this.errorEvent.emit("Oops! , Something went wrong");
            }
        );
    }
    onChange(e) {
        // console.log("TCL: ContentListingComponent -> onOk -> e", e);
    }
}
