import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { tap, catchError } from 'rxjs/operators';

import { siteConfig } from '../../siteSettings';

@Injectable({
    providedIn: 'root'
})
export class PiplService {



    constructor(private http: HttpClient) {
    }

    searchContact(postData) {
        const url = `${siteConfig.nodeAPIBaseUrl}pipl/searchContact`;
        const data = {
            'company': postData.company,
            'first_name': postData.first_name,
            'middle_name': postData.middle_name,
            'last_name': postData.last_name,
            'email': postData.email,
            'source_type': postData.source_type,
            'plan': postData.plan.toLowerCase(),
            'linkedin_url': postData.linkedin_url,
            'location': postData.location,
            'is_premium': postData.is_premium
        };
        return this.http.post(url, data);

    }

    addToJobdiva(person) {
        return this.http.post(`${siteConfig.nodeAPIBaseUrl}jobdiva/add-person-to-jobdiva`, person);
    }
}
