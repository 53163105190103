import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import {TranslateModule} from '@ngx-translate/core';

import {PulseNavigationComponent} from './navigation.component';
import {PulseNavHorizontalItemComponent} from './horizontal/nav-item/nav-horizontal-item.component';
import {PulseNavHorizontalCollapseComponent} from './horizontal/nav-collapse/nav-horizontal-collapse.component';
import {PulseSharedModule} from "../../shared.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatRippleModule,

        TranslateModule.forChild(),
        PulseSharedModule
    ],
    exports: [
        PulseNavigationComponent
    ],
    declarations: [
        PulseNavigationComponent,
        PulseNavHorizontalItemComponent,
        PulseNavHorizontalCollapseComponent
    ]
})
export class PulseNavigationModule {
}
