import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from '../../../services/auth/auth-guard.service';

const routes: any = [
    {
        path: 'qcomm/plan',
        loadChildren: () => import('./plan/plan.module').then(m => m.PlanModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'qcomm/comm-report',
        loadChildren: () => import('./report/comm-report/comm-report.module').then(m => m.CommReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'qcomm/user-detailed-report',
        loadChildren: () => import('./report/user-detailed-report/user-detailed-report.module').then(m => m.UserDetailedReportModule),
        canActivate: [AuthGuard]
    }
]

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        CommonModule
    ]
})
export class QcommModule { }
