import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { siteConfig } from '../../siteSettings';

import * as FileSaver from 'file-saver';
import {map} from 'rxjs/operators';

//import * as EventSource from 'eventsource';

// const httpOptions = {
//   headers: new HttpHeaders({
//       'Content-Type': 'application/json',
//       'Authorization': `JWT ${localStorage.getItem('userToken')}`
//   })
// }

// const headersOptions =
//      new HttpHeaders({
//         'Content-Type': 'application/json',
//         'Authorization': `JWT ${localStorage.getItem('userToken')}`
//     })

@Injectable()
export class VmsServiceService {

  //baseApiHost = 'https://pulse2.aptask.net/api/2.0/';

  constructor(private _http: HttpClient) { }

  // Getting clients of VMS
  getVMSClients(){
    let url = `${siteConfig.nodeAPIBaseUrl}vms/clients`;
    let req = this._http.get<any>(url);
    return req;
  }

  // Update Worker
  updateVmsWorker(workerName,body: any){
    let url = `${siteConfig.nodeAPIBaseUrl}vms/${workerName}/workers`;
    let req = this._http.put(url, body);
    return req;
  }

  // Get Progressive data for workers update
	getProgressData(){
		let url = `${siteConfig.nodeAPIBaseUrl}vms/workers/update/progress`;
		let source = new EventSource(url);
		return source;
  }

   // Search Worker
   searchVmsWorker(workerName){
    let url = `${siteConfig.nodeAPIBaseUrl}vms/${workerName}/workers`;
    let req = this._http.get<any>(url);
    return req;
  }

   // VMS Time sheet status
   getAllTimesheetStatuses () {
    let url = `${siteConfig.nodeAPIBaseUrl}vms/timesheets/status`;
    let req = this._http.get<any>(url);
    return req;
  }

    // VMS No - TImesheet Search
    getAllWorkersWithNoTimesheetsInPeriod (clientName, startDate, endDate){
      let url = `${siteConfig.nodeAPIBaseUrl}vms/${clientName}/workers/no-timesheets/?startDate=${startDate}&endDate=${endDate}`;
      let req = this._http.get<any>(url);
      return req;
    }

    // VMS Timesheet Search
    getAllTimesheetsInPeriod (clientName, startDate, endDate, statusFilter, workerNameFilter){
      let url = `${siteConfig.nodeAPIBaseUrl}vms/${clientName}`+
            `/timesheets?startDate=${startDate}&endDate=${endDate}&statusFilter=${statusFilter}`+
            `&workerNameFilter=${workerNameFilter}`;

      let req = this._http.get<any>(url);
      return req;
    }

    // VMS Timesheet of Worker
    getTimesheetsForWoker (clientName, timesheetsId) {
      let url = `${siteConfig.nodeAPIBaseUrl}vms/${clientName}/timesheets/${timesheetsId}`;
      let req = this._http.get<any>(url);
      return req;
    }

    // VMS Timesheet PDF Viewer
    getTimesheetPDFView (clientName, timesheetID) {
      let url = `${siteConfig.nodeAPIBaseUrl}vms/${clientName}/timesheets/${timesheetID}/pdf-stream`;
      let req = this._http.get(url, { responseType: 'blob' as 'json' });
      return req;
    }

    // VMS Timesheet PDF Download
    getTimesheetPDFURL (clientName, timesheetID) {
      let url = `${siteConfig.nodeAPIBaseUrl}vms/${clientName}/timesheets/${timesheetID}/pdf`;
      let req = this._http.get(url, {responseType: 'blob'}).pipe(map((file: Blob) => {
          FileSaver.saveAs(file, timesheetID);
      }));
      return req;
    }

     // update timesheet
    updateVMSTimeshee(workerName,body: any, fromDate, toDate){
      let url = `${siteConfig.nodeAPIBaseUrl}vms/${workerName}/timesheets?startDate=${fromDate}&endDate=${toDate}&skipPDF=false`;
      let req = this._http.put(url, body);
      return req;
    }

    // Get Progressive data for workers update
    getTimeSheetProgressData(){
      let url = `${siteConfig.nodeAPIBaseUrl}vms/timesheets/update/progress`;
      let source = new EventSource(url);
      return source;
    }

      // Get all Timesheet Schedules
    getAllTimesheetSchedules() {
      let url = `${siteConfig.nodeAPIBaseUrl}vms/timesheets/schedules`;
      let req = this._http.get<any>(url);
      return req;
    }

    // Add/Update new Timesheet Schedule
    addTimesheetSchedule(schedule, update) {
      let url = `${siteConfig.nodeAPIBaseUrl}vms/timesheets/schedules`;
      let body = {
        params: {
          schedule: schedule,
          update: update || false
        }
      };
      let req = this._http.post<any>(url, body);
      return req;
    }

    // Delete Timesheet Schedule
    deleteTimesheetSchedule(timesheetScheduleID) {
      let url = `${siteConfig.nodeAPIBaseUrl}vms/timesheets/schedules/${timesheetScheduleID}`;
      let req = this._http.delete<any>(url);
      return req;
    }

}
