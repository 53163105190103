import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { ListServiceService } from "../../../../../services/emailSearch/list-service.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
@Component({
    selector: "app-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements OnInit {
    @Output() public errorEvent = new EventEmitter();
    searchTerm: any;
    subscribe: any;
    facetSubscribe: any;
    selectedForPreview: any;
    mailto: string;
    toggleFlag: any;
    constructor(
        private listService: ListServiceService,
        private notification: NzNotificationService
    ) {}

    ngOnInit() {
        this.searchTerm = this.listService.getMailBoxList();
        this.selectedForPreview = this.listService.getSelectedForPreview();
        this.mailto = `mailto:${
            this.selectedForPreview.from_emailAddress_address
        }`;
        this.toggleFlag = this.listService.getInitialToggleFlag();
    }
    searchTextChange(event) {
        // this.searcTerm = event.target.value;
        if (this.subscribe) {
            this.subscribe.unsubscribe();
        }
        this.subscribe = this.listService.fetchList(false).subscribe(
            response => {
                this.listService.setMailBoxList(response);
            },
            error => {
                this.errorEvent.emit("Oops! , Something went wrong");
            }
        );
        if (this.facetSubscribe) {
            this.facetSubscribe.unsubscribe();
        }
        this.facetSubscribe = this.listService.fetchFacetList().subscribe(
            response => {
                this.listService.setFacetList(response);
            },
            error => {
                this.errorEvent.emit("Oops! , Something went wrong");
            }
        );
    }
    toggleCollapse() {
        this.listService.setInitialToggleFlag();
        // console.log("flagset", this.listService.getInitialToggleFlag());
    }
    resetFilters() {
        this.listService.clearFilters();
        this.listService.fetchList(false).subscribe(
            response => {
                this.listService.setMailBoxList(response);
            },
            error => {
                this.errorEvent.emit("Oops! , Something went wrong");
            }
        );
        this.facetSubscribe = this.listService.fetchFacetList().subscribe(
            response => {
                this.listService.setFacetList(response);
            },
            error => {
                this.errorEvent.emit("Oops! , Something went wrong");
            }
        );
    }
}
