import {Component, HostBinding, Input} from '@angular/core';
import {LoginService} from 'app/services/login.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';

@Component({
    selector: 'pulse-nav-horizontal-item',
    templateUrl: './nav-horizontal-item.component.html',
    styleUrls: ['./nav-horizontal-item.component.scss']
})
export class PulseNavHorizontalItemComponent {
    @HostBinding('class') classes = 'nav-item';
    @Input() item: any;
    itemVisible: boolean = true;

    constructor(
        private loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.loginService.isLoggedInObj.subscribe(value => {
            if (value) {
                this.item.visible = true;
                const roles = localStorage.getItem('roleId');
                const userRoleAr = roles ? roles.split(',') : [];
                if (this.item && this.item.visibleFor && this.item.visibleFor.length > 0) {
                    this.item.visible = userRoleAr.filter(x => this.item.visibleFor.includes(x)).length > 0;
                }
            }
        });
    }
}
