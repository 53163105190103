import { EmailListService } from '../../../services/emailSearch/emailList.service';
import { Component, OnInit, AfterViewInit} from '@angular/core';
import { ListServiceService } from '../../../services/emailSearch/list-service.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
    selector: 'app-email-search',
    templateUrl: './email-search.component.html',
    styleUrls: [
        './email-search.component.scss'
    ]
})
export class EmailSearchComponent implements OnInit, AfterViewInit {
    edgeValue: any;
    constructor(
        private emailListService: EmailListService,
        private listService: ListServiceService,
        private notification: NzNotificationService
    ) {}
    public emailLoading = true;
    toggleFlag: any;
    ngOnInit() {
        this.emailListService.load().subscribe(emails => {
            this.emailListService.setMails(emails);
            this.listService.fetchList(false).subscribe(
                response => {
                    this.listService.setMailBoxList(response);
                    this.emailLoading = false;
                },
                err => {
                    this.notification.blank('Oops!', 'Something went wrong');
                }
            );
            this.listService.fetchFacetList().subscribe(
                response => {
                    this.listService.setFacetList(response);
                    this.emailLoading = false;
                },
                err => {
                    this.notification.blank('Oops!', 'Something went wrong');
                }
            );
        });
        this.toggleFlag = this.listService.getInitialToggleFlag();
    }
    ngAfterViewInit() {}
    errorEventHandler(msg) {
        this.notification.blank('Oops!!!', 'Something went wrong');
    }
}
