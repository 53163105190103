import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

import {siteConfig} from '../../siteSettings';

export class EmailTemplateList {
    constructor(public template_id: number,
                public template_type: string,
                public template_status: string,
                public template_name: string,
                public to: string,
                public cc: string,
                public subject: string,
                public content: string,
                public comment: string,
                public is_active: number,
                public last_edited_on: string,
                public created_by: string,
                public users: any,
                public attachment: string) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class EmailTemplateService {

    constructor(private _http: HttpClient) {
    }

    getAllEmailTemplates(templateType = 'EMAIL'): Observable<EmailTemplateList[]> {
        // let url = siteConfig.nodeAPIBaseUrl + 'email_templates';
        const url = siteConfig.nodeAPIBaseUrl + 'communication-templates?type=' + templateType;
        return this._http.get<EmailTemplateList[]>(url);
    }

    getEmailTemplateById(id: number): Observable<any> {
        const url = siteConfig.nodeAPIBaseUrl + 'communication-templates/id/' + id;
        return this._http.get<any>(url);

    }

    addEmailTemplate(body: any) {
        const url = siteConfig.nodeAPIBaseUrl + 'communication-templates';
        return this._http.post<any>(url, body);
    }

    editEmailTemplate(id: any, body: any) {
        const url = siteConfig.nodeAPIBaseUrl + 'communication-templates/' + id;
        return this._http.post<any>(url, body);
    }

    getEmailTemplateName() {
        const url = siteConfig.nodeAPIBaseUrl + 'communication-templates?filter[order]=template_name%20ASC';
        return this._http.get<any>(url);
    }

    uploadEmailFile(constId, formData) {
        const url = `${siteConfig.nodeAPIBaseUrl}consultant/downloadAndSaveEmail`;
        return this._http.post(url, formData);
    }
}
