import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChatUploadComponent } from './ChatUpload/chatupload.component';
import { ChatFileComponent } from './ChatFile/chatfile.component';
import {
    DxPopupModule,
    DxFileUploaderModule,
    DxButtonModule,
    DxLoadPanelModule
} from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxPopupModule,
        DxFileUploaderModule,
        DxButtonModule,
        FormsModule,
        DxLoadPanelModule
    ],
    exports: [
        ChatUploadComponent,
        ChatFileComponent
    ],
    declarations: [
        ChatUploadComponent,
        ChatFileComponent
    ]
})
export class ChatMediaModule {
}
