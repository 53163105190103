import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SwalService } from 'app/services/swal.service';

// VMS List API
import { VmsServiceService } from 'app/services/vms-service/vms-service.service' ;

@Component({
  selector: 'app-worker-update',
  templateUrl: './worker-update.component.html',
  styleUrls: ['./worker-update.component.scss']
})
export class WorkerUpdateComponent {
  @ViewChild('tab') myDiv: ElementRef;
  vmsClientList;
  selectedCLient: any[] = [];  tableDetails = [];
  isPopupVisible;
  selectedClients;

  arrIndex = {};
  progressobj = {};
  objectKeys = Object.keys;

  constructor(private router: Router,
    private route: ActivatedRoute,    
    private swalService: SwalService,
    private vms_Name_List: VmsServiceService,
    private elementRef: ElementRef) { }

    ngOnInit() {
      this.loadClientList();
    }
    loadClientList(){
      // VMS Clients
      this.vms_Name_List.getVMSClients().subscribe(data => {
        this.vmsClientList = JSON.parse(JSON.stringify(data));
      });
    }

    refreshGridForClient(args) {
      this.selectedCLient= args['value'];
    }
  
    updateWorker() {
      this.tableDetails = [];
      this.progressobj = {}

      for(let i=0 ;i<this.selectedCLient.length;i++){
        for(let j=0; j<this.vmsClientList.length;j++){
          if(this.vmsClientList[j]['businessEntityId'] === this.selectedCLient[i]){
            this.progressobj[this.selectedCLient[i]] = {
              Created: '-',
              Updated: '-',
              Errored: '-',
              TotalExisting: '-',
              businessName: this.vmsClientList[j]['businessName']
            }
          }
        }
        
        this.vms_Name_List.updateVmsWorker(this.selectedCLient[i], '').subscribe(data => {
          // Get the Progressive data information
          let progressData = this.vms_Name_List.getProgressData();
          
          progressData.addEventListener('message', message => {
            var dynamicData = JSON.parse(message['data']);
            this.FillData(dynamicData,progressData);
          });
    
        }, error => {
          console.log(error);
        });
      }    
    }
  
    clearFilter() { }
  
    FillData(dynamicData, progressData) {

      if(dynamicData.event === 'percentage'){
        this.progressobj[dynamicData.client] = {
          ...this.progressobj[dynamicData.client],
          Progrss: dynamicData.data
        }
      } else if(dynamicData.event === 'results'){
        this.progressobj[dynamicData.client] = {
          ...this.progressobj[dynamicData.client],
          TotalExisting: dynamicData.data.existing,
          Created: dynamicData.data.created,
          Updated: dynamicData.data.updated,
          Errored: dynamicData.data.errored,
        }
      } else if(dynamicData.event === 'done'){
        this.progressobj[dynamicData.client] = {
          ...this.progressobj[dynamicData.client],
          Done: true
        }
      }
      if(Object.keys(this.progressobj).every(obj => this.progressobj[obj].Done == true)){
        progressData.close();
      }

      if(dynamicData.event === 'error'){
        progressData.close();
        const swalData = {
          title: 'Error',
          message: 'Client: '+ this.progressobj[dynamicData.client].businessName,
          info: dynamicData.data || 'Unable to complete login'
        };
        this.swalService.info(swalData);
      }  

    }

    buttonClicked(){
      this.isPopupVisible = false;
    }

}
