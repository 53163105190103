import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {tap, catchError} from 'rxjs/operators';

import {siteConfig} from '../../siteSettings';

@Injectable({
    providedIn: 'root'
})
export class TeamsService {
    private _url_base: string = siteConfig.pulse3APIUrl;
    private roleObj;


    constructor(private http: HttpClient) {
    }

    getTeams() {
        return this.http.get(`${this._url_base}teams`);
    }
}
