import { EmailListService } from "./emailList.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { map } from "rxjs/operators";
import { siteConfig } from "../../siteSettings";
import { timeout, catchError } from "rxjs/operators";

import {
    EMAIL_SEARCH,
    MAIL_FILTER,
    FOLDER_FILTER,
    ES_KEY_BODY_CONTENT,
    ES_KEY_HIGHLIGHT,
    SUBJECT,
    SEND_DATE_TIME,
    FOLDER_NAME,
    SENDER_EMAIL_NAME,
    SENDER_EMAIL_ADDRESS,
    EMAIL_INDEX_TYPE,
    ATTACHMENT_TYPE,
    ATTACHMENT_SIZE,
    ATTACHMENT_UPLOAD_TIME,
    ATTACHMENT_FILENAME,
    ATTACHMENT_FILTER
} from "../../main/content/email-search/Constants/constants";
import { ActivatedRoute, Router } from '@angular/router';
@Injectable({
    providedIn: "root"
})
export class ListServiceService {
    date: any = new Date();
    userMailFilters: any = [];
    userMailList: any = [];
    initialToggleFlag: any = { set: false };
    asideFilters: any = {
        userFilter: false,
        folderFilter: false
    };
    indexType: any = {
        type: EMAIL_INDEX_TYPE
    };
    mailBoxList: any = {
        list: [],
        loading: true,
        total: 0,
        filters: [],
        attachemntFilters: [],
        size: 30,
        current: 1,
        query: ""
    };
    selectedForPreview: any = {
        id: "",
        props: {}
    };
    folderTypes: any = [];
    attachemntFilters: any = [];
    emailTableHeaders: any = [
        {
            key: FOLDER_NAME,
            label: "Search folder",
            inputModel: "",
            type: "input",
            width: "180px"
        },
        {
            key: SENDER_EMAIL_NAME,
            label: "Search name",
            inputModel: "",
            type: "input",
            width: "350px"
        },
        {
            key: SENDER_EMAIL_ADDRESS,
            label: "Search from",
            inputModel: "",
            type: "input",
            width: "350px"
        },
        {
            key: SUBJECT,
            label: "Search subject",
            inputModel: "",
            type: "input",
            width: "350px"
        },
        {
            key: SEND_DATE_TIME,
            label: "Send date",
            inputModel: "",
            type: "date",
            width: ""
        }
    ];
    attachmentTableHeaders: any = [
        {
            key: ATTACHMENT_TYPE,
            label: "Type",
            inputModel: "",
            type: "input",
            width: "180px"
        },
        {
            key: ATTACHMENT_FILENAME,
            label: "Filename",
            inputModel: "",
            type: "input",
            width: "250px"
        },
        {
            key: ATTACHMENT_SIZE,
            label: "Size",
            inputModel: "",
            type: "input",
            width: "350px"
        },
        {
            key: ATTACHMENT_UPLOAD_TIME,
            label: "Upload date",
            inputModel: "",
            type: "date",
            width: ""
        }
    ];
    accessToken: any;
    userEmail: any;
    constructor(
        private http: HttpClient,
        private EmailListService: EmailListService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.userMailList = this.EmailListService.getMailsList();
        this.userMailFilters = this.EmailListService.getFilterMails();
        /* this.route.queryParams.subscribe(params => {
            if (params["token"]) {
                this.accessToken = params["token"];
                this.userEmail = params["email"];
            } else {
                this.router.navigate(["/home"]);
            }
        }); */
    }

    getHeaders() {
        return {
            headers: new HttpHeaders().set("Content-Type", "application/json")
        };
    }
    getTableHeaders() {
        // For getting the table headers for Mail and attachment
        return {
            emailTableHeaders: this.emailTableHeaders,
            attachmentTableHeaders: this.attachmentTableHeaders
        };
    }
    fetchFacetList() {
        // Api call for getting the facets list
        let mailListFilter = this.EmailListService.getMailForFilter().map(
            mail => {
                return { filter: mail, type: MAIL_FILTER };
            }
        );
        let folderFilter = this.mailBoxList.filters
            .filter(value => value.checked == true)
            .map(value => {
                return {
                    filter: value.name,
                    type: FOLDER_FILTER
                };
            });
        let queryOnField = [];
        for (var item of this.emailTableHeaders) {
            if (item.inputModel != "")
                queryOnField.push({
                    field: item.key,
                    value: item.inputModel
                });
        }
        let params = {
            query: this.mailBoxList.query,
            queryOn: queryOnField,
            mailList: [...mailListFilter, ...folderFilter],
            type: this.indexType.type,
            subTask: "facet"
            // token: this.accessToken
        };
        this.mailBoxList.loading = true;
        return this.http
            .post(siteConfig.nodeAPIBaseUrl + EMAIL_SEARCH, params)
            .pipe(
                timeout(500000),
                map((response: any) => {
                    return response;
                }),
                catchError(err => {
                    throw "Erro" + err;
                })
            );
    }
    fetchList(page: any) {
        // Api Call for getting table results
        let mailListFilter = this.EmailListService.getMailForFilter().map(
            mail => {
                return { filter: mail, type: MAIL_FILTER };
            }
        );
        let folderFilter = this.mailBoxList.filters
            .filter(value => value.checked == true)
            .map(value => {
                return {
                    filter: value.name,
                    type: FOLDER_FILTER
                };
            });
        let attachmentFilter = this.mailBoxList.attachemntFilters
            .filter(value => value.checked == true)
            .map(value => {
                return {
                    filter: value.name,
                    type: ATTACHMENT_FILTER
                };
            });
        let current;
        let queryOnField = [];
        if (page != false) {
            current = page - 1;
        } else {
            current = 0;
        }
        for (var item of this.emailTableHeaders) {
            if (item.inputModel != "")
                queryOnField.push({
                    field: item.key,
                    value: item.inputModel
                });
        }
        for (var item of this.attachmentTableHeaders) {
            if (item.inputModel != "")
                queryOnField.push({
                    field: item.key,
                    value: item.inputModel
                });
        }
        let params = {
            query: this.mailBoxList.query,
            from: current * this.mailBoxList.size,
            size: this.mailBoxList.size,
            queryOn: queryOnField,
            mailList: [
                ...mailListFilter,
                ...folderFilter,
                ...attachmentFilter
            ],
            type: this.indexType.type,
            subTask: "search"
            // token: this.accessToken
        };
        this.mailBoxList.loading = true;
        return this.http
            .post(siteConfig.nodeAPIBaseUrl + EMAIL_SEARCH, params)
            .pipe(
                timeout(500000),
                map((response: any) => {
                    return response;
                }),
                catchError(err => {
                    throw "Erro" + err;
                })
            );
    }

    getMailBoxList() {
        // For getting table data
        return this.mailBoxList;
    }
    setFacetList(value: any) {
        // For setting the results from the api call (Facet listing data)
        let folderFilter = [];
        let attachemntFilter = [];
        value.folderTypes.map(value => {
            folderFilter.push({
                name: value.key,
                count: value.doc_count,
                checked: false
            });
        });
        this.mailBoxList.filters.map(prevFilter => {
            let index = folderFilter.findIndex(
                value => value.name == prevFilter.name
            );
            if (index != -1) {
                folderFilter[index].checked = prevFilter.checked;
            }
        });
        value.attachemntFilters.map(value => {
            attachemntFilter.push({
                name: value.key,
                count: value.doc_count,
                checked: false
            });
        });
        this.mailBoxList.attachemntFilters.map(prevFilter => {
            let index = attachemntFilter.findIndex(
                value => value.name == prevFilter.name
            );
            if (index != -1) {
                attachemntFilter[index].checked = prevFilter.checked;
            }
        });
        this.mailBoxList.filters = folderFilter;
        this.mailBoxList.attachemntFilters = attachemntFilter;
        this.mailBoxList.tabCounts = {
            email: `${value.emailCount}`,
            attachment: `${value.attachmentCount}`
        };
    }
    setMailBoxList(value: any) {
        // For setting the results from the api call (table listing data)
        this.mailBoxList.list = value.list;
        this.mailBoxList.loading = false;
        this.mailBoxList.total = value.total;
    }
    setSelectedForPreview(value: any) {
        // For setting the current selected row
        if (this.indexType == EMAIL_INDEX_TYPE) {
            this.selectedForPreview.id = value.id;
            this.selectedForPreview.props = value;
        } else {
            this.selectedForPreview.id = value.key;
            value["id"] = value.key;
            this.selectedForPreview.props = value;
        }
    }
    getSelectedForPreview() {
        // For getting the current selected row from result set
        return this.selectedForPreview;
    }
    clearFilters() {
        // For clearing all the applied filters
        this.mailBoxList.current = 1;
        this.mailBoxList.query = "";
        this.mailBoxList.list = [];
        this.mailBoxList.loading = true;
        this.EmailListService.resetMailFilter();
        this.mailBoxList.filters.map(value => (value.checked = false));
        this.emailTableHeaders.map(item => (item.inputModel = ""));
        this.attachmentTableHeaders.map(item => (item.inputModel = ""));
    }

    clearResults() {
        // For clearing the results state and enabling loading on Tab change (mail, attachment)
        this.mailBoxList.list = [];
        this.mailBoxList.loading = true;
    }
    setInitialToggleFlag() {
        // For toggling the facet collapse staate
        this.initialToggleFlag.set = !this.initialToggleFlag.set;
    }
    getInitialToggleFlag() {
        // For getting side panel collapse state
        return this.initialToggleFlag;
    }
    getAsideFilter() {
        // For getting the facet filters
        return this.asideFilters;
    }
    setAllFilters(flag, type) {
        // For enabling all available filters based on type
        if (type == "user") {
            this.EmailListService.setAllMailStatus(flag);
        }
        if (type == "folder") {
            this.mailBoxList.filters.map(value => (value.checked = flag));
        }
    }
    getIndexType() {
        // For getting the current tab type (mail or attachment)
        return this.indexType;
    }
    resetFrom(){
        this.mailBoxList.current = 1;
    }
}


