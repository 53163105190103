<div id="forms" class="page-layout simple fullwidth p-24" fxLayout="column" pulsePerfectScrollbar>
    <div class="container mt-12">
        <div class="content">
            <div class="mat-white-bg mat-elevation-z4 mb-24">
                    <div class="page-title-wrapper" fxLayout="row" fxFlex="1 0 auto">
                            <div class="pulse-main-heading">Compose mail for Github</div>
                    </div>
                  <div class="github-email-form">
                            <!-- <form  (submit)="onFormSubmit($event)">

                                <div id="form-container" class="mb-20">

                                    <dx-form id="form"
                                             [readOnly]="false"
                                             [showColonAfterLabel]="true"
                                             [showValidationSummary]="true"
                                             [colCount]="12"
                                             labelLocation="top"
                                             [formData]="formData"
                                             validationGroup="githubValidationGroupData"
                                             >
                                        <dxi-item dataField="name" [colSpan]="10">
                                            <dxo-label text="Github search or URL or email list"></dxo-label>
                                           
                                        </dxi-item>
                                        <dxi-item  [colSpan]="2">
                                                <dxo-label text=" "></dxo-label>
                                                <dx-button text="Search"
                                                type="normal"
                                                class="pulse-btn pulse-btn-normal"
                                                (onClick)="searchEmailOnGithub()">
                                                </dx-button>
                                        </dxi-item>
                                        <dxi-item dataField="from" [colSpan]="6">
                                            <dxo-label text="From Email"></dxo-label>
                                        </dxi-item>
                                        <dxi-item dataField="template" [colSpan] ="6" editorType="dxSelectBox" [editorOptions]="{ items: templates, value: '', displayExpr:'template_name',onSelectionChanged:onTemplateChanged }">
                                            <dxo-label text="Template"></dxo-label>
                                            <dxi-validation-rule type="required" message="Template is required"></dxi-validation-rule>
                                        </dxi-item>
                                        <dxi-item dataField="subject" [colSpan]="12">
                                            <dxo-label text="Subject"></dxo-label>
                                        </dxi-item>
                                        <dxi-item dataField="emailText" editorType="dxTextArea"
                                            [editorOptions]="{ height: 200 }" 
                                            [colSpan]="12">
                                            <dxo-label text="Email Text"></dxo-label>
                                        </dxi-item> 
                                        <dxi-item
                                            [colSpan]="8">
                                            <dx-check-box
                                            text="Skip sending email if I sent email to the same person in the last"
                                            ></dx-check-box>
                                        </dxi-item>
                                        <dxi-item
                                            [colSpan]="4">
                                            <dx-number-box
                                                [min]="0"
                                                [max]="100"
                                                [showSpinButtons]="true"
                                            ></dx-number-box>
                                        </dxi-item>
                                        <dxi-item
                                            [colSpan]="8">
                                            <dx-check-box
                                            text="Skip sending email if I sent email to the same person in the last"
                                            ></dx-check-box>
                                        </dxi-item>
                                        <dxi-item
                                            [colSpan]="4">
                                            <dx-number-box
                                                [min]="0"
                                                [max]="100"
                                                [showSpinButtons]="true"
                                            ></dx-number-box>
                                        </dxi-item>
                                       
                                     </dx-form>
                                </div>
                                <div class="buttons-container">
                                    <div class="left">
                                        <dx-button text="Cancel"
                                                   type="normal"
                                                   class="pulse-btn pulse-btn-normal"
                                                   (onClick)="cancelForm()"
                                                   >
                                        </dx-button>
                                    </div>
                                    <div class="right">
                                        <dx-button *ngIf="action"
                                                   text="Save"
                                                   type="success"
                                                   validationGroup="githubValidationGroupData"
                                                   class="pulse-btn pulse-btn-primary"
                                                   [useSubmitBehavior]="true"
                                                   >
                                        </dx-button>
                                        <dx-button *ngIf="!action"
                                                   text="Save"
                                                   type="success"
                                                   class="pulse-btn pulse-btn-primary"
                                                   validationGroup="githubValidationGroupData"
                                                   [useSubmitBehavior]="true"
                                                   >
                                        </dx-button>
                                    </div>
                                </div>
                              </form> -->
                              <p> Please click following link to acces github search: <a href='http://source.aptask.com/composer/github/' target="_blank" > Github Search</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
