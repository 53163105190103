import {
    Component,
    ElementRef,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {NavigationStart, Router} from '@angular/router';
import {Platform} from '@angular/cdk/platform';
import {CommonService} from '../services/common/common.service';
// import {Subscription} from 'rxjs/Subscription';
// import {LoginService} from 'app/services/login.service';

// import {FuseConfigService} from '@fuse/services/config.service';

@Component({
    selector: 'pulse-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PulseMainComponent implements OnInit, OnDestroy {
    // onConfigChanged: Subscription;
    isLoggedIn: boolean;
    @HostBinding('attr.pulse-layout-mode') layoutMode;
    loadingVisible = false;
    constructor(
        private router: Router,
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
         private commonService: CommonService,
        private platform: Platform,
        // private loginService: LoginService,
        @Inject(DOCUMENT) private document: any
    ) {
    }

    ngOnInit() {
        this.commonService.showLoadPanel.subscribe(value => {
            setTimeout(() => {
                this.loadingVisible = !!value;
            }, 300);
        });
    }
    ngOnDestroy() {
       // this.onConfigChanged.unsubscribe();
    }

    addClass(className: string) {
        this._renderer.addClass(this._elementRef.nativeElement, className);
    }

    removeClass(className: string) {
        this._renderer.removeClass(this._elementRef.nativeElement, className);
    }
}
