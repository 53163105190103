import { Component, ElementRef, ViewChild } from '@angular/core';
import { SwalService } from 'app/services/swal.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

// VMS List API
import { VmsServiceService } from '../../../../../services/vms-service/vms-service.service' ;

@Component({
  selector: 'app-timesheet-update',
  templateUrl: './timesheet-update.component.html',
  styleUrls: ['./timesheet-update.component.scss']
})
export class TimesheetUpdateComponent {

  @ViewChild('tab') myDiv: ElementRef;

  vmsClientList;
  selectedCLient: any;
  selectedDateRange;
  checkBoxTimesheet: boolean;
  workerSearchFilter;

  selectBoxDataSource = ["Custom Range"];
  m_day;
  dropDownDates;
  selectedClients;
  
  minDate = new Date(1900, 0, 1);
  maxDate = new Date();
  currentDate = new Date();
  isPopupVisible;
  tableDetails = [];
  arrIndex = {};
  progressobj = {};
  objectKeys = Object.keys;

  fromDate;
  toDate;
  isPopupVisibleError;

  constructor(private router: Router,
    private route: ActivatedRoute,    private swalService: SwalService,
    private vms_Name_List: VmsServiceService,
    public datePipe: DatePipe,
    private elementRef: ElementRef) { }

  ngOnInit() {
    this.loadClientList();
  }

  loadClientList(){
    // VMS Clients
    this.vms_Name_List.getVMSClients().subscribe(data => {
      this.vmsClientList = JSON.parse(JSON.stringify(data));
    });

    this.nexttestweek();
  }

  nexttestweek(){
    var i, d = new Date;
    for (i = 1; i <= 4; ++i) {
        this.setSundays(d, i);
    }
  }

  setSundays(d, week_starts_monday) {
    var c_day = d.getDay();
    if (week_starts_monday && c_day === 0)
    c_day = 7; // adjust so `0` is last week sunday, 7 is this week sunday
    d.setDate(d.getDate() - c_day );
    this.m_day = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 6);
    this.m_day = this.datePipe.transform(this.m_day, 'yyyy-MM-dd');
    d = this.datePipe.transform(d, 'yyyy-MM-dd');
    this.dropDownDates = this.m_day +' - '+ d;
    this.selectBoxDataSource.push(this.dropDownDates);
    return d;
  }

  selectDateRange(customRange){
    this.selectedDateRange = customRange.value;
    if(this.selectedDateRange == 'Custom Range'){
      this.isPopupVisible = true;
    }else{
      let dateString;
      dateString = customRange.value;
      this.fromDate = dateString.substring(0,10);
      this.toDate = dateString.substring(dateString.length - 10);
    }
  }

  buttonClicked(frmDateVal, toDateVal){
    this.fromDate = this.datePipe.transform(frmDateVal, 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(toDateVal, 'yyyy-MM-dd');
    this.isPopupVisible = false;
  }

  refreshGridForClient(args) {
    this.selectedCLient= args['value'];
  }

  checkBoxToggled(e) {
    this.checkBoxTimesheet = e.value;
  }

  searchTimesheetUpdate(){
    this.tableDetails = [];
    this.progressobj = {}

    for(let i=0 ;i<this.selectedCLient.length;i++){
      for(let j=0; j<this.vmsClientList.length;j++){
        if(this.vmsClientList[j]['businessEntityId'] === this.selectedCLient[i]){
          this.progressobj[this.selectedCLient[i]] = {
            Created: '-',
            Updated: '-',
            Errored: '-',
            TotalExisting: '-',
            businessName: this.vmsClientList[j]['businessName']
          }
        }
      }
      
      this.vms_Name_List.updateVMSTimeshee(this.selectedCLient[i], '', this.fromDate, this.toDate).subscribe(data => {
        // Get the Progressive data information
        let progressData = this.vms_Name_List.getTimeSheetProgressData();
        progressData.addEventListener('message', message => {
          var dynamicData = JSON.parse(message['data']);
          this.FillData(dynamicData, progressData);
        });
  
      }, error => {
        console.log(error);
      });
    } 
    
  }

  clearFilter(){}

  FillData(dynamicData, progressData) {
    
    if(dynamicData.event === 'percentage'){
      this.progressobj[dynamicData.client] = {
        ...this.progressobj[dynamicData.client],
        Progrss: dynamicData.data
      }
    } else if(dynamicData.event === 'results'){
      this.progressobj[dynamicData.client] = {
        ...this.progressobj[dynamicData.client],
        TotalExisting: dynamicData.data.existing,
        Created: dynamicData.data.created,
        Updated: dynamicData.data.updated,
        Errored: dynamicData.data.errored,
      }
    } else if(dynamicData.event === 'done'){
      this.progressobj[dynamicData.client] = {
        ...this.progressobj[dynamicData.client],
        Done: true
      }
    }
    if(Object.keys(this.progressobj).every(obj => this.progressobj[obj].Done == true)){
      progressData.close();
    }

    if(dynamicData.event === 'error'){
      const swalData = {
        title: 'Error',
        message: 'Client: '+ this.progressobj[dynamicData.client].businessName,
        info: 'Unable to complete login'
      };
      this.swalService.info(swalData);
    }     
  }

  butCloseClicked(){
    this.isPopupVisibleError = false;
  }
}
