// https://restcountries.eu/rest/v2/all?fields=name;alpha2Code;callingCodes;flag
export const countryCodes = [
    {
        'flag': 'https://restcountries.eu/data/afg.svg',
        'name': 'Afghanistan',
        'alpha2Code': 'AF',
        'callingCodes': [
            '93'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ala.svg',
        'name': 'Åland Islands',
        'alpha2Code': 'AX',
        'callingCodes': [
            '358'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/alb.svg',
        'name': 'Albania',
        'alpha2Code': 'AL',
        'callingCodes': [
            '355'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/dza.svg',
        'name': 'Algeria',
        'alpha2Code': 'DZ',
        'callingCodes': [
            '213'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/asm.svg',
        'name': 'American Samoa',
        'alpha2Code': 'AS',
        'callingCodes': [
            '1684'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/and.svg',
        'name': 'Andorra',
        'alpha2Code': 'AD',
        'callingCodes': [
            '376'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ago.svg',
        'name': 'Angola',
        'alpha2Code': 'AO',
        'callingCodes': [
            '244'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/aia.svg',
        'name': 'Anguilla',
        'alpha2Code': 'AI',
        'callingCodes': [
            '1264'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ata.svg',
        'name': 'Antarctica',
        'alpha2Code': 'AQ',
        'callingCodes': [
            '672'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/atg.svg',
        'name': 'Antigua and Barbuda',
        'alpha2Code': 'AG',
        'callingCodes': [
            '1268'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/arg.svg',
        'name': 'Argentina',
        'alpha2Code': 'AR',
        'callingCodes': [
            '54'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/arm.svg',
        'name': 'Armenia',
        'alpha2Code': 'AM',
        'callingCodes': [
            '374'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/abw.svg',
        'name': 'Aruba',
        'alpha2Code': 'AW',
        'callingCodes': [
            '297'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/aus.svg',
        'name': 'Australia',
        'alpha2Code': 'AU',
        'callingCodes': [
            '61'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/aut.svg',
        'name': 'Austria',
        'alpha2Code': 'AT',
        'callingCodes': [
            '43'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/aze.svg',
        'name': 'Azerbaijan',
        'alpha2Code': 'AZ',
        'callingCodes': [
            '994'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bhs.svg',
        'name': 'Bahamas',
        'alpha2Code': 'BS',
        'callingCodes': [
            '1242'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bhr.svg',
        'name': 'Bahrain',
        'alpha2Code': 'BH',
        'callingCodes': [
            '973'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bgd.svg',
        'name': 'Bangladesh',
        'alpha2Code': 'BD',
        'callingCodes': [
            '880'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/brb.svg',
        'name': 'Barbados',
        'alpha2Code': 'BB',
        'callingCodes': [
            '1246'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/blr.svg',
        'name': 'Belarus',
        'alpha2Code': 'BY',
        'callingCodes': [
            '375'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bel.svg',
        'name': 'Belgium',
        'alpha2Code': 'BE',
        'callingCodes': [
            '32'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/blz.svg',
        'name': 'Belize',
        'alpha2Code': 'BZ',
        'callingCodes': [
            '501'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ben.svg',
        'name': 'Benin',
        'alpha2Code': 'BJ',
        'callingCodes': [
            '229'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bmu.svg',
        'name': 'Bermuda',
        'alpha2Code': 'BM',
        'callingCodes': [
            '1441'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/btn.svg',
        'name': 'Bhutan',
        'alpha2Code': 'BT',
        'callingCodes': [
            '975'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bol.svg',
        'name': 'Bolivia (Plurinational State of)',
        'alpha2Code': 'BO',
        'callingCodes': [
            '591'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bes.svg',
        'name': 'Bonaire, Sint Eustatius and Saba',
        'alpha2Code': 'BQ',
        'callingCodes': [
            '5997'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bih.svg',
        'name': 'Bosnia and Herzegovina',
        'alpha2Code': 'BA',
        'callingCodes': [
            '387'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bwa.svg',
        'name': 'Botswana',
        'alpha2Code': 'BW',
        'callingCodes': [
            '267'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bvt.svg',
        'name': 'Bouvet Island',
        'alpha2Code': 'BV',
        'callingCodes': [
            ''
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bra.svg',
        'name': 'Brazil',
        'alpha2Code': 'BR',
        'callingCodes': [
            '55'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/iot.svg',
        'name': 'British Indian Ocean Territory',
        'alpha2Code': 'IO',
        'callingCodes': [
            '246'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/umi.svg',
        'name': 'United States Minor Outlying Islands',
        'alpha2Code': 'UM',
        'callingCodes': [
            ''
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/vgb.svg',
        'name': 'Virgin Islands (British)',
        'alpha2Code': 'VG',
        'callingCodes': [
            '1284'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/vir.svg',
        'name': 'Virgin Islands (U.S.)',
        'alpha2Code': 'VI',
        'callingCodes': [
            '1 340'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/brn.svg',
        'name': 'Brunei Darussalam',
        'alpha2Code': 'BN',
        'callingCodes': [
            '673'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bgr.svg',
        'name': 'Bulgaria',
        'alpha2Code': 'BG',
        'callingCodes': [
            '359'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bfa.svg',
        'name': 'Burkina Faso',
        'alpha2Code': 'BF',
        'callingCodes': [
            '226'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/bdi.svg',
        'name': 'Burundi',
        'alpha2Code': 'BI',
        'callingCodes': [
            '257'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/khm.svg',
        'name': 'Cambodia',
        'alpha2Code': 'KH',
        'callingCodes': [
            '855'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cmr.svg',
        'name': 'Cameroon',
        'alpha2Code': 'CM',
        'callingCodes': [
            '237'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cpv.svg',
        'name': 'Cabo Verde',
        'alpha2Code': 'CV',
        'callingCodes': [
            '238'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cym.svg',
        'name': 'Cayman Islands',
        'alpha2Code': 'KY',
        'callingCodes': [
            '1345'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/caf.svg',
        'name': 'Central African Republic',
        'alpha2Code': 'CF',
        'callingCodes': [
            '236'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tcd.svg',
        'name': 'Chad',
        'alpha2Code': 'TD',
        'callingCodes': [
            '235'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/chl.svg',
        'name': 'Chile',
        'alpha2Code': 'CL',
        'callingCodes': [
            '56'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/chn.svg',
        'name': 'China',
        'alpha2Code': 'CN',
        'callingCodes': [
            '86'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cxr.svg',
        'name': 'Christmas Island',
        'alpha2Code': 'CX',
        'callingCodes': [
            '61'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cck.svg',
        'name': 'Cocos (Keeling) Islands',
        'alpha2Code': 'CC',
        'callingCodes': [
            '61'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/col.svg',
        'name': 'Colombia',
        'alpha2Code': 'CO',
        'callingCodes': [
            '57'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/com.svg',
        'name': 'Comoros',
        'alpha2Code': 'KM',
        'callingCodes': [
            '269'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cog.svg',
        'name': 'Congo',
        'alpha2Code': 'CG',
        'callingCodes': [
            '242'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cod.svg',
        'name': 'Congo (Democratic Republic of the)',
        'alpha2Code': 'CD',
        'callingCodes': [
            '243'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cok.svg',
        'name': 'Cook Islands',
        'alpha2Code': 'CK',
        'callingCodes': [
            '682'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cri.svg',
        'name': 'Costa Rica',
        'alpha2Code': 'CR',
        'callingCodes': [
            '506'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/hrv.svg',
        'name': 'Croatia',
        'alpha2Code': 'HR',
        'callingCodes': [
            '385'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cub.svg',
        'name': 'Cuba',
        'alpha2Code': 'CU',
        'callingCodes': [
            '53'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cuw.svg',
        'name': 'Curaçao',
        'alpha2Code': 'CW',
        'callingCodes': [
            '599'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cyp.svg',
        'name': 'Cyprus',
        'alpha2Code': 'CY',
        'callingCodes': [
            '357'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/cze.svg',
        'name': 'Czech Republic',
        'alpha2Code': 'CZ',
        'callingCodes': [
            '420'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/dnk.svg',
        'name': 'Denmark',
        'alpha2Code': 'DK',
        'callingCodes': [
            '45'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/dji.svg',
        'name': 'Djibouti',
        'alpha2Code': 'DJ',
        'callingCodes': [
            '253'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/dma.svg',
        'name': 'Dominica',
        'alpha2Code': 'DM',
        'callingCodes': [
            '1767'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/dom.svg',
        'name': 'Dominican Republic',
        'alpha2Code': 'DO',
        'callingCodes': [
            '1809',
            '1829',
            '1849'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ecu.svg',
        'name': 'Ecuador',
        'alpha2Code': 'EC',
        'callingCodes': [
            '593'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/egy.svg',
        'name': 'Egypt',
        'alpha2Code': 'EG',
        'callingCodes': [
            '20'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/slv.svg',
        'name': 'El Salvador',
        'alpha2Code': 'SV',
        'callingCodes': [
            '503'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/gnq.svg',
        'name': 'Equatorial Guinea',
        'alpha2Code': 'GQ',
        'callingCodes': [
            '240'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/eri.svg',
        'name': 'Eritrea',
        'alpha2Code': 'ER',
        'callingCodes': [
            '291'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/est.svg',
        'name': 'Estonia',
        'alpha2Code': 'EE',
        'callingCodes': [
            '372'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/eth.svg',
        'name': 'Ethiopia',
        'alpha2Code': 'ET',
        'callingCodes': [
            '251'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/flk.svg',
        'name': 'Falkland Islands (Malvinas)',
        'alpha2Code': 'FK',
        'callingCodes': [
            '500'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/fro.svg',
        'name': 'Faroe Islands',
        'alpha2Code': 'FO',
        'callingCodes': [
            '298'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/fji.svg',
        'name': 'Fiji',
        'alpha2Code': 'FJ',
        'callingCodes': [
            '679'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/fin.svg',
        'name': 'Finland',
        'alpha2Code': 'FI',
        'callingCodes': [
            '358'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/fra.svg',
        'name': 'France',
        'alpha2Code': 'FR',
        'callingCodes': [
            '33'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/guf.svg',
        'name': 'French Guiana',
        'alpha2Code': 'GF',
        'callingCodes': [
            '594'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/pyf.svg',
        'name': 'French Polynesia',
        'alpha2Code': 'PF',
        'callingCodes': [
            '689'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/atf.svg',
        'name': 'French Southern Territories',
        'alpha2Code': 'TF',
        'callingCodes': [
            ''
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/gab.svg',
        'name': 'Gabon',
        'alpha2Code': 'GA',
        'callingCodes': [
            '241'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/gmb.svg',
        'name': 'Gambia',
        'alpha2Code': 'GM',
        'callingCodes': [
            '220'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/geo.svg',
        'name': 'Georgia',
        'alpha2Code': 'GE',
        'callingCodes': [
            '995'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/deu.svg',
        'name': 'Germany',
        'alpha2Code': 'DE',
        'callingCodes': [
            '49'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/gha.svg',
        'name': 'Ghana',
        'alpha2Code': 'GH',
        'callingCodes': [
            '233'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/gib.svg',
        'name': 'Gibraltar',
        'alpha2Code': 'GI',
        'callingCodes': [
            '350'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/grc.svg',
        'name': 'Greece',
        'alpha2Code': 'GR',
        'callingCodes': [
            '30'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/grl.svg',
        'name': 'Greenland',
        'alpha2Code': 'GL',
        'callingCodes': [
            '299'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/grd.svg',
        'name': 'Grenada',
        'alpha2Code': 'GD',
        'callingCodes': [
            '1473'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/glp.svg',
        'name': 'Guadeloupe',
        'alpha2Code': 'GP',
        'callingCodes': [
            '590'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/gum.svg',
        'name': 'Guam',
        'alpha2Code': 'GU',
        'callingCodes': [
            '1671'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/gtm.svg',
        'name': 'Guatemala',
        'alpha2Code': 'GT',
        'callingCodes': [
            '502'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ggy.svg',
        'name': 'Guernsey',
        'alpha2Code': 'GG',
        'callingCodes': [
            '44'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/gin.svg',
        'name': 'Guinea',
        'alpha2Code': 'GN',
        'callingCodes': [
            '224'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/gnb.svg',
        'name': 'Guinea-Bissau',
        'alpha2Code': 'GW',
        'callingCodes': [
            '245'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/guy.svg',
        'name': 'Guyana',
        'alpha2Code': 'GY',
        'callingCodes': [
            '592'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/hti.svg',
        'name': 'Haiti',
        'alpha2Code': 'HT',
        'callingCodes': [
            '509'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/hmd.svg',
        'name': 'Heard Island and McDonald Islands',
        'alpha2Code': 'HM',
        'callingCodes': [
            ''
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/vat.svg',
        'name': 'Holy See',
        'alpha2Code': 'VA',
        'callingCodes': [
            '379'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/hnd.svg',
        'name': 'Honduras',
        'alpha2Code': 'HN',
        'callingCodes': [
            '504'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/hkg.svg',
        'name': 'Hong Kong',
        'alpha2Code': 'HK',
        'callingCodes': [
            '852'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/hun.svg',
        'name': 'Hungary',
        'alpha2Code': 'HU',
        'callingCodes': [
            '36'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/isl.svg',
        'name': 'Iceland',
        'alpha2Code': 'IS',
        'callingCodes': [
            '354'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ind.svg',
        'name': 'India',
        'alpha2Code': 'IN',
        'callingCodes': [
            '91'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/idn.svg',
        'name': 'Indonesia',
        'alpha2Code': 'ID',
        'callingCodes': [
            '62'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/civ.svg',
        'name': 'Côte d\'Ivoire',
        'alpha2Code': 'CI',
        'callingCodes': [
            '225'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/irn.svg',
        'name': 'Iran (Islamic Republic of)',
        'alpha2Code': 'IR',
        'callingCodes': [
            '98'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/irq.svg',
        'name': 'Iraq',
        'alpha2Code': 'IQ',
        'callingCodes': [
            '964'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/irl.svg',
        'name': 'Ireland',
        'alpha2Code': 'IE',
        'callingCodes': [
            '353'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/imn.svg',
        'name': 'Isle of Man',
        'alpha2Code': 'IM',
        'callingCodes': [
            '44'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/isr.svg',
        'name': 'Israel',
        'alpha2Code': 'IL',
        'callingCodes': [
            '972'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ita.svg',
        'name': 'Italy',
        'alpha2Code': 'IT',
        'callingCodes': [
            '39'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/jam.svg',
        'name': 'Jamaica',
        'alpha2Code': 'JM',
        'callingCodes': [
            '1876'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/jpn.svg',
        'name': 'Japan',
        'alpha2Code': 'JP',
        'callingCodes': [
            '81'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/jey.svg',
        'name': 'Jersey',
        'alpha2Code': 'JE',
        'callingCodes': [
            '44'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/jor.svg',
        'name': 'Jordan',
        'alpha2Code': 'JO',
        'callingCodes': [
            '962'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/kaz.svg',
        'name': 'Kazakhstan',
        'alpha2Code': 'KZ',
        'callingCodes': [
            '76',
            '77'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ken.svg',
        'name': 'Kenya',
        'alpha2Code': 'KE',
        'callingCodes': [
            '254'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/kir.svg',
        'name': 'Kiribati',
        'alpha2Code': 'KI',
        'callingCodes': [
            '686'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/kwt.svg',
        'name': 'Kuwait',
        'alpha2Code': 'KW',
        'callingCodes': [
            '965'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/kgz.svg',
        'name': 'Kyrgyzstan',
        'alpha2Code': 'KG',
        'callingCodes': [
            '996'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/lao.svg',
        'name': 'Lao People\'s Democratic Republic',
        'alpha2Code': 'LA',
        'callingCodes': [
            '856'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/lva.svg',
        'name': 'Latvia',
        'alpha2Code': 'LV',
        'callingCodes': [
            '371'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/lbn.svg',
        'name': 'Lebanon',
        'alpha2Code': 'LB',
        'callingCodes': [
            '961'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/lso.svg',
        'name': 'Lesotho',
        'alpha2Code': 'LS',
        'callingCodes': [
            '266'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/lbr.svg',
        'name': 'Liberia',
        'alpha2Code': 'LR',
        'callingCodes': [
            '231'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/lby.svg',
        'name': 'Libya',
        'alpha2Code': 'LY',
        'callingCodes': [
            '218'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/lie.svg',
        'name': 'Liechtenstein',
        'alpha2Code': 'LI',
        'callingCodes': [
            '423'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ltu.svg',
        'name': 'Lithuania',
        'alpha2Code': 'LT',
        'callingCodes': [
            '370'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/lux.svg',
        'name': 'Luxembourg',
        'alpha2Code': 'LU',
        'callingCodes': [
            '352'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mac.svg',
        'name': 'Macao',
        'alpha2Code': 'MO',
        'callingCodes': [
            '853'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mkd.svg',
        'name': 'Macedonia (the former Yugoslav Republic of)',
        'alpha2Code': 'MK',
        'callingCodes': [
            '389'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mdg.svg',
        'name': 'Madagascar',
        'alpha2Code': 'MG',
        'callingCodes': [
            '261'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mwi.svg',
        'name': 'Malawi',
        'alpha2Code': 'MW',
        'callingCodes': [
            '265'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mys.svg',
        'name': 'Malaysia',
        'alpha2Code': 'MY',
        'callingCodes': [
            '60'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mdv.svg',
        'name': 'Maldives',
        'alpha2Code': 'MV',
        'callingCodes': [
            '960'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mli.svg',
        'name': 'Mali',
        'alpha2Code': 'ML',
        'callingCodes': [
            '223'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mlt.svg',
        'name': 'Malta',
        'alpha2Code': 'MT',
        'callingCodes': [
            '356'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mhl.svg',
        'name': 'Marshall Islands',
        'alpha2Code': 'MH',
        'callingCodes': [
            '692'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mtq.svg',
        'name': 'Martinique',
        'alpha2Code': 'MQ',
        'callingCodes': [
            '596'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mrt.svg',
        'name': 'Mauritania',
        'alpha2Code': 'MR',
        'callingCodes': [
            '222'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mus.svg',
        'name': 'Mauritius',
        'alpha2Code': 'MU',
        'callingCodes': [
            '230'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/myt.svg',
        'name': 'Mayotte',
        'alpha2Code': 'YT',
        'callingCodes': [
            '262'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mex.svg',
        'name': 'Mexico',
        'alpha2Code': 'MX',
        'callingCodes': [
            '52'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/fsm.svg',
        'name': 'Micronesia (Federated States of)',
        'alpha2Code': 'FM',
        'callingCodes': [
            '691'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mda.svg',
        'name': 'Moldova (Republic of)',
        'alpha2Code': 'MD',
        'callingCodes': [
            '373'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mco.svg',
        'name': 'Monaco',
        'alpha2Code': 'MC',
        'callingCodes': [
            '377'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mng.svg',
        'name': 'Mongolia',
        'alpha2Code': 'MN',
        'callingCodes': [
            '976'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mne.svg',
        'name': 'Montenegro',
        'alpha2Code': 'ME',
        'callingCodes': [
            '382'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/msr.svg',
        'name': 'Montserrat',
        'alpha2Code': 'MS',
        'callingCodes': [
            '1664'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mar.svg',
        'name': 'Morocco',
        'alpha2Code': 'MA',
        'callingCodes': [
            '212'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/moz.svg',
        'name': 'Mozambique',
        'alpha2Code': 'MZ',
        'callingCodes': [
            '258'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mmr.svg',
        'name': 'Myanmar',
        'alpha2Code': 'MM',
        'callingCodes': [
            '95'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/nam.svg',
        'name': 'Namibia',
        'alpha2Code': 'NA',
        'callingCodes': [
            '264'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/nru.svg',
        'name': 'Nauru',
        'alpha2Code': 'NR',
        'callingCodes': [
            '674'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/npl.svg',
        'name': 'Nepal',
        'alpha2Code': 'NP',
        'callingCodes': [
            '977'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/nld.svg',
        'name': 'Netherlands',
        'alpha2Code': 'NL',
        'callingCodes': [
            '31'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ncl.svg',
        'name': 'New Caledonia',
        'alpha2Code': 'NC',
        'callingCodes': [
            '687'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/nzl.svg',
        'name': 'New Zealand',
        'alpha2Code': 'NZ',
        'callingCodes': [
            '64'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/nic.svg',
        'name': 'Nicaragua',
        'alpha2Code': 'NI',
        'callingCodes': [
            '505'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ner.svg',
        'name': 'Niger',
        'alpha2Code': 'NE',
        'callingCodes': [
            '227'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/nga.svg',
        'name': 'Nigeria',
        'alpha2Code': 'NG',
        'callingCodes': [
            '234'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/niu.svg',
        'name': 'Niue',
        'alpha2Code': 'NU',
        'callingCodes': [
            '683'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/nfk.svg',
        'name': 'Norfolk Island',
        'alpha2Code': 'NF',
        'callingCodes': [
            '672'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/prk.svg',
        'name': 'Korea (Democratic People\'s Republic of)',
        'alpha2Code': 'KP',
        'callingCodes': [
            '850'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/mnp.svg',
        'name': 'Northern Mariana Islands',
        'alpha2Code': 'MP',
        'callingCodes': [
            '1670'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/nor.svg',
        'name': 'Norway',
        'alpha2Code': 'NO',
        'callingCodes': [
            '47'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/omn.svg',
        'name': 'Oman',
        'alpha2Code': 'OM',
        'callingCodes': [
            '968'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/pak.svg',
        'name': 'Pakistan',
        'alpha2Code': 'PK',
        'callingCodes': [
            '92'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/plw.svg',
        'name': 'Palau',
        'alpha2Code': 'PW',
        'callingCodes': [
            '680'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/pse.svg',
        'name': 'Palestine, State of',
        'alpha2Code': 'PS',
        'callingCodes': [
            '970'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/pan.svg',
        'name': 'Panama',
        'alpha2Code': 'PA',
        'callingCodes': [
            '507'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/png.svg',
        'name': 'Papua New Guinea',
        'alpha2Code': 'PG',
        'callingCodes': [
            '675'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/pry.svg',
        'name': 'Paraguay',
        'alpha2Code': 'PY',
        'callingCodes': [
            '595'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/per.svg',
        'name': 'Peru',
        'alpha2Code': 'PE',
        'callingCodes': [
            '51'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/phl.svg',
        'name': 'Philippines',
        'alpha2Code': 'PH',
        'callingCodes': [
            '63'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/pcn.svg',
        'name': 'Pitcairn',
        'alpha2Code': 'PN',
        'callingCodes': [
            '64'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/pol.svg',
        'name': 'Poland',
        'alpha2Code': 'PL',
        'callingCodes': [
            '48'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/prt.svg',
        'name': 'Portugal',
        'alpha2Code': 'PT',
        'callingCodes': [
            '351'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/pri.svg',
        'name': 'Puerto Rico',
        'alpha2Code': 'PR',
        'callingCodes': [
            '1787',
            '1939'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/qat.svg',
        'name': 'Qatar',
        'alpha2Code': 'QA',
        'callingCodes': [
            '974'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/kos.svg',
        'name': 'Republic of Kosovo',
        'alpha2Code': 'XK',
        'callingCodes': [
            '383'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/reu.svg',
        'name': 'Réunion',
        'alpha2Code': 'RE',
        'callingCodes': [
            '262'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/rou.svg',
        'name': 'Romania',
        'alpha2Code': 'RO',
        'callingCodes': [
            '40'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/rus.svg',
        'name': 'Russian Federation',
        'alpha2Code': 'RU',
        'callingCodes': [
            '7'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/rwa.svg',
        'name': 'Rwanda',
        'alpha2Code': 'RW',
        'callingCodes': [
            '250'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/blm.svg',
        'name': 'Saint Barthélemy',
        'alpha2Code': 'BL',
        'callingCodes': [
            '590'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/shn.svg',
        'name': 'Saint Helena, Ascension and Tristan da Cunha',
        'alpha2Code': 'SH',
        'callingCodes': [
            '290'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/kna.svg',
        'name': 'Saint Kitts and Nevis',
        'alpha2Code': 'KN',
        'callingCodes': [
            '1869'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/lca.svg',
        'name': 'Saint Lucia',
        'alpha2Code': 'LC',
        'callingCodes': [
            '1758'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/maf.svg',
        'name': 'Saint Martin (French part)',
        'alpha2Code': 'MF',
        'callingCodes': [
            '590'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/spm.svg',
        'name': 'Saint Pierre and Miquelon',
        'alpha2Code': 'PM',
        'callingCodes': [
            '508'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/vct.svg',
        'name': 'Saint Vincent and the Grenadines',
        'alpha2Code': 'VC',
        'callingCodes': [
            '1784'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/wsm.svg',
        'name': 'Samoa',
        'alpha2Code': 'WS',
        'callingCodes': [
            '685'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/smr.svg',
        'name': 'San Marino',
        'alpha2Code': 'SM',
        'callingCodes': [
            '378'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/stp.svg',
        'name': 'Sao Tome and Principe',
        'alpha2Code': 'ST',
        'callingCodes': [
            '239'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/sau.svg',
        'name': 'Saudi Arabia',
        'alpha2Code': 'SA',
        'callingCodes': [
            '966'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/sen.svg',
        'name': 'Senegal',
        'alpha2Code': 'SN',
        'callingCodes': [
            '221'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/srb.svg',
        'name': 'Serbia',
        'alpha2Code': 'RS',
        'callingCodes': [
            '381'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/syc.svg',
        'name': 'Seychelles',
        'alpha2Code': 'SC',
        'callingCodes': [
            '248'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/sle.svg',
        'name': 'Sierra Leone',
        'alpha2Code': 'SL',
        'callingCodes': [
            '232'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/sgp.svg',
        'name': 'Singapore',
        'alpha2Code': 'SG',
        'callingCodes': [
            '65'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/sxm.svg',
        'name': 'Sint Maarten (Dutch part)',
        'alpha2Code': 'SX',
        'callingCodes': [
            '1721'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/svk.svg',
        'name': 'Slovakia',
        'alpha2Code': 'SK',
        'callingCodes': [
            '421'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/svn.svg',
        'name': 'Slovenia',
        'alpha2Code': 'SI',
        'callingCodes': [
            '386'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/slb.svg',
        'name': 'Solomon Islands',
        'alpha2Code': 'SB',
        'callingCodes': [
            '677'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/som.svg',
        'name': 'Somalia',
        'alpha2Code': 'SO',
        'callingCodes': [
            '252'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/zaf.svg',
        'name': 'South Africa',
        'alpha2Code': 'ZA',
        'callingCodes': [
            '27'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/sgs.svg',
        'name': 'South Georgia and the South Sandwich Islands',
        'alpha2Code': 'GS',
        'callingCodes': [
            '500'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/kor.svg',
        'name': 'Korea (Republic of)',
        'alpha2Code': 'KR',
        'callingCodes': [
            '82'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ssd.svg',
        'name': 'South Sudan',
        'alpha2Code': 'SS',
        'callingCodes': [
            '211'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/esp.svg',
        'name': 'Spain',
        'alpha2Code': 'ES',
        'callingCodes': [
            '34'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/lka.svg',
        'name': 'Sri Lanka',
        'alpha2Code': 'LK',
        'callingCodes': [
            '94'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/sdn.svg',
        'name': 'Sudan',
        'alpha2Code': 'SD',
        'callingCodes': [
            '249'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/sur.svg',
        'name': 'Suriname',
        'alpha2Code': 'SR',
        'callingCodes': [
            '597'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/sjm.svg',
        'name': 'Svalbard and Jan Mayen',
        'alpha2Code': 'SJ',
        'callingCodes': [
            '4779'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/swz.svg',
        'name': 'Swaziland',
        'alpha2Code': 'SZ',
        'callingCodes': [
            '268'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/swe.svg',
        'name': 'Sweden',
        'alpha2Code': 'SE',
        'callingCodes': [
            '46'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/che.svg',
        'name': 'Switzerland',
        'alpha2Code': 'CH',
        'callingCodes': [
            '41'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/syr.svg',
        'name': 'Syrian Arab Republic',
        'alpha2Code': 'SY',
        'callingCodes': [
            '963'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/twn.svg',
        'name': 'Taiwan',
        'alpha2Code': 'TW',
        'callingCodes': [
            '886'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tjk.svg',
        'name': 'Tajikistan',
        'alpha2Code': 'TJ',
        'callingCodes': [
            '992'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tza.svg',
        'name': 'Tanzania, United Republic of',
        'alpha2Code': 'TZ',
        'callingCodes': [
            '255'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tha.svg',
        'name': 'Thailand',
        'alpha2Code': 'TH',
        'callingCodes': [
            '66'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tls.svg',
        'name': 'Timor-Leste',
        'alpha2Code': 'TL',
        'callingCodes': [
            '670'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tgo.svg',
        'name': 'Togo',
        'alpha2Code': 'TG',
        'callingCodes': [
            '228'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tkl.svg',
        'name': 'Tokelau',
        'alpha2Code': 'TK',
        'callingCodes': [
            '690'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ton.svg',
        'name': 'Tonga',
        'alpha2Code': 'TO',
        'callingCodes': [
            '676'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tto.svg',
        'name': 'Trinidad and Tobago',
        'alpha2Code': 'TT',
        'callingCodes': [
            '1868'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tun.svg',
        'name': 'Tunisia',
        'alpha2Code': 'TN',
        'callingCodes': [
            '216'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tur.svg',
        'name': 'Turkey',
        'alpha2Code': 'TR',
        'callingCodes': [
            '90'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tkm.svg',
        'name': 'Turkmenistan',
        'alpha2Code': 'TM',
        'callingCodes': [
            '993'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tca.svg',
        'name': 'Turks and Caicos Islands',
        'alpha2Code': 'TC',
        'callingCodes': [
            '1649'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/tuv.svg',
        'name': 'Tuvalu',
        'alpha2Code': 'TV',
        'callingCodes': [
            '688'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/uga.svg',
        'name': 'Uganda',
        'alpha2Code': 'UG',
        'callingCodes': [
            '256'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ukr.svg',
        'name': 'Ukraine',
        'alpha2Code': 'UA',
        'callingCodes': [
            '380'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/are.svg',
        'name': 'United Arab Emirates',
        'alpha2Code': 'AE',
        'callingCodes': [
            '971'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/gbr.svg',
        'name': 'United Kingdom of Great Britain and Northern Ireland',
        'alpha2Code': 'GB',
        'callingCodes': [
            '44'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/usa.svg',
        'name': 'United States of America',
        'alpha2Code': 'US',
        'callingCodes': [
            '1'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/can.svg',
        'name': 'Canada',
        'alpha2Code': 'CA',
        'callingCodes': [
            '1'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ury.svg',
        'name': 'Uruguay',
        'alpha2Code': 'UY',
        'callingCodes': [
            '598'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/uzb.svg',
        'name': 'Uzbekistan',
        'alpha2Code': 'UZ',
        'callingCodes': [
            '998'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/vut.svg',
        'name': 'Vanuatu',
        'alpha2Code': 'VU',
        'callingCodes': [
            '678'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/ven.svg',
        'name': 'Venezuela (Bolivarian Republic of)',
        'alpha2Code': 'VE',
        'callingCodes': [
            '58'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/vnm.svg',
        'name': 'Viet Nam',
        'alpha2Code': 'VN',
        'callingCodes': [
            '84'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/wlf.svg',
        'name': 'Wallis and Futuna',
        'alpha2Code': 'WF',
        'callingCodes': [
            '681'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/esh.svg',
        'name': 'Western Sahara',
        'alpha2Code': 'EH',
        'callingCodes': [
            '212'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/yem.svg',
        'name': 'Yemen',
        'alpha2Code': 'YE',
        'callingCodes': [
            '967'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/zmb.svg',
        'name': 'Zambia',
        'alpha2Code': 'ZM',
        'callingCodes': [
            '260'
        ]
    },
    {
        'flag': 'https://restcountries.eu/data/zwe.svg',
        'name': 'Zimbabwe',
        'alpha2Code': 'ZW',
        'callingCodes': [
            '263'
        ]
    }
];
