import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "fileSizeFormatter"
})
export class FileSizeFormatterPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        let formatSize = bytes => {
            if (bytes === 0) return "0 Bytes";

            const k = 1024;
            const dm = 2 < 0 ? 0 : 2;
            const sizes = [
                "Bytes",
                "KB",
                "MB",
                "GB",
                "TB",
                "PB",
                "EB",
                "ZB",
                "YB"
            ];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return (
                parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
                " " +
                sizes[i]
            );
        };
        return formatSize(value);
    }
}
