<div id="home-page-content" class="home-content" fxLayout="column" fxLayoutAlign="center center">
	<section fxLayout="column" fxLayoutAlign="center center">
		<div class="heading text-center">
			<h1 class="mt-0">Welcome to <span>{{siteConfig.companyName}}</span></h1>
			<h4>This portal is only for ApTask Employees, Contractors, Franchisees, Vendors and Clients</h4>
		</div>
		<div class="btn-group">
			<button class="goto"><a [href]="siteConfig.companyWebsite" target="_blank">Go to {{siteConfig.companyDomain}}</a></button>
			<span class="vl"></span>
			<button class="signup"><a routerLink="/register">Request for Sign Up</a></button>
		</div>
	</section>
</div>
