import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EeoDetailsComponent } from './eeo-details.component';
import {RouterModule} from '@angular/router';
import {PulseSharedModule} from '../../../@pulse/shared.module';
import {DxButtonModule, DxFormModule} from 'devextreme-angular';
import {ConsultantService} from '../../services/consultant/consultant.service';
import {SignaturePadModule} from 'angular2-signaturepad';
import {RecaptchaModule} from 'ng-recaptcha';

const routes = [
    {
        path: 'eeo-detail/:consultantId',
        component: EeoDetailsComponent
    }
];

@NgModule({
  declarations: [EeoDetailsComponent],
  imports: [
    CommonModule,
      RouterModule.forChild(routes),
      PulseSharedModule,

      DxFormModule,
      DxButtonModule,
      SignaturePadModule,
      RecaptchaModule
  ],

    providers: [ConsultantService]
})
export class EeoDetailsModule { }
