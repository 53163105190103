import {Component, OnInit, ViewChild} from '@angular/core';
import {UsersService} from "../../../../services/users/users.service";
import {SwalService} from "../../../../services/swal.service";
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import {RolesService} from "../../../../services/roles/role.service";
import {Router} from "@angular/router";
import {PeriodicElement} from "../../rbac/role-list/role-list.component";
import {CommonService} from "../../../../services/common/common.service";
import {DxDataGridComponent, DxFormComponent} from "devextreme-angular";
import {EmailTemplateService} from "../../../../services/email-template/email-template.service";


@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})


export class UserListComponent implements OnInit {

    public userDataSource: DataSource;
    @ViewChild('sendMailForm') sendMailForm: DxFormComponent;
    @ViewChild('gridContainer') dxReportDataGrid: DxDataGridComponent;
    loadingVisible = false;
    showFilter = false;
    totalClient = 0;
    searchString: string = null;
    currentSelectedObject: PeriodicElement;
    menuItems = [
        {
            icon: 'preferences',
            items: [
                {name: 'Edit', icon: 'edit', id: 'edit'},
                {name: 'Delete', icon: 'clear', id: 'delete'},

            ]
        }
    ];
    currentUser: any;
    isAdmin = false;
    sendEmailPopup = false;
    emailFormData: any = {};
    allEmailList = [];
    templateNameList = [];
    usersList = [];
    placeHolderType = [
        'first_name',
        'last_name',
        'company_name'
    ];
    selectedCandidates: any[] = [];

    constructor(private userService: UsersService,
                private swalService: SwalService,
                private rolesService: RolesService,
                private commonService: CommonService,
                private usersService: UsersService,
                private emailTemplateService: EmailTemplateService,
                private router: Router) {

        this.currentUser = JSON.parse(localStorage.getItem('userData'));
        let roles = this.currentUser.roles;
        if (this.currentUser.roleNames.indexOf('Admin') > -1) {
            this.isAdmin = true;
        }
        let roleArr = roles.split(",");

        if (roleArr.indexOf("1") < 0 && roleArr.indexOf("2") < 0) {
            this.router.navigate(['/dashboard']);
        }

        this.getUserList();
        this.getEmailTemplateContent = this.getEmailTemplateContent.bind(this);
        this.updateEmailContent = this.updateEmailContent.bind(this);
        this.addRemoveSignature = this.addRemoveSignature.bind(this);
        this.onEmailBoxOpen = this.onEmailBoxOpen.bind(this);

        this.getAllEmailTemplates();
        this.getAllUsers();

    }

    ngOnInit() {
    }

    getAllUsers() {
        this.usersService.getUserList()
            .subscribe(
                (result: any) => {
                    result.data.forEach((e) => {
                        this.usersList.push({
                            FullName: e.first_name + ' ' + e.last_name,
                            Email: e.email,
                            userId: e.user_id
                        });
                    });
                },
                (error) => {
                    console.log('getuserERROR', error);
                });
    }

    getAllEmailTemplates() {
        this.loadingVisible = true;
        this.emailTemplateService.getAllEmailTemplates()
            .subscribe(
                (result: any) => {
                    this.templateNameList = result.data || [];

                    this.loadingVisible = false;
                },
                (error) => {
                    console.log('getAllEmailTemplates_ERROR', error);
                    const swalData = {
                        title: 'Error',
                        message: 'Something went wrong',
                        info: 'Something went wrong'
                    };
                    this.swalService.error(swalData);
                    this.loadingVisible = false;
                }
            );
    }


    onEmailBoxOpen() {
        const selectedRowData = this.dxReportDataGrid.instance.getSelectedRowsData();
        console.log(selectedRowData);
        this.emailFormData.frmTo = selectedRowData.map((item) => {
            return {
                consultantID: item.user_id,
                consultantName: item.name,
                email: item.email,
            };
            // return item.id;
        });
        this.sendEmailPopup = true;
        console.log(this.emailFormData.frmTo);
    }

    onCancel() {
        this.sendMailForm.instance.resetValues();
        this.sendEmailPopup = false;
    }

    addRemoveSignature(e) {
        if (e.value) {
            this.emailFormData.frmEmailSignature = this.currentUser.signature;
            if (this.emailFormData.frmEmailSignature == 'null') {
                this.emailFormData.frmEmailSignature = null;
            }
        } else {
            this.emailFormData.frmEmailSignature = null;
        }
    }

    updateEmailContent(changes): any {
        const placeholderData = changes.selectedItem;

        this.emailFormData.frmEmailContent = this.insertTextAtCursor('emailTextArea', `{{${placeholderData}}}`);
    }

    insertTextAtCursor(areaId, text) {
        const txtarea = <HTMLInputElement>document.querySelector(`#${areaId} textarea`);

        if (!txtarea) {
            return;
        }

        const scrollPos = txtarea.scrollTop;
        let strPos = 0;
        const br = ((txtarea.selectionStart || txtarea.selectionStart == 0) ?
            'ff' : ((document as any).selection ? 'ie' : false));
        if (br == 'ie') {
            txtarea.focus();
            const range = (document as any).selection.createRange();
            range.moveStart('character', -txtarea.value.length);
            strPos = range.text.length;
        } else if (br == 'ff') {
            strPos = txtarea.selectionStart;
        }

        const front = (txtarea.value).substring(0, strPos);
        const back = (txtarea.value).substring(strPos, txtarea.value.length);
        txtarea.value = front + text + back;
        strPos = strPos + text.length;
        if (br == 'ie') {
            txtarea.focus();
            const ieRange = (document as any).selection.createRange();
            ieRange.moveStart('character', -txtarea.value.length);
            ieRange.moveStart('character', strPos);
            ieRange.moveEnd('character', 0);
            ieRange.select();
        } else if (br == 'ff') {
            txtarea.selectionStart = strPos;
            txtarea.selectionEnd = strPos;
            txtarea.focus();
        }

        txtarea.scrollTop = scrollPos;
        return txtarea.value;
    }

    getEmailTemplateContent(event) {
        console.log(event);
        if (event.selectedItem) {
            this.emailFormData.frmEmailContent = event.selectedItem.template_content;
        }
    }

    searchData(e) {
        this.searchString = e.value;
        if (this.searchString.length > 2 || this.searchString.length == 0) {
            this.userDataSource.reload();
        }
    }

    sendMail(e) {

        if (this.emailFormData.frmTo.length <= 0) {
            const swalData = {
                title: 'Email Error',
                message: 'Please select at least one candidate',
                info: ''
            };
            this.swalService.error(swalData);
            return;
        }

        if (this.emailFormData.frmEmailContent) {
            let emailContent = this.emailFormData.frmEmailContent;
            if (this.emailFormData.frmAddSignature) {
                emailContent += `\n\n\n\n${this.emailFormData.frmEmailSignature}`;
            }

            const emailData = {
                to: this.emailFormData.frmTo,
                from: this.currentUser.email,
                cc: this.emailFormData.frmCC,
                subject: this.emailFormData.frmSubject,
                content: emailContent,
                add_signature: this.emailFormData.frmAddSignature,
                user_signature: `\n\n\n\n${this.emailFormData.frmEmailSignature}`,
                source_name: 'user_bulk_email',
                type: 'EMAIL',
                priority: 1,
            };

            this.commonService.scheduleEmails(emailData)
                .subscribe(
                    (result: any) => {
                        this.sendMailForm.instance.resetValues();
                        this.sendEmailPopup = false;

                        const swalData = {
                            title: 'Success',
                            message: 'Emails are scheduled to be sent',
                            info: ''
                        };
                        this.swalService.success(swalData);
                    },
                    (error) => {
                        console.log('error', error);
                    });
        }
    }

    getUserList() {
        this.userDataSource = new DataSource({
            store: new CustomStore({
                key: 'user_id',
                load: (loadOptions: any) => {
                    const params: any = {
                        from: loadOptions.skip || 0,
                        limit: loadOptions.take || 10
                    };

                    if (loadOptions.sort) {
                        params.orderBy = loadOptions.sort[0].selector;
                        if (loadOptions.sort[0].desc) {
                            params.order = 'desc';
                        }
                    }

                    if (this.searchString) {
                        params.search = this.searchString;
                    }
                    this.loadingVisible = true;
                    return this.userService.postUserList(loadOptions)
                        .toPromise()
                        .then((data: any) => {
                            this.loadingVisible = false;
                            this.totalClient = data.data.total_users;
                            return {
                                data: data.data.result,
                                totalCount: data.data.total_users
                            }
                        }).catch(error => {
                            this.loadingVisible = false;
                            throw 'Data Loading Error'
                        });
                }
            })
        });
    }

    searchUsers(e) {
        console.log(e);
    }

    optionClick(event: any, record) {

        switch (event.itemData.id) {
            case "edit" :
                this.router.navigate(['user/edit/' + record.data.user_id]);
                break;
            case "delete" :
                this.deleteUser(record.data);
                break;
            default :
                return false;
        }
    }

    deleteUser(userObj) {
        console.log(userObj);
        const swalData = {
            title: 'Delete User',
            message: `Are you sure you want to delete user: ${userObj.name}?`,
            info: 'Once it is deleted then it cannot be reverted!'
        };
        //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
        this.swalService.delete(swalData).then((res) => {
            if (res.value) {

                this.userService.deleteUser(userObj.user_id).subscribe((result: any) => {

                    if (result['code'] === 200) {
                        this.userDataSource.reload();//remove(userObj.user_id);

                        const swalData = {
                            title: 'User Deleted',
                            message: `User is successfully deleted!`,
                            info: 'Now it can not be reverted!'
                        };
                        this.swalService.success(swalData);
                    }
                });
            }

        });
    }
}
