import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService as AuthGuard} from '../../../services/auth/auth-guard.service';
import {ChatReportComponent} from './chat-report/chat-report.component';
import {
    DxButtonModule, DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxFormModule, DxPopupModule, DxRadioGroupModule,
    DxSelectBoxModule,
    DxTabsModule
} from 'devextreme-angular';
import {PulseSharedModule} from '@pulse/shared.module';
import {AceReportComponent} from './ace-report/ace-report.component';
import {Pulse3Service} from '../../../services/pulse3/pulse3.service';
import {DynamicReportComponent} from './dynamic-report/dynamic-report.component';
import {DxiRowModule} from 'devextreme-angular/ui/nested';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserApiLogsComponent } from './user-api-logs/user-api-logs.component';

const routes: Routes = [
    {
        path: 'chat-report',
        component: ChatReportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/ace',
        component: AceReportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/request-logs',
        loadChildren: () => import('./request-logs/request-log.module').then(m => m.RequestLogModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'log/login',
        loadChildren: () => import('./login-logs/login-logs.module').then(m => m.LoginLogsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/ace/activity-tracker',
        loadChildren: () => import('./ace-activity-tracker/ace-activity-tracker.module').then(m => m.AceActivityTrackerModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/ace/user-api-logs',
        loadChildren: () => import('./user-api-logs/user-api-logs.module').then(m => m.UserApiLogsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/dynamic',
        component: DynamicReportComponent,
        canActivate: [AuthGuard]
    },
];

@NgModule({
    declarations: [ChatReportComponent, AceReportComponent, DynamicReportComponent, UserApiLogsComponent],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        PulseSharedModule,
        DxDataGridModule,
        DxFormModule,
        DxButtonModule,
        DxDateBoxModule,
        DxTabsModule,
        DxSelectBoxModule,
        DxCheckBoxModule,
        DxRadioGroupModule,
        DxiRowModule,
        DxPopupModule, MatTooltipModule
    ],
    exports: [ChatReportComponent],
    providers: [Pulse3Service]
})
export class ReportsModule {
}
