import * as moment from 'moment';

/**
 * Class:-------Audio Helper
 * ClassName:---AudioHelper
 * Created By:--Brijesh Borad.
 * Summary:-----To Handle Audio recording process in simple functions, It will record audio with two channel (left / right)
 */
export class AudioHelper {
    private recorder = null;
    private chunks = [];
    private messageTimer = null;
    private context = null;
    private mediaStream = null;
    private blob = null;
    private stream: MediaStream = null;
    private leftChannel = [];
    private rightChannel = [];
    private recordingLength = 0;
    private callBack = (recordingTime) => {};

    /**
     * If has permission returns stream.
     * DO not pass stopTracks params from external
     * @returns {Promise<unknown>}
     */
    public checkPermission = (stopTracks = true) => new Promise((resolve, reject) => {
        let audioInputExist = false;
        navigator.mediaDevices.enumerateDevices().then(devices => {
            devices.forEach((device) => {
                if (device.kind === 'videoinput' || device.kind === 'audioinput') {
                    audioInputExist = true;
                }
            });
            if (!audioInputExist) {
                reject(new Error('Microphone is not connected with your system'));
                return 0;
            }
            let nav = <any>navigator
            nav.getUserMedia({audio: true}, streams => {
                /** to remove red (audio-icon dot) record-icon from tab **/
                if (stopTracks) {
                    streams.getAudioTracks().forEach((track) => {
                        track.stop();
                    });
                }
                resolve(streams);
            }, fail => {
                console.log(fail);
                reject(new Error('Microphone access denied.'));
            });
        }).catch(e => {
            console.log(e);
            reject(new Error('Something went wrong while connecting microphone'));
        });
    })

    /**
     * Start recording, returns boolean
     * @returns {Promise<unknown>}
     */
    public startRecording = () => new Promise((resolve, reject) => {
        this.resetEverything();
        this.checkPermission(false).then((stream: MediaStream) => {
            this.stream = stream;
            try {
                this.chunks = [];
                // window.AudioContext = window.AudioContext || window.webkitAudioContext;
                this.context = new AudioContext();
                this.mediaStream = this.context.createMediaStreamSource(stream);

                // bufferSize: the onaudioprocess event is called when the buffer is full
                const bufferSize = 2048;
                const numberOfInputChannels = 2;
                const numberOfOutputChannels = 2;

                if (this.context.createScriptProcessor) {
                    this.recorder = this.context.createScriptProcessor(bufferSize, numberOfInputChannels, numberOfOutputChannels);
                } else {
                    this.recorder = this.context.createJavaScriptNode(bufferSize, numberOfInputChannels, numberOfOutputChannels);
                }

                this.recorder.onaudioprocess = (data) => {
                    this.leftChannel.push(new Float32Array(data.inputBuffer.getChannelData(0)));
                    this.rightChannel.push(new Float32Array(data.inputBuffer.getChannelData(1)));
                    this.recordingLength += bufferSize;
                };

                this.mediaStream.connect(this.recorder);
                this.recorder.connect(this.context.destination);
                this.startTimer();
                resolve({started: true});

            } catch (e) {
                console.log(e);
                /** to remove red (audio-icon dot) record-icon from tab **/
                if (stream && stream.getAudioTracks()) {
                    stream.getAudioTracks().forEach((track) => {
                        track.stop();
                    });
                }
                reject(new Error('Internal media access error.'));
            }
        }).catch(err => {
            reject(err);
        });
    })

    /**
     * Stop recording, returns (recorded)blob data
     * @returns {Promise<unknown>}
     */
    public stopRecording = () => new Promise((resolve, reject) => {
        try {
            this.recorder.disconnect(this.context.destination);
            this.mediaStream.disconnect(this.recorder);

            const leftBuffer = this.flattenArray(this.leftChannel, this.recordingLength);
            const rightBuffer = this.flattenArray(this.rightChannel, this.recordingLength);

            const interleaved = this.interleave(leftBuffer, rightBuffer);

            const buffer = new ArrayBuffer(44 + interleaved.length * 2);
            const view = new DataView(buffer);

            this.writeUTFBytes(view, 0, 'RIFF');
            view.setUint32(4, 44 + interleaved.length * 2, true);
            this.writeUTFBytes(view, 8, 'WAVE');
            // FMT sub-chunk
            this.writeUTFBytes(view, 12, 'fmt ');
            view.setUint32(16, 16, true); // chunkSize
            view.setUint16(20, 1, true); // wFormatTag
            view.setUint16(22, 2, true); // wChannels: stereo (2 channels)
            view.setUint32(24, 44100, true); // dwSamplesPerSec
            view.setUint32(28, 44100 * 4, true); // dwAvgBytesPerSec
            view.setUint16(32, 4, true); // wBlockAlign
            view.setUint16(34, 16, true); // wBitsPerSample
            // data sub-chunk
            this.writeUTFBytes(view, 36, 'data');
            view.setUint32(40, interleaved.length * 2, true);

            let index = 44;
            const volume = 1;
            for (let i = 0; i < interleaved.length; i++) {
                view.setInt16(index, interleaved[i] * (0x7FFF * volume), true);
                index += 2;
            }

            this.blob = new Blob([view], {type: 'audio/mpeg'});

            this.leftChannel = [];
            this.rightChannel = [];
            this.recordingLength = 0;

            if (this.stream) {
                this.stream.getAudioTracks().forEach((track) => {
                    track.stop();
                });
                this.stream = null;
            }
            this.stopTimer();
            resolve(this.blob);
        } catch (e) {
            this.stopTimer();
            console.log(e);
            reject(new Error('Error While storing recorded data'));
        }
    })

    /**
     * Callback every second on recording, return duration
     * @param callBack function
     */
    public onRecording = (callBack) => {
        this.callBack = callBack;
    }

    /**
     * Create object url from blob
     * @param blob
     * @returns {string}
     */
    public getUrlFromBlob = (blob) => {
        return URL.createObjectURL(blob);
    }

    /**
     * Create MP3 file from blob
     * @param blob
     * @returns {File}
     */
    public getMP3FileFromBlob = (blob) => {
        return (new File([blob], (moment().format('YYYYMMDDHHmmss') + '.mp3'), {lastModified: (new Date()).getMilliseconds()}));
    }

    /**
     * Reset Everything
     * For Internal Use.
     * Do not call from external
     */
    private resetEverything = () => {
        this.recorder = null;
        this.chunks = [];
        this.messageTimer = null;
        this.context = null;
        this.mediaStream = null;
        this.blob = null;
        this.stream = null;
        this.leftChannel = [];
        this.rightChannel = [];
        this.recordingLength = 0;
        this.callBack = (recordingTime) => {};
    }

    /**
     * startTimer
     * For Internal Use.
     * Do not call from external
     */
    private startTimer = () => {
        const st = new Date();
        let displayedElapsedTime = '00:00';
        const prefix = 'Recording ';
        this.callBack({displayedElapsedTime});
        this.messageTimer = setInterval(() => {
            const ct = new Date();
            let timeDiff = ((ct.getTime() - st.getTime()) / 1000);

            const seconds = Math.floor(timeDiff % 60);
            const secondString = seconds < 10 ? '0' + seconds : seconds;

            timeDiff = Math.floor(timeDiff / 60);

            const minutes = timeDiff % 60;
            const minuteString = minutes < 10 ? '0' + minutes : minutes;

            timeDiff = Math.floor(timeDiff / 60);
            const hours = timeDiff % 24;
            timeDiff = Math.floor(timeDiff / 24);
            const days = timeDiff;
            const totalHours = hours + (days * 24);
            const hourString = totalHours < 10 ? '0' + totalHours : totalHours;

            if (hourString === '00') {
                displayedElapsedTime = minuteString + ':' + secondString;
            } else {
                displayedElapsedTime = hourString + ':' + minuteString + ':' + secondString;
            }
            this.callBack({displayedElapsedTime});
        }, 1000);
    }

    /**
     * stopTimer
     * For Internal Use.
     * Do not call from external
     */
    private stopTimer = () => {
        if (this.messageTimer) {
            clearInterval(this.messageTimer);
        }
    }

    /**
     * flattenArray
     * For Internal Use.
     * Do not call from external
     */
    private flattenArray = (channelBuffer, recordingLength) => {
        const result = new Float32Array(recordingLength);
        let offset = 0;
        for (let i = 0; i < channelBuffer.length; i++) {
            const buffer = channelBuffer[i];
            result.set(buffer, offset);
            offset += buffer.length;
        }
        return result;
    }

    /**
     * interleave
     * For Internal Use.
     * Do not call from external
     */
    private interleave = (leftChannel, rightChannel) => {
        const length = leftChannel.length + rightChannel.length;
        const result = new Float32Array(length);

        let inputIndex = 0;

        for (let index = 0; index < length;) {
            result[index++] = leftChannel[inputIndex];
            result[index++] = rightChannel[inputIndex];
            inputIndex++;
        }
        return result;
    }

    /**
     * writeUTFBytes
     * For Internal Use.
     * Do not call from external
     */
    private writeUTFBytes = (view, offset, string) => {
        for (let i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    }
}
