import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { PulseSharedModule } from '@pulse/shared.module';

import {
  DxTemplateModule,
  DxTextBoxModule,
  DxSelectBoxModule,
  DxFormModule,
  DxButtonModule,
  DxPopupModule,
  DxHtmlEditorModule,
  DxDataGridModule,
  DxValidatorModule
} from 'devextreme-angular';

import { GithubEmailComponent } from './github-email.component';

import { JobPortalService } from 'app/services/job-portal/job_portal.service';

const routes: Routes = [{
  path: 'github/email',
  component: GithubEmailComponent
}];

@NgModule({
  declarations: [
    GithubEmailComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    BrowserModule,
    PulseSharedModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxFormModule,
    DxButtonModule,
    DxPopupModule,
    DxHtmlEditorModule,
    DxDataGridModule,
    DxValidatorModule
  ],
  exports: [
    GithubEmailComponent
  ]
})

export class GithubEmailModule {}
