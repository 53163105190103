import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import {PiplService} from 'app/services/pipl/pipl.service';
import {CommonService} from 'app/services/common/common.service';
import {SwalService} from '../../../services/swal.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DxFormComponent} from 'devextreme-angular';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-pipl',
    templateUrl: './pipl.component.html',
    styleUrls: ['./pipl.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PIPLComponent implements OnInit, OnDestroy {
    @ViewChild(DxFormComponent) searchForm: DxFormComponent;
    private unsubscribe$: Subject<void> = new Subject<void>();

    profileTabs: any;
    formData: any = {};

    socialIcon = {
        'linkedin.com': 'fa-linkedin',
        'facebook.com': 'fa-facebook',
        'foursquare.com': 'fa-foursquare',
        'pinterest.com': 'fa-pinterest',
        'twitter.com': 'fa-twitter',
        'flickr.com': 'fa-flickr',
        'amazon.com': 'fa-amazon',
        'plus.google.com': 'fa-google-plus',
        'vimeo.com': 'fa-vimeo',
        'github.com': 'fa-github',
        'slideshare.net': 'fa-slideshare',
    };

    sources: any = [];

    filters = {
        sourceId: 0,
        planId: 0
    };


    plans: string[] = [
        'Contact',
        'Business',
        'Social'

    ];

    singlePerson = null;
    source = null;
    iframeUrl: SafeResourceUrl;
    twitterUrl: any;

    personData: any = null;
    allPersonData: any = null;

    loadingVisible = false;
    linkedinOnly = false;
    lusaFields = false;

    constructor(private piplService: PiplService,
                private commonService: CommonService,
                private swalService: SwalService,
                private sanitizer: DomSanitizer
    ) {
        //this.getAllSources();
        this.setDefaults();
        this.onTabInitialized = this.onTabInitialized.bind(this);
        this.setBoxOptions = this.setBoxOptions.bind(this);

        // this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.linkedin.com/in/tajhaslani');
    }

    ngOnInit() {
        this.commonService.masterData
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
            if (!data) {
                return;
            }
            if (data.contactSources) {
                for (let i = 0; i < data.contactSources.length; i++) {
                    this.sources.push({
                        id: Number(data.contactSources[i].value),
                        name: data.contactSources[i].master_lookup_name,
                        selected: false,
                        disabled: (Number(data.contactSources[i].value) != 350000)
                    });
                }
            }

        });

    }


    setDefaults() {
        this.formData = {
            source_type: [350000],
            // source_type: 350000,
            plan: 'Business',
            is_premium: true,
            email: null,
            first_name: null,
            last_name: null,
            middle_name: null,
            location: null,
            linkedin_url: null,
            company: null,
        };
    }

    ResetData() {
        this.setDefaults();
        this.singlePerson = null;
        this.personData = null;
    }

    getAllSources() {
        this.commonService.getMasterData('contactSources').subscribe(response => {
            this.sources = [];
            const result = response['data'];
            for (let i = 0; i < result.length; i++) {
                this.sources.push({
                    id: Number(result[i].value),
                    name: result[i].master_lookup_name,
                    selected: false,
                    disabled: (Number(result[i].value) != 350000)
                });
            }
        });

    }

    onFormSubmit(e) {
        // e.preventDefault();
        this.personData = [];
        this.allPersonData = [];
        this.source = null;
        this.singlePerson = null;

        if (this.formData.source_type.length == 0) {
            const swalData = {
                title: 'People Search',
                message: `Please select a source type.`,
                info: ' '
            };
            this.swalService.error(swalData);
            return;
        }

        this.sources.map((source) => {
            source.selected = false;
            source.disabled = false;

            // IF LINKEDINURL PRESENT THEN ONLY SIGNALHIRE & CONTACTOUT ENABLE
            if (!this.formData.linkedin_url && (source.id == 350004 || source.id == 350006)) {
                source.disabled = true;
            }

            // for clearbit and fullcontact email is required field
            if (!this.formData.email && (source.id == 350002 || source.id == 350003)) {
                source.disabled = true;
            }
        });

        // for clearbit and fullcontact email is required field
        if (!this.formData.email && (this.formData.source_type.indexOf(350002) > -1 || this.formData.source_type.indexOf(350003) > -1)) {
            const swalData = {
                title: 'People Search',
                message: `Email is required`,
                info: 'For Clearbit and Fullcontact email is required field'
            };
            this.swalService.error(swalData);
            return;
        }

        if (this.formData.email == null &&
            this.formData.first_name == null &&
            this.formData.last_name == null &&
            this.formData.middle_name == null &&
            this.formData.linkedin_url == null &&
            this.formData.location == null) {
            const swalData = {
                title: 'Error',
                message: `Please insert some data for search people.`,
                info: ''
            };
            this.swalService.error(swalData);
            return;
        }

        this.loadingVisible = true;

        let ctr = 0;
        const successVar = [];
        const successSource = [];
        (this.formData.source_type).forEach((element) => {
            this.sources.map((source) => {
                if (source.id == element) {
                    source.selected = true;
                }
            });

            ctr++;
            const searchData = {
                source_type: element,
                // source_type: 350000,
                plan: 'Business',
                is_premium: true,
                email: this.formData.email,
                first_name: this.formData.first_name,
                last_name: this.formData.last_name,
                middle_name: this.formData.middle_name,
                location: this.formData.location,
                linkedin_url: this.formData.linkedin_url,
                company: this.formData.company,
            };

            this.piplService.searchContact(searchData)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(response => {
                    if (response['code'] == 200) {
                        successVar.push(true);
                        successSource.push(element);


                        if (response['data'].persons) {
                            response['data'].persons.map(item => {
                                item.source = response['data'].source;
                                // return (item.phone || item.email);
                                if (item.phone || item.email) {

                                    this.personData.push(item);
                                }
                            });
                        } else if (response['message'] == 'Profile Found') {
                            this.personData.push(response['data']);
                        }

                        this.source = (response['data'].source) ? response['data'].source : 'aptask';

                        this.sources.map((source) => {
                            if (source.id == element) {
                                source.selected = true;
                            }
                        });

                        if (ctr === (this.formData.source_type).length && this.personData.length > 9) {
                            const swalData = {
                                title: 'Info',
                                message: `Too many records found`,
                                info: 'Please add additional parameters like company name to refine the results'
                            };
                            this.swalService.info(swalData);
                        }

                    }
                },
                error => {
                    this.loadingVisible = false;
                    successVar.push(false);
                    let swalData = {
                        title: 'People search',
                        message: error.error.status || `Some problem in fetching people data.`,
                        info: error.error.data || 'Please try again later.'
                    };

                    if (error.status == 404) {
                        swalData = {
                            title: 'People search',
                            message: `Profile not found.`,
                            info: 'Please try again later.'
                        };
                    } else if (error.status == 422) {
                        swalData = {
                            title: 'People search',
                            message: `Missing required fields.`,
                            info: error.error.message || 'Please enter Name and company.'
                        };
                    } else if (error.status == 400) {
                        swalData = {
                            title: error.error.status || 'error',
                            message: error.error.status || `Profile Not found`,
                            info: ''
                        };
                    }

                    if (ctr === (this.formData.source_type).length && successVar.length === ctr && successVar.indexOf(true) < 0) {
                        this.swalService.error(swalData);
                    }
                }, () => {
                    if (ctr === (this.formData.source_type).length) {
                        this.loadingVisible = false;
                    }
                });
        });
    }

    showProfileDetail(person) {
        this.singlePerson = null;
        this.twitterUrl = null;
        this.singlePerson = person;
        if (this.profileTabs) {
            this.profileTabs.option('selectedIndex', 0);
        }
        if (person.social_profiles.length > 0) {

            (person.social_profiles).forEach(singleProfile => {
                if (singleProfile['@domain'] == 'twitter.com') {
                    this.twitterUrl = singleProfile.url;
                    return;
                }
            });
        }
    }

    onTabInitialized(e) {
        this.profileTabs = e.component;
    }

    setBoxOptions(e) {
        if (e.value && e.value.length > 0 && e.value.indexOf(350000) > -1) {
            this.formData.source_type = [350000];
            this.sources.forEach((item) => {
                item.disabled = (item.id != 350000);
            });
        } else {
            this.sources.forEach((item) => {
                item.disabled = false;
            });
        }

        this.linkedinOnly = e.value && e.value.length > 0 && (e.value.indexOf(350004) > -1 || e.value.indexOf(350006) > -1);
        this.lusaFields = e.value && e.value.length > 0 && (e.value.indexOf(350007) > -1);

    }

    sourceCheckChange(e, sourceId) {
        if (e.value === true && this.formData.source_type.indexOf(sourceId) < 0) {
            this.formData.source_type.push(sourceId);
        }

        if (e.value === false && this.formData.source_type.indexOf(sourceId) > -1) {
            delete this.formData.source_type[this.formData.source_type.indexOf(sourceId)];
        }
        this.formData.source_type = this.formData.source_type.filter(Boolean);
        this.linkedinOnly = e.value && e.value === true && (sourceId == 350004 || sourceId == 350006);
        this.lusaFields = e.value && e.value === true && (sourceId == 350007);
        this.searchForm.instance.repaint();
    }

    searchPeople() {
        this.onFormSubmit('');
    }

    addToJobDiva(person) {
        this.commonService.showLoadPanel.next(true);
        this.piplService.addToJobdiva(person)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
            this.commonService.showLoadPanel.next(false);
            if (response['message'] == 'Success') {
                const swalData = {
                    title: 'Job Diva',
                    message: `${person.first_name} is added successfully to Jobdiva.`,
                    info: ''
                };
                this.swalService.success(swalData);
            } else {
                const swalData = {
                    title: 'Job Diva',
                    message: `Some problem in adding ${person.first_name} to Jobdiva.`,
                    info: response['message']
                };
                this.swalService.error(swalData);
            }
        }, (error) => {
            this.commonService.showLoadPanel.next(false);
            console.error(error);
            const swalData = {
                title: 'Job Diva',
                message: `Some problem in adding ${person.first_name} to Jobdiva.`,
                info: ''
            };
            this.swalService.error(swalData);
        }, () => {
            this.commonService.showLoadPanel.next(false);
        });
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this.unsubscribe$.next();
        this.unsubscribe$.complete();

    }

}
