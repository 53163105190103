<mat-toolbar>

    <!-- <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <a href="http://themeforest.net/item/fuse-angularjs-material-design-admin-template/12931855?ref=srcn"
           target="_blank" mat-button class="mat-pink-bg" fxFlex="0 0 auto" fxLayout="row"
           fxLayoutAlign="start center">
            <mat-icon class="s-16 mr-sm-4">shopping_cart</mat-icon>
            <span>Purchase FUSE (Angular5+)</span>
        </a>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button href="http://fusetheme.com/angular/docs" target="_blank">Documentation</a>
            <span>&bull;</span>
            <a mat-button href="http://fusetheme.com/angular/changelog" target="_blank">Changelog</a>
        </div>

    </div> -->
    <div class="container">
      <div fxLayout="row" fxHide fxShow.gt-xs>
        <div class="text-left" fxFlex="33"> ©{{currentYear}} Aptask, All Rights Reserved.</div>
        <div class="text-center" fxFlex="34"><span  style="color: blue;">Build : </span>{{buildNumber}}</div>
        <div class="text-right" fxFlex="15"> Version: {{versionNumber}}</div>
        <div class="text-right" fxFlex="15"> Build-Date: {{buildDate}}</div>
      </div>
    </div>


</mat-toolbar>
