<div class="page-layout blank p-24" pulsePerfectScrollbar>
<div  id="forms" class="page-layout simple fullwidth" fxLayout="column">
    <div class="container mt-12">
        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row" fxFlex="1 0 auto">
                    <div class="pulse-main-heading" fxFlex="92">
                        Scraper Accounts
                    </div>
                    <div fxFlex="8" fxLayoutAlign="end">

                        <!-- <div class="dx-field-value" style="width: 45px; float: right;">
                            <dx-button icon="find" style="height: 33px;widows: 42px; background-color: white; border-color: #ddd;"
                                      >
                            </dx-button>
                        </div> -->

                        <div class="dx-field-value">
                            <dx-button icon="plus" class="pulse-btn pulse-btn-primary has-icon" (click)="navigateToAdd()" style="height: 33px;widows: 42px;">
                            </dx-button>
                        </div>
                    </div>
                </div>

                <div class="p-30 pt-15 pb-15 grid-wrapper">

                    <dx-data-grid (onRowClick)="onRowClickData($event.data)"
                        [dataSource]="dataSource"
                        [showRowLines]="true"
                        [showColumnLines]="false"
                        [showBorders]="true"
                        [allowColumnResizing]="true">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager
                            [showPageSizeSelector]="true"
                            [allowedPageSizes]="[10, 30, 50, 100]"
                            [showInfo]="true">
                        </dxo-pager>
                        <dxi-column dataField="name" dataType="string" caption="Account"></dxi-column>
                        <dxi-column dataField="username" dataType="string"></dxi-column>
                        <dxi-column dataField="password" dataType="string"></dxi-column>
                        <dxi-column dataField="inUse" dataType="boolean" caption="In Use"></dxi-column>
                        <dxi-column [width]="80" cellTemplate="cellTemplate" caption="Actions"></dxi-column>

                        <div class="grid-menu" *dxTemplate="let cell of 'cellTemplate'">
                            <div >
                                <dx-menu style="padding: 0px 0px 0px !important;" [dataSource]="menuItems"
                                         displayExpr="name"
                                         (onItemClick)="optionClick($event, cell)"></dx-menu>
                            </div>
                        </div>
                    </dx-data-grid>

                </div>

            </div>
        </div>

    </div>

</div>
