<div class="page-layout simple fullwidth" pulsePerfectScrollbar>

    <!-- container -->
    <div class="container mt-12">

        <div class="content mat-white-bg mat-elevation-z4 mb-24" fxLayout="column">
            <div class="content-wrapper">
                <div class="page-title-wrapper" fxLayout="row wrap" fxFlex="1 0 auto">
                    <div class="pulse-main-heading" fxFlex="50">
                       Dashboard
                    </div>
                </div>

                <div class="p-30 pt-15 pb-15 grid-wrapper">
                    <!-- Refer the below link for datagrid of devextreme -->
                    <!--https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/-->
                   <h2 class="text-center"> Coming Soon</h2>
                </div>
            </div>
        </div>

    </div>
    <!-- container -->

</div>
