import {UsersService} from '../users/users.service';
import {Injectable} from '@angular/core';
import {Route, Router} from "@angular/router";
import {LoginService} from "../login.service";

@Injectable()
export class AppConfig {

    constructor(private usersService: UsersService, private loginService: LoginService) {}

    Init() {
        return new Promise<void>((resolve, reject) => {
            if(!this.loginService.isAuthenticated()) {
                resolve();
                return;
            }
            this.usersService.getUsersPermissions().subscribe((result: any) => {
                this.usersService.userPermissionsRetrieved$.next(result.data);
                resolve();
            })
        })
    }
}
