import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {UsersService} from '../../../../services/users/users.service';
import {CommonService} from '../../../../services/common/common.service';
import {DxFormComponent} from 'devextreme-angular';
import {SwalService} from '../../../../services/swal.service';

@Component({
    selector: 'app-team-list',
    templateUrl: './team-list.component.html',
    styleUrls: ['./team-list.component.scss']
})
export class TeamListComponent implements OnInit {

    public teamDataSource: DataSource;
    @ViewChild('teamPopupForm') teamPopupForm: DxFormComponent;
    loadingVisible = false;
    showFilter = false;
    selectedCandidates: any[] = [];
    searchString: string = null;
    totalTeams = 0;
    menuItems = [
        {
            icon: 'preferences',
            items: [
                {name: 'Edit', icon: 'edit', id: 'edit'},
                // {name: 'Delete', icon: 'clear', id: 'delete'},
            ]
        }
    ];
    addPopup = false;
    frmTeamData: any = {};
    userList: any[];

    constructor(
        private router: Router,
        private usersService: UsersService,
        private commonService: CommonService,
        private swalService: SwalService,
    ) {
        this.getTeamList();
        this.getAllUser();
    }

    ngOnInit(): void {
    }

    getTeamList() {
        this.teamDataSource = new DataSource({
            store: new CustomStore({
                key: 'id',
                load: (loadOptions: any) => {
                    return this.usersService.getTeamList()
                        .toPromise()
                        .then((data: any) => {
                            this.totalTeams = data.data.length;
                            return {
                                data: data.data,
                                totalCount: data.data.length
                            }
                        }).catch(error => {
                            if (error.error.error == 'jwt expired') {
                                this.router.navigate(['home/login']);
                            }
                            throw new Error('Data Loading Error')
                        });
                }
            })
        });
    }

    getAllUser() {
        this.commonService.getAllUsers()
            .subscribe((data: any) => {
                const result = data.data.result;
                this.userList = [];
                result.map(item => {
                    this.userList.push({
                        ID: item.user_id,
                        Name: item.first_name + ' ' + item.last_name
                    });
                });
            });
    }

    openAddModel() {
        this.addPopup = true;
        this.teamPopupForm.instance.resetValues();
    }

    optionClick(event: any, record) {
        switch (event.itemData.id) {
            case 'edit' :
                this.frmTeamData.frmTeamId = record.data.id
                this.frmTeamData.frmName = record.data.name
                this.addPopup = true;
                break;
            case 'delete' :
                // this.deleteTeam(record.data);
                break;
            default :
                return false;
        }
    }

    deleteTeam(userObj) {
        const swalData = {
            title: 'Delete User',
            message: `Are you sure you want to delete team: ${userObj.name}?`,
            info: 'Once it is deleted then it cannot be reverted!'
        };
        this.swalService.delete(swalData).then((res) => {
            if (res.value) {
                this.usersService.deleteUser(userObj.id).subscribe((result: any) => {
                    if (result['code'] === 200) {
                        const swalData1 = {
                            title: 'Team Deleted',
                            message: `Team is successfully deleted!`,
                            info: 'Now it can not be reverted!'
                        };
                        this.swalService.success(swalData1);
                    }
                });
            }

        });
    }

    onFormSubmit() {
        this.loadingVisible = true;
        this.addPopup = false;
        const dataToSend = {
            name: this.frmTeamData.frmName,
            team_lead_id: this.frmTeamData.frmLeadId || null
        }
        if (this.frmTeamData.frmTeamId) {
            this.usersService.editTeam(this.frmTeamData.frmTeamId, dataToSend).subscribe(
                (result: any) => {
                    this.loadingVisible = false;
                })
        } else {
            this.usersService.addTeam(dataToSend).subscribe(
                (result: any) => {
                    this.loadingVisible = false;
                })
        }
        this.getTeamList();
    }
}
