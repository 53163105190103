<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
    <div class="container mt-12">
        <!-- CONTENT -->
        <div class="mat-white-bg" fxLayout="column">
            <div class="content-wrapper" fxLayout="column">
                <div class="pb-15" id="addUserForm">
                    <form (submit)="onFormSubmit($event)" class="add-user-form">
                        <div id="form-container" class="mb-20">
                            <dx-form id="form"
                                     [formData]="frmUserCommissionData"
                                     [readOnly]="false"
                                     [showColonAfterLabel]="true"
                                     [showValidationSummary]="true"
                                     [colCount]="12"
                                     labelLocation="top"
                                     validationGroup="userCommData">

                                <dxi-item [colCount]="3" [colSpan]="12" itemType="group">

                                    <dxi-item [colCount]="2" itemType="group">
                                        <dxi-item dataField="frmQbUser"
                                                  editorType="dxSelectBox"
                                                  [editorOptions]="{ items: qbUsers,
                                                    valueExpr: 'user_id',
                                                    displayExpr: 'name',
                                                    onSelectionChanged: populateQbData,
                                                    searchEnabled: true }">
                                            <dxo-label text="QB Users"></dxo-label>
                                        </dxi-item>

                                        <dxi-item>
                                            <dxo-label text="Create QB User"></dxo-label>
                                            <dx-check-box
                                                [value]="frmUserCommissionData.frmNewQbUser"
                                                (onValueChanged)="createQbUserToggle($event)"
                                                class="pt-8"
                                            ></dx-check-box>
                                        </dxi-item>
                                    </dxi-item>

                                    <dxi-item dataField="frmFirstName" [editorOptions]="{disabled: true}">
                                        <dxo-label text="First Name"></dxo-label>
                                    </dxi-item>

                                    <!-- ==================================================================== -->
                                    <dxi-item dataField="frmLastName" [editorOptions]="{disabled: true}">
                                        <dxo-label text="Last Name"></dxo-label>
                                        <dxi-validation-rule
                                            type="required"
                                            message="Last Name is required">
                                        </dxi-validation-rule>
                                    </dxi-item>

                                    <dxi-item dataField="frmUserName" [editorOptions]="{disabled: true}">
                                        <dxo-label text="User Name"></dxo-label>
                                        <dxi-validation-rule
                                            type="required"
                                            message="User Name is required">
                                        </dxi-validation-rule>
                                    </dxi-item>

                                    <dxi-item dataField="frmUserType"
                                              editorType="dxSelectBox"
                                              [editorOptions]="{ items: userType,
                                                    valueExpr: 'value',
                                                    displayExpr: 'name',
                                                    searchEnabled: true }">
                                        <dxo-label text="User Type"></dxo-label>
                                        <dxi-validation-rule
                                            type="required"
                                            message="User Type is required">
                                        </dxi-validation-rule>
                                    </dxi-item>


                                    <dxi-item dataField="frmUserStatus"
                                                  editorType="dxSelectBox"
                                                  [editorOptions]="{ items: statusList, disabled: true, 
                                                    valueExpr: 'id',
                                                    displayExpr: 'name',
                                                    searchEnabled: true }">
                                                    <dxo-label text="Status"></dxo-label>
                                        </dxi-item>

                                    <!-- <dxi-item [editorOptions]="{disabled: true}">
                                        <dx-select-box
                                            [disabled]="true"
                                            [displayExpr]="'name'"
                                            [valueExpr]="'id'"
                                            [value]="frmUserCommissionData.frmUserStatus"
                                            [dataSource]="statusList">
                                        </dx-select-box>
                                        <dxo-label text="Status"></dxo-label>
                                    </dxi-item> -->
                                    
                                    <dxi-item dataField="frmPayType" [visible]="false"
                                              editorType="dxSelectBox"
                                              [editorOptions]="{ items: payType,
                                                    valueExpr: 'value',
                                                    displayExpr: 'name',
                                                    searchEnabled: true }">
                                        <dxo-label text="Pay Type"></dxo-label>
                                        <dxi-validation-rule
                                            type="required"
                                            message="Pay Type is required">
                                        </dxi-validation-rule>
                                    </dxi-item>

                                    <!-- <dxi-item dataField="frmCommissionRole"
                                              editorType="dxTagBox"
                                              [editorOptions]="{
                                                items:  commissionBasedRole,
                                                valueExpr: 'role_id',
                                                displayExpr: 'name',
                                                searchEnabled: true
                                                 }">
                                        <dxo-label text="Commission Role"></dxo-label>
                                        <dxi-validation-rule
                                            type="required"
                                            message="Role is required">
                                        </dxi-validation-rule>
                                    </dxi-item> -->

                                    

                                    <dxi-item [editorOptions]="{disabled: true}">
                                        
                                    </dxi-item>

                                    <dxi-item [editorOptions]="{disabled: true}">
                                        
                                    </dxi-item>
 
                                </dxi-item>


                                <dxi-item [colCount]="3" [colSpan]="12" itemType="group" name="cmrPlans">

                                        <dxi-item>
                                            <h3 class="sysAd" style="font-weight: 300;font-size: 16px;padding: 0;margin-right: 40px;opacity: 1;min-width: auto;font-family: 'Archivo', 'Helvetica Neue', 'Arial', sans-serif;">Role</h3>
                                        </dxi-item>

                                        <dxi-item>
                                            <h3 class="sysAd" style="font-weight: 300;font-size: 16px;padding: 0;margin-right: 40px;opacity: 1;min-width: auto;font-family: 'Archivo', 'Helvetica Neue', 'Arial', sans-serif;">Plan</h3>
                                        </dxi-item>

                                        <dxi-item>
                                            <h3 class="sysAd" style="font-weight: 300;font-size: 16px;padding: 0;margin-right: 40px;opacity: 1;min-width: auto;font-family: 'Archivo', 'Helvetica Neue', 'Arial', sans-serif;">PayType</h3>
                                        </dxi-item>

                                 </dxi-item>

                                 <dxi-item itemType="group" [colCount]="1" [colSpan]="12">
                                    <dxi-item name="cmrPlans" [colCount]="3" [colSpan]="12" itemType="group" *ngFor="let CommissionRoleObject of CommissionRoleObjects; let i = index">

                                        <dxi-item>
                                            <h3 style="font-weight: 300;font-size: 16px;padding: 0;margin-right: 40px;opacity: 1;min-width: auto;font-family: 'Archivo', 'Helvetica Neue', 'Arial', sans-serif;">{{CommissionRoleObject.name}}</h3>
                                        </dxi-item>
    
                                        <dxi-item dataField="frmCRP{{i}}"
                                                  editorType="dxSelectBox"
                                                  [editorOptions]="{ items: QBPlansData,
                                                    valueExpr: 'id',
                                                    displayExpr: 'plan_name',
                                                    searchEnabled: true }">
                                            <dxo-label [visible]="false" text="Pay Type is required"></dxo-label>
                                        </dxi-item>
      
                                        <dxi-item dataField="frmPayType{{i}}"
                                                  editorType="dxSelectBox"
                                                  [editorOptions]="{ items: payType,
                                                    valueExpr: 'value',
                                                    displayExpr: 'name',
                                                    
                                                    searchEnabled: true }">
                                            <dxo-label [visible]="false" text="QB Users"></dxo-label>
                                        </dxi-item>
    
                                     </dxi-item>
                                 </dxi-item  >

                                 

                                     
                               
                            </dx-form>
                        </div>
  





                        <div class="actions-buttons" fxLayout="row" fxLayoutAlign="space-between center">
                            <dx-button
                                text="Cancel"
                                class="pulse-btn pulse-btn-normal"
                                (onClick)="cancel()"
                            >
                            </dx-button>
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
                                <dx-button text="Save"
                                           validationGroup="userCommData"
                                           class="pulse-btn pulse-btn-primary"
                                           [useSubmitBehavior]="true">
                                </dx-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<p></p>
